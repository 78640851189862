import React, { useEffect } from "react";
import { Image as Images } from "./Images";
import PeopleIcon from '@mui/icons-material/People';
import {Link} from "react-router-dom";
import {reverse} from "named-urls";
import {routes} from "../controller/routes";
import {methods} from "../controller/Global";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import get from 'lodash/get';


export const PersonalReferenceDashboard = (props) => {
    const {
      btnVisible,
      transaction,
      personalReference,
    } = props;
  const { t, i18n } = useTranslation();


    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row flex-align-center-between px-3">
            <div className="col-md-11">
              <div className="profile-card flex-align-center mb-3">
                <div className={"icon-circle-transparent"}>
                  <img
                      alt="add icon"
                      className="img-fluid"
                      src={Images.personalInformationIcon}
                  />
                </div>
                <div className="profile-info">
                  <span className="license-text text-uppercase">{t('personalReferenceInformation.title')}</span>
                </div>
              </div>
            </div>
            <div className={`col-md-1 mb-2 ${btnVisible && !personalReference ? 'align-tem-right-right' : ''}`}>
              {btnVisible ? (
                  personalReference ? (
                      <Link
                          to={reverse(routes.dashboard.transaction.personal_reference_info, {
                            trans_id: transaction,
                            method: methods.edit,
                            id: personalReference.id,
                          })}
                          className="bg-transparent border-0 shadow-none p-0"
                      >
                        <Button className={"btn-update"}>{t('utils.update')}</Button>
                      </Link>
                  ) : (
                      <Link
                          to={reverse(routes.dashboard.transaction.personal_reference_info, {
                            trans_id: transaction,
                            method: methods.add,
                          })}
                          className="bg-transparent border-0 shadow-none p-0"
                      >
                        <Button className={"btn-add"}>{t('utils.add')}</Button>
                      </Link>
                  )
              ) : (
                  ""
              )}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="col-12">
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('personalReferenceInformation.name')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  { personalReference ? `
                  ${get(personalReference, 'reference_first_name', '-') ? get(personalReference, 'reference_first_name', '-') : '-' }
                  ${get(personalReference, 'reference_last_name', '-') ? get(personalReference, 'reference_last_name', '-') : '-'}
                  ` : '-' }
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('personalReferenceInformation.relationship')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  { personalReference ? `
                  ${get(personalReference, 'reference_relation', '-') ? get(personalReference, 'reference_relation', '-') : '-' }
                  ` : '-' }
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('personalReferenceInformation.address')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  { personalReference ? `
                  ${get(personalReference, 'address', '-') ? get(personalReference, 'address', '-') : '-' }
                  ${get(personalReference, 'suite', '-') ? get(personalReference, 'suite', '-') : '-' }
                  ${get(personalReference, 'city', '-') ? get(personalReference, 'city', '-') : '-' }
                  ${get(personalReference, 'state', '-') ? get(personalReference, 'state', '-') : '-' }
                  ${get(personalReference, 'zip_code', '-') ? get(personalReference, 'zip_code', '-') : '-' }
                  ` : '-' }
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('personalReferenceInformation.phone')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  { personalReference ? `
                  ${get(personalReference, 'reference_phone', '-') ? get(personalReference, 'reference_phone', '-') : '-' }
                  ` : '-' }
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
}


