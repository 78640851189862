import React, { Component } from "react";
import { Image as Images } from "./Images";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';


class StaffDashboard extends Component {
  render() {
    const { sales_manager, salesperson, fi_manager, t } = this.props;
    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row flex-align-center-between px-4">
            <div className="profile-card flex-align-center mb-3">
              <div className={"icon-circle-transparent"}>
                <img
                    alt="add icon"
                    className="img-fluid"
                    src={Images.staffIcon}
                />
              </div>
              <div className="profile-info">
                <span className="license-text text-uppercase">{t('staffInformation.title')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="col-12">
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('staffInformation.dealerRep')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  {salesperson
                    ? `${get(salesperson, "first_name", "-")} ${get(
                        salesperson,
                        "last_name",
                        "-"
                      )}`
                    : "-"}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('staffInformation.salesManager')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  {sales_manager
                    ? `${get(sales_manager, "first_name", "")} ${get(
                        sales_manager,
                        "last_name",
                        ""
                      )}`
                    : "-"}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('staffInformation.fiManager')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  {fi_manager
                    ? `${get(fi_manager, "first_name", "")} ${get(
                        fi_manager,
                        "last_name",
                        ""
                      )}`
                    : "-"}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withTranslation()(StaffDashboard);
