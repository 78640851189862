import { fetchProfile, login } from "../../controller/AuthService";
import {
  logout,
  setRefreshToken,
  setUserToken,
} from "../../controller/localStorageHandler";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import LogRocket from "logrocket";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CHANGE_DEALERSHIP = "CHANGE_DEALERSHIP";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export function userLoginAction(data) {
  return (dispatch) => {
    return login(data).then((response) => {
      setUserToken(response.data.access);
      setRefreshToken(response.data.refresh);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data.profile,
      });
      LogRocket.identify(response.data.profile.id, {
        name: `${response.data.profile.first_name} ${response.data.profile.last_name}`,
        email: `${response.data.profile.email}`,
        role: `${response.data.profile.role}`,
      });
    });
  };
}
export function fetchProfileAction() {
  return (dispatch) => {
    return fetchProfile().then((response) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response?.data,
      });
    });
  };
}

export function changeDefaultDealership(id) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_DEALERSHIP,
      payload: id,
    });
  };
}

export const userLogOutAction = () => {
  logout();
  history.push(routes.login);
  return { type: LOGOUT_SUCCESS };
};
