import React, { Component } from "react";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import { routes } from "../controller/routes";
import { formatPhone, methods } from "../controller/Global";
import { reverse } from "named-urls";
import {Button} from "antd";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';



class PersonalInformationDashboard extends Component {
  render() {
    const { t } = this.props;
    let {
      personal_info,
      transaction,
      buyer_info,
      co_buyer_info,
      current_transaction,
      btnVisible,
    } = this.props;
    if (buyer_info) {
      personal_info = current_transaction.buyer_personal_info;
    }
    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row flex-align-center px-3">
            <div className="col-md-11">
              <div className="profile-card flex-align-center mb-3">

                <div className={"icon-circle-transparent"}>
                  <img
                      alt="add icon"
                      className="img-fluid"
                      src={Images.personalInformationIcon}
                  />
                </div>

                <div className="profile-info">
                  <span className="license-text text-uppercase">
                    {buyer_info ? t('personalInformation.buyerTitle') :  t('personalInformation.title')}

                  </span>
                </div>
              </div>
            </div>
            <div className={`col-md-1 mb-2 ${btnVisible && !personal_info ? 'align-tem-right-right' : ''}`}>
            {btnVisible ? (
              buyer_info || co_buyer_info ? (
                ""
              ) : personal_info ? (
                <Link
                  to={reverse(routes.dashboard.transaction.personal_info, {
                    trans_id: transaction,
                    method: methods.edit,
                    id: personal_info.id,
                  })}
                  className="bg-transparent border-0 shadow-none p-0"
                >
                  <Button className={"btn-update"}>{t('utils.update')}</Button>
                </Link>
              ) : (
                <Link
                  to={reverse(routes.dashboard.transaction.personal_info, {
                    trans_id: transaction,
                    method: methods.add,
                  })}
                  className="bg-transparent border-0 shadow-none p-0"
                >
                  <Button className={"btn-add"}>{t('utils.add')}</Button>
                </Link>
              )
            ) : (
              ""
            )}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="col-12">
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 float-left heading-name-text content-options-weight">
                  {t('personalInformation.name')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  {`${get(personal_info, "first_name", "-")} ${
                    get(personal_info, "middle_name", "-") || ""
                  } ${get(personal_info, "last_name", "-")}`}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('personalInformation.phone')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {formatPhone(get(personal_info, "phone", "-"))}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('personalInformation.email')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {get(personal_info, "email", "-")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PersonalInformationDashboard);
