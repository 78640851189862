import React, { Component } from "react";
import { Button, Modal } from "antd";
import { Image as Images } from "../../Images";

class PromptConfirm extends Component {
  render() {
    return (
      <Modal
        // onCancel={""}
        title={""}
        onOk={""}
        width={"488px"}
        open={this.props.visible}
        className="prompt-modal close-btn-none"
        footer={false}
        centered
      >
        <div className="row prompt-modal-row">
          <div className="col-12 text-center">
            <img
              alt="icon question mark"
              className="img-fluid"
              src={Images.question_mark_icon_primary}
            />
            <h4>{this.props.heading}</h4>
            {this.props.subHeading}
            {this.props.cancelText ? (
              <div className="row mx-0 align-items-center justify-content-between">
                <Button type="primary" onClick={this.props.onCancel}>
                  {this.props.cancelText}
                </Button>
                <Button onClick={() => this.props.onOk()} type="primary">
                  {this.props.okText}
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => this.props.onOk()}
                type={"primary"}
                className="w-100"
              >
                {this.props.okText}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default PromptConfirm;
