import React, { Component } from "react";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import { routes } from "../controller/routes";
import { reverse } from "named-urls";
import { methods } from "../controller/Global";
import { Button } from "antd";
import { withTranslation } from "react-i18next";


const DealTypeToString = {
  'paying_cash': 'Cash',
  'lease': 'Lease',
  'finance': 'Finance',
  'outside_finance_source': 'Outside Finace Source',
  'undecided': 'Undecided',
}
class DealInformationDashboard extends Component {
  render() {
    const { deal_info, transaction, is_co_buyer, btnVisible, t } = this.props;
    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row flex-align-center-between px-3">
          <div className="col-md-11">
            <div className="profile-card flex-align-center mb-3">

              <div className={"icon-circle-transparent"}>
                <img
                    alt="add icon"
                    className="img-fluid"
                    src={Images.dealInformationIcon}
                />
              </div>

              <div className="profile-info">
                <span className="license-text text-uppercase">{t('dealInformation.title')}</span>
              </div>
            </div>
            </div>
            <div className={`col-md-1 mb-2 ${!is_co_buyer && btnVisible && !deal_info ? 'align-tem-right-right' : ''}`}>

            {!is_co_buyer &&
              (btnVisible ? (
                <>
                  {deal_info ? (
                    <Link
                      to={reverse(routes.dashboard.transaction.deal_info, {
                        trans_id: transaction,
                        method: methods.edit,
                        id: deal_info.id,
                      })}
                      className="bg-transparent border-0 shadow-none p-0"
                    >
                      <Button className={"btn-update"}>{t('utils.update')}</Button>
                    </Link>
                  ) : (
                    <Link
                      to={reverse(routes.dashboard.transaction.deal_info, {
                        trans_id: transaction,
                        method: methods.add,
                      })}
                      className="bg-transparent border-0 shadow-none p-0"
                    >
                      <Button className="btn-add">{t('utils.add')}</Button>
                    </Link>
                  )}
                </>
              ) : (
                ""
              ))}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="col-12">
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('dealInformation.dealType')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  {deal_info && DealTypeToString[deal_info.deal_type]}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withTranslation()(DealInformationDashboard);
