import React, { Component } from "react";
import {Button, message, Radio, Upload} from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { methods } from "../../controller/Global";
import { getTransactionOne } from "../../controller/api/customerAPI";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { getEnvValue } from "../../controller/Environment";
import {Image as Images} from "../Images";
import ScannerBorder from "../Atoms/ScannerBorder/ScannerBorder";
import { withTranslation } from "react-i18next";


const BASE_URL = getEnvValue("REACT_APP_API_URL");


class UploadRegistration extends Component {
  state = {
    registration_front: this.props.registration_front,
    isVehicleOnHand: true,
    imageUrl: null
  };


 getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleChange = (info, state) => {
    this.setState({ [state]: info.fileList });
      const imageUrl = URL.createObjectURL(info.fileList[0].originFileObj);
      console.log(imageUrl);
      this.setState({imageUrl: imageUrl })

  };

  componentDidMount() {
    let { params } = this.props.match;
    if (params.method === methods.edit)
      if (this.props?.current_transaction?.id) {
        this.setState({
          registration_front: this.props.current_transaction.vehicle_for_trade
            .document
            ? this.props.current_transaction.vehicle_for_trade.document[0]
            : "",
          // registration_back: this.props.current_transaction.vehicle_for_trade.document[1]
        });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.vehicle_for_trade.id !== parseInt(params.id)) {
              message.error("Invalid URL for updating");
              history.push(routes.dashboard.self);
            } else {
              this.setState({
                registration_front: res.data.vehicle_for_trade.document
                  ? res.data.vehicle_for_trade.document[0]
                  : "",
              });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
  }

  render() {
      const { t } = this.props;
    return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <div className="transition-information-heading w-100 font-weight-bold">
              {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepOne.formTitle')}
          </div>
          <p className="font-weight-normal all-information-p mb-5">
              {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepOne.subTitle')}
          </p>
          <div className="row mx-0 radio-find-us mb-5">
            <Radio.Group
                className="w-100"
                onChange={(e) => {
                  this.setState({isVehicleOnHand: e.target.value})
                }}
                value={this.state.isVehicleOnHand}
            >
              <Radio
                  className="w-100 flex-align-center px-3"
                  value={true}
              >
                {t('utils.yes')}
              </Radio>
              <Radio
                  className="w-100 flex-align-center px-3"
                  value={false}
              >
                {t('utils.no')}
              </Radio>
            </Radio.Group>
          </div>
            {this.state.isVehicleOnHand === true ? (
                <div className="d-flex justify-content-center mt-5">
                    <div className={'w-50'}>
                        {this.state.registration_front && (
                            <img
                                src={this.state.registration_front}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                }}
                            />
                        )}
                        {this.state.imageUrl ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                }}
                            />
                        ) : (
                            <ScannerBorder>
                                <Upload
                                    onChange={(obj) => this.handleChange(obj, "registration_front")}
                                    multiple={false}
                                    beforeUpload={() => false}
                                    showUploadList={false}
                                    // defaultFileList={this.state.registration_front}
                                >
                                    <div className="d-flex flex-column justify-content-center">
                                        <div className={'text-uppercase font-weight-bold camera-context'}>
                                            Click here
                                        </div>
                                        <div className={'camera-context mb-2'}>to capture</div>
                                        <img
                                            src={Images.cameraIcon}
                                            alt="license icon"
                                            className="img-fluid mb-3"
                                            onClick={() => {
                                            }}
                                        />
                                        <div
                                            className={'text-uppercase font-weight-bold'}
                                            style={{color: 'rgba(41, 107, 104, 1)'}}
                                        >
                                            Front
                                        </div>
                                    </div>
                                </Upload>
                            </ScannerBorder>
                        )}
                    </div>
                </div>
            ) : ('')}
        </div>
        <div className="steps-action flex-align-center justify-content-end w-100">
          <Button
              type="primary"
              onClick={() =>
                  this.props.next({
                    registration_front: this.state.registration_front,
                  })
              }
          >
            {t('utils.next')}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {current_transaction: state.current_transaction, user: state.user};
};


export default withTranslation()(connect(mapStateToProps)(withRouter(UploadRegistration)));
