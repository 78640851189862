
import React, { Component, useEffect, useState, useRef } from "react";
import {Button, Form, Input, InputNumber, message, Modal, Radio, Select, Spin} from "antd";
import {
  addAdditionalAddress,
  getHardCredit,
  getZipcode,
  removeAdditionalAddress,
  updateAdditionalAddress,
} from "../../controller/api/customerAPI";
import { states } from "../../controller/states";
import { Image as Images } from "../Images";
import {
  methods,
  formatMoney,
  onNumberBlurred,
  onNumberFocused,
  onSelectBlurred,
  onSelectFocused,
} from "../../controller/Global";
import { connect } from "react-redux";
import {DEAL_TYPES_ARR, HOUSING_STATUS} from "../../controller/enums";
import toWords from "../../utils/ToWords";
import {useTranslation} from "react-i18next";
import i18next from 'i18next';



const { Option } = Select;

const address_title = [
  i18next.t('hardCreditForm.hcFormStepTwo.addressTitle.current'),
  i18next.t('hardCreditForm.hcFormStepTwo.addressTitle.previous'),
  i18next.t('hardCreditForm.hcFormStepTwo.addressTitle.secondPrevious'),
];

export const AddressInformation = (props) => {
  const formRef = useRef(null);

  const [addresses , setAddresses] = useState([undefined, undefined, undefined]);
  const [editAddress, setEditAddress] = useState(false);
  const [formFilled, setFormFilled] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [matchZipCode, setMatchZipCode] = useState(null);
  const [addressFine, setAddressFine] = useState(false);
  const [checkDuration, setCheckDuration] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [housingStatus, setHousingStatus] = useState(null);
  const [hardCreditInfo, setHardCreditInfo] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [disableField, setDisableField] = useState(false);
  const [fillAddressAddressForm, setFillAddressAddressForm] = useState('current_address')
  const [ isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const handleChange = (field, value) => {
    formRef.current.setFieldsValue({
      [field]: value,
    });
    if (field === "housing_status") {
      setHousingStatus(value)
    }
  };

  const handleEdit = (data) => {
    setIsEditAddress(true);
    setDisableField(false);

    if (data.monthly_payment) {
      data.monthly_payment = data?.monthly_payment?.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
      );
    }
    formRef.current.setFieldsValue({...data});
    setEditAddress(data.id);
    setFormFilled(true);
    if (data?.housing_status) {
      setHousingStatus(data?.housing_status);
    }
  };

  const updateAddress = async () => {
    let _addresses = []

    for (const address of addresses) {
      if (!address) {
        continue;
      }
      delete address["id"];
      const res = await addAdditionalAddress(address)

          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(`${e}: ${err.response.data[e]}`);
              });
            }
          })
          .finally(() => {
            handleButtonLoading(false);
          });
      _addresses.push(res.data)
    }

    _addresses.forEach((address, index) => {
      addresses[address.position] = address;
    });
    setAddresses(addresses);
  }


  const handleFillForm = async () => {
    setIsLoading(true);
    const { current_transaction } = props;
    if (
        props.match.params.method === methods.edit
    ) {
      const hard_credit_info = await getHardCredit(props.hard_credit.id)
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(`${e}: ${err.response.data[e]}`);
              });
            }
          })
          .finally(() => {
          });

      setHardCreditInfo(hard_credit_info.data);

      if(hard_credit_info.data?.additional_info?.length > 0 ) {
        hard_credit_info.data?.additional_info.forEach((address, index) => {

          addresses[address.position] = address;
        });
      }
      setAddresses(addresses);
      setIsLoading(false);
    } else {
      if (addresses[0] === undefined) {
        const lastHardCreditInfo = current_transaction?.hard_credit_info?.additional_info;
        if (lastHardCreditInfo && lastHardCreditInfo.length > 0) {

          lastHardCreditInfo.forEach((address, index) => {
            let foundPosition = -1;
            foundPosition = address.position;
            addresses[address.position] = address;
            if (foundPosition !== -1) {
              addresses[foundPosition]['hard_credit'] = props.hard_credit?.id;
            }
          });

          setAddresses(addresses);
          await updateAddress();
          setIsLoading(false);

        } else {
          setIsFirstLoad(false)
          fillCurrentAddress();
          setIsLoading(false);
        }
      }
    }

    if (
        addresses[0] !== undefined &&
        addresses[1] !== undefined &&
        addresses[2] !== undefined
    ) {
      setDisableField(true)
    } else setDisableField(false)
  }


  const addressPosition = () => {
    return addresses.findIndex((o) => o === undefined);
  };

  const updateAddressPosition = () => {
    if (editAddress) {
      return addresses.findIndex((o) => o && o.id === editAddress);
    }
    return -1;
  };

  const handleButtonLoading = (value) => {
    setBtnLoading(value);
  };

  const handleSubmit = (values) => {
    handleButtonLoading(true);
    if (values.monthly_payment) {
      values.monthly_payment = values?.monthly_payment?.replace(/,/g, "");
    }
    const position = addressPosition();
    if (position !== -1) {
      values["position"] = position;
    }

    if (
      values["duration_years"] ||
      values["duration_months"] ||
      values["duration_years"] === 0 ||
      values["duration_months"] === 0
    ) {
      values["duration_months"] = values["duration_months"] || 0;
      values["duration_years"] = values["duration_years"] || 0;
      if (
        values["housing_status"] === HOUSING_STATUS.OWNED ||
        values["housing_status"] === HOUSING_STATUS.LIVE_WITH_RELATIVES ||
        values["housing_status"] === HOUSING_STATUS.I_DONT_PAY_RENT
      ) {
        values["monthly_payment"] = null;
      }
      if (props.hard_credit && props.hard_credit.id) {
        values["hard_credit"] = props.hard_credit.id;
      }
      if (props.is_edit || isEditAddress) {
        if (editAddress) {
          values["position"] = updateAddressPosition();
          updateAdditionalAddress(editAddress, values)
            .then((resp) => {
              const index = addresses.findIndex(
                (obj) => obj && obj.id === resp.data.id
              );
              addresses[index] = resp.data;
              setAddresses([...addresses]);
              setEditAddress(null);
              setFormFilled(false);
            })
            .catch((err) => {
              if (err.response) {
                Object.keys(err.response.data).forEach((e) => {
                  message.error(`${e}: ${err.response.data[e]}`);
                });
              }
            })
            .finally(() => {
              handleButtonLoading(false);
            });
          if (
            addresses[0] !== undefined &&
            addresses[1] !== undefined &&
            addresses[2] !== undefined
          ) {
            setDisableField(true)
          } else  setDisableField(false);
        } else {
          // add the address in case not present
          if (position === -1) {
            message.error(t('hardCreditForm.hcFormStepTwo.warningMessage.cannotAdd'));
            handleButtonLoading(false);
            return;
          }

          values["is_current_address"] = !position;
          addAdditionalAddress(values)
            .then((resp) => {
              addresses[position] = resp.data;
              setAddresses(addresses);
              setEditAddress(null);
              setFormFilled(false);
            })
            .catch((err) => {
              if (err.response) {
                Object.keys(err.response.data).forEach((e) => {
                  message.error(`${e}: ${err.response.data[e]}`);
                });
              }
            })
            .finally(() => {
              handleButtonLoading(false)
            });
          const data = addresses.filter((item, i) => i !== position);
          if (data[0] !== undefined && data[1] !== undefined) {
            setDisableField(true)
          }
        }
      } else {
        if (position === -1) {
          message.error(t('hardCreditForm.hcFormStepTwo.warningMessage.cannotAdd'));
          this.buttonLoading(false);
          return;
        }

        values["is_current_address"] = !position;
        addAdditionalAddress(values)
          .then((resp) => {
            addresses[position] = resp.data;
            setAddresses(addresses);
            setEditAddress(null);
            setFormFilled(false);
          })
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(`${e}: ${err.response.data[e]}`);
              });
            }
          })
          .finally(() => {
            handleButtonLoading(false)
          });
      }
      checkYearDuration(addresses, values);
      formRef.current.resetFields();
    } else {
      handleButtonLoading(false)
      setCheckDuration(true)
    }
  };


  const checkYearDuration = (addresses, values) => {
    const data = addresses.filter((item, i) => i !== values.position);
    let totalYear = 0;
    let totalMonth = 0;
    data.forEach((item) => {
      if (item) {
        totalYear += item.duration_years;
        totalMonth += item.duration_months;
      }
    });
    totalYear += values.duration_years;
    totalMonth += values.duration_months;
    let totalTime = totalYear + totalMonth / 12;
  };

  const handleRemoveCard = (id, index) => {
    setDisableField(false);
    removeAdditionalAddress(id)
      .then((res) => {
        addresses[index] = undefined;
        setAddresses(addresses);
      })
      .catch((error) => {
        if (error?.response?.data) {
          Object.keys(error.response.data).forEach((e) => {
            message.error(`${e}:${error.response.data[e]}`);
          });
        }
      });
    // prefill current address
    const { current_transaction } = props;
    if (index === 0 || addresses[0] === undefined) {
      if (current_transaction.license) {
        formRef.current.setFieldsValue({ ...current_transaction.license });
      } else {
        formRef.current.setFieldsValue({
          address: current_transaction?.address_information?.address_1 ? current_transaction.address_information.address_1 : '',
          suite: current_transaction?.address_information?.suite_1 ? current_transaction.address_information.suite_1 : '',
          zip_code: current_transaction?.address_information?.zip_code_1 ? current_transaction.address_information.zip_code_1 : '',
          city: current_transaction?.address_information?.city_1 ? current_transaction.address_information.city_1 : '',
          state: current_transaction?.address_information?.state_1 ? current_transaction.address_information.state_1 : '',
        });
      }
    }
  };

  const onChangeZip = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode = response.data;
        setMatchZipCode(matchZipCode);
        if (matchZipCode) {
          formRef.current.setFieldsValue({
            city: matchZipCode.city,
            state: matchZipCode.state,
          });
          checkformfill();
        }
      });
    }
  };

  const checkformfill = () => {
    const formData = formRef.current.getFieldsValue();
    if (addresses[0] === undefined) {
      if (
        formData.address &&
        formData.city &&
        formData.zip_code &&
        formData.state &&
        formData.housing_status
      ) {
        setFormFilled(true);
      } else {
        setFormFilled(false)
      }
    } else {
      if (
        formData.address &&
        formData.city &&
        formData.zip_code &&
        formData.state
      ) {
        setFormFilled(true);
      } else {
        setFormFilled(false);
      }
    }
  };

  const onBlurZip = (e) => {
  let value = e.target.value;
    if (matchZipCode && value.length === 3) {
      formRef.current.setFieldsValue({
        zip_code: `00${value}`,
      });
    } else if (matchZipCode && value.length === 4) {
      formRef.current.setFieldsValue({
        zip_code: `0${value}`,
      });
    }
  };

  const onFormChange = (allFields) => {
    let housingValue = allFields.find(
      (item) => item.name[0] === "housing_status"
    );
    if (
      housingValue?.value === HOUSING_STATUS.OWN_FREE_AND_CLEAR ||
      housingValue?.value === HOUSING_STATUS.LIVE_WITH_RELATIVES ||
      housingValue?.value === HOUSING_STATUS.I_DONT_PAY_RENT
    ) {
      formRef.current.setFieldsValue({ monthly_payment: null });
      let newFields = allFields.filter(
        (item) =>
          item.name[0] !== "duration_months" &&
          item.name[0] !== "duration_years" &&
          item.name[0] !== "suite" &&
          item.name[0] !== "monthly_payment"
      );
      if (newFields.every((item) => !!item.value || item.value === 0))
        setFormFilled(true)
      else setFormFilled(false);
    } else {
      let newFields = allFields.filter(
        (item) =>
          item.name[0] !== "duration_months" &&
          item.name[0] !== "duration_years" &&
          item.name[0] !== "suite"
      );
      if (
        newFields
          .find((item) => item.name[0] === "monthly_payment")
          ?.value?.toString().length < 6
      ) {
        setFormFilled(false)
      } else {
        if (newFields.every((item) => !!item.value || item.value === 0))
          setFormFilled(true);
        else setFormFilled(false);
      }
    }
  };


  const next = (total) => {
    const address_requirement = props.current_transaction.dealership
        .hard_credit_address_requirement
    if (total < address_requirement) {
      setAddressErr(true)
    } else {
      props.next();
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const handleDollarField = (value) => {
    if (value[0] === "$") {
      value = value?.replace("$", "");
      formRef.current.setFieldsValue({
        monthly_payment: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    }
    if (value === "0.0") {
      value = value + "0";
      if (value.toString().length > 5) {
        setErrorMessage(null)
      } else {
        setErrorMessage(t('hardCreditForm.hcFormStepTwo.warningMessage.fiveMinimumRequired'))
      }
      formRef.current.setFieldsValue({
        monthly_payment: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else {
      value = value?.toString();
      value = value?.replace(/\./g, "");
      value = value?.replace(/,/g, "");
      if (value[0] === "0" && value[1] === "0") {
        value = value.substring(2);
      }

      if (value) {
        if (value.length === 1) {
          value = "0.0" + value;
          if (value.toString().length > 5) {
            setErrorMessage(null)
          } else {
            setErrorMessage(t('hardCreditForm.hcFormStepTwo.warningMessage.fiveMinimumRequired'))
          }
          formRef.current.setFieldsValue({
            monthly_payment: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else if (value.length === 2) {
          value = "0." + value;
          if (value.toString().length > 5) {
            setErrorMessage(null)
          } else {
            setErrorMessage(t('hardCreditForm.hcFormStepTwo.warningMessage.fiveMinimumRequired'))
          }
          formRef.current.setFieldsValue({
            monthly_payment: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else {
          value = value.slice(0, -2) + "." + value.substr(-2);
          if (value[0] === "0") {
            value = value.substring(1);
          }
          if (value.toString().length > 5) {
            setErrorMessage(null)
          } else {
            setErrorMessage("enter a minimum of 5 digits amount")
          }
          formRef.current.setFieldsValue({
            monthly_payment: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        }
      }
    }
  };

  const enterOnlyNumber = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
    }
  };

  const AddressCard = ({address, index}) => {
    return (
      <div key={`address_card_0`} className="col-12">
        <div className="row address-add-row add-address-row-second position-relative">
          <div className="col-12">
            <div className="row">
              <div className="col-sm-9 col-12 col-md-9 col-lg-9">

                <div className="row add-address-inner-row">
                  <div className="col-12 mb-4">
                    <h6>Address:</h6>
                    <p className="mb-0">{`${address.address},${
                        address.suite || ""
                    }, ${address.city}, ${address.state}, ${
                        address.zip_code
                    }`}</p>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6 col-6">
                    <div className="row">
                      {index === 0 && (
                          <div className="col-12 mb-2">
                            <h6>{t('hardCreditForm.hcFormStepTwo.housingStatusLabel')}:</h6>
                            <p className="text-capitalize">
                              {address.housing_status ? (
                                  address?.housing_status.split("_").join(" ")
                              ): ('-')}
                            </p>
                          </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6 col-12">
                    <div className="row">
                      {index === 0 && (
                          <div className="col-12">
                            <h6>{t('hardCreditForm.hcFormStepTwo.monthlyPaymentLabel')}:</h6>
                            <p>
                              {formatMoney(address.monthly_payment) ||
                                  "N/A"}
                            </p>
                          </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <h6>{t('hardCreditForm.hcFormStepTwo.timeAtAddress')}:</h6>
                    <p className="mb-0">{`${address.duration_years} Yrs ${address.duration_months} Mos`}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-dm-3 col-sm-3 col-12 edit-delete-div">
                <Button
                    onClick={() => {
                      handleRemoveCard(address.id, index)
                      scrollToTop()
                    }}
                    className="edit-delete-btn delete-btn"
                >
                  {t('utils.remove')}
                </Button>
                <Button
                    className="mt-n5 edit-btn edit-delete-btn active"
                    onClick={() => {
                      scrollToTop();
                      handleEdit(address);
                    }}
                >
                  {t('utils.edit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const fillCurrentAddress = () => {
    const { current_transaction } = props;
    formRef.current.setFieldsValue({
      address: current_transaction?.address_information?.address_1 ? current_transaction.address_information.address_1 : '',
      suite: current_transaction?.address_information?.suite_1 ? current_transaction.address_information.suite_1 : '',
      zip_code: current_transaction?.address_information?.zip_code_1 ? current_transaction.address_information.zip_code_1 : '',
      city: current_transaction?.address_information?.city_1 ? current_transaction.address_information.city_1 : '',
      state: current_transaction?.address_information?.state_1 ? current_transaction.address_information.state_1 : '',
    });
  }


  const fillRegisterAddress = () => {
    const { current_transaction } = props;
    formRef.current.setFieldsValue({
      address: current_transaction?.address_information?.address_2 ? current_transaction.address_information.address_2 : '',
      suite: current_transaction?.address_information?.suite_2 ? current_transaction.address_information.suite_2 : '',
      zip_code: current_transaction?.address_information?.zip_code_2 ? current_transaction.address_information.zip_code_2 : '',
      city: current_transaction?.address_information?.city_2 ? current_transaction.address_information.city_2 : '',
      state: current_transaction?.address_information?.state_2 ? current_transaction.address_information.state_2 : '',
    });
  }



    const currentPosition = addressPosition();
    const address_requirement = props.current_transaction.dealership.hard_credit_address_requirement;
    let totalYear = 0;
    let totalMonth = 0;
    addresses.forEach((item) => {
      if (item) {
        totalYear += item.duration_years;
        totalMonth += item.duration_months;
      }
    });
    let totalTime = totalYear + totalMonth / 12;
    const hardCreditAddressRequirementYearsInWords = toWords.convert(
      props.current_transaction.dealership.hard_credit_address_requirement
    );


    useEffect(() => {
      handleFillForm();
    }, []);

    useEffect(() => {
      if (props.match.params.method !== methods.edit && !isFirstLoad) {
        if (fillAddressAddressForm === 'current_address') {
          fillCurrentAddress();
        } else {
          fillRegisterAddress();
        }
      }
    },[fillAddressAddressForm])


    return (
      <React.Fragment>
        { isLoading ? (
          <div className="col-12 text-center spiner">
              <Spin size="large"/>
          </div>
        ) : (
          <div className="col-12 transition-information-div">
            <div className="transition-information-heading w-100 font-weight-bold mb-2">
              {t('hardCreditForm.hcFormStepTwo.title')}
            </div>
            {props.current_transaction.dealership
                .hard_credit_address_requirement !== 0 && (
                <p>
                  {t('hardCreditForm.hcFormStepTwo.content_one')}
                  {hardCreditAddressRequirementYearsInWords}
                  {t('hardCreditForm.hcFormStepTwo.content_two')}
                </p>
            )}

            {props.match.params.method !== methods.edit && addresses[0] === undefined ? (
                <>
                  <p className="font-weight-bold mb-4">
                    {t('hardCreditForm.hcFormStepTwo.which_address_are_you_using')}
                  </p>
                  <div className="col-12 mb-5">
                    <div className="row mx-0 radio-find-us radio-border">
                      <Radio.Group
                          className="w-100"
                          onChange={(e) => {
                            console.log(e.target.value)
                            setFillAddressAddressForm(e.target.value)
                          }}
                          value={fillAddressAddressForm}
                      >
                        <Radio
                            className="w-100 flex-align-center px-3"
                            value={'current_address'}
                            key={'current_address'}
                        >
                          {t('hardCreditForm.hcFormStepTwo.currentAddress')}
                        </Radio>
                        <Radio
                            className="w-100 flex-align-center px-3"
                            value={'register_address'}
                            key={'register_address'}
                        >
                          {t('hardCreditForm.hcFormStepTwo.registerToAddress')}
                        </Radio>

                      </Radio.Group>
                    </div>
                  </div>
                </>
            ) : ("")}


            <div className="mb-2 verify-identy-small-div font-weight-bold w-100">
              {
                address_title[
                    updateAddressPosition() !== -1
                        ? updateAddressPosition()
                        : currentPosition
                    ]
              }
            </div>
            <div className="row mx-0">
              <Form
                  className="w-100 mt-3"
                  ref={formRef}
                  onFieldsChange={(changedFields, allFields) => {
                    onFormChange(allFields);
                  }}
                  onFinish={handleSubmit}
              >
                <div className="row">
                  <div className="col-12">
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.streetAddressLabel')} *</label>
                    <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          disabled={disableField}
                          autoFocus
                          placeholder=" "
                          className="inp-form-wrap"
                          onChange={() => {
                            checkformfill()
                          }}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.suiteLabel')}</label>
                    <Form.Item
                        name="suite"
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          disabled={disableField}
                          onChange={() => {
                            checkformfill()
                          }}
                          autoFocus
                          placeholder=" "
                          className="inp-form-wrap"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-4 select_floating zip-inp">
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.zipCodeLabel')} *</label>
                    <Form.Item
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label select-float group-floating-label-second"
                        }
                    >
                      <Input
                          disabled={disableField}
                          placeholder=" "
                          onChange={onChangeZip}
                          onBlur={onBlurZip}
                      />
                    </Form.Item>
                    <span className="subtext">{t('hardCreditForm.hcFormStepTwo.zipHelperText')}</span>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-4">
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.cityLabel')} *</label>
                    <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          disabled={disableField}
                          placeholder=" "
                          className="inp-form-wrap"
                      />
                    </Form.Item>
                  </div>
                  <div
                      className="col-12 col-sm-12 col-lg-4 select_floating"
                      id={"select_state"}
                  >
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.stateLabel')} *</label>
                    <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label select-float group-floating-label-second"
                        }
                    >
                      <Select
                          disabled={disableField}
                          onFocus={() => onSelectFocused("select_state")}
                          onBlur={() => onSelectBlurred("select_state")}
                          onChange={() => {
                            checkformfill()
                          }}
                          suffixIcon={
                            <img
                                src={Images.down_arrow_select}
                                width={15}
                                alt="down arrow"
                                className="img-fluid"
                            />
                          }
                          placeholder=""
                      >
                        {states.map((item) => (
                            <Option
                                key={item.abbreviation}
                                value={item.abbreviation}
                            >{`${item.name} (${item.abbreviation})`}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  {(currentPosition === 0 || updateAddressPosition() === 0) && (
                      <div
                          className="col-12 col-sm-12 col-lg-6 select_floating"
                          id={"select_housing_state"}
                      >
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.housingStatusLabel')} *</label>
                        <Form.Item
                            name="housing_status"
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                            ]}
                            className={`group-floating-label select-float group-floating-label-second position-relative  ${
                                editAddress ? "has-value" : ""
                            }`}
                        >
                          <Select
                              disabled={disableField}
                              onFocus={() => onSelectFocused("select_housing_state")}
                              onBlur={() => {
                                onSelectBlurred("select_housing_state")
                              }}
                              suffixIcon={
                                <img
                                    src={Images.down_arrow_select}
                                    width={15}
                                    alt="down arrow"
                                    className="img-fluid"
                                />
                              }
                              id="housing_status"
                              placeholder=""
                              onChange={(value) => {
                                handleChange("housing_status", value);
                                checkformfill();
                              }}
                          >
                            <Option
                                disabled
                                className="disbaled-optn-color"
                                value={null}
                            >
                              {"Housing Status"}
                            </Option>
                            <Option value="rent">{t('hardCreditForm.hcFormStepTwo.options.rent')}</Option>
                            <Option value="own">{t('hardCreditForm.hcFormStepTwo.options.ownMortgage')}</Option>
                            <Option value="own_free_and_clear">
                              {t('hardCreditForm.hcFormStepTwo.options.ownFree')}
                            </Option>
                            <Option value="other">{t('hardCreditForm.hcFormStepTwo.options.other')}</Option>
                            <Option value="living_with_relatives">
                              {t('hardCreditForm.hcFormStepTwo.options.livingWithRelatives')}
                            </Option>
                            <Option value="i_dont_pay_rent">
                              {t('hardCreditForm.hcFormStepTwo.options.doNotPayRent')}
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                  )}
                  {
                    housingStatus === HOUSING_STATUS.OWN_FREE_AND_CLEAR ||
                    housingStatus === HOUSING_STATUS.LIVE_WITH_RELATIVES ||
                    housingStatus === HOUSING_STATUS.I_DONT_PAY_RENT ? (
                        ""
                    ) : (
                      <>
                        {(currentPosition === 0 || updateAddressPosition() === 0) && (
                          <div
                              id={"monthly_payment"}
                              className="col-12 col-sm-12 col-lg-6"
                          >
                            <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.monthlyPaymentLabel')} *</label>
                            <Form.Item
                                name="monthly_payment"
                                rules={[
                                  {
                                    required: true,
                                    message: "this field is required",
                                  },
                                ]}
                                className={`group-floating-label select-float group-floating-label-second price-field ${
                                    editAddress ? "has-value" : ""
                                }`}
                            >
                              <Input
                                disabled={disableField}
                                className="ant-input-number-input"
                                onFocus={() => onNumberFocused("monthly_payment")}
                                onBlur={() => onNumberBlurred("monthly_payment")}
                                placeholder="0.00"
                                onChange={(e) =>
                                    handleDollarField(e?.target?.value)
                                }
                                onKeyPress={enterOnlyNumber}
                              />
                            </Form.Item>
                            {errorMessage === null ? (
                                ""
                            ) : (
                              <div
                                style={{
                                  color: "#EB5757",
                                  marginTop: "-24px",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                }}
                              >
                                {errorMessage}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}

                  <div id={"duration"} className="col-12 col-sm-12 col-lg-6">
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.durationYearLabel')} *</label>
                    <Form.Item
                      name="duration_years"
                      rules={[
                        {
                          required: false,
                          message: "this field is required",
                        },
                        {
                          type: "number",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                    >
                      <InputNumber
                        disabled={disableField}
                        placeholder=""
                        onFocus={() => onNumberFocused("duration")}
                        onBlur={() => {
                          onNumberBlurred("duration");
                          checkformfill()
                        }}
                      />
                    </Form.Item>
                    <span className="subtext">
                      {t('hardCreditForm.hcFormStepTwo.durationYearHelperText')}
                    </span>
                  </div>

                  <div
                    id={"duration-month"}
                    className="col-12 col-sm-12 col-lg-6"
                  >
                    <label className="font-weight-bold">{t('hardCreditForm.hcFormStepTwo.durationMonthLabel')} </label>
                    <Form.Item
                      name="duration_months"
                      rules={[
                        {
                          required: false,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                    >
                      <InputNumber
                        disabled={disableField}
                        max={12}
                        onFocus={() => onNumberFocused("duration-month")}
                        onBlur={() => {
                          onNumberBlurred("duration-month");
                          checkformfill()
                        }}
                        placeholder=""
                      />
                    </Form.Item>
                    <span className="subtext">
                      {t('hardCreditForm.hcFormStepTwo.durationMonthHelperText')}
                    </span>
                  </div>

                  <div className="col-12 mt-5">
                    <Button
                        loading={btnLoading}
                        disabled={!formFilled || disableField}
                        htmlType="submit"
                        className={`add-prev-address font-weight-normal ${
                            !formFilled && "in-active"
                        }`}
                    >
                      {editAddress ? t('hardCreditForm.hcFormStepTwo.updateAddress') : t('hardCreditForm.hcFormStepTwo.addAddress')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        )}

        {addresses.map((address, index) => (
          <div
              key={`addresses_${index}`}
              className="col-12 p-0 address-filed-show-address-div"
          >
            <div className="row">
              <div className="col-12">
                <h5>
                  {index === 0
                      ? t('hardCreditForm.hcFormStepTwo.current') + ' ' + t('hardCreditForm.hcFormStepTwo.mandatory')
                      : index === 1
                          ? t('hardCreditForm.hcFormStepTwo.previous')
                          : t('hardCreditForm.hcFormStepTwo.secondPrevious')}
                </h5>
              </div>
              <div className="col-12">
                <>
                  {address ? (
                      <AddressCard address={address} index={index} key={index}/>
                  ) : (
                      <div className="row address-add-row when-data-not-show d-inline-flex align-items-center">
                        <h6 className="mb-0">
                          {index === 0
                              ? t('hardCreditForm.hcFormStepTwo.currentAddressEmptyCardContent')
                              : index === 1
                                  ? t('hardCreditForm.hcFormStepTwo.previousAddressEmptyCardContent')
                                  : t('hardCreditForm.hcFormStepTwo.secondPreviousAddressEmptyCardContent')
                          }
                        </h6>
                      </div>
                  )}
                </>
              </div>
            </div>
          </div>
        ))}

        <div className="steps-action flex-align-center justify-content-between w-100">
          <Button
            style={{marginRight: "11%"}}
            onClick={() => props.prev()}
          >
            {t('utils.back')}
          </Button>
          <Button
            disabled={currentPosition === 0}
            type="primary"
            onClick={() => next(totalTime)}
          >
            {t('utils.next')}
          </Button>
        </div>
        <Modal
          open={addressErr}
          onOk={() => setAddressErr(false)}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
                key="submit"
                type={"primary"}
                onClick={() => setAddressErr(false)}
            >
              {t('hardCreditForm.hcFormStepTwo.addInformation')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                  alt="images"
                  className="img-fluid"
                  src={Images.error_prompt_img}
              />
              <p className={`font-weight-bold mb-2 text-color-red`}>
                {t('hardCreditForm.hcFormStepTwo.addInformationModal.title_one')}
              </p>
              <small className="d-inline-block w-100">
                {t('hardCreditForm.hcFormStepTwo.addInformationModal.content_one')}
                {address_requirement}
                {t('hardCreditForm.hcFormStepTwo.addInformationModal.content_two')}
              </small>
            </div>
          </div>
        </Modal>
        <Modal
          open={addressFine}
          onOk={() => {
            setAddressFine(false);
            props.next();
          }}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
              key="submit"
              type={"primary"}
              onClick={() => setAddressFine(false)}
            >
              {t('utils.addMore')}
            </Button>,
            <Button
              key="submit"
              type={"primary"}
              onClick={() => {
                setAddressFine(false);
                props.next();
              }}
            >
              {t('utils.next')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <h4 className={`font-weight-bold mb-2 text-color-blue`}>
                {address_requirement === 0 ? t('hardCreditForm.hcFormStepTwo.addMoreInformationModal.title')
                    : t('hardCreditForm.hcFormStepTwo.addMoreInformationModal.title_one') +
                      address_requirement +
                      t('hardCreditForm.hcFormStepTwo.addMoreInformationModal.title_two')
                }
              </h4>
              <small className="d-inline-block w-100">
                {t('hardCreditForm.hcFormStepTwo.addMoreInformationModal.content')}
              </small>
            </div>
          </div>
        </Modal>

        <Modal
          open={checkDuration}
          onOk={() => checkDuration(false)}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
              key="submit"
              type={"primary"}
              onClick={() => checkDuration(false)}
            >
              {t('hardCreditForm.hcFormStepTwo.addInformation')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.error_prompt_img}
              />
              <p className={`font-weight-bold mb-2 text-color-red`}>
                {t('hardCreditForm.hcFormStepTwo.addTimeInformationModal.content')}
              </p>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
  };
};

export default connect(mapStateToProps)(AddressInformation);
