import { getEnvValue } from "./Environment";
import { include, reverse } from "named-urls";

const moment = require("moment");
const endpoint = {
  auth: include("/api/v1/auth/", {
    user: "user/:id?/",
    login: "login/",
    profile: "profile/",
    check_password: "check-password/",
    change_password: "change-password/",
    reset_password: "reset-password/",
    reset_password_email: "reset-password-email/",
  }),
  privacy_policy: "/static/documents/PrivacyPolicy_en.html",
  application_disclosure: "/static/documents/TermsAndConditions_en.html",
  client: include("/api/v1/client/", {
    sign_document: "sign-document",
    setup_complete: ":id/setup-complete/",
    zipcode: "zipcode/",
    // tech_support: "tech-support/",
    tech: "tech/",
    notes: "notes/",
    vin: "vin/",
    transaction: include("transaction/:dealership", {
      self: "",
      one: ":id/",
      history: "history/",
      co_buyer: ":id/co_buyer/",
      update_seen: ":id/update_seen/",
      remove_co_buyer: ":id/remove_co_buyer/",
    }),
    privacy: "transaction-v2/:dealership/",
    privacy_update: "transaction-v2/privacy-policy/:dealership/",
    personal_info: include("customer-information", {
      self: "",
      one: ":id/",
    }),
    address_info: include("address-information", {
      self: "",
      one: ":id/",
    }),
    bank_info: include("bank-info", {
      self: "",
      one: ":id/",
    }),
    deal_info: include("deal-info", {
      self: "",
      one: ":id/",
    }),
    hard_credit: include("hard-credit", {
      self: "",
      one: include(":id", {
        self: "",
        verify: "verify/",
      }),
    }),
    vehicle_for_purchase: include("vehicle-for-purchase", {
      self: "",
      one: ":id/",
    }),
    vehicle_for_trade: include("vehicle-for-trade", {
      self: "",
      one: ":id/",
    }),
    pre_qualification: include("pre-qualification", {
      self: "",
      one: include(":id", {
        self: "",
        verify: "verify/",
      }),
    }),
    deal_jacket: include("deal-jacket", {
      self: "",
      one: ":id/",
      identity: "identity_theft_prevention/",
    }),
    additional_address: include("additional-address", {
      self: "",
      one: ":id/",
    }),
    employment_info: include("employment-info", {
      self: "",
      one: ":id/",
    }),
    license: include("identity-document", {
      self: "",
      one: ":id/",
    }),
    trans_deal_jacket: ":trans_id/deal-jacket/",
    referral_dealership: "referral-dealership/",
    co_buyer: ":trans_id/co-buyer/",
    request_otp: "request-otp/",
    verify_otp: "verify-otp/",
    get_stock_data: "get-stock-value/:dealership/:stockNumber/:id/",
    login_prompt: "login-prompt/",
    update_email: "update-email/",
    personal_reference: include("personal-reference", {
      self: "",
      one: ":id/",
    }),
  }),
  ocr_scan: "/api/v1/util/ocr-scan/",
  v3: include("/api/v3/", {
    transaction: include("transaction/", {
      self: "",
      one: ":id/",
      "is-identity-verified": ":id/is-identity-verified/",
      send_web_portal_link: ":id/send-webportal-link-to-phone/",
    }),
    "identity-document": include("identity-document/", {
      one: ":id/",
      is_pending: ":verification_request_id/is-pending/",
      get_new_capture: ":verification_request_id/capture_new/",
      validate_transaction: ":verification_request_id/validate-transaction/",
    }),
    "verification-request": include("verification-request/", {
      all: "",
      one: ":id/",
    }),
    insurance: include("insurance/", {
      all: "",
      one: ":id/",
    }),
    dealership: include("dealership/", {}),

    personal_reference: include("personal-reference/", {
      all: "",
      one: ":id/",
    }),
    additional_address: include("additional-address/", {
      one: ":id/",
      all: "",
    }),
    employment_information: include("employment-information/", {
      one: ":id/",
      all: "",
    }),
    customer: include("customer/", {
      one: ":id/",
      all: "",
    }),
    deal: include("deal/", {
      pre_screen_report: ":id/pre-screen-report/",
    }),
    otp: include("otp/", {
      request_otp: "",
      verify_otp: "verify/",
    }),
  }),
};

export const methods = {
  add: "add",
  // view: 'view',
  edit: "edit",
};

export const buyer_type = {
  primary_buyer: "primary_buyer",
  co_buyer: "co_buyer",
};

export const hard_credit_type = {
  personal: "personal",
  business: "business",
};

export const transaction_status = {
  in_progress: "in_progress",
  prospect: "prospect",
  completed: "completed",
  dead: "dead",
  pushed: "pushed",
};

export const confirm_status = {
  add: "add",
  edit: "edit",
  exit: "exit",
};

export function getAPIUrl(url, params = null) {
  const path = reverse(
    url.split(".").reduce((o, i) => o[i], endpoint),
    params
  );
  return getEnvValue("REACT_APP_API_URL") + path;
}

export function onChangeDate(value, id) {
  const element = document.getElementById(id);
  if (value) {
    element.classList.add("date-focused");
  } else {
    element.classList.remove("date-focused");
  }
}

export function onNumberFocused(id) {
  const element = document.getElementById(id);
  element.classList.add("date-focused");
}

export function onNumberBlurred(id) {
  const element = document.getElementById(id);
  element.classList.remove("date-focused");
}
export function onTextAreaFocused(id) {
  const element = document.getElementById(id);
  const parentDiv = element.closest(".message-div-main");
  parentDiv.classList.add("textArea-focused");
}

export function onTextAreaBlurred(id) {
  const element = document.getElementById(id);
  const parentDiv = element.closest(".message-div-main");
  parentDiv.classList.remove("textArea-focused");
}

export function onSelectFocused(id) {
  const element = document.getElementById(id);

  element.classList.add("select_focus");
}

export function onSelectBlurred(id) {
  const element = document.getElementById(id);
  element.classList.remove("select_focus");
}

export function formatPhone(phone) {
  return phone
    .match(/\d*/g)
    .join("")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    .slice(1)
    .join("-")
    .replace(/-*$/g, "");
}

export function formatMoney(money) {
  return money ? `$ ${money.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-";
}

export function formatDecimal(value) {
  return value ? `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "";
}

export const phoneValidationPattern = new RegExp(/^\d{10,}(?:,\d{10,})*$/);
export const ssnValidationPattern = new RegExp(/^\d{9,}(?:,\d{9,})*$/);

export const checkCreationDate = (transaction) => {
  let current_transaction = transaction;
  let today = new Date()?.toISOString()?.slice(0, 10);
  const startDate = moment(current_transaction?.created_at)?.format(
    "YYYY-MM-DD"
  );
  const endDate = today;
  const diffInMs = new Date(endDate) - new Date(startDate);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
  if (diffInDays > 90) {
    return false;
  } else {
    return true;
  }
};
