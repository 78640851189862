import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { pStore, store } from "./store/store";
import ReactLoading from "react-loading";
import { PersistGate } from "redux-persist/lib/integration/react";
import setupLogRocketReact from "logrocket-react";
import LogRocket from "logrocket";
import { getEnvValue } from "./controller/Environment";
import { HelmetProvider } from "react-helmet-async";
import './i18n';

require("dotenv").config();
LogRocket.init(getEnvValue("REACT_APP_LOG_ROCKET_APP_ID") as string);
setupLogRocketReact(LogRocket);


const root = createRoot(document.getElementById("root") as HTMLElement); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <PersistGate
        loading={<ReactLoading type={"bubbles"} />}
        persistor={pStore}
      >
        <App />
      </PersistGate>
    </HelmetProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
