export const USER_TOKEN = "eunifi_portal_user_token";
export const USER_ROLE = "eunifi_portal_user_role";
export const REFRESH_TOKEN = "eunifi_portal_refresh_role";
export const OTP_VERIFIED = "eunifi_portal_otp_verified";
export const TUTORIAL = "eunifi_portal_tutorial";

export function logout() {
  clearUserToken();
}

export function setUserToken(TOKEN) {
  localStorage.setItem(USER_TOKEN, TOKEN);
}

export function setRefreshToken(TOKEN) {
  localStorage.setItem(REFRESH_TOKEN, TOKEN);
}

export function setUserRole(ROLE) {
  localStorage.setItem(USER_ROLE, ROLE);
}

export function setOTPVerified(OTP) {
  localStorage.setItem(OTP_VERIFIED, OTP);
}

export function getUserRole() {
  return localStorage.getItem(USER_ROLE);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

export function getOTPVerified() {
  return localStorage.getItem(OTP_VERIFIED);
}

export function getUserToken() {
  return localStorage.getItem(USER_TOKEN);
}

export function clearUserToken() {
  localStorage.removeItem(USER_TOKEN);
  localStorage.removeItem(USER_ROLE);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(OTP_VERIFIED);
}

export function isLoggedIn() {
  const accessToken = getUserToken();
  const isVerified  = getOTPVerified();
  return !!accessToken && !!isVerified;
}

export function showTutorial() {
  const status = localStorage.getItem(TUTORIAL);
  return status;
}

export function setTutorial(status) {
  localStorage.setItem(TUTORIAL, status);
}

export function clearTutorial() {
  localStorage.removeItem(TUTORIAL);
}
