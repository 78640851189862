import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image as Images } from "./Images";
import { reverse } from "named-urls";
import { routes } from "../controller/routes";
import { withTranslation, Trans } from "react-i18next";

class DocumentDashboard extends Component {
  render() {
    const { transaction, btnVisible, t } = this.props;
    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row align-items-center px-3">
            <div className="profile-card flex-align-center mb-3">
              <div className={"icon-circle-transparent"}>
                <img
                    alt="add icon"
                    className="img-fluid"
                    src={Images.documentsIcon}
                />
              </div>
              <div className="profile-info">
                <span className="license-text">{t('myDocumentCard.title')}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row all-inner-details p-0">
            <div className="col-12 ">
              <Link
                to={reverse(routes.dashboard.transaction.upload_documents, {
                  trans_id: transaction,
                })}
                className={`row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between border-bottom-gray`}
              >
                <div className="text-details flex-align-center">
                  <img
                    src={Images.document_upload}
                    alt="license icon"
                    className="img-fluid"
                  />
                  <div className="db-second">
                    <span className="license-text d-inline-block w-100">
                      {t('myDocumentCard.uploadDocument.title')}
                    </span>
                  </div>
                </div>
                <div className="db-right-inner">
                  {btnVisible ? (
                    <img
                      src={Images.arrow_right_gray}
                      alt="right_arrow_primary icon"
                      className="img-fluid"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </Link>
              <Link
                to={reverse(routes.dashboard.transaction.view_documents, {
                  trans_id: transaction,
                })}
                className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between pb-0"
              >
                <div className="text-details flex-align-center">
                  <img
                    src={Images.document_view}
                    alt="license icon"
                    className="img-fluid"
                  />
                  <div className="db-second">
                    <span className="license-text d-inline-block w-100">
                      {t('myDocumentCard.viewDocument')}
                    </span>
                  </div>
                </div>
                <div className="db-right-inner">
                  <img
                    src={Images.arrow_right_gray}
                    alt="right_arrow_primary icon"
                    className="img-fluid"
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default DocumentDashboard;


export default withTranslation()(DocumentDashboard);
