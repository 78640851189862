import { Get, Post } from "./headerIntercepter";
import { getAPIUrl } from "./Global";

export function login(data) {
  const url = getAPIUrl("auth.login");
  return Post(url, data, false);
}

export function fetchProfile() {
  const url = getAPIUrl("auth.profile");
  return Get(url);
}

export function resetPassword(data) {
  const url = getAPIUrl("auth.reset_password");
  return Post(url, data, false);
}

export function resetPasswordEmail(data) {
  const url = getAPIUrl("auth.reset_password_email");
  return Post(url, data, false);
}
