
import i18n from '../i18n'
export const CUSTOMER_NOTE = "CUSTOMER_NOTE";
export const CUSTOMER_STAFF_NOTE = "CUSTOMER_STAFF_NOTE";
export const CO_BUYER_NOTE = "CO_BUYER_NOTE";
export const CO_BUYER_STAFF_NOTE = "CO_BUYER_STAFF_NOTE";

export const DISASSOCIATED_CO_BUYER = "disassociated_co_buyer";
export const DISASSOCIATED = "disassociated";
export const NOTES_TYPES = {
  CUSTOMER_NOTE: CUSTOMER_NOTE,
  CUSTOMER_STAFF_NOTE: CUSTOMER_STAFF_NOTE,
  CO_BUYER_NOTE: CO_BUYER_NOTE,
  CO_BUYER_STAFF_NOTE: CO_BUYER_STAFF_NOTE,
};

export const PAYING_CASH = "paying_cash";
export const OUTSIDE_FINANCE_SOURCE = "outside_finance_source";
export const LEASE = "lease";
export const FINANCE = "finance";
export const UNDECIDED_DEAL_TYPE = "undecided";

export const DEAL_TYPES_ARR = [
  {
    key: LEASE,
    value: i18n.t('dealInformation.dealForm.dealInfoChoice.lease'),
  },
  {
    key: FINANCE,
    value: i18n.t('dealInformation.dealForm.dealInfoChoice.finance'),
  },
  {
    key: PAYING_CASH,
    value: i18n.t('dealInformation.dealForm.dealInfoChoice.cash'),
  },
  {
    key: OUTSIDE_FINANCE_SOURCE,
    value: i18n.t('dealInformation.dealForm.dealInfoChoice.outsideFinance'),
  },
  {
    key: UNDECIDED_DEAL_TYPE,
    value: i18n.t('dealInformation.dealForm.dealInfoChoice.undecided'),
  },
];

export const PURCHASE_TYPES = {
  PAYING_CASH: PAYING_CASH,
  OUTSIDE_FINANCE_SOURCE: OUTSIDE_FINANCE_SOURCE,
  LEASE: LEASE,
  FINANCE: FINANCE,
  UNDECIDED_DEAL_TYPE: UNDECIDED_DEAL_TYPE,
};

export const DELIVERED = "delivered";
export const COMPLETED = "completed";

export const HOUSING_STATUS = {
  OWNED: "owned",
  LIVE_WITH_RELATIVES: "living_with_relatives",
  I_DONT_PAY_RENT: "i_dont_pay_rent",
  RENT: "rent",
  OWN_FREE_AND_CLEAR: "own_free_and_clear",
  OTHER: "other",
};
