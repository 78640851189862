import React, { Component } from "react";
import {Button, Form, Input, InputNumber, message, Radio, Select} from "antd";
import { states } from "../../controller/states";
import TradeInfoAdded from "../modal/TradeInfoAdded";
import {
  addVehicleForTrade,
  getTransactionOne,
  updateVehicleForTrade,
} from "../../controller/api/customerAPI";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  methods,
  onNumberBlurred,
  onNumberFocused,
  onSelectBlurred,
  onSelectFocused,
  getAPIUrl,
} from "../../controller/Global";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { Image as Images } from "../Images";
import { Post } from "../../controller/headerIntercepter";
import { withTranslation } from "react-i18next";

const { Option } = Select;

class VehicleInformation extends Component {
  formRef = React.createRef();

  state = {
    visible: false,
    make: null,
    model: null,
    validation_mileage: [],
    isRegisteredOwner: true,
  };

  componentDidMount() {
    let { params } = this.props.match;
    if (params.method === methods.edit) {
      if (this.props.current_transaction && this.props.current_transaction?.id) {
        this.formRef.current.setFieldsValue({
          ...this.props.current_transaction?.vehicle_for_trade,
        });
      } else {
        getTransactionOne(this.props.user?.default_dealership, params.trans_id)
            .then((res) => {
              if (res.data?.vehicle_for_trade.id !== parseInt(params.id)) {
                message.error("Invalid URL for updating");
                history.push(routes.dashboard.self);
              } else {
                this.formRef.current.setFieldsValue({
                  ...res.data?.vehicle_for_trade,
                });
              }
            })
            .catch(() => {
              message.error("Error while fetching transaction details");
              history.push(routes.dashboard.self);
            });
      }

      let dealership = this.props.user.available_dealerships.find(
          (item, key) => item.id === this.props.user.default_dealership
      )

      if ('is_odometer_required' in dealership && dealership.is_odometer_required){
        this.setState({validation_mileage: [
            {
              required: true,
              message: "this field is required",
            },
          ]})
      }
    } else {
      this.formRef.current.setFieldsValue({
        registered_owner: this.props.user.first_name ? this.props.user.first_name : '',
        relationship_to_registered_owner: 'Self',
        state: this.props?.current_transaction?.address_information?.state_1 ?
            this.props?.current_transaction?.address_information?.state_1 : '',
      });
    }

  }

  showTradeInfo = (visible) => {
    this.setState({ visible });
  };

  handleTradeCheckBox = (data) => {
    const { t } = this.props;
    let { params } = this.props.match;
    this.formRef.current.validateFields().then((values) => {
      const { registration_front } = this.props;
      let form_data = new FormData();
      let options = {
        "have_vehicle_title": false,
        "is_vehicle_paid_off": false,
        "have_vehicle_paid_proof": false
      }

      for (let item of data) {
        if (item in options) {
          options[item] = true
        }
      }

      for (let key in options) {
        form_data.append(key, options[key] === true ? "1" : "0");
      }

      for (let key in values) {
        if ( !values[key] ) {
        } else {
          form_data.append(key, values[key]);
        }
      }
      form_data.append("transaction", this.props.transaction);

      //edit
      registration_front &&
      registration_front.length > 0 &&
      typeof registration_front !== "string" &&
      form_data.append(
          "registration_front",
          registration_front[0].originFileObj
      );

      if (params.method === methods.edit) {
        updateVehicleForTrade(form_data, params.id)
            .then(() => {
              message.success(
              t('vehicleForTradeInformation.vehicleForTradeForm.warningMessage.successfulUpdate')
              );
              history.push(routes.dashboard.self);
            })
            .catch((err) => {
              if (err.response) {
                Object.keys(err.response.data).forEach((e) => {
                  message.error(`${e}: ${err.response.data[e]}`);
                });
              }
            });
      } else {
        addVehicleForTrade(form_data)
            .then(() => {
              message.success(
                  t('vehicleForTradeInformation.vehicleForTradeForm.warningMessage.successfulAdd')
              );
              this.props.history.push(routes.dashboard.self);
            })
            .catch((err) => {
              if (err.response) {
                Object.keys(err.response.data).forEach((e) => {
                  message.error(err.response.data[e]);
                });
              }
            });
      }
    });
  };

  handleSubmit = () => {
    this.formRef.current.validateFields().then((values) => {
      this.setState({ values: values });
      this.setState({ visible: true });
    });
  };

  handleChange = (e) => {
    this.formRef.current.setFieldsValue({
      vin_number: e.target.value.toUpperCase(),
    });

    // ......Vin-number Checked and Hitting API.......

    //eslint-disable-next-line
    if (e.target.name === "vin_number" && e.target.value.length == 17) {
      const data = { vin: e.target.value };
      Post(getAPIUrl("client.vin"), data)
        .then((response) => {
          if (response.data) {
            let make_temp = response?.data?.make;
            let model_temp = response?.data?.model;
            const make_temp_two = make_temp?.split(" ");
            const model_temp_two = model_temp?.split(" ");

            let mak = make_temp_two
              ?.map((word) => {
                return (
                  word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase()
                );
              })
              .join(" ");
            let modl = model_temp_two
              ?.map((word) => {
                return (
                  word[0]?.toUpperCase() + word?.substring(1)?.toLowerCase()
                );
              })
              .join(" ");

            this.setState({
              year: response.data.year,
              model: modl,
              make: mak,
            });
            this.formRef.current.setFieldsValue({
              year: response.data.year,
              model: modl,
              make: mak,
            });
          }
        })
        .catch(() => {
          message.error("Invalid subscription/credits");
        });
    }
  };


  handleChangeRegisteredOwner = (e) => {
    this.setState({isRegisteredOwner: e.target.value})
    if ( e.target.value === true) {
      this.formRef.current.setFieldsValue({
        registered_owner: this.props?.user?.first_name ? this.props.user.first_name : '',
        relationship_to_registered_owner: 'Self',
        state: this.props?.current_transaction?.address_information?.state_1 ?
            this.props?.current_transaction?.address_information?.state_1 : '',
      });
    } else {
      this.formRef.current.setFieldsValue({
        registered_owner: '',
        relationship_to_registered_owner: '',
        state: '',
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <div className="transition-information-heading w-100 font-weight-bold mb-3">
            {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.formTitle')}
          </div>
          <p>
            {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.subTitle')}
          </p>
          <div className="row">
            <Form ref={this.formRef} className="w-100 mt-5">
              <div className="row">
                <div className="col-12">
                  <label className="font-weight-bold">
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.vinLabel')}
                  </label>
                  <Form.Item
                      name="vin_number"
                      label={""}
                      className={
                        "group-floating-label group-floating-label-second"
                      }
                  >
                    <Input
                        autoFocus
                        name={"vin_number"}
                        onChange={this.handleChange}
                        maxLength={17}
                        placeholder=" "
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-12">
                  <label className="font-weight-bold">
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.makeLabel')} *
                  </label>
                  <Form.Item
                      name="make"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label group-floating-label-second"
                      }
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.modelLabel')}*
                  </label>
                  <Form.Item
                      name="model"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label group-floating-label-second"
                      }
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div id={"year"} className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.yearLabel')}*
                  </label>
                  <Form.Item
                      name="year"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <InputNumber
                        onFocus={() => onNumberFocused("year")}
                        onBlur={() => onNumberBlurred("year")}
                        placeholder=""
                    />
                  </Form.Item>
                </div>
                <div id={"mileage"} className="col-12 col-sm-12 col-lg-6 mb-4">
                  <label className="font-weight-bold">
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.mileageLabel')}
                  </label>
                  <Form.Item
                      name="mileage"
                      rules={this.state.validation_mileage}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <InputNumber
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onFocus={() => onNumberFocused("mileage")}
                        onBlur={() => onNumberBlurred("mileage")}
                        placeholder=""
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-12 mb-5">
                  <p className="font-weight-normal all-information-p">
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.areYouTheRegisteredOwner')}
                  </p>
                  <div className="mx-0 horizon-radio">
                    <Radio.Group
                        className="w-50"
                        onChange={this.handleChangeRegisteredOwner}
                        value={this.state.isRegisteredOwner}
                    >
                      <Radio
                          className="w-100 flex-align-center px-3"
                          value={true}
                      >
                        {t('utils.yes')}
                      </Radio>
                      <Radio
                          className="w-100 flex-align-center px-3"
                          value={false}
                      >
                        {t('utils.no')}
                      </Radio>
                    </Radio.Group>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-6">
                  <label
                      className="font-weight-bold"
                  >
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.nameOfRegisteredOwnerLabel')} *
                  </label>
                  <Form.Item
                      name="registered_owner"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label group-floating-label-second"
                      }
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-6 col-lg-6">
                  <label
                      className="font-weight-bold"
                  >
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.relationshipToTheRegisteredOwnerLabel')}
                  </label>
                  <Form.Item
                      name="relationship_to_registered_owner"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label group-floating-label-second"
                      }
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div
                    className="col-12 col-sm-12 col-lg-12 select_floating"
                    id={"select_state"}
                >
                  <label
                      className="font-weight-bold"
                  >
                    {t('vehicleForTradeInformation.vehicleForTradeForm.vehicleForTradeFormStepTwo.stateLabel')} *
                  </label>
                  <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "select-float group-floating-label group-floating-label-second position-relative"
                      }
                  >
                    <Select
                        onFocus={() => onSelectFocused("select_state")}
                        onBlur={() => onSelectBlurred("select_state")}
                        suffixIcon={
                          <img
                              src={Images.down_arrow_select}
                              width={15}
                              alt="down arrow"
                              className="img-fluid"
                          />
                        }
                        placeholder=""
                    >
                      {states.map((item, index) => (
                          <Option
                              key={`state_${index}`}
                              value={item.abbreviation}
                          >{`${item.name} (${item.abbreviation})`}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="col-12 p-0">
          <div className="steps-action row flex-align-center justify-content-between mx-0">
            <Button onClick={() => this.props.back()}>{t('utils.back')}</Button>
            <Button
                htmlType="submit"
                type="primary"
                onClick={() => this.handleSubmit()}
            >
              {t('utils.submit')}
            </Button>
          </div>
        </div>
        <TradeInfoAdded
            visible={this.state.visible}
            onClose={() => this.showTradeInfo(false)}
            submitCheckBoxes={(val) => this.handleTradeCheckBox(val)}
            tradeData={this.props?.current_transaction?.vehicle_for_trade}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {current_transaction: state.current_transaction, user: state.user};
};

export default withTranslation()(connect(mapStateToProps)(withRouter(VehicleInformation)));
