import React, { Component } from "react";
import { Image as Images } from "../Images";
import { Button, Form, message, Modal } from "antd";
import { NavLink } from "react-router-dom";
import { routes } from "../../controller/routes";
import { resetPasswordEmail } from "../../controller/AuthService";
import { history } from "../../controller/history";
import TextField from "@mui/material/TextField";

class ForgotPassword extends Component {
  state = {
    formFilled: false,
    visible: false,
    linkJustSent: false,
    submitLoading: false,
  };
  formRef = React.createRef();

  handleOK = () => {
    this.setState({ visible: false });
    this.formRef.current.resetFields();
  };

  handleSubmit = (values) => {
    this.setState({ submitLoading: true });
    values["platform"] = "client";
    resetPasswordEmail(values)
      .then(() => {
        message.success("Password Reset Email Sent");
        // history.push(routes.login);
        this.handleChangeJustSent(true);
      })
      .catch((err) => {
        if (err.response) {
          if (Object.keys(err.response.data).includes("not_exist")) {
            this.setState({ visible: true });
          }
        }
      })
      .finally(() => {
        this.setState({ submitLoading: false });
      });
  };

  onFormChange = (allFields) => {
    if (allFields.every((item) => !!item.value))
      this.setState({ formFilled: true });
    else this.setState({ formFilled: false });
  };
  handleCancel = () => {
    this.handleChangeJustSent(false);
    history.push(routes.login);
  };
  handleChangeJustSent = (val) => {
    this.setState({ linkJustSent: val });
  };
  render() {
    const { formFilled, linkJustSent, submitLoading } = this.state;
    return (
      <div className="container-fluid h-100 login-div-fluid">
        <div className="row login-main-row mx-auto">
          <div className="col-12 logo-div-main">
            <NavLink to={routes.dashboard.self} className="d-inline-block">
              <Images.VirtualLogo/>
            </NavLink>
          </div>
          <div className="col-12 login-card-main bg-white">
            <h6 className="text-center mb-2">
              {linkJustSent ? "Reset Link Sent!" : "Forgot Password"}
            </h6>
            <p className="text-center header-p-tag">
              {linkJustSent
                ? " Please check your email to access the reset password link."
                : "Type your email to receive a link to reset your password."}
            </p>
            <div className="login_form_design w-100">
              {!linkJustSent ? (
                <Form
                  ref={this.formRef}
                  onFieldsChange={(changedFields, allFields) => {
                    this.onFormChange(allFields);
                  }}
                  onFinish={this.handleSubmit}
                >
                  <Form.Item
                    name="email"
                    label={""}
                    rules={[
                      {
                        required: true,
                        message: "this field is required",
                      },
                      {
                        type: "email",
                      },
                    ]}
                    className={"group-floating-label"}
                  >
                    <TextField
                      autoCapitalize={"off"}
                      className={"w-100"}
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                    />
                  </Form.Item>
                  <Form.Item className="forgot-password-submit-btn-item">
                    <Button
                      htmlType="submit"
                      type="primary"
                      disabled={!formFilled}
                      className={`w-100 text-white border-0 font-weight-normal ${
                        !formFilled && "in-active"
                      }`}
                      loading={submitLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                  <Form.Item className="forgot-password-cancel-btn-item">
                    <Button
                      htmlType="button"
                      // type="secondary"
                      // disabled={!formFilled}
                      className={`w-100 text-white border-0 font-weight-normal forgot-password-cancel-btn`}
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <div className="col-12">
                  <Button
                    htmlType="button"
                    type="primary"
                    className={`w-100 text-white border-0 font-weight-normal forgot-passowrd-exit-login-btn`}
                    onClick={this.handleCancel}
                  >
                    Exit to Login
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          title={false}
          open={this.state.visible}
          onOk={() => this.handleOK()}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button key="submit" type="primary" onClick={() => this.handleOK()}>
              Input another email address
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.error_prompt_img}
              />
              <p
                className={`font-weight-bold text-capitalize mb-2 text-color-red`}
              >
                The email does not exist in Eunifi's system.
              </p>
              <small className="d-inline-block w-100">
                Please input another email address.
              </small>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ForgotPassword;
