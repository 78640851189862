import React, { Component } from "react";
import { routes } from "../../controller/routes";
import { Button, Modal } from "antd";
import { Image as Images } from "../Images";
import { withRouter } from "react-router-dom";
import {
  fetchTransactionAction,
  setCurrentTransactionAction,
} from "../../store/actions/transactionAction";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

class PopConfirm extends Component {
  handleOK = () => {
    if (this.props.widget) {
      this.props.fetchTransactionAction(this.props.user.default_dealership);
      this.props.setCurrentTransactionAction(null);
    }
    this.props.history.push(routes.dashboard.self);
  };

  handleCancel = () => {
    this.props.handleExit();
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={false}
        open={this.props.visible}
        onOk={() => this.handleOK()}
        onCancel={() => this.handleCancel()}
        maskClosable={false}
        className={`main-modal exit-modal-back ${
          this.props.exit ? "" : "close-btn-none"
        }`}
        width="33.889%"
        centered
        footer={[
          <Button key="back" onClick={() => this.handleCancel()}>
            {t('utils.cancel')}
          </Button>,
          <Button
            key="submit"
            type={this.props.exit ? "danger" : "primary"}
            onClick={() => this.handleOK()}
          >
            {this.props.exit ? t('utils.exitBtn') : "OK"}
          </Button>,
        ]}
      >
        <div className="row modal-inner-row-data">
          <div className="col-12 text-center">
            <img
              alt="images"
              className="img-fluid"
              src={
                this.props.exit
                  ? Images.error_prompt_img
                  : Images.check_icon_rounded
              }
            />
            <p
              className={`font-weight-bold text-capitalize mb-2 ${
                this.props.exit ? "text-color-red" : ""
              }`}
            >
              {this.props.children}
            </p>
            {this.props.exit && (
              <small className="d-inline-block w-100">
                {this.props.show_custom
                  ? this.props.custom_message
                  : t('utils.will_not_save')}

              </small>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(connect(null, {
  setCurrentTransactionAction,
  fetchTransactionAction,
})(withRouter(PopConfirm)));
