import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, message, Radio, Select } from "antd";
import {
  methods,
  hard_credit_type,
  onNumberBlurred,
  onNumberFocused,
  onSelectBlurred,
  onSelectFocused,
  phoneValidationPattern,
} from "../../controller/Global";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import { connect } from "react-redux";
import {
  addHardCredit,
  updateHardCredit,
} from "../../controller/api/customerAPI";
import { Image as Images } from "../Images";
import HiddenSsnInput from "../../controller/HiddenSsnInput";
import HiddenDobInput from "../HiddenDobInput";
import {SsnValidator, AgeValidator} from "../../utils/validators";
import { DateTime } from "luxon";
import {useTranslation} from "react-i18next";


export const BasicInformation = (props) => {
  const formRef = useRef(null);

  const [creditType, setCreditType] = useState(hard_credit_type.personal);
  const [selectedDate, setSelectedDate] = useState(utils('en').getToday());
  const [dealStatus, setDealStatus] = useState();
  const [source, setSource] = useState();
  const { t, i18n } = useTranslation();
  const lng = navigator.language;


  const onDateChange = (value) => {
    this.setState({ selectedDate: value });
  };

  const formatDate = () => {
    const { selectedDate } = this.state;
    return `${selectedDate.month}/${selectedDate.day}/${selectedDate.year}`;
  };


  const formatSsn = (ssn) => {
    return ssn
      .match(/\d*/g)
      .join("")
      .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
      .slice(1)
      .join("-");
  };


  const handleFillForm = () => {
    const { current_transaction, hard_credit } = props;

    let ssn_new;
    let ssn_new_two;
    let ssn_new_three;
    if (
        props.match.params.method === methods.edit &&
        hard_credit &&
        !hard_credit.is_verified
    ) {
      setCreditType(hard_credit.credit_type)
      const _dob = DateTime.fromISO(hard_credit.dob);
      const dob = {
        day: _dob.day,
        month: _dob.month,
        year: _dob.year,
      };
      if (hard_credit?.ssn) {
        if (
            hard_credit.ssn.indexOf("-") !== -1
        ) {
          ssn_new = hard_credit.ssn;
        } else {
          ssn_new = formatSsn(hard_credit.ssn);
        }
      }
      // prefilling-cell-number-from-personal-info
      formRef?.current.setFieldsValue({
        ...hard_credit,
        cell_phone: current_transaction?.personal_information.phone,
        dob: dob,
        ssn: ssn_new,
        source: hard_credit.source.split("_").join(" "),
        deal_status: hard_credit.deal_status.split("_").join(" "),
      });
      setSelectedDate(dob);
      setSource(hard_credit.source.split("_").join(" "));
      setDealStatus(hard_credit.deal_status.split("_").join(" "));
    }
    //add case
    else {
      //if hard credit exist on same transaction
      if (hard_credit) {
        setCreditType(hard_credit.credit_type)
        const _dob = DateTime.fromISO(hard_credit.dob);
        const dob = {
          day: _dob.day,
          month: _dob.month,
          year: _dob.year,
        };
        if (hard_credit?.ssn) {
          if (hard_credit.ssn.indexOf("-") !== -1) {
            ssn_new_three = hard_credit.ssn;
          } else {
            ssn_new_three = this.formatSsn(hard_credit.ssn);
          }
        }
        formRef?.current.setFieldsValue({
          ...hard_credit,
          dob: dob,
          ssn: ssn_new_three,
        });
        setSelectedDate(dob);
        setSource(hard_credit.source.split("_").join(" "));
        setDealStatus(hard_credit.deal_status.split("_").join(" "));
      } else {
        //if no hard credit available (fresh entry)
        const { personal_information, pre_qualification, license } = current_transaction;
        if (personal_information) {
          const _dob = DateTime.fromISO(personal_information.dob);
          const dob = {
            day: _dob.day,
            month: _dob.month,
            year: _dob.year,
          };
          formRef.current.setFieldsValue({
            ...personal_information,
            cell_phone: personal_information.phone,
            dob: dob,
          });
          setSelectedDate(dob);
        }
        if (pre_qualification && pre_qualification.ssn) {
          if (pre_qualification.ssn.indexOf("-") !== -1) {
            ssn_new_two = pre_qualification.ssn;
          } else {
            ssn_new_two = formatSsn(pre_qualification.ssn);
          }

          formRef.current?.setFieldsValue({
            ssn: ssn_new_two,
          });
        }
        if (license) {
          formRef.current?.setFieldsValue({
            driver_licence_number: license.id_number,
            driver_licence_state: license.state,
          });
        }
        setSource(current_transaction.deal_source.split("_").join(" "));
        setDealStatus(current_transaction.status.split("_").join(" "));
      }
    }
  }

  const handleSubmit = (values) => {
    const { current_transaction } = props;
    values["credit_type"] = creditType;
    values["transaction"] = props.trans_id;
    values["customer"] = props.user.id;
    values["source"] = source;
    values["deal_status"] = dealStatus;
    if (values.ssn.indexOf("-") !== -1) {
      values.ssn = values.ssn.replace(/[^0-9]/g, "");
    }
    values.dob = `${values.dob.year}-${values.dob.month}-${values.dob.day}`;

    if (!values.suffix) {
      delete values.suffix;
    }
    if (
      current_transaction.hard_credit_info &&
      !current_transaction.hard_credit_info.is_verified
    ) {
      updateHardCredit(current_transaction.hard_credit_info.id, values)
        .then((resp) => props.next(resp.data))
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`[${e}]: ${err.response.data[e]}`);
            });
          }
        });
    } else {
      addHardCredit(values)
        .then((resp) => {
          props.next(resp.data);
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`[${e}]: ${err.response.data[e]}`);
            });
          }
        });
    }
  };

  const ssnValidator = (rule, value, callback) => {
    if (!SsnValidator(value)) {
      callback("Please input valid SSN");
      return;
    }
    callback();
  };

  const dobValidator = (rule, value, callback) => {
    if (!AgeValidator(value)) {
      callback("Please input DOB between 16-95 years old");
      return;
    }
    callback();
  };


  useEffect(() => {
    handleFillForm()
  },[])

    return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <div className="transition-information-heading w-100 font-weight-bold mb-3">
            {t('hardCreditForm.hcFormStepOne.title')}
          </div>
          <p>
            {t('hardCreditForm.hcFormStepOne.content')}
          </p>

          <div className="row mx-0">
            <Form
              ref={formRef}
              className="w-100 mt-3"
              onFinish={handleSubmit}
            >
              <div className="row">
                  <React.Fragment>
                    <div className="col-12 col-sm-12 col-lg-6">
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.firstNameLabel')} *</label>
                      <Form.Item
                          name="first_name"
                          id="first_name"
                          label={""}
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                          className={
                            "group-floating-label group-floating-label-second"
                          }
                      >
                        <Input
                            id="first_name"
                            placeholder=" "
                            className="inp-form-wrap"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.middleNameLabel')}</label>
                      <Form.Item
                          name="middle_name"
                          label={""}
                          className={
                            "group-floating-label group-floating-label-second"
                          }
                      >
                        <Input
                            placeholder=" "
                            className="inp-form-wrap"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.lastNameLabel')} *</label>
                      <Form.Item
                          name="last_name"
                          label={""}
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                          className={
                            "group-floating-label group-floating-label-second"
                          }
                      >

                        <Input
                            placeholder=" "
                            className="inp-form-wrap"
                        />
                      </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-lg-6 select_floating"
                        id={"select_suffix"}
                    >
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.suffixLabel')}</label>
                      <Form.Item
                          name="suffix"
                          className={
                            "group-floating-label select-float group-floating-label-second"
                          }
                      >
                        <Select
                            onFocus={() => onSelectFocused("select_suffix")}
                            onBlur={() => onSelectBlurred("select_suffix")}
                            placeholder={""}
                            suffixIcon={
                              <img
                                  src={Images.down_arrow_select}
                                  width={15}
                                  alt="down arrow"
                                  className="img-fluid"
                              />
                            }
                        >
                          <Select.Option
                              disabled
                              className="disbaled-optn-color"
                              value={null}
                          >
                            {"Suffix"}
                          </Select.Option>
                          <Select.Option value="Sr">Sr</Select.Option>
                          <Select.Option value="Jr">Jr</Select.Option>
                          <Select.Option value="II">II</Select.Option>
                          <Select.Option value="III">III</Select.Option>
                          <Select.Option value="IV">IV</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-6">
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.DOBLabel')} *</label>
                      <Form.Item
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                            {validator: dobValidator},
                          ]}
                          className={
                            "group-floating-label select-float group-floating-label-second"
                          }
                      >
                        <HiddenDobInput value={selectedDate} onchange={( value ) => setSelectedDate(value)}/>
                      </Form.Item>
                    </div>
                  </React.Fragment>

                <div id={"ssn"} className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.ssnLabel')} *</label>
                  <Form.Item
                      name="ssn"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {validator: ssnValidator},
                      ]}
                      className={
                        "group-floating-label group-floating-label-second"
                      }
                  >
                    <HiddenSsnInput
                        placeholder=" "
                    />
                  </Form.Item>
                </div>
                <div
                    id={"home_phone"}
                    className="col-12 col-sm-12 col-lg-6 phone-no"
                >
                  <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.homePhoneNumberLabel')}</label>
                  <Form.Item
                      name="home_phone"
                      rules={[
                        {required: false},
                        {
                          pattern: phoneValidationPattern,
                          message: "Minimum digit should be 10",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second position-relative"
                      }
                  >
                    <InputNumber
                        formatter={(value) =>
                            `${value}`
                                .match(/\d*/g)
                                .join("")
                                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                .slice(1)
                                .join("-")
                                .replace(/-*$/g, "")
                        }
                        parser={(value) => value.replace(/\$\s?|(-*)/g, "")}
                        onFocus={() => onNumberFocused("home_phone")}
                        onBlur={() => onNumberBlurred("home_phone")}
                        placeholder=""
                    />
                  </Form.Item>
                </div>
                <div
                    id={"cell_phone"}
                    className="col-12 col-sm-12 col-lg-6 phone-no"
                >
                  <label className="font-weight-bold">{t('hardCreditForm.hcFormStepOne.cellPhoneNumberLabel')} *</label>
                  <Form.Item
                      name="cell_phone"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {
                          pattern: phoneValidationPattern,
                          message: "Minimum digit should be 10",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second position-relative"
                      }
                  >
                    <InputNumber
                        formatter={(value) =>
                            `${value}`
                                .match(/\d*/g)
                                .join("")
                                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                .slice(1)
                                .join("-")
                                .replace(/-*$/g, "")
                        }
                        parser={(value) => value.replace(/\$\s?|(-*)/g, "")}
                        onFocus={() => onNumberFocused("cell_phone")}
                        onBlur={() => onNumberBlurred("cell_phone")}
                        placeholder=""
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="steps-action flex-align-center justify-content-between w-100">
                <Button
                  style={{ marginRight: "11%" }}
                  onClick={() => props.prev()}
                >
                  {t('utils.back')}
                </Button>
                <Button htmlType="submit" type="primary">
                  {t('utils.next')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
  };
};

export default connect(mapStateToProps)(BasicInformation);
