import { getAPIUrl } from "../Global";
import { Get, Patch, Post, Remove } from "../headerIntercepter";

export function addTransaction(dealership, data) {
  const url = getAPIUrl("client.transaction.self", { dealership: dealership });
  return Post(url, data);
}

export function getTransaction(dealership, params = {}) {
  const url = getAPIUrl("client.transaction.self", { dealership: dealership});
  return Get(url, params);
}

export function getNotes(params = {}) {
  const url = getAPIUrl("client.notes");
  return Get(url, params);
}
export function addNotes(data) {
  const url = getAPIUrl("client.notes");
  return Post(url, data);
}

export function getHistory(dealership, params = {}) {
  const url = getAPIUrl("client.transaction.history", {
    dealership: dealership,
  });
  return Get(url, params);
}

export function getCobuyerTransaction(dealership, id) {
  const url = getAPIUrl("client.transaction.co_buyer", {
    dealership: dealership,
    id: id,
  });
  return Get(url);
}

export function loadMoreTransaction(next_url) {
  return Get(next_url);
}

export function loadMoreHistory(next_url) {
  return Get(next_url);
}

export function updateTransaction(dealership, id, data) {
  const url = getAPIUrl("client.transaction.one", {
    dealership: dealership,
    id: id,
  });
  return Patch(url, data);
}

export function getTransactionOne(dealership, id) {
  const url = getAPIUrl("client.transaction.one", {
    dealership: dealership,
    id: id,
  });
  return Get(url);
}
export function getTransactionHistory(dealership, id) {
  const url = getAPIUrl("client.transaction.history", {
    dealership: dealership,
  });
  return Get(url, { transaction: id });
}
export function UpdateTransactionSeen(dealership, id) {
  const url = getAPIUrl("client.transaction.update_seen", {
    dealership: dealership,
    id: id,
  });
  return Get(url);
}

export function addPersonalInfo(data) {
  const url = getAPIUrl("client.personal_info.self");
  return Post(url, data);
}

export function addLicense(data) {
  const url = getAPIUrl("client.license.self");
  return Post(url, data);
}

export function updateLicense(data, id) {
  const url = getAPIUrl("client.license.one", { id });
  return Patch(url, data);
}

export function getInsurance(transactionId) {
  const url = getAPIUrl("v3.insurance.all");
  return Get(url, { transaction: transactionId });
}

export function updateInsurance(data, id) {
  const url = getAPIUrl("v3.insurance.one", { id });
  return Patch(url, data);
}

export function addInsurance(data) {
  const url = getAPIUrl("v3.insurance.all");
  return Post(url, data);
}

export function addIdentityVerification(data) {
  const url = getAPIUrl("v3.transaction.all");
  return Post(url, data);
}

export function getAddresses(data) {
  const url = getAPIUrl("v3.additional_address.all");
  return Get(url, data);
}

export function getEmployments(data) {
  const url = getAPIUrl("v3.employment_information.all");
  return Get(url, data);
}




export function updatePersonalInfo(data, id) {
  const url = getAPIUrl("client.personal_info.one", { id });
  return Patch(url, data);
}

export function addAddressInfo(data) {
  const url = getAPIUrl("client.address_info.self");
  return Post(url, data);
}

export function updateAddressInfo(data, id) {
  const url = getAPIUrl("client.address_info.one", { id });
  return Patch(url, data);
}

export function addDealInfo(data) {
  const url = getAPIUrl("client.deal_info.self");
  return Post(url, data);
}

export function updateDealInfo(data, id) {
  const url = getAPIUrl("client.deal_info.one", { id });
  return Patch(url, data);
}

export function addVehicleForPurchase(data) {
  const url = getAPIUrl("client.vehicle_for_purchase.self");
  return Post(url, data);
}

export function updateVehicleForPurchase(data, id) {
  const url = getAPIUrl("client.vehicle_for_purchase.one", { id });
  return Patch(url, data);
}

export function addVehicleForTrade(data) {
  const url = getAPIUrl("client.vehicle_for_trade.self");
  return Post(url, data);
}

export function updateVehicleForTrade(data, id) {
  const url = getAPIUrl("client.vehicle_for_trade.one", { id });
  return Patch(url, data);
}

export function addPreQualification(data) {
  const url = getAPIUrl("client.pre_qualification.self");
  return Post(url, data);
}

export function updatePreQualification(data, id) {
  const url = getAPIUrl("client.pre_qualification.one.self", { id });
  return Patch(url, data);
}

export function addDocument(data) {
  const url = getAPIUrl("client.deal_jacket.self");
  return Post(url, data);
}

export function addDocumentIdentity(data) {
  const url = getAPIUrl("client.deal_jacket.identity");
  return Post(url, data);
}

export function addBankInfo(data) {
  const url = getAPIUrl("client.bank_info.self");
  return Post(url, data);
}

export function updateBankInfo(id, data) {
  const url = getAPIUrl("client.bank_info.one", { id: id });
  return Patch(url, data);
}

export function applyReferral(data) {
  const url = getAPIUrl("client.referral_dealership");
  return Post(url, data);
}

export function removeDocument(id) {
  const url = getAPIUrl("client.deal_jacket.one", { id: id });
  return Remove(url);
}

export function transDealJacket(id) {
  const url = getAPIUrl("client.trans_deal_jacket", { trans_id: id });
  return Get(url);
}

export function addHardCredit(data) {
  const url = getAPIUrl("client.hard_credit.self");
  return Post(url, data);
}

export function getHardCredit(id, params = {}) {
  const url = getAPIUrl("client.hard_credit.one.self", { id: id });
  return Get(url, params);
}

export function getStockData(dealership, stock_number, id, params = {}) {
  const url = getAPIUrl("client.get_stock_data", {
    dealership: dealership,
    stockNumber: stock_number,
    id: id,
  });
  return Get(url, params);
}

export function updateHardCredit(id, data) {
  const url = getAPIUrl("client.hard_credit.one.self", { id: id });
  return Patch(url, data);
}

export function verifyHardCredit(id, data) {
  const url = getAPIUrl("client.hard_credit.one.verify", { id: id });
  return Post(url, data);
}
export function verifyPreQualification(id, data) {
  const url = getAPIUrl("client.pre_qualification.one.verify", { id: id });
  return Post(url, data);
}

export function addAdditionalAddress(data) {
  const url = getAPIUrl("v3.additional_address.all");
  return Post(url, data);
}

export function updateAdditionalAddress(id, data) {
  const url = getAPIUrl("v3.additional_address.one", { id: id });
  return Patch(url, data);
}

export function removeAdditionalAddress(id) {
  const url = getAPIUrl("v3.additional_address.one", { id: id });
  return Remove(url);
}

export function addEmploymentInfo(data) {
  const url = getAPIUrl("v3.employment_information.all");
  return Post(url, data);
}

export function updateEmploymentInfo(id, data) {
  const url = getAPIUrl("v3.employment_information.one", { id: id });
  return Patch(url, data);
}

export function removeEmploymentInfo(id) {
  const url = getAPIUrl("v3.employment_information.one", { id: id });
  return Remove(url);
}

export function addCoBuyer(trans_id, data) {
  const url = getAPIUrl("client.co_buyer", { trans_id });
  return Post(url, data);
}

export function signDocument(data) {
  const url = getAPIUrl("client.sign_document");
  return Post(url, data);
}

export function ValidatePassword(data) {
  const url = getAPIUrl("auth.check_password");
  return Post(url, data);
}

export function ChangePassword(data) {
  const url = getAPIUrl("auth.change_password");
  return Patch(url, data);
}

export function removeCoBuyer(dealership, trans_id) {
  const url = getAPIUrl("client.transaction.remove_co_buyer", {
    dealership,
    id: trans_id,
  });
  return Remove(url);
}

export function signPrivacy(id, data) {
  const url = getAPIUrl("client.privacy", { dealership: id });
  return Post(url, data);
}

export function signPrivacyPolicy(id, data) {
  const url = getAPIUrl("client.privacy_update", { dealership: id });
  return Post(url, data);
}

export function setupComplete(id) {
  const url = getAPIUrl("client.setup_complete", { id });
  return Post(url);
}

export function requestOTP(data) {
  const url = getAPIUrl("client.request_otp");
  return Post(url, data);
}

export function verifyOTP(data) {
  const url = getAPIUrl("client.verify_otp");
  return Post(url, data);
}


export function requestOTPV3(data) {
  const url = getAPIUrl("v3.otp.request_otp");
  return Post(url, data);
}

export function verifyOTPV3(data) {
  const url = getAPIUrl("v3.otp.verify_otp");
  return Post(url, data);
}

export function getZipcode(data) {
  const url = getAPIUrl("client.zipcode");
  return Post(url, data);
}
//old api for tech-support
// export function createTechQuery(data) {
//   const url = getAPIUrl("client.tech_support");
//   return Post(url, data);
// }

// new api for tech support(hubspot)
export function createTechQuery(data) {
  const url = getAPIUrl("client.tech");
  return Post(url, data);
}
//cancelling Prompts
export function cancelPromptAfterOneTime(data) {
  const url = getAPIUrl("client.login_prompt");
  return Post(url, data);
}

//updating/changing emails
export function ChangeEmail(data) {
  const url = getAPIUrl("client.update_email");
  return Post(url, data);
}

export function addPersonalReferenceHardCredit(data) {
  const url = getAPIUrl("client.personal_reference.self");
  return Post(url, data);
}
export function editPersonalReferenceHardCredit(id, data) {
  const url = getAPIUrl("client.personal_reference.one", { id });
  return Patch(url, data);
}

export function getPersonalReferenceByCustomerId(customerId) {
  const url = getAPIUrl("v3.personal_reference.all");
  return Get(url, { customer_id: customerId, ordering: 'id' });
}


export function addPersonalReference(data) {
  const url = getAPIUrl("v3.personal_reference.all");
  return Post(url, data);
}

export function updatePersonalReference(data, id) {
  const url = getAPIUrl("v3.personal_reference.one", { id });
  return Patch(url, data);
}


export function updateCustomerInfoByID(data, id) {
  const url = getAPIUrl("v3.customer.one", { id });
  return Patch(url, data);
}

export function preScreenReportTrigger(data, id) {
  const url = getAPIUrl("v3.deal.pre_screen_report", { id });
  return Get(url, data);
}




