import React, { Component } from "react";
import { Button, Form, Input, Select, InputNumber, message } from "antd";
import {
  addVehicleForPurchase,
  getTransactionOne,
  updateVehicleForPurchase,
} from "../../controller/api/customerAPI";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  methods,
  onNumberBlurred,
  onNumberFocused,
} from "../../controller/Global";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { withTranslation } from "react-i18next";
const {Option} = Select;

class VehiclePurchaseStepTwo extends Component {
  formRef = React.createRef();

  state = {
    make: null,
    model: null,
    disabled: false,
    checked: false,
  };

  handleSubmit = () => {
    let { params } = this.props.match;
    this.formRef.current.validateFields().then((values) => {
      values["stock_number"] = this.props.vehicle_purchase_data?.stock;
      values["transaction"] = this.props.transaction;
      values["msrp"] = values["msrp"]?.replace(/,/g, "");
      if (params.method === methods.edit) {
        updateVehicleForPurchase(values, params.id)
          .then(() => {
            this.props.onSubmit();
          })
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(`${e}: ${err.response.data[e]}`);
              });
            }
          });
      } else {
        addVehicleForPurchase(values)
          .then(() => this.props.onSubmit())
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(err.response.data[e]);
              });
            }
          });
      }
    });
  };

  componentDidMount() {
    let { params } = this.props.match;
    const { t } = this.props;
    if (this.props.from_api && this.props.vehicle_purchase_data?.VIN) {
      const { vehicle_purchase_data } = this.props;
      let msrp = ""
      if (vehicle_purchase_data.msrp){
        msrp = vehicle_purchase_data.msrp.includes(".")
          ? vehicle_purchase_data.msrp.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : vehicle_purchase_data.msrp.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ".00"
      }
      this.formRef.current.setFieldsValue({
        vin_number: vehicle_purchase_data.VIN,
        make: vehicle_purchase_data.make,
        year: parseInt(vehicle_purchase_data.year),
        model: vehicle_purchase_data.model,
        msrp: msrp,
      });
    } else {
      if (this.props.current_transaction && this.props.current_transaction.id) {
        this.formRef.current.setFieldsValue({
         ...this.props.current_transaction.vehicle_for_purchase
        });
        //do nothing
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.vehicle_for_purchase.id !== parseInt(params.id)) {
              message.error(t('utils.warningMessage.InvalidURL'));
              history.push(routes.dashboard.self);
            } else {
              this.formRef.current.setFieldsValue({
                ...res.data.vehicle_for_purchase,
                msrp: res.data.vehicle_for_purchase.msrp.includes(".")
                  ? res.data.vehicle_for_purchase.msrp.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )
                  : res.data.vehicle_for_purchase.msrp.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    ) + ".00",
              });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
    }
  }



  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <div className="transition-information-heading w-100 font-weight-bold mb-3">
            {t('vehicleForPurchaseInformation.vehicleForPurchaseForm.vehicleForPurchaseFormStepTwo.formTitle')}
          </div>
          <p className="font-weight-normal">
            {t('vehicleForPurchaseInformation.vehicleForPurchaseForm.vehicleForPurchaseFormStepTwo.subTitle')}
          </p>

          {this.props.match.params.method !== methods.edit && (
            <p className="subheading">
              {t('vehicleForPurchaseInformation.vehicleForPurchaseForm.vehicleForPurchaseFormStepTwo.vehicleInformation')}
            </p>
          )}
          <div className="row">
            <Form ref={this.formRef} className="w-100">
              <div className="row mt-3">
                <div id={"vin_number"} className="col-12 col-sm-12 col-lg-12">
                  <label className="font-weight-bold">
                    {t('vehicleForPurchaseInformation.vin')}
                  </label>
                  <Form.Item
                      name="vin_number"
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <Input
                        onFocus={() => onNumberFocused("vin_number")}
                        onBlur={() => onNumberBlurred("vin_number")}
                        placeholder="VIN#"
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>

                <div id={"make"} className="col-12 col-sm-12 col-lg-12 ">
                  <label className="font-weight-bold">
                    {t('vehicleForPurchaseInformation.make')} *
                  </label>
                  <Form.Item
                      name="make"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <Input
                        onFocus={() => onNumberFocused("make")}
                        onBlur={() => onNumberBlurred("make")}
                        placeholder="Make *"
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div id={"model"} className="col-12 col-sm-12 col-lg-12">
                  <label className="font-weight-bold">
                    {t('vehicleForPurchaseInformation.model')} *
                  </label>
                  <Form.Item
                      name="model"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <Input
                        onFocus={() => onNumberFocused("model")}
                        onBlur={() => onNumberBlurred("model")}
                        placeholder="Model *"
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div id={"year"} className="col-12 col-sm-12 col-lg-12">
                  <label className="font-weight-bold">
                    {t('vehicleForPurchaseInformation.year')} *
                  </label>
                  <Form.Item
                      name="year"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <InputNumber
                        placeholder="Year *"
                        onFocus={() => onNumberFocused("year")}
                        onBlur={() => onNumberBlurred("year")}
                    />
                  </Form.Item>
                </div>
                <div id={"trim"} className="col-12 col-sm-12 col-lg-12">
                  <label className="font-weight-bold">
                    {t('vehicleForPurchaseInformation.trim')}
                  </label>
                  <Form.Item
                      name="trim"
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <Input
                        onFocus={() => onNumberFocused("trim")}
                        onBlur={() => onNumberBlurred("trim")}
                        placeholder="Trim"
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div id={"condition"} className="col-12 col-sm-12 col-lg-12">
                  <label className="font-weight-bold">
                    {t('vehicleForPurchaseInformation.condition')}
                  </label>
                  <Form.Item
                      name="condition"
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <Select
                        onFocus={() => onNumberFocused("condition")}
                        onBlur={() => onNumberBlurred("condition")}
                        placeholder="Condition"
                        className="inp-form-wrap"
                    >
                      <Option disabled selected>{t('vehicleForPurchaseInformation.conditionOption.selected')}</Option>
                      <Option value={"NEW"}>{t('vehicleForPurchaseInformation.conditionOption.new')}</Option>
                      <Option value={"PREOWNED"}>{t('vehicleForPurchaseInformation.conditionOption.preOwned')}</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="steps-action flex-align-center justify-content-between w-100">
            <Button onClick={() => this.props.back()}>{t('utils.back')}</Button>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() => this.handleSubmit()}
            >
              {t('utils.submit')}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(VehiclePurchaseStepTwo)));
