import rootReducer from "./reducers/mainReducer";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { logger } from "redux-logger/src";
// import { getEnvValue } from "../controller/Environment";

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistConfig = {
  key: "root",
  // key:"webportal",
  storage: storage,
  whitelist: ["user"],
};

function handleCreateStore() {
  if (process.env.NODE_ENV === "production") {
    return createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));
  } else {
    return createStore(
      pReducer,
      composeEnhancers(applyMiddleware(thunk, logger))
    );
  }
}
const pReducer = persistReducer(persistConfig, rootReducer);
// export const store = createStore(
//   pReducer,
//   composeEnhancers(applyMiddleware(thunk, logger))
// );
export const store = handleCreateStore();
export const pStore = persistStore(store);
