import React, { useState, useEffect, useRef } from "react";
import {Button, Form, Input, InputNumber, message, Radio, Select} from "antd";
import {
    getZipcode,
    addPersonalReference,
    updatePersonalReference, getPersonalReferenceByCustomerId,
} from "../controller/api/customerAPI";
import { states } from "../controller/states";
import {
  confirm_status,
  methods, onNumberBlurred, onNumberFocused,
  onSelectBlurred,
  onSelectFocused, phoneValidationPattern,
} from "../controller/Global";
import { connect, useSelector } from "react-redux";
import SideBar from "./sidebar/SideBar";
import PopConfirm from "./modal/PopConfirm";
import { Image as Images } from "./Images";
import {useTranslation} from "react-i18next";

const { Option } = Select;

export const PersonalReferenceInfo = (props) => {
    const formRef = useRef(null);
    const current_transaction = useSelector(states => states.current_transaction);
    const user_info = useSelector(states => states.user);

    const [matchZipCode, setMatchZipCode] = useState(null);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [confirmationStatus, setConfirmationStatus] = useState(null);
    const [currentPersonalReference, setCurrentPersonalReference] = useState(null)
    const [addressesData, setAddressData] = useState([]);
    const { t, i18n } = useTranslation();
    const lng = navigator.language;


    const handleExit = (visible) => {
        setConfirmationStatus(confirm_status.exit);
        setOpenConfirmationModal(visible);
    };

    const get_message = () => {
        if (confirmationStatus === confirm_status.add) {
          return t('personalReferenceInformation.popModal.addTitle');
        } else if (confirmationStatus === confirm_status.edit) {
          return t('personalReferenceInformation.popModal.updatedTitle');
        } else {
          return t('personalReferenceInformation.popModal.exitTitle');
        }
    };

  const onChangeZip = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCodeValue = response.data;
          setMatchZipCode(matchZipCodeValue)
        if (matchZipCodeValue) {
          formRef.current.setFieldsValue({
            city: matchZipCodeValue.city,
            state: matchZipCodeValue.state,
          });
        }
      });
    }
  };

  const onBlurZip = (e) => {
    let value = e.target.value;
    if (matchZipCode && value.length === 3) {
      this.formRef.current.setFieldsValue({
        zip_code: `00${value}`,
      });
    } else if (matchZipCode && value.length === 4) {
      this.formRef.current.setFieldsValue({
        zip_code: `0${value}`,
      });
    }
  };

    const handleSubmit = async (values) => {
       if (user_info) {
           values["customer"] = user_info.id;
           values["position"] = 0;
           values["is_current_personal_reference"] = true;

           if(currentPersonalReference && currentPersonalReference.id) {
               try {
                   await updatePersonalReference( values, currentPersonalReference.id).then((res) => {
                       console.log(res, 'res from update personal reference')
                       setOpenConfirmationModal(true);
                   })
               } catch (error) {
                   console.error('Error updating personal reference:', error);
                   Object.keys(error.response.data).forEach((e) => {
                       message.error(error.response.data[e]);
                   });
                   throw error;
               }
           } else {
               try {
                   await addPersonalReference(values).then((res) => {
                       console.log(res, 'res from add personal reference');
                       setOpenConfirmationModal(true);
                   })
               } catch (error) {
                   console.error('Error adding personal reference:', error);
                   Object.keys(error.response.data).forEach((e) => {
                       message.error(error.response.data[e]);
                   });
                   throw error;
               }
           }
       }
    };


    const handleFilledForm = async () => {
        const transaction = current_transaction;
        if (transaction?.customer) {
            try {
                await getPersonalReferenceByCustomerId(transaction.customer).then((res) => {
                    if (res?.data?.results.length > 0 ) {
                        const dataLength = res.data.results.length - 1;
                        const referenceData = res.data.results[dataLength]
                        setCurrentPersonalReference(referenceData);
                        console.log(res?.data?.results, ' get customer reference filled form')
                        formRef.current?.setFieldsValue({
                            reference_first_name: referenceData.reference_first_name ? referenceData.reference_first_name : "",
                            reference_middle_name: referenceData.reference_middle_name ? referenceData.reference_middle_name : "",
                            reference_last_name: referenceData.reference_last_name ? referenceData.reference_last_name : "",
                            reference_relation: referenceData.reference_relation ? referenceData.reference_relation : "",
                            reference_phone: referenceData.reference_phone ? referenceData.reference_phone : "",
                            address: referenceData.address ? referenceData.address : "",
                            suite: referenceData.suite ? referenceData.suite : "",
                            zip_code: referenceData.zip_code ? referenceData.zip_code : "",
                            city: referenceData.city ? referenceData.city : "",
                            state: referenceData.state ? referenceData.state : "",
                        });
                    }
                })
            } catch (error) {
                console.error('Error fetch personal reference data:', error);
                throw error;
            }
        }
    }



    useEffect(() => {
        if (props.match.params) {
            const { params } = props.match
            if (params.method === methods.edit) {
                setConfirmationStatus(confirm_status.edit);
                if (current_transaction) {
                    handleFilledForm();
                }
            } else {
                setConfirmationStatus(confirm_status.add);
            }
        }

    },[current_transaction, props.match])


    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid main-inner-fluid mt-66">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className={"col-12 h-100 dashboard-inner-div p-0"}>
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 position-relative transition-info-div">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => handleExit(true)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>

                          <span className="d-inline-block w-100 text-center">
                              {t('personalReferenceInformation.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 deal-info-div">
                      <div className="transition-information-heading w-100 font-weight-bold mb-3">
                          {t('personalReferenceInformation.title')}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Form
                            ref={formRef}
                            className={"w-100"}
                            onFinish={handleSubmit}
                          >
                            <div className="row">
                              <div
                                  className="small-heading-div w-100 mb-5"
                                  style={{
                                    fontSize: "22px",
                                    lineHeight: "22px",
                                    fontWeight: "300",
                                    marginLeft: "3%",
                                  }}
                              >
                                  {t('personalReferenceInformation.personalReferenceForm.content')}
                              </div>
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.firstNameLabel')} *
                                    </label>
                                    <Form.Item
                                        name="reference_first_name"
                                        label={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.middleNameLabel')}
                                    </label>
                                    <Form.Item
                                        name="reference_middle_name"
                                        label={""}
                                        rules={[
                                            {
                                                required: false,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.lastNameLabel')} *
                                    </label>
                                    <Form.Item
                                        name="reference_last_name"
                                        label={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.relationshipLabel')} *
                                    </label>
                                    <Form.Item
                                        name="reference_relation"
                                        label={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div id={"phone_number"} className="col-12 col-sm-12 col-lg-6">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.cellPhoneNumberLabel')} *
                                    </label>
                                    <Form.Item
                                        name="reference_phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                            {
                                                pattern: phoneValidationPattern,
                                                message: "Minimum digit should be 10",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label select-float group-floating-label-second position-relative"
                                        }
                                    >
                                        <InputNumber
                                            formatter={(value) =>
                                                `${value}`
                                                    .match(/\d*/g)
                                                    .join("")
                                                    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                                    .slice(1)
                                                    .join("-")
                                                    .replace(/-*$/g, "")
                                            }
                                            parser={(value) => value.replace(/\$\s?|(-*)/g, "")}
                                            onFocus={() => onNumberFocused("phone_number")}
                                            onBlur={() => onNumberBlurred("phone_number")}
                                            placeholder=""
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.addressLabel')} *
                                    </label>
                                    <Form.Item
                                        name="address"
                                        label={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            suffix={
                                                <label className="floating-label" htmlFor="address">
                                                    Address *
                                                </label>
                                            }
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.suiteLabel')}
                                    </label>
                                    <Form.Item
                                        name="suite"
                                        label={""}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.zipCodeLabel')} *
                                    </label>
                                    <Form.Item
                                        name="zip_code"
                                        label={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            onChange={onChangeZip}
                                            onBlur={onBlurZip}
                                            placeholder=" "
                                        />
                                    </Form.Item>
                                    <span className="subtext"> {t('personalReferenceInformation.personalReferenceForm.zipHelperText')}</span>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-4">
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.cityLabel')} *
                                    </label>
                                    <Form.Item
                                        name="city"
                                        label={""}
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label group-floating-label-second"
                                        }
                                    >
                                        <Input
                                            placeholder=" "
                                            className="inp-form-wrap"
                                        />
                                    </Form.Item>
                                </div>
                                <div
                                    className="col-12 col-sm-12 col-lg-4 select_floating"
                                    id={"select_state"}
                                >
                                    <label className="font-weight-bold">
                                        {t('personalReferenceInformation.personalReferenceForm.stateLabel')} *
                                    </label>
                                    <Form.Item
                                        name="state"
                                        rules={[
                                            {
                                                required: true,
                                                message: "this field is required",
                                            },
                                        ]}
                                        className={
                                            "group-floating-label select-float group-floating-label-second position-relative"
                                        }
                                    >
                                        <Select
                                            onFocus={() => onSelectFocused("select_state")}
                                            onBlur={() => onSelectBlurred("select_state")}
                                            suffixIcon={
                                                <img
                                                    src={Images.down_arrow_select}
                                                    width={15}
                                                    alt="down arrow"
                                                    className="img-fluid"
                                                />
                                            }
                                            placeholder=""
                                        >
                                            {states.map((item) => (
                                                <Option
                                                    key={item.abbreviation}
                                                    value={item.abbreviation}
                                                >{`${item.name} (${item.abbreviation})`}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                              <div className="col-12 add-new-customer-div footer-customer-div p-0 shadow-none">
                              <div className="steps-action flex-align-center justify-content-end w-100">
                                <Button
                                  htmlType="submit"
                                  type="primary"
                                >
                                    {t('utils.save')}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <PopConfirm
              visible={openConfirmationModal}
              user={user_info}
              exit={confirmationStatus === confirm_status.exit}
              handleExit={() => handleExit(false)}
          >
              {get_message()}
          </PopConfirm>
      </React.Fragment>
    );
}

export default PersonalReferenceInfo;
