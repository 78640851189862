import React, { Component } from "react";
import { Image as Images } from "../Images";
import { Button, Form } from "antd";
import { routes } from "../../controller/routes";
import { Link, NavLink } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { userLoginAction } from "../../store/actions/authAction";
import { history } from "../../controller/history";
import {
  isLoggedIn,
  setOTPVerified,
} from "../../controller/localStorageHandler";
import TextField from "@mui/material/TextField";
import OTP from "./OTP";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filled: false,
      dialogOTP: false,
    };
  }

  componentDidMount() {
    document
      .getElementsByTagName("body")[0]
      .classList.add("menu-button-remove");
    if (isLoggedIn())
      history.push(
        this.props.location.state.from.pathname || routes.dashboard.self
      );
  }

  componentWillUnmount() {
    document
      .getElementsByTagName("body")[0]
      .classList.remove("menu-button-remove");
  }

  redirectTo =
    this.props.location.state?.from.pathname || routes.dashboard.self;

  handleSubmit = (values) => {
    values.email = values.email.toLowerCase();
    this.props.userLoginAction(values, this.redirectTo).then(() => {
      this.setState((prev) => ({ ...prev, dialogOTP: true }));
    });
  };

  onFormChange = (data) => {
    if (data[0].value && data[1].value) {
      this.setState({ filled: true });
    } else {
      this.setState({ filled: false });
    }
  };

  render() {
    const { user } = this.props;
    return (
      <div className="container-fluid login-div-fluid">
        <div className="row login-main-row mx-auto">
          <div className="col-12 logo-div-main">
            <NavLink to={routes.dashboard.self} className="d-inline-block">
              <Images.VirtualLogo />
            </NavLink>
          </div>
          <div className="col-12 login-card-main bg-white">
            <h6 className="font-weight-bold text-center">Login</h6>
            <div className="login_form_design w-100">
              <Form
                onFieldsChange={(changedFields, allFields) => {
                  this.onFormChange(allFields);
                }}
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  name="email"
                  label={""}
                  rules={[
                    {
                      required: true,
                      message: "this field is required",
                    },
                  ]}
                  className={"group-floating-label"}
                >
                  <TextField
                    autoCapitalize={"off"}
                    className={"w-100"}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={""}
                  rules={[
                    {
                      required: true,
                      message: "this field is required",
                    },
                  ]}
                  className={"group-floating-label mb-2"}
                >
                  <TextField
                    autoCapitalize={"off"}
                    className={"w-100"}
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    variant="outlined"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className={`w-100 text-white border-0 font-weight-normal ${
                      !this.state.filled && "in-active"
                    }`}
                  >
                    Sign In
                  </Button>
                </Form.Item>
                <div className="w-100 mt-2 text-center">
                  <p className="mb-0">
                    Forgot your password?{" "}
                    <NavLink to={routes.forgetPassword}>Click here</NavLink>
                  </p>
                </div>
              </Form>
            </div>
          </div>
          <div className="col-12 login-privacy-policy-div">
            <p className="mb-0 text-center">
              <Link target={"_blank"} to={"api/v1/dealership/privacy-policy/"}>
                Privacy Policy |
              </Link>{" "}
              <Link target={"_blank"} to={"api/v1/dealership/term-condition/"}>
                Terms and Conditions
              </Link>
            </p>
          </div>
        </div>
        <OTP
          onClose={() =>
            this.setState((prev) => ({ ...prev, dialogOTP: false }))
          }
          onVerified={() => {
            setOTPVerified(true);
            history.push(this.redirectTo);
          }}
          phone_number={user?.phone ?? ""}
          email={user?.email ?? ""}
          visible={this.state.dialogOTP}
          useApiV3
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapActionToProps = {
  userLoginAction,
};

export default connect(mapStateToProps, mapActionToProps)(LoginForm);
