import React, { Component } from "react";

class TermsConditions extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid p-0 mt-0 main-inner-fluid">
          <div className="row mx-0 h-100 dashboard-main-inner-row">
            <div className="col-12 h-100 dashboard-inner-div p-0">
              <div className="right-side-div-main w-100">
                <div className="row mx-0">
                  <div className="col-12 add-new-customer-div p-p-t-c">
                    <h5 className="text-center">Terms & Conditions</h5>
                    <p className="text-center font-weight-bold">
                      *Before Getting Started with Eunifi*
                    </p>
                    <h6 className="font-weight-bold">
                      YOU ARE AUTHORIZING OUR SERVICE TO TEXT MESSAGE YOUR
                      PERSONAL CELL PHONE NUMBER, as follows.
                    </h6>
                    <h6 className="font-weight-bold">WHY?</h6>
                    <ol className="font-weight-bold">
                      <li>
                        To make sure that only YOU may access your account with
                        us in the future – this will ensure that the identity of
                        a user is confirmed every time.
                      </li>
                      <li>
                        To Protect information provided from misuse or theft.
                      </li>
                      <li>To Prevent unauthorized access to our service. </li>
                    </ol>
                    <p className="font-weight-bold">
                      These text messages will only be used by Eunifi for the above
                      reasons.{" "}
                    </p>
                    <p className="font-weight-bold">
                      Please read these <u>terms and conditions</u> carefully
                      before using Our Service.
                    </p>
                    <p>
                      The words of which the initial letter is capitalized have
                      meanings defined under the following conditions.
                    </p>
                    <p>
                      The following definitions shall have the same meaning
                      regardless of whether they appear in singular or in
                      plural.
                    </p>
                    <p>For the purposes of these Terms and Conditions:</p>
                    <ul>
                      <li>
                        <p>
                          <b>Application</b> means the software program provided
                          by the Company downloaded by You on any electronic
                          device, named Eunifi
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Application</b> Store means the digital
                          distribution service operated and developed by Apple
                          Inc. (Apple App Store) or Google Inc. (Google Play
                          Store) in which the Application has been downloaded.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Affiliate</b> means an entity that controls, is
                          controlled by or is under common control with a party,
                          where &quot;control&quot; means ownership of 50% or
                          more of the shares, equity interest or other
                          securities entitled to vote for election of directors
                          or other managing authority.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Account</b> means a unique account created for You
                          to access our Service or parts of our Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Company</b> (referred to as either &quot;the
                          Company&quot;, &quot;We&quot;, &quot;Us&quot; or
                          &quot;Our&quot; in this Agreement) refers to Eunifi, Inc. 2125 Center Ave, Suite 400
                          Fort Lee, NJ 07024.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Content</b> refers to content such as text, images,
                          or other information that can be posted, uploaded,
                          linked to or otherwise made available by You,
                          regardless of the form of that content.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Country</b> refers to: New York, United States
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Device</b> means any device that can access the
                          Service such as a computer, a cellphone or a digital
                          tablet.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Service</b> refers to the Application or the
                          Website or both.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Terms and Conditions</b> (also referred as
                          &quot;Terms&quot;) mean these Terms and Conditions
                          that form the entire agreement between You and the
                          Company regarding the use of the Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Third-party Social Media Service</b> means any
                          services or content (including data, information,
                          products or services) provided by a third-party that
                          may be displayed, included or made available by the
                          Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Website</b> refers to Eunifi,
                          accessible from eunifi.io
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>You</b> means the individual accessing or using the
                          Service, or the company, or other legal entity on
                          behalf of which such individual is accessing or using
                          the Service, as applicable.
                        </p>
                      </li>
                      <li>
                        <p>
                          <b>Your Consent</b>I (i) agree that any of the
                          Communications we provide to you, or that you sign or
                          agree to at our request, may be in electronic form;
                          and (ii) consent to receiving any of the
                          Communications we provide to you via email, text
                          message, or other electronic medium. We may also use
                          electronic signatures and obtain them from you on any
                          Communication, even if we provide you the
                          Communications in paper form* The Communications may
                          be presented, executed, and delivered at the
                          DEALERSHIP, or, via electronic means, with information
                          to be delivered to your phone or e-mail address.
                          <p>
                            The Communications may be presented, executed, and
                            delivered at the DEALERSHIP, or, via electronic
                            means, with information to be delivered to your
                            phone or e-mail address.
                          </p>
                          *You must have an active email address and SMS-enabled
                          mobile/cellular telephone to use this Service.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>
                        These are the Terms and Conditions governing the use of
                        this Service and the agreement that operates between You
                        and the Company:
                      </b>{" "}
                      These Terms and Conditions set out the rights and
                      obligations of all users regarding the use of the Service.
                    </p>
                    <p>
                      Your access to and use of the Service is conditioned on
                      Your acceptance of and compliance with these Terms and
                      Conditions. These Terms and Conditions apply to all
                      visitors, users and others who access or use the Service.
                    </p>
                    <p>
                      By accessing or using the Service You agree to be bound by
                      these Terms and Conditions. If You disagree with any part
                      of these Terms and Conditions then You may not access the
                      Service.
                    </p>
                    <p>
                      You represent that you are over the age of 18. The Company
                      does not permit those under 18 to use the Service.
                    </p>
                    <p>
                      Your access to and use of the Service is also conditioned
                      on Your acceptance of and compliance with the Privacy
                      Policy of the Company. Our Privacy Policy describes Our
                      policies and procedures on the collection, use and
                      disclosure of Your personal information when You use the
                      Application or the Website and tells You about Your
                      privacy rights and how the law protects You. Please read
                      Our Privacy Policy carefully before using Our Service.
                    </p>
                    <p>
                      <b> When You create an account with Us:</b> You must
                      provide Us information that is accurate, complete, and
                      current at all times. Failure to do so constitutes a
                      breach of the Terms, which may result in immediate
                      termination of Your account on Our Service.
                    </p>
                    <p>
                      You are responsible for safeguarding the password that You
                      use to access the Service and for any activities or
                      actions under Your password, whether Your password is with
                      Our Service or a Third-Party Social Media Service.
                    </p>
                    <p>
                      You agree not to disclose Your password to any third
                      party. You must notify Us immediately upon becoming aware
                      of any breach of security or unauthorized use of Your
                      account.
                    </p>
                    <p>
                      You may not use as a username the name of another person
                      or entity or that is not lawfully available for use, a
                      name or trademark that is subject to any rights of another
                      person or entity other than You without appropriate
                      authorization, or a name that is otherwise offensive,
                      vulgar or obscene.
                    </p>
                    <p>
                      <b>Content Transmitted Through the Web Site.</b> With
                      respect to the content or other materials you upload
                      through the Web Site (collectively, &apos;Content&apos;),
                      you represent and warrant that you own all right, title,
                      and interest in and to such Content. You acknowledge and
                      agree that any questions, comments, suggestions, ideas,
                      feedback, or other information about the Service
                      (&apos;Submissions&apos;), provided by you to Company are
                      non-confidential and Company will be entitled to the
                      unrestricted use and dissemination of these Submissions
                      for any purpose, commercial or otherwise, without
                      acknowledgment or compensation to you.
                    </p>
                    <p>
                      You acknowledge and agree that Company may preserve
                      content and may also disclose content if required to do so
                      by law or in the good faith belief that such preservation
                      or disclosure is reasonably necessary to: (a) comply with
                      legal process, applicable laws or government requests; (b)
                      enforce these Terms; (c) respond to claims that content
                      violates another 3rd party’s rights, or (d) protect the
                      rights of others.
                    </p>
                    <p>
                      <b>
                        The Company has no control over, and assumes no
                        responsibility for, the content, privacy policies, or
                        practices of any third party web sites or services.
                      </b>{" "}
                      You further acknowledge and agree that the Company shall
                      not be responsible or liable, directly or indirectly, for
                      any damage or loss caused or alleged to be caused by or in
                      connection with the use of or reliance on any such
                      content, goods or services available on or through any
                      such web sites or services.
                    </p>
                    <p>
                      We strongly advise You to read the terms and conditions
                      and privacy policies of any third-party web sites or
                      services that You visit.
                    </p>
                    <p>
                      <b>
                        We may terminate or suspend Your Account immediately,
                        without prior notice or liability, for any reason
                        whatsoever, including without limitation if You breach
                        these Terms and Conditions.
                      </b>
                    </p>
                    <p>
                      Upon termination, Your right to use the Service will cease
                      immediately. If You wish to terminate Your Account, You
                      may simply discontinue using the Service.
                    </p>
                    <p>
                      <b>
                        Notwithstanding any damages that You might incur, the
                        entire liability of the Company and any of its suppliers
                        under any provision of this Terms and Your exclusive
                        remedy for all of the foregoing shall be limited to the
                        amount actually paid by You through the Service or $100
                        USD if You haven&apos;t purchased anything through the
                        Service.
                      </b>
                    </p>
                    {/* <p>
                      Our Service allows You to post Content. You are
                      responsible for the Content that You post to the Service,
                      including its legality, reliability, and appropriateness.
                    </p>
                    <p>
                      By posting Content to the Service, You grant Us the right
                      and license to use, modify, publicly perform, publicly
                      display, reproduce, and distribute such Content on and
                      through the Service. You retain any and all of Your rights
                      to any Content You submit, post or display on or through
                      the Service and You are responsible for protecting those
                      rights. You agree that this license includes the right for
                      Us to make Your Content available to other users of the
                      Service, who may also use Your Content subject to these
                      Terms.
                    </p>
                    <p>
                      You represent and warrant that: (i) the Content is Yours
                      (You own it) or You have the right to use it and grant Us
                      the rights and license as provided in these Terms, and
                      (ii) the posting of Your Content on or through the Service
                      does not violate the privacy rights, publicity rights,
                      copyrights, contract rights or any other rights of any
                      person.
                    </p>
                    <p>
                      The Company is not responsible for the content of the
                      Service&rsquo;s users. You expressly understand and agree
                      that You are solely responsible for the Content and for
                      all activity that occurs under your account, whether done
                      so by You or any third person using Your account.
                    </p>
                    <p>
                      You may not transmit any Content that is unlawful,
                      offensive, upsetting, intended to disgust, threatening,
                      libelous, defamatory, obscene or otherwise objectionable.
                      Examples of such objectionable Content include, but are
                      not limited to, the following:
                    </p>
                    <p>Unlawful or promoting unlawful activity.</p>
                    <p>
                      Defamatory, discriminatory, or mean-spirited content,
                      including references or commentary about religion, race,
                      sexual orientation, gender, national/ethnic origin, or
                      other targeted groups.
                    </p>
                    <p>
                      Spam, machine – or randomly – generated, constituting
                      unauthorized or unsolicited advertising, chain letters,
                      any other form of unauthorized solicitation, or any form
                      of lottery or gambling.
                    </p>
                    <p>
                      Containing or installing any viruses, worms, malware,
                      trojan horses, or other content that is designed or
                      intended to disrupt, damage, or limit the functioning of
                      any software, hardware or telecommunications equipment or
                      to damage or obtain unauthorized access to any data or
                      other information of a third person.
                    </p>
                    <p>
                      Infringing on any proprietary rights of any party,
                      including patent, trademark, trade secret, copyright,
                      right of publicity or other rights.
                    </p>
                    <p>
                      Impersonating any person or entity including the Company
                      and its employees or representatives.
                    </p>
                    <p>Violating the privacy of any third person.</p>
                    <p>False information and features.</p>
                    <p>
                      The Company reserves the right, but not the obligation,
                      to, in its sole discretion, determine whether or not any
                      Content is appropriate and complies with this Terms,
                      refuse or remove this Content. The Company further
                      reserves the right to make formatting and edits and change
                      the manner any Content. The Company can also limit or
                      revoke the use of the Service if You post such
                      objectionable Content.
                    </p>
                    <p>
                      As the Company cannot control all content posted by users
                      and/or third parties on the Service, you agree to use the
                      Service at your own risk. You understand that by using the
                      Service You may be exposed to content that You may find
                      offensive, indecent, incorrect or objectionable, and You
                      agree that under no circumstances will the Company be
                      liable in any way for any content, including any errors or
                      omissions in any content, or any loss or damage of any
                      kind incurred as a result of your use of any content.
                    </p>
                    <p>
                      Although regular backups of Content are performed, the
                      Company do not guarantee there will be no loss or
                      corruption of data.
                    </p>
                    <p>
                      Corrupt or invalid backup points may be caused by, without
                      limitation, Content that is corrupted prior to being
                      backed up or that changes during the time a backup is
                      performed.
                    </p>
                    <p>
                      The Company will provide support and attempt to
                      troubleshoot any known or discovered issues that may
                      affect the backups of Content. But You acknowledge that
                      the Company has no liability related to the integrity of
                      Content or the failure to successfully restore Content to
                      a usable state.
                    </p>
                    <p>
                      You agree to maintain a complete and accurate copy of any
                      Content in a location independent of the Service.
                    </p>
                    <p>
                      Upon receipt of a notification, the Company will take
                      whatever action, in its sole discretion, it deems
                      appropriate, including removal of the challenged content
                      from the Service.
                    </p>
                    <p>
                      The Service and its original content (excluding Content
                      provided by You or other users), features and
                      functionality are and will remain the exclusive property
                      of the Company and its licensors.
                    </p>
                    <p>
                      The Service is protected by copyright, trademark, and
                      other laws of both the Country and foreign countries.
                    </p>
                    <p>
                      Our trademarks and trade dress may not be used in
                      connection with any product or service without the prior
                      written consent of the Company.
                    </p>
                    <p>
                      Our Service may contain links to third-party web sites or
                      services that are not owned or controlled by the Company.
                    </p> */}
                    {/* <p>
                      The Company has no control over, and assumes no
                      responsibility for, the content, privacy policies, or
                      practices of any third party web sites or services. You
                      further acknowledge and agree that the Company shall not
                      be responsible or liable, directly or indirectly, for any
                      damage or loss caused or alleged to be caused by or in
                      connection with the use of or reliance on any such
                      content, goods or services available on or through any
                      such web sites or services.
                    </p> */}
                    <p>
                      To the maximum extent permitted by applicable law, in no
                      event shall the Company or its suppliers be liable for any
                      special, incidental, indirect, or consequential damages
                      whatsoever (including, but not limited to, damages for
                      loss of profits, loss of data or other information, for
                      business interruption, for personal injury, loss of
                      privacy arising out of or in any way related to the use of
                      or inability to use the Service, third-party software
                      and/or third-party hardware used with the Service, or
                      otherwise in connection with any provision of this Terms),
                      even if the Company or any supplier has been advised of
                      the possibility of such damages and even if the remedy
                      fails of its essential purpose.
                    </p>
                    <p>
                      Some states do not allow the exclusion of implied
                      warranties or limitation of liability for incidental or
                      consequential damages, which means that some of the above
                      limitations may not apply. In these states, each
                      party&rsquo;s liability will be limited to the greatest
                      extent permitted by law.
                    </p>
                    <p>
                      The Service is provided to You &quot;AS IS&quot; and
                      &quot;AS AVAILABLE&quot; and with all faults and defects
                      without warranty of any kind. To the maximum extent
                      permitted under applicable law, the Company, on its own
                      behalf and on behalf of its Affiliates and its and their
                      respective licensors and service providers, expressly
                      disclaims all warranties, whether express, implied,
                      statutory or otherwise, with respect to the Service,
                      including all implied warranties of merchantability,
                      fitness for a particular purpose, title and
                      non-infringement, and warranties that may arise out of
                      course of dealing, course of performance, usage or trade
                      practice. Without limitation to the foregoing, the Company
                      provides no warranty or undertaking, and makes no
                      representation of any kind that the Service will meet Your
                      requirements, achieve any intended results, be compatible
                      or work with any other software, applications, systems or
                      services, operate without interruption, meet any
                      performance or reliability standards or be error free or
                      that any errors or defects can or will be corrected.
                    </p>
                    <p>
                      Without limiting the foregoing, neither the Company nor
                      any of the company&rsquo;s provider makes any
                      representation or warranty of any kind, express or
                      implied: (i) as to the operation or availability of the
                      Service, or the information, content, and materials or
                      products included thereon; (ii) that the Service will be
                      uninterrupted or error-free; (iii) as to the accuracy,
                      reliability, or currency of any information or content
                      provided through the Service; or (iv) that the Service,
                      its servers, the content, or e-mails sent from or on
                      behalf of the Company are free of viruses, scripts, trojan
                      horses, worms, malware, timebombs or other harmful
                      components.
                    </p>
                    <p>
                      Some jurisdictions do not allow the exclusion of certain
                      types of warranties or limitations on applicable statutory
                      rights of a consumer, so some or all of the above
                      exclusions and limitations may not apply to You. But in
                      such a case the exclusions and limitations set forth in
                      this section shall be applied to the greatest extent
                      enforceable under applicable law.
                    </p>
                    <p>
                      The laws of the USA, excluding its conflicts of law rules,
                      shall govern this Terms and Your use of the Service. Your
                      use of the Application may also be subject to other local,
                      state, national, or international laws.
                    </p>
                    <p>
                      <b>
                        If You have any concern or dispute about the Service,
                        You agree to first try to resolve the dispute informally
                        by contacting the Company.
                      </b>
                    </p>
                    <p>
                      You represent and warrant that (i) You are not located in
                      a country that is subject to the United States government
                      embargo, or that has been designated by the United States
                      government as a &quot;terrorist supporting&quot; country,
                      and (ii) You are not listed on any United States
                      government list of prohibited or restricted parties.
                    </p>
                    <p>
                      If any provision of these Terms is held to be
                      unenforceable or invalid, such provision will be changed
                      and interpreted to accomplish the objectives of such
                      provision to the greatest extent possible under applicable
                      law and the remaining provisions will continue in full
                      force and effect.
                    </p>
                    <p>
                      Except as provided herein, the failure to exercise a right
                      or to require performance of an obligation under this
                      Terms shall not effect a party&rsquo;s ability to exercise
                      such right or require such performance at any time
                      thereafter nor shall be the waiver of a breach constitute
                      a waiver of any subsequent breach.
                    </p>
                    <p>
                      <b>
                        If You have any concern or dispute about the Service,
                        You agree to first try to resolve the dispute informally
                        by contacting the Company.{" "}
                      </b>
                      We encourage you to print or download, for your records, a
                      copy of all electronic Communications. This ESIGN Consent
                      disclosure will be a part of the information available to
                      you on the Eunifi application
                    </p>
                    <p>
                      <b>
                        These Terms and Conditions may have been translated if
                        We have made them available to You on our Service.{" "}
                      </b>
                      Translations of any materials into languages other than
                      English are intended solely as a convenience to the
                      non-English-reading public and are not legally binding. We
                      have attempted to provide an accurate translation of the
                      original material in English, but due to the nuances in
                      translating to a foreign language, slight differences may
                      exist. References identifying the original document in
                      English are available in most non-English documents.
                    </p>
                    <p>
                      You agree that the original English text shall prevail in
                      the case of a dispute.
                    </p>
                    <p>
                      <b>
                        We reserve the right, at Our sole discretion, to modify
                        or replace these Terms at any time.{" "}
                      </b>
                      If a revision is material We will make reasonable efforts
                      to provide at least 30 days&rsquo; notice prior to any new
                      terms taking effect. What constitutes a material change
                      will be determined at Our sole discretion.
                    </p>
                    <p>
                      By continuing to access or use Our Service after those
                      revisions become effective, You agree to be bound by the
                      revised terms. If You do not agree to the new terms, in
                      whole or in part, please stop using the website and the
                      Service.
                    </p>
                    <p>
                      <b>
                        Binding Arbitration and Exclusions from Class Action
                        Lawsuits.{" "}
                      </b>
                      Except as provided below OR UNLESS YOU SUBMIT A VALID
                      ARBITRATION/CLASS ACTION WAIVER OPT-OUT NOTICE (in writing
                      sent to address(es) below) any and all claims between you
                      and Company will be resolved in binding arbitration rather
                      than in court. You and VII agree to submit to individual
                      arbitration the resolution of any and all Claims by or
                      between you and/or Company (A) any Claim filed by you with
                      respect to any violation, enforcement or validity of
                      patent, trademark, trade dress, service mark, copyright
                      and/or trade secret rights of you, or any third party,
                      including, but not limited to, Claims related to content
                      that you upload to or share on the Site and/or (B) you may
                      seek a preliminary injunction, restraining order or other
                      provisional equitable relief in any court as provided in
                      connection with any Claim whereby you as applicable, may
                      suffer immediate and irreparable harm for which money
                      damages may be inadequate and impossible to calculate,
                      where such Claim under this will not be subject to the
                      informal dispute resolution procedures described below;
                      provided, however, that, subsequent to obtaining such
                      preliminary injunction, restraining order or other
                      provisional equitable relief, the Claim will then be
                      submitted to arbitration. You and Company agree that this
                      Agreement affects interstate commerce, and that the
                      enforceability of this clause will be governed by,
                      construed, and enforced, both procedurally and
                      substantively, by the Federal Arbitration Act, 9 U.S.C.
                      sections 1–9 (&quot;FAA&quot;). Arbitration is the
                      referral of a dispute to one or more impartial persons for
                      a final and binding determination. There is no judge or
                      jury in arbitration, discovery is more limited than in
                      court, there are no class or representative proceedings,
                      and court review of an arbitration decision is limited. An
                      arbitrator must follow this Agreement and can award on an
                      individual basis the same damages and relief as a court
                      (including, but not limited to, injunctive and declaratory
                      relief, statutory damages, and attorneys&apos; fees).
                      &quot;Claim(s)&quot; means any dispute, claim or
                      controversy by or between you and/or Company relating to
                      the Site and/or this Agreement (including, but not limited
                      to, this Site&apos;s Privacy Policy and all Additional
                      Terms, Rules, regulations, procedures and policies which
                      we refer to in this Agreement), as may be modified from
                      time-to-time, and the subject matter hereof, including,
                      but not limited to, any contract, tort, statutory, or
                      equity claims.
                    </p>
                    <p>
                      <b>Electronic Signatures.</b> All participating parties
                      agree that the electronic signatures, whether digital or
                      encrypted, of the parties using this Product, if any, are
                      intended to authenticate terms in writing and to have the
                      same force and effect as manual signatures.
                    </p>
                    <p>
                      <b>Contact Us</b>
                    </p>
                    <p>
                      If you have any questions about these Terms and
                      Conditions, You can contact us:
                    </p>
                    <p>
                      Email:{" "}
                      <a href="compliance@eunifi.io">
                        compliance@eunifi.io
                      </a>
                    </p>
                    <h6 className="font-weight-bold">Mailing Address</h6>
                    <p>
                      2125 Center Avenue
                      <br />
                      Suite 400
                      <br />
                      Fort Lee, NJ 07024
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsConditions;
