export default function MakeHeightArray() {
  const HeightArray = [];

  for (let i = 4; i <= 8; i++) {
    //inner loop
    for (let j = 0; j <= 11; j++) {
      if (i === 8 && j === 0) {
        HeightArray.push(`${i}'${j}"`);
        break;
      } else HeightArray.push(`${i}'${j}"`);
    }
  }
  return HeightArray;
}
