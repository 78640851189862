import React, {useState, useEffect} from "react";
import {Button, Form, Radio, Space} from "antd";
import {connect} from "react-redux";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import "./css/stepThreeVerifyIdentity.css";
import CaptureDoc from "../Atoms/CaptureDoc/CaptureDoc";
import { DateTime } from "luxon";
import SendLink from "../Atoms/SendLink/SendLink.tsx";
import {useTranslation} from "react-i18next";


const now = DateTime.now()
const default_date = {
    day: now.day,
    month: now.month,
    year: now.year,
}

export const StepThreeVerifyIdentity = (props) => {
    const formRef = React.createRef();
    const [btnLoading, setBtnLoading] = useState(false);
    const [formVisible, setFormVisible] = useState(null)
    const [uploadRadio, setUploadRadio] = useState(false)
    const [skipRadio, setSkipRadio] = useState(true)
    const [selectedDoctype, setSelectedDocType] = useState({
        stateID: false,
        driverLicense: true,
        other: false,
    })
    const [onRevokeObjectURL, setOnRevokeObjectURL] = useState(false)
    const [disabledBtn, setDisableBtn] = useState(false)
    const [userInformation, setUserInformation] = useState({
        license_front: null,
        license_back: null,
        expiry_date: default_date,
        dob: default_date,
        first_name: null,
        last_name: null,
        issued_date: default_date,
        id_number: null,
        sex: null,
    })

    const [validAddress, setValidAddress] = useState({
        address: null,
        zip_code: null,
        state: null,
        city: null,
    });
    const { t, i18n } = useTranslation();

    const onPassDataToNextStep = () => {
        props.getUserIdInformation({
            expiry_date: userInformation.expiry_date ?? default_date,
            dob: userInformation.dob ?? default_date,
            first_name: userInformation.first_name,
            last_name: userInformation.last_name,
            issued_date: userInformation.issued_date ?? default_date,
            id_number: userInformation.id_number,
            sex: userInformation.sex,
            license_front: userInformation.license_front,
            license_back: userInformation.license_back,
            address: validAddress.address,
            zip_code: validAddress.zip_code,
            state: validAddress.state,
            city: validAddress.city,
            document_type: selectedDoctype.stateID
                ? "state_id"
                : selectedDoctype.driverLicense
                    ? "driver_license"
                    : "other",
            doc_verified: "",
            eye_color: userInformation.eye_color,
            height: userInformation.height
        });

        props.next();
        setOnRevokeObjectURL(true)
    };

    const onChangeSubRadio = (e) => {
        if (e.target.value === "state_Id") {
            setSelectedDocType({
                stateID: true,
                driverLicense: false,
                other: false,
            })
        } else if (e.target.value === "driver_license") {
            setSelectedDocType({
                stateID: false,
                driverLicense: true,
                other: false,
            })
        } else {
            setSelectedDocType({
                stateID: false,
                driverLicense: false,
                other: true,
            })

        }
    };

    const onRadioChange = (e) => {
        if (e.target.value === "upload") {
            setFormVisible(true);
            setDisableBtn(true)
            setUploadRadio(true);
            setSkipRadio(false)
        } else {
            formRef.current.resetFields();
            setFormVisible(false);
            setUploadRadio(false);
            setSkipRadio(true);
            setDisableBtn(false);
        }
    };

    useEffect(() => {
            setDisableBtn(false)
    },[validAddress, userInformation])

    return (
        <div className="container-fluid">
            <div className="row login-main-row w-auto main-row-width mx-auto">
                <div className="col-12 add-new-customer-div p-0 shadow-none mt-0">
                    <div className="row">
                        <Form ref={formRef}>
                            <div className="col-12 transition-information-div mb-4">
                                <div className="transition-information-heading-container">
                                    <div className="transition-information-heading font-weight-bold">
                                        {t('transactionForm.stepThree.title')}
                                    </div>
                                    <SendLink transId={props.transaction.id} dealershipId={props.transaction.dealership.id}/>
                                </div>
                                <p className="font-weight-normal mb-5">
                                    {t('transactionForm.stepThree.context')}
                                </p>
                                <div className="skip-radio-div row">
                                    <div className="col-12">
                                        <Radio.Group
                                            onChange={onRadioChange}
                                            defaultValue={"skip"}
                                        >
                                            <Space direction="vertical">
                                                <Radio
                                                    value={"upload"}
                                                    className={`radioOption
                                                        ${
                                                        uploadRadio
                                                            ? "selectedRadio"
                                                            : "untouched-color"
                                                    }`}>
                                                    {t('transactionForm.stepThree.idTypeChoice.captureId')}
                                                </Radio>

                                                {uploadRadio ? (
                                                    <Radio.Group
                                                        onChange={onChangeSubRadio}
                                                        defaultValue={"driver_license"}
                                                        className={'no-border'}
                                                    >
                                                        <Radio
                                                            value={"driver_license"}
                                                            className={`sub ${
                                                                selectedDoctype.driverLicense
                                                                    ? "skip-color"
                                                                    : "untouched-color"
                                                            }`}
                                                        >
                                                            {t('transactionForm.stepThree.idTypeChoice.driverLicense')}
                                                        </Radio>
                                                        <Radio
                                                            value={"state_Id"}
                                                            className={`sub ${
                                                                selectedDoctype.stateID
                                                                    ? "skip-color"
                                                                    : "untouched-color"
                                                            }`}
                                                        >
                                                            {t('transactionForm.stepThree.idTypeChoice.stateId')}
                                                        </Radio>
                                                        <Radio
                                                            value={"other"}
                                                            className={`sub ${
                                                                selectedDoctype.other
                                                                    ? "skip-color"
                                                                    : "untouched-color"
                                                            }`}
                                                        >
                                                            {t('transactionForm.stepThree.idTypeChoice.otherId')}
                                                        </Radio>
                                                    </Radio.Group>
                                                ) : null}

                                                <Radio
                                                    value={"skip"}
                                                    className={`radioOption ${
                                                        skipRadio
                                                            ? "selectedRadio"
                                                            : "untouched-color"
                                                    }`}
                                                >
                                                    {t('transactionForm.stepThree.idTypeChoice.skipForNow')}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </div>
                                </div>
                                {formVisible ? (
                                    <CaptureDoc
                                        userInformation={userInformation}
                                        setUserInformation={setUserInformation}
                                        setValidAddress={setValidAddress}
                                        onRevokeObjectURL={onRevokeObjectURL}
                                        setIsLoading={setBtnLoading}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="steps-action flex-align-center justify-content-between w-100">
                                <Button onClick={() => props.prev()}>{t('utils.back')}</Button>
                                <Button
                                    onClick={
                                        skipRadio
                                            ? () => props.skipVerifyDocStep()
                                            : () => onPassDataToNextStep()
                                    }
                                    loading={btnLoading}
                                    type="primary"
                                    disabled={disabledBtn}
                                >
                                    {t('utils.next')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(StepThreeVerifyIdentity);

