export const english = {
    translation: {
        greeting: {
            hello: 'Hello',
        },
        utils: {
            yes: 'Yes',
            addNow: 'Yes, add now',
            no: 'No',
            back: 'Back',
            next: 'Next',
            save: 'Save',
            update: 'Update',
            submit: 'Submit',
            add: 'Add',
            addNew: 'Add New',
            addMore: 'Add More',
            cancel: 'Cancel',
            close: 'close',
            clear: 'Clear',
            continue: 'Continue',
            edit: 'Edit',
            remove: 'Remove',
            exitBtn: 'Yes, Exit',
            skip: 'Skip',
            resend: 'Resend',
            send: 'Send',
            sendRequest: 'Send Request',
            drawSignature: 'Draw Signature',
            start: 'Start',
            will_not_save: 'None of your progress will be saved',
            prefillContent: 'Your full name, email, and cell phone number are pre-filled automatically',
            loadMore: 'Load More',
            warningMessage: {
                InvalidURL: 'Invalid URL',
                fetchingError: 'Error while fetching transaction details',
                inputRequired: "this field is required",
                minimumDigit: 'Minimum digit should be 10',
            }
        },
        personalInformation: {
            title: 'Personal Information',
            buyerTitle: 'Buyer Information',
            name: 'Name',
            phone: 'Phone',
            email: 'Email',
            firstNameLabel: 'First Name',
            middleNameLabel: 'Middle Name',
            lastNameLabel: 'Last Name',
            suffixLabel: 'Suffix',
            DOBLabel: 'Date of Birth (mm/dd/yyyy)',
            cellPhoneNumberLabel: 'Cell Phone Number',
            popModal: {
                addTitle: 'Personal Information Added!',
                updatedTitle: 'Personal Information Saved!',
                exitTitle: 'Exit adding your information?',
            }
        },
        addressInformation: {
            title: 'Address Information',
            address: 'Address',
            housingStatus: 'Housing Status',
            timeAtAddress: 'Time at Address',
            monthlyPayment: 'Monthly Payment',
            currentAddress: 'Current Address',
            registerToAddress: 'Register to Address',
            register_to_address_is_the_same_current_address: 'Register To Address is the same as my Current Address',
            streetAddressLabel: 'Street Address',
            suiteLabel: 'Suite, Apt #',
            zipCodeLabel: 'Zip Code',
            zipHelperText: 'Zip prefills city, state',
            cityLabel: 'City',
            stateLabel: 'State',
            current: 'Current',
            previous: 'Previous',
            secondPrevious: 'Second previous',
            popModal: {
                addTitle: 'Address Information Added!',
                updatedTitle: 'Address Information Updated!',
                exitTitle: 'Exit adding your information?',
            }
        },
        employmentInformation: {
            title: 'Employment Information',
            howDoYouGetPaid: 'How do you get paid?',
            employmentStatus: 'Employment Status',
            employerName: 'Employer Name',
            occupation: 'Occupation',
            phoneNumber: 'Phone Number',
            employerAddress: 'Employer Address',
            employmentDuration: 'Employment Duration',
            grossIncome: 'Gross Income',
            payFrequency: 'Pay Frequency',
            currentTab: 'Current',
            previousTab: 'Previous',
            secondPreviousTab: 'Second previous',
        },
        coBuyerInformation: {
            title: 'Co-Buyer',
            addCoBuyer: 'Add Co-Buyer',
            coBuyerInformation: 'Co-Buyer Information',
            coBuyerFormStepOne: {
                formTitle: 'Step 1: Co-Buyer Invitation Link',
                contentOne: 'The next step prompts you to input your co-buyer’s first and last name, email, and cell phone number.',
                contentTwo: 'The system will then automatically generate and send an invitation link via email for them to start the process.',
                contentThree: 'It’s <strong>Simple, Safe, and Secure!</strong>'
            },
            coBuyerFormStepTwo: {
                formTitle: 'Step 2: Co-Buyer Information',
                firstNameLabel: 'First Name',
                lastNameLabel: 'Last Name',
                emailLabel: 'Email',
                cellPhoneNumberLabel: 'Cell Phone Number',
                contentOne: 'By clicking <strong>Send Request</strong>,you are giving the dealership or its authorized representative consent to email Co-Buyer on your behalf.',
                contentTwo: '<strong>Please Note:</strong>: For Security Purposes, all sensitive Buyer & Co-Buyer information is kept separately.'
            },
            popModal: {
                addTitle: 'Co-Buyer Information Added!',
                updatedTitle: 'Co-Buyer Information Saved!',
                exitTitle: 'Exit adding your Co-Buyer information?',
                removeTitle: 'Are you sure you want to remove your Co-Buyer?',
                removeSubTitle: 'You cannot undo this action',
                inputAnotherEmail: 'Input another email address',
                warningMessage: 'You cannot add yourself as a Co-Buyer',
                emailSentToCoBuyerMessage: 'An email has been sent to the email provided to finish setup.',
                returnToTransactionButton: 'Return To Transaction',
            }
        },
        dealInformation: {
            title: 'Deal Information',
            dealType: 'Deal Type',
            dealForm: {
                formTitle: 'Will you be leasing, financing or cash?',
                dealInfoChoice: {
                    lease: 'Lease',
                    finance: 'Finance',
                    cash: 'Cash',
                    outsideFinance: 'Outside Finance Source',
                    undecided: 'Undecided',
                }
            },
            popModal: {
                addTitle: 'Deal Information Added!',
                updatedTitle: 'Deal Information Updated!',
                exitTitle: 'Exit Adding Deal Information?',
            }
        },
        vehicleForPurchaseInformation: {
            title: 'Vehicle For Purchase',
            vin: 'Vin#',
            stockNo: 'Stock No.',
            make: 'Make',
            model: 'Model',
            year: 'Year',
            trim: 'Trim',
            condition: 'Condition',
            conditionOption: {
                selected: 'Select a Condition',
                new: 'New',
                preOwned: 'Pre-Owned',
            },
            vehicleForPurchaseForm: {
                vehicleForPurchaseFormStepOne: {
                    formTitle: 'Step 1: Do You Know the Dealership’s Vehicle Inventory Stock #?',
                    subTitle: 'If not, leave blank and click Next to continue.',
                    stockNumber: 'Stock Number',
                },
                vehicleForPurchaseFormStepTwo: {
                    formTitle: 'Step 2: Vehicle Information',
                    subTitle: 'Verify the below information. If no results returned through the stock number, ' +
                        'enter the description of the vehicle you would like to purchase.',
                    vehicleInformation: 'Vehicle Information',
                    conditionChoice: {
                        select: 'Select a Condition',
                        new: 'New',
                        preowned: 'Preowned',
                    }
                }
            },
            popModal: {
                addTitle: 'Vehicle For Purchase Added!',
                updatedTitle: 'Vehicle For Purchase Updated!',
                exitTitle: 'Exit Adding a Vehicle For Purchase?',
            }
        },
        vehicleForTradeInformation: {
            title: 'Vehicle for Trade',
            make: 'Make',
            model: 'Model',
            year: 'Year',
            tradeRegistration: 'Trade Registration',
            vehicleForTradeForm: {
                vehicleForTradeFormStepOne: {
                    formTitle: 'Step 1: Trade Information',
                    subTitle: 'Do you have your vehicle registration on hand?',
                },
                vehicleForTradeFormStepTwo: {
                    formTitle: 'Step 2: Vehicle Information',
                    subTitle: 'Please Enter or Verify information below:',
                    vinLabel: 'Vin#',
                    makeLabel: 'Make',
                    modelLabel: 'Model',
                    yearLabel: 'Year',
                    mileageLabel: 'Mileage',
                    areYouTheRegisteredOwner: 'Are you the registered owner?',
                    nameOfRegisteredOwnerLabel: 'Name of registered owner',
                    relationshipToTheRegisteredOwnerLabel: 'Relationship to the registered owner',
                    stateLabel: 'State',
                },
                warningMessage: {
                    successfulAdd: 'Vehicle for Trade Added Successfully!',
                    successfulUpdate: 'Vehicle for trade updated successfully',
                }
            },
            popModal: {
                exitTitle: 'Exit Adding Trade Information ?',
                tradeInfoAddedTitle: 'Trade Information Added!',
                tradeInfoAddedSubTitle: 'Now you can call your lender and get the <strong>Payoff Amount</strong> of your trade.',
                questionOne: 'Do you have the vehicle’s title?',
                questionTwo: 'Is the vehicle paid off?',
                questionThree: 'Do you have proof the vehicle is paid off?',
                tradeInfoAddedHelperText: '<strong>Remember:</strong> You can upload an image of any document later, including the vehicle title!'
            }
        },
        staffInformation: {
            title: 'Staff',
            dealerRep: 'Dealer Rep',
            salesManager: 'Sales Manager',
            fiManager: 'F&I Manager',
        },
        personalReferenceInformation: {
            title: 'Personal Reference',
            name: 'Name',
            relationship: 'Relationship',
            address: 'Address',
            phone: 'Phone',
            personalReferenceForm: {
                content: 'Please fill up the form below',
                firstNameLabel: 'First Name',
                middleNameLabel: 'Middle Name',
                lastNameLabel: 'Last Name',
                relationshipLabel: 'Relationship',
                cellPhoneNumberLabel: 'Cell Phone Number',
                addressLabel: 'Address',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Zip Code',
                zipHelperText: 'Zip prefills city, state',
                cityLabel: 'City',
                stateLabel: 'State',
            },
            popModal: {
                addTitle: 'Personal Reference Added!',
                updatedTitle: 'Personal Reference Updated!',
                exitTitle: 'Exit adding your information?',
            }
        },
        IdInformation: {
            title: 'ID',
            personalIdentification: 'Personal Identification',
            driverLicense: 'Driver\'s License',
            stateId: 'State Id',
            noDriverLicense: 'No Driver\'s License or State ID',
            valid: 'Valid',
            invalid: 'Invalid',
            idValidity: 'ID Validity',
            noId: "No Driver's License or State ID",
            IdInformationForm: {
                Title: 'ID Information',
                contentOne: 'Upload photos of your Driver’s License or State Issued ID.',
                contentTwo: 'Your ID is used for identification and to detect fraud. The image is securely stored. ' +
                    'Other than for the security of your personal information, it may also be used for your motor vehicle finance and vehicle registration.',
                selectIdTypeLabel: 'Select ID Type',
                idNumberLabel: 'ID Number',
                stateLabel: 'State',
                dobLabel: 'Date of Birth',
                idIssuedDateLabel: 'Your ID\'s Issued Date',
                idExpirationDateLabel: 'Your ID\'s Expiration Date',
                firstNameLabel: 'First Name',
                middleNameLabel: 'Middle Name',
                lastNameLabel: 'Last Name',
                suffixLabel: 'Suffix',
                streetAddressLabel: 'Street Address',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Zip Code',
                cityLabel: 'City',
                sexLabel: 'Sex',
                heightLabel: 'Height',
                eyeColorLabel: 'Eye Color',
                zipHelperText: 'Zip prefills city, state',
                idTypeChoice: {
                    captureId: 'Capture ID',
                    driverLicense: 'Driver\'s License',
                    stateId: 'State Issued ID',
                    otherId: 'Other ID',
                },
                genderChoice: {
                    male: 'Male',
                    female: 'Female',
                    x: 'X',
                }
            },
        },
        insuranceInformation: {
            title: 'Insurance',
            insuranceCard: 'Insurance Card',
            noInsurance: 'No Insurance Added',
            insuranceFrom: 'Insurance from',
            valid: 'Valid',
            expired: 'Expired',
            insuranceInformationForm: {
                title: 'Insurance Information',
                content: 'Please fill the information in the fields below.',
                providerLabel: 'Provider',
                policyNumberLabel: 'Policy Number',
                effectiveDateLabel: 'Effective Date',
                expirationDateLabel: 'Expiration Date',
            },
            popModal: {
                addTitle: 'Insurance Added!',
                updatedTitle: 'Insurance Updated!',
                exitTitle: 'Exit Adding Insurance Information?',
            }
        },
        preRequisiteFormsCard: {
            title: 'Pre-Requisite Forms',
            description: 'Description',
            privacyPolicy: 'Privacy Policy',
            contactAcknowledgement: 'Contact Acknowledgement',
            identityTheftPrevention: 'Identity Theft Prevention',
            acknowledgementDocument: 'Acknowledgement Document',
        },
        creditFormsCard: {
            title: 'Credit Forms',
            addNew: 'Add New',
            notActivated: 'Feature Not Activated',
            preQualification: 'Pre-Qualification',
            hardCredit: 'Hard Credit',
            applicationForm: 'Application Form',
            addNewFormPopModal: {
                title: 'Add New Application Form',
                contentOne: 'This feature gives you the option to submit a New Application Form. ' +
                    'The fields will be pre-filled with any previously entered information for you to edit/modify.',
                acknowledgeContent:
                    'I am modifying my original application due to an error or omission on my behalf. ' +
                    'I do this of my own free will and understand that this information may be used to make a credit decision by a bank or other similar entity.',
            }

        },
        preQualificationForm: {
            title: 'Pre-Qualification Application',
            contentOne: 'This allows the dealer to view your credit score and accurately check what programs are available for your purchase.',
            contentTwo: '<strong>Note:</strong> This action will not affect your credit score and is not considered a Hard Inquiry on your credit file.',
            firstNameLabel: 'First Name',
            middleNameLabel: 'Middle Name',
            lastNameLabel: 'Last Name',
            suffixLabel: 'Suffix',
            DOBLabel: 'Date of Birth (mm/dd/yyyy)',
            ssnLabel: 'SSN or ITIN',
            streetAddressLabel: 'Street Address',
            suiteLabel: 'Suite, Apt #',
            zipCodeLabel: 'Zip Code',
            zipHelperText: 'Zip prefills city, state',
            cityLabel: 'City',
            stateLabel: 'State',
            privacyPolicy: 'Privacy Policy',
            applicationDisclosure: 'Application Disclosure',
            and: 'and',
            commitmentPrivacy: {
                title: 'Our Commitment to Privacy',
                content: 'All information stored in our database is secure and is strictly confidential. ' +
                    'Your personal and credit informational will only be used to fulfill your request and in accordance with our',

            },
            acknowledgementAndConsent: {
                title: 'Acknowledgement and Consent',
                acknowledgementOne: 'I understand that this is not an application for credit, and submission will not affect credit score.',
                acknowledgementTwo: 'I acknowledge that I received a privacy notice (signed upon transaction creation)',
                acknowledgementThree: 'By clicking the "Submit" button and providing an electronic signature below, you are confirming you have read and understood the',
                acknowledgementFour: ',and you are providing written instructions to',
                acknowledgementFive: 'to obtain information from Experian and/or TransUnion and/or Equifax solely to apply for a pre-qualification for credit.',
                signature: 'Signature',
            },
            popModal: {
                addTitle: 'Pre-Qualification Application Submitted!',
                updatedTitle: 'Pre-Qualification Application Updated!',
                exitTitle: 'Exit Pre-Qualification Application?',
            }
        },
        hardCreditForm: {
            title: 'Hard Credit Application',
            hcFormStepOne: {
                title: 'Step 1: Basic Information',
                content: 'Filling out a Credit Application allows the dealer to submit your request for credit to the financial institutions to secure an approval for the loan. ' +
                    'If the dealer submits the request for credit, it will be considered a Credit Inquiry.',
                firstNameLabel: 'First Name',
                middleNameLabel: 'Middle Name',
                lastNameLabel: 'Last Name',
                suffixLabel: 'Suffix',
                DOBLabel: 'Date of Birth (mm/dd/yyyy)',
                ssnLabel: 'SSN or ITIN',
                homePhoneNumberLabel: 'Cell Phone Number',
                cellPhoneNumberLabel: 'Cell Phone Number',
            },
            hcFormStepTwo: {
                title: 'Step 2: Address Information',
                content_one: 'Your previous ',
                content_two: ' years of housing are required,',
                which_address_are_you_using: 'Are you using current address or register to address ' +
                    'for this hard credit app?',
                optional: '(Optional)',
                mandatory: '(Mandatory)',
                current: 'Current',
                currentAddress: 'Current Address',
                registerToAddress: 'Register to Address',
                currentAddressEmptyCardContent: 'Your current address will show up here.',
                previous: 'Previous',
                previousAddressEmptyCardContent: 'Your previous address will show up here.',
                secondPrevious: 'Second Previous',
                secondPreviousAddressEmptyCardContent: 'Your second previous address will show up here.',
                streetAddressLabel: 'Street Address',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Zip Code',
                zipHelperText: 'Zip prefills city, state',
                cityLabel: 'City',
                stateLabel: 'State',
                housingStatusLabel: 'Housing Status',
                monthlyPaymentLabel: 'Monthly Payment',
                durationYearLabel: 'Duration Year(s)',
                durationYearHelperText: 'How many years have you been living at this address?',
                durationMonthLabel: 'Duration (Mos)',
                durationMonthHelperText: 'How many months have you been living at this address?',
                timeAtAddress: 'Time at Address',
                updateAddress: 'Update Address',
                addAddress: 'Add Address',
                addInformation: 'Add Information',
                addInformationModal: {
                    title_one: 'The address(es) you added does not cover ',
                    title_two: ' years of housing',
                    content_one: 'Please add first and/or second previous addresses to cover ',
                    content_two: ' years of housing'
                },
                addMoreInformationModal: {
                    title: 'The address(es) you added covers requirement of housing',
                    title_one: 'The address(es) you added covers ',
                    title_two: ' years of housing',
                    content: `"Please tap "Next" to move on to the next step. ` +
                        `If not, tap "add more" to add more info"`
                },
                addTimeInformationModal: {
                    content: 'Please enter an amount in years and/or months'
                },
                warningMessage: {
                    cannotAdd: 'Cannot add more than 3 addresses',
                    fiveMinimumRequired: 'enter a minimum of 5 digits amount'
                },
                options: {
                    rent: 'Rent',
                    ownMortgage: 'Own Mortgage',
                    ownFree: 'Own Free and Clear',
                    other: 'Other',
                    livingWithRelatives: 'Living with Relatives',
                    doNotPayRent: 'I don’t pay rent'
                },
                addressTitle: {
                    current: 'Current (Mandatory)',
                    previous: 'Previous (optional)',
                    secondPrevious: 'Second Previous (Optional)'
                }
            },
            hcFormStepThree: {
                title: 'Step 3: Employment Information',
                contentOne: 'Your previous',
                contentTwo: 'years of employment are required.',
                optional: '(Optional)',
                mandatory: '(Mandatory)',
                current: 'Current',
                currentAddressEmptyCardContent: 'Your current employment will show up here.',
                previous: 'Previous',
                previousAddressEmptyCardContent: 'Your previous employment will show up here.',
                secondPrevious: 'Second Previous',
                secondPreviousAddressEmptyCardContent: 'Your second previous employment will show up here.',
                howDoYouGetPaidLabel: 'How do you get paid?',
                employmentStatusLabel: 'Employment Status',
                employerNameLabel: 'Employer Name',
                occupationLabel: 'Occupation',
                employerPhoneNumberLabel: 'Employer\'s Phone Number',
                phoneNumber: 'Phone Number',
                employmentDuration: 'Employment Duration',
                salary: 'Salary',
                type: 'Type',
                otherIncome: 'Other Income',
                otherIncomeType: 'Other Income Type',
                employerAddressLabel: 'Employer Address',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Zip Code',
                zipHelperText: 'Zip prefills city, state',
                cityLabel: 'City',
                stateLabel: 'State',
                durationYearLabel: 'Duration Year(s)',
                durationYearHelperText: 'Enter the amount of years you have been working at this job',
                durationMonthLabel: 'Duration (Mos)',
                durationMonthHelperText: 'Enter the amount of months you have been working at this job',
                grossIncomeLabel: 'Gross Income',
                grossIncomeHelperText: 'Your total earnings before taxes or other deductions',
                payFrequencyLabel: 'Pay Frequency',
                payFrequencyHelperText: 'How often are you paid?',
                otherIncomeSourceLabel: 'Other Income Source',
                otherIncomeSourceHelperText: 'Do you have another job/source of income you would like to\n' +
                    '                          add?',
                amountLabel: 'Amount',
                amountHelperText: 'How much do you make in your other income source?',
                monthAnnuallyLabel: 'Monthly, Annually...',
                monthAnnuallyHelperText: 'How often are you paid with your other income source?',
                updateEmployment: 'Update Employment',
                addEmployment: 'Add Employment',
                addInfoModal: {
                    addInformation: 'Add Information',
                    titleOne: 'The employment information you added does not cover',
                    titleTwo: 'years of employment',
                    contentOne: 'Please add first and/or second previous employment to cover',
                    contentTwo: 'years of employment',
                    enterAmount: 'Please enter an amount in years and/or months',
                },
                addMoreModal: {
                    timeCover: "The employment history you added covers employment requirement",
                    notCover: 'The employment history you added covers',
                    year: 'years',
                    nextStepContent: 'Please tap \'Next \' to move on to the next step. If not, tap \' add more \' to add more info',
                },
                warningMessage: {
                    cannotAdd: 'Cannot add more than 3 addresses',
                    fiveMinimumRequired: 'enter a minimum of 5 digits amount',
                    tenMinimumRequired: '"Minimum digit should be 10"',
                },
                options: {
                    unemployed: 'Unemployed',
                    employed: 'Employed',
                    self_employed: 'Self-Employed',
                    retired: 'Retired',
                    student: 'Student/Apprentice',
                    cash: 'Cash',
                    check: 'Check',
                    weekly: 'Weekly',
                    biWeekly: 'Bi-Weekly',
                    semiWeekly: 'Semi-Monthly',
                    yearly: 'Yearly',
                    annual: 'Annual',
                    monthly: 'Monthly',
                },
                addressTitle: {
                    current: 'Current Employment (mandatory)',
                    previous: 'Previous (optional)',
                    secondPrevious: 'Second Previous (Optional)'
                }
            },
            hcFormStepFour: {
                title: 'Step 4: Submit Application',
                subTitle: 'Please read the information below before agreeing.',
                hardCreditAlreadyVerified: 'Hard Credit already verified.',
                commitmentToPrivacy: {
                    title: 'Our Commitment to Privacy',
                    content: 'All information stored in our database is secure and is strictly confidential. ' +
                        'Your personal and credit information will only be used to fulfill your request and in accordance with our Privacy Policy.'
                },
                acknowledgementAndConsent: {
                    title: 'Acknowledgement and Consent',
                    acknowledgementOne: 'By clicking the "Submit" button and providing an electronic signature below, you are confirming you have read and understood the',
                    acknowledgementTwo: ',and you are providing written instructions to',
                    acknowledgementThree: ',to obtain information from Experian and/or TransUnion and/or Equifax to apply for a pre-qualification for credit.',
                    privacyPolicy: 'Privacy Policy',
                    applicationDisclosure: 'Application Disclosure',
                    and: 'and',
                    signature: 'Signature'
                }
            },
            popModal: {
                addTitle: 'Credit Application Submitted!',
                updatedTitle: 'Credit Application Updated!',
                exitTitle: 'Exit Credit Application?',
            }
        },
        closingFormCard: {
            title: 'Closing Forms',
            description: 'Description',
            purchaseAcknowledgement: 'Purchase Acknowledgement',
            purchaseForm: 'Purchase Form'
        },
        myDocumentCard: {
            title: 'My document',
            uploadDocument: {
                title: 'Upload Documents',
                payStub: 'Pay Stub',
                utilityBill: 'Utility Bill',
                identityTheftPrevention: 'Identity Theft Prevention',
                other: 'Other'
            },
            viewDocument: 'View Documents'
        },
        welcomePage: {
            welcomeText: 'Selecting Spanish as default language will change platform and Specific PDFs to Spanish, confirm?',
            title: 'Welcome!',
            subTitle: 'Here are the steps you will go through to have a convenient vehicle purchase:',
            bulletOne: 'Fill out all the requested fields of information',
            bulletTwo: 'E-sign the form for electronic contact consent (allows dealership\n' +
                'staff to call, email or text you).',
            bulletThree: 'Snap a picture of your Driver’s License',
            bulletFour: 'Upload any supporting documents that may be needed.',
            content: '<strong>Electronic Signatures.</strong> Clicking \"Let’s Get Started\" below, user agrees that this\n' +
                'consent and any other documents to be delivered in connection with\n' +
                'this application may be electronically signed, and that any\n' +
                'electronic signatures appearing on applications or other documents\n' +
                'are the same as handwritten signatures for the purposes of validity,\n' +
                'enforceability, and admissibility.',
            getStart: 'Let\'s Get Started'
        },
        transactionForm: {
            stepOne: {
                title: 'Step 1: Review and acknowledge our Privacy Policy',
                subTitle: 'Our Commitment to Privacy.',
                dealershipWeb: 'Dealership Website',
                questions: 'Questions?',
                policySigned: 'Policy signed',
                drawSignature: 'Draw Signature',
                otherImportantInfo: 'Other Important Information',
                context: 'All information stored in our database is secure and is strictly\n' +
                    '          confidential. Your personal and credit information will only be used\n' +
                    '          to fulfill your request and in accordance with our Privacy Policy\n' +
                    '          below.',

                fact: 'Facts',
                factContextOne: 'What does',
                factContextTwo: 'do with your personal information?',
                why: 'Why?',
                whyContext:'Financial companies choose how they share your\n' +
                    'personal information. Federal law gives consumers the\n' +
                    'right to limit some but not all sharing. Federal law\n' +
                    'also requires us to tell you how we collect, share,\n' +
                    'and protect your personal information. Please read\n' +
                    'this notice carefully to understand what we do.',
                what: 'What?',
                whatContext: 'The types of personal information we collect, and\n' +
                    'share depend on the product or service you have with\n' +
                    'us. This information can include',
                whatBulletOne: 'Social Security Number and income',
                whatBulletTwo: 'Account balances and payment history',
                whatBulletThree: 'Credit history and credit score',
                whatBulletFour: 'When you are no longer our customer, we continue to\n' +
                    'share your information as described in this notice.',
                table: {
                    headerOne: 'Reasons we can share your personal information',
                    headerTwo: 'Does Dealership Share?',
                    headerThree: 'Can you limit this sharing?',
                    yes: 'Yes',
                    no: 'No',
                    doNotShare: 'We don’t share',
                    rowOne: {
                        columnHeader: 'For our everyday business purposes –',
                        columnOne: 'Such as to process your transaction, maintain your account(s),\n' +
                            'respond to court orders and legal investigation, or report to credit\n' +
                            'bureaus.',
                    },
                    rowTwo: {
                        columnHeader: 'For our marketing purposes –',
                        columnOne: 'To offer our product services to you',
                    },
                    rowThree: {
                        columnHeader: 'For our joint marketing with other financial companies',
                    },
                    rowFour: {
                        columnHeader: 'For our affiliates’ everyday business purposes –',
                        columnOne: 'Information about your transactions and experiences',
                    },
                    rowFive: {
                        columnHeader: 'For our affiliates’ to market to you',
                    },
                    rowSix: {
                        columnHeader: 'For non-affiliates to market to you',
                    },
                },
                wvdTable: {
                    title: 'What we do',
                    rowOne: {
                        columnHeaderOne: 'How does ',
                        columnHeaderTwo: 'protect my personal information?',
                        context: 'To protect your personal information from unauthorized ' +
                            'access and use, we use security measures that comply with federal law.' +
                            '  These measures include computer safeguards, secured files, and buildings.',
                    },
                    rowTwo: {
                        columnHeaderOne: 'How does ',
                        columnHeaderTwo: 'collect my personal information?',
                        context: 'We collect your personal information, for example, when you',
                        bulletOne: 'Show us your driver’s license',
                        bulletTwo: 'Pay us by check',
                        bulletThree: 'Apply for a loan',
                        bulletFour: 'Apply for financing',
                        bulletFive: 'Provide account information',
                        bulletSix: 'We also collect your personal information from others, such as\n' +
                            '                credit bureaus, affiliates, or other companies.',
                    },
                    rowThree: {
                        columnHeaderOne: 'Why can’t I limit all sharing?',
                        context: 'Federal law gives you the right to limit only:',
                        bulletOne: 'Sharing for affiliates’ everyday business purposes –\n' +
                            'information about your creditworthiness',
                        bulletTwo: 'Affiliates from using your information to market to you',
                        bulletThree: 'Sharing for nonaffiliates to market to you',
                        bulletFour: 'State laws and individual companies may give you additional\n' +
                            'rights to limit sharing.',

                    },
                },
                defTable: {
                    title: 'Definitions',
                    rowOne: {
                        columnHeader: 'Affiliates',
                        context: 'Companies related by common ownership or control. They can be\n' +
                            'financial and nonfinancial companies.',
                        bulletOne: 'has no affiliates'
                    },
                    rowTwo: {
                        columnHeader: 'Non-affiliates',
                        context: 'Companies related by common ownership or control. They can be\n' +
                            'financial and nonfinancial companies.',
                        bulletOne: 'does not share with non-affiliates so\n' +
                            'they can market to you.'
                    },
                    rowThree: {
                        columnHeader: 'Joint Marketing',
                        context: 'A formal agreement between nonaffiliated financial companies that ' +
                            'together market financial products or services to you.',
                        bulletOne: 'doesn’t jointly market.'
                    },

                }

            },
            stepTwo: {
                title: 'Step 2: Contact Acknowledgement',
                contextOne: 'In an effort to collect and process information safely and quickly, ' +
                    'we need your permission to communicate with you on your personal devices.',
                contextTwo: 'By submitting my cell number below, I consent to receive text messages, email,' +
                    ' and phone calls which may be recorded and/or sent using automated dialing equipment or software from this Company and its affiliates.' +
                    ' I understand that I can opt-out at any time. You agree to pay your mobile service provider’s text messaging & data rates, if applicable.',
                cellPhone: 'Cell Phone Number',
                verifyCode: 'Verification Code',
                oneTimeAccessCode: 'One-Time Access Code',
                warningMessage: {
                    phoneError: 'Some error occurred, please check the Number',
                    phoneRequired: 'Cell Phone Number is required',
                    minimumDigit: 'Minimum digit should be 10',
                    otpContext: 'Please input the access code texted to your phone number and then\n' +
                        '              click/tap Submit. If you didn’t receive the text message, please\n' +
                        '              click/tap Resend.'
                },
            },
            stepThree: {
                title: 'Step 3: ID Capture',
                context: 'Your ID is used for Identification and to detect fraud.\n' +
                    'The image is securely stored. It may also be used for your Motor Vehicle Finance & Vehicle Registration.',
                sendToPhone: 'Send to Phone',
                idTypeChoice: {
                    captureId: 'Capture ID',
                    driverLicense: 'Driver\'s License',
                    stateId: 'State Issued ID',
                    otherId: 'Other ID',
                    skipForNow: 'Skip for now'
                }
            },
            verifyIdStep: {
                title: 'Validate ID Information',
                subTitle: 'Please validate the information below',
                warningMessage: {
                    pleaseReviewYorInfo: 'Please review the captured information to make sure everything is correct. ' +
                        'If any information needs to be correct, please update the information.'
                },
            },
            stepFour: {
                title: 'Step 4: Address Information',
                subTitle: 'Please fill up the form below.',
                streetAddressLabel: 'Street Address',
                addressLabel: 'Address',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Zip Code',
                zipHelperText: 'Zip prefills city, state',
                cityLabel: 'City',
                stateLabel: 'State',
                registerToAddress: 'Register to Address',
                registerToAddressSameAsCurrentAddress: 'Register To Address is the same as my Current Address',
                warningMessage: {
                    pleaseReviewYorInfo: 'Please review the captured information to make sure everything is correct. ' +
                        'If any information needs to be correct, please update the information.'
                },
            },
            stepFive: {
                title: 'Step 5: Identity Theft Prevention',
                subTitle: 'Please read the information below before uploading a photo of your face',
                contextOne: ' By submitting my picture, I agree that the Dealership may use my\n' +
                    ' provided photo/image only for lawful purposes, in this case, to\n' +
                    ' identify, process, and secure information to purchase an\n' +
                    ' automobile.',
                contextTwo: 'All information provided is my own and has been prepared based\n' +
                    ' on my representations alone. No one else has assisted me in the\n' +
                    ' information provided and may be used in my credit application if\n' +
                    ' I choose to do so.',
                upload: 'Upload',
                loadMore: 'Load More',
                popModal: {
                    coBuyerAddedTitle: 'Co-Buyer Information Added Successfully!',
                    buyerAddedTitle: 'Transaction Information successfully completed!',
                    addedSubTitle: 'Click Continue for Visual Guide of the App\'s Dashboard.'
                }
            },
        },
        dashboard: {
            dashboardContent: 'Select a transaction on the left to view transaction' +
                'information.',
            techSupport: 'Tech Support',
            updateEmail: 'Update Email',
            resetEmail: 'Reset Password',
            signOut: 'Sign Out',
            currentEmail: 'Current Email',
            newEmail: 'New Email',
            password: 'Password',
            popModal: {
                dealInfoContext: 'In the next step, you will be asked to answer a few questions\n' +
                    '              about this transaction.',
                disassociatedCoBuyer: 'Disassociated Co-buyer',
                trackYorProgress: 'Track your transaction\'s progress',
                actionPersonalInfo: 'View or update your Personal Information.',
                addCoBuyer: 'Add a Co-Buyer (if you have one)',
                doYouHaveCoBuyer: 'Do you have a Co-Buyer?',
                addCoBuyerPop: ' Adding your Co-Buyer information now (if you have one) will save\n' +
                    '              you time at the dealership.',
                addYourDeal: 'Add your Deal Information.',
                addVehicleForPurchase: 'Add a Vehicle for Purchase',
                addVehicleForTrade: 'Add a Vehicle for Trade.',
                addId: 'Add your ID (Driver’s License or State ID).',
                trackYourPrerequisite: 'Track your Pre-Requisite forms.',
                fillCreditForm: 'Fill up, Sign, & Submit your credit forms.',
                trackClosingForm: 'Track your Closing Form.',
                uploadDocument: ' Upload and view Supporting Documents (Pay Stubs, Utility\n' +
                    '                      Bills, etc.).',
                saveTime: 'Save time later!',
                completedCreditApp: 'If you do not provide a completed credit application now, the\n' +
                    '                  dealer cannot submit a request for credit to financial\n' +
                    '                  institutions.',
                start: 'Start',
                preQualInfoContext: 'This next step allows the dealer to accurately check what\n' +
                    '                  programs are available for your purchase.',
                creditAppInfoContext: 'Now Let’s Get You Approved. In this next step, you will be providing the dealership with a\n' +
                    '                Credit Application.',
                will_you_trade_vehicle: 'Will you be trading in a vehicle?',
                addVehicleSaveTime: 'Save time later! Add your Vehicle\'s information.',
                vehicleForPurchasePopContext: 'Providing the vehicle information now will help the dealer search\n' +
                    '              multiple inventories giving you additional choices for comparison',
                dealerRequestedDoc: 'Has a dealer representative requested any documents at this time?',
            },
        },
        techSupport: {
            title: 'Tech Support',
            contentOne: 'How can we help you?',
            contentTwo: 'We’re here to address your questions regarding Eunifi.',
            subjectLabel: 'Subject',
            messagePlaceHolder: 'Message',
            messageTextHelper: 'Characters Left',
            messageWarningText: 'maximum 200 character',

        }
    }
}