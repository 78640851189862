import React, { Component } from "react";
import {Button, Checkbox, Form, Input, message, Radio, Select} from "antd";
import { states } from "../../controller/states";
import { connect } from "react-redux";
import {
  addAddressInfo,
  getZipcode,
  updateAddressInfo,
  preScreenReportTrigger,
} from "../../controller/api/customerAPI";
import { Image as Images } from "../Images";
import { onSelectBlurred, onSelectFocused } from "../../controller/Global";
import { isEmptyObject } from "jquery";
import { withTranslation } from "react-i18next";

const { Option } = Select;

class StepsFiveAddressInformation extends Component {
  formRef = React.createRef();

  state = {
    value: "",
    use_current_address: false,
    filled: false,
    matchZipCode1: null,
    matchZipCode: null,
    btnLoading: false,
  };
  onChange = (e) => {
    const formData = this.formRef.current.getFieldsValue()
    this.setState({
      use_current_address: e.target.checked,
    });


    if (e.target.checked === false) {
      this.formRef.current.setFieldsValue({
        address_2: '',
        city_2: '',
        state_2: '',
        suite_2: '',
        zip_code_2: ''
      });
    } else {
      this.setState({ formFilled: true });
      this.formRef.current.setFieldsValue({
        address_2: formData.address_1 ? formData.address_1 : '',
        city_2: formData.city_1 ? formData.city_1 : '',
        state_2: formData.state_1 ? formData.state_1 : '',
        suite_2: formData.suite_1 ? formData.suite_1 : '',
        zip_code_2: formData.zip_code_1 ? formData.zip_code_1 : '',
      });
    }
  };

  componentDidMount() {
    const { transaction } = this.props;
    // if licence step is filled  , then prefill
    if (transaction?.license) {
      this.formRef.current.setFieldsValue({
        // ...transaction.license
        address_1: transaction.license.address,
        city_1: transaction.license.city,
        state_1: transaction.license.state,
        zip_code_1: transaction.license.zip_code,
        suite_1: transaction.license.suite,
        address_2: transaction.license.address,
        city_2: transaction.license.city,
        state_2: transaction.license.state,
        zip_code_2: transaction.license.zip_code,
        suite_2: transaction.license.suite,

      });
      this.setState({
        use_current_address: true,
        filled: true,
      });
    }
    // if licence not filled , prefill from address info
    else {
      if (transaction?.address_information) {
        this.formRef.current.setFieldsValue({
          ...transaction.address_information,
        });
        this.setState({
          use_current_address: transaction.address_information.register_vehicle,
          filled: true,
        });
      }
    }
  }

  handlePreScreenTrigger = () => {
    const { is_co_buyer, id } = this.props.transaction;
    if(!this.props.transaction.dealership['credit_700_pre_screen_enabled']) {
      this.props.next();
      return
    }
    if ( this.props.transaction){
      try {
        preScreenReportTrigger( {co_buyer: is_co_buyer}, id).then((res) => {
          this.props.next();
        })
      } catch (error) {
        Object.keys(error.response.data).forEach((e) => {
          message.error(error.response.data[e]);
        });
      }
      this.props.next();
    }
  }

  handleSubmit = (values) => {
    this.setState({ btnLoading: true });
    const { transaction, user } = this.props;
    values["register_vehicle"] = this.state.use_current_address;
    values["transaction"] = transaction.id;
    values["customer"] = user.id;
    if (values.register_vehicle === true) {
      values = {
        ...values,
        address_2: values.address_1,
        city_2: values.city_1,
        state_2: values.state_1,
        suite_2: values.suite_1,
        zip_code_2: values.zip_code_1,
      };
    }
    if (transaction?.address_information) {
      updateAddressInfo(values, transaction.address_information.id)
        .then(() => {
          message.success("Address Update successfully");
          this.handlePreScreenTrigger();
          this.setState({ btnLoading: false });
        })
        .catch((err) => {
          this.setState({ btnLoading: false });
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
          }
        });
    } else {
      addAddressInfo(values)
        .then(() => {
          this.handlePreScreenTrigger();
          this.setState({ btnLoading: false });
        })
        .catch((err) => {
          this.setState({ btnLoading: false });
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
          }
        });
    }
  };
  onFormChange = (data) => {
    if (data[0].value && data[2].value && data[3].value && data[4].value) {
      this.setState({ filled: true });
    } else {
      this.setState({ filled: false });
    }
  };
  onChangeZip = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode = response.data;
        this.setState({ matchZipCode });
        if (!isEmptyObject(matchZipCode)) {
          this.formRef.current.setFieldsValue({
            city_1: matchZipCode.city,
            state_1: matchZipCode.state,
          });
          // enable-button-when-prefilled
          this.setState({ filled: true });
        }
      });
    }
  };

  onBlurZip = (e) => {
    let value = e.target.value;
    const { matchZipCode } = this.state;
    if (matchZipCode && value.length === 3) {
      this.formRef.current.setFieldsValue({
        zip_code_1: `00${value}`,
      });
    } else if (matchZipCode && value.length === 4) {
      this.formRef.current.setFieldsValue({
        zip_code_1: `0${value}`,
      });
    }
  };

  onChangeZip1 = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode1 = response.data;
        this.setState({ matchZipCode1 });

        if (matchZipCode1) {
          this.formRef.current.setFieldsValue({
            city_2: matchZipCode1.city,
            state_2: matchZipCode1.state,
          });
        }
      });
    }
  };

  onBlurZip1 = (e) => {
    let value = e.target.value;
    const { matchZipCode1 } = this.state;
    if (matchZipCode1 && value.length === 3) {
      this.formRef.current.setFieldsValue({
        zip_code_2: `00${value}`,
      });
    } else if (matchZipCode1 && value.length === 4) {
      this.formRef.current.setFieldsValue({
        zip_code_2: `0${value}`,
      });
    }
  };

  render() {
    const { is_co_buyer } = this.props.transaction;
    const { t } = this.props;
    return (
        <div className="col-12 transition-information-div mb-4">
          <div className="transition-information-heading w-100 font-weight-bold mb-3">
            { t('transactionForm.stepFour.title')}
          </div>
          <p className="font-weight-normal">
            { t('transactionForm.stepFour.subTitle')}
          </p>
          <div className="row mx-0 mt-4">
            <div className="col-12">
              <Form
                  onFieldsChange={(changedFields, allFields) => {
                    this.onFormChange(allFields);
                  }}
                  ref={this.formRef}
                  className={"w-100"}
                  onFinish={this.handleSubmit}
              >
                <div className="row mb-5">
                  <div className="col-12">
                    <label className="font-weight-bold">{ t('transactionForm.stepFour.streetAddressLabel')}*</label>
                    <Form.Item
                        name="address_1"
                        label={""}
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          autoFocus
                          className="inp-form-wrap"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <label className="font-weight-bold">{ t('transactionForm.stepFour.suiteLabel')}</label>
                    <Form.Item
                        name="suite_1"
                        label={""}
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          autoFocus
                          placeholder=" "
                          className="inp-form-wrap"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                    <label className="font-weight-bold">{ t('transactionForm.stepFour.zipCodeLabel')} *</label>
                    <Form.Item
                        name="zip_code_1"
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          placeholder=" "
                          onChange={this.onChangeZip}
                          onBlur={this.onBlurZip}
                      />
                    </Form.Item>
                    <span className="subtext">{ t('transactionForm.stepFour.zipHelperText')}</span>
                  </div>
                  <div className="col-12 col-sm-12 col-lg-4">
                    <label className="font-weight-bold">{ t('transactionForm.stepFour.cityLabel')} *</label>
                    <Form.Item
                        name="city_1"
                        label={""}
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "group-floating-label group-floating-label-second"
                        }
                    >
                      <Input
                          placeholder=" "
                          className="inp-form-wrap"
                      />
                    </Form.Item>
                  </div>
                  <div
                      id={"states"}
                      className="col-12 col-sm-12 col-lg-4 select_floating"
                  >
                    <label className="font-weight-bold">{ t('transactionForm.stepFour.stateLabel')}  *</label>
                    <Form.Item
                        name="state_1"
                        rules={[
                          {
                            required: true,
                            message: "this field is required",
                          },
                        ]}
                        className={
                          "select-float group-floating-label group-floating-label-second position-relative"
                        }
                    >
                      <Select
                          onFocus={() => onSelectFocused("states")}
                          onBlur={() => onSelectBlurred("states")}
                          suffixIcon={
                            <img
                                src={Images.down_arrow_select}
                                width={15}
                                alt="down arrow"
                                className="img-fluid"
                            />
                          }
                          placeholder=""
                      >
                        {states.map((item, index) => (
                            <Option
                                key={`state_${index}`}
                                value={item.abbreviation}
                            >{`${item.name} (${item.abbreviation})`}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {!is_co_buyer ? (
                    <>
                      <div className="personal-info-strong w-100 our-commitment-div mb-2">
                        Register To Address
                        { t('transactionForm.stepFour.registerToAddress')}
                      </div>
                      <div className="transition-check-box w-100 mb-5">
                        <Checkbox
                            onChange={this.onChange}
                            defaultChecked={this.state.use_current_address
                            }
                        >
                          { t('transactionForm.stepFour.registerToAddressSameAsCurrentAddress')}
                        </Checkbox>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label className="font-weight-bold">
                            { t('transactionForm.stepFour.addressLabel')} *
                          </label>
                          <Form.Item
                              name="address_2"
                              label={""}
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },
                              ]}
                              className={
                                "group-floating-label group-floating-label-second"
                              }
                          >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12">
                          <label className="font-weight-bold">
                            { t('transactionForm.stepFour.suiteLabel')}
                          </label>
                          <Form.Item
                              name="suite_2"
                              label={""}
                              className={
                                "group-floating-label group-floating-label-second"
                              }
                          >
                            <Input
                                autoFocus
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                          <label
                              className="font-weight-bold"
                          >
                            { t('transactionForm.stepFour.zipCodeLabel')}
                          </label>
                          <Form.Item
                              name="zip_code_2"
                              label={""}
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },
                              ]}
                              className={
                                "group-floating-label group-floating-label-second"
                              }
                          >
                            <Input
                                placeholder=" "
                                onChange={this.onChangeZip1}
                                onBlur={this.onBlurZip1}

                            />
                          </Form.Item>
                          <span className="subtext">{ t('transactionForm.stepFour.zipHelperText')}</span>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-4">
                          <label
                              className="font-weight-bold"
                          >
                            { t('transactionForm.stepFour.cityLabel')} *
                          </label>
                          <Form.Item
                              name="city_2"
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },
                              ]}
                              className={
                                "group-floating-label group-floating-label-second"
                              }
                          >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-4">
                          <label
                              className="font-weight-bold"
                          >
                            { t('transactionForm.stepFour.stateLabel')} *
                          </label>
                          <Form.Item
                              name="state_2"
                              rules={[
                                {
                                  required: false,
                                  message: "this field is required",
                                },
                              ]}
                              className={
                                "select-float group-floating-label group-floating-label-second position-relative"
                              }
                          >
                            <Select
                                suffixIcon={
                                  <img
                                      src={Images.down_arrow_select}
                                      width={15}
                                      alt="down arrow"
                                      className="img-fluid"
                                  />
                                }
                                placeholder=""
                            >
                              {states.map((item, index) => (
                                  <Option
                                      key={`state_${index}`}
                                      value={item.abbreviation}
                                  >{`${item.name} (${item.abbreviation})`}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </>
                ) : ('')}
                <div className="col-12 add-new-customer-div shadow-none p-0 footer-customer-div">
                  <div className="steps-action flex-align-center justify-content-between w-100">
                    <Button onClick={() => this.props.prev()}>{ t('utils.back')}</Button>
                    <Button
                        disabled={!this.state.filled}
                        htmlType="submit"
                        type="primary"
                        loading={this.state.btnLoading}
                    >
                      { t('utils.next')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {user: state.user};
};

export default withTranslation()(connect(mapStateToProps)(StepsFiveAddressInformation));
