import React, { Component } from "react";
import { Button, Spin, Steps } from "antd";
import StepsOnePrivacyPolicy from "../transaction-information/StepsOnePrivacyPolicy";
import StepTwoContactAcknowledgement from "../transaction-information/StepTwoContactAcknowledgement";
import StepThreeVerifyIdentity from "../transaction-information/StepThreeVerifyIdentity";
import StepsSixPhotoUpload from "../transaction-information/StepsSixPhotoUpload";
import StepsFiveAddressInformation from "../transaction-information/StepsFiveAddressInformation";
import CustomHeader from "../header/CustomHeader";
import { withTranslation } from "react-i18next";
import {
  getTransactionHistory,
  getTransactionOne,
} from "../../controller/api/customerAPI";
import { connect } from "react-redux";
import { routes } from "../../controller/routes";
import { confirm_status } from "../../controller/Global";
import PopConfirm from "../modal/PopConfirm";
import { Image, Image as Images } from "../Images";
import Welcome from "../Welcome";
import VerifyIdentity from "../transaction-information/VerifyIdentity";
import { Paper } from "@mui/material";

class AddTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      privacy_agreed: true,
      contact_acknowledgement: false,
      transaction: null,
      pop_confirm: null,
      visible: false,
      fixed: false,
      showWelcome: true,
      userInformation: {
        license_front: null,
        license_back: null,
        expiry_date: null,
        dob: null,
        first_name: null,
        last_name: null,
        issued_date: null,
        id_number: null,
        sex: null,
        address: null,
        zip_code: null,
        state: null,
        city: null,
        document_type: null,
        doc_verified: "",
        height: null,
        eye_color: null,
      },
      isLoading: false,
    };
  }

  getUserIdInformation = (data) => {
    this.setState({ userInformation: { ...data } });
  };

  componentDidMount() {
    if (
      this.props.match.path === routes.dashboard.transaction.transaction_widget
    ) {
      this.setState({ isLoading: true });
      const dealership = this.props.user.default_dealership;

      if (this.props?.current_transactions_type === "inprogress") {
        getTransactionOne(dealership, this.props.match.params.trans_id).then(
          (resp) => {
            this.setState({ transaction: resp.data, isLoading: false });

            const transactionData = resp.data;

            // check is customer phone verified
            if (
              transactionData?.is_co_buyer &&
              transactionData?.co_buyer_phone_verified
            ) {
              this.setState({ current: 2 });
            } else if (transactionData?.customer_phone_verified) {
              this.setState({ current: 2 });
            }
          }
        );
      } else {
        getTransactionHistory(
          dealership,
          this.props.match.params.trans_id
        ).then((resp) => {
          this.setState({ transaction: resp.data, isLoading: false });
        });
      }
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  skipVerifyDocStep = () => {
    const dealership = this.props.user.default_dealership;
    const { transaction } = this.state;
    getTransactionOne(dealership, transaction.id).then((resp) =>
      this.setState({ transaction: resp?.data }, () => {
        const current = this.state.current + 2;
        this.setState({ current });
      })
    );
  };

  backToVerifyDocStep = () => {
    const current = this.state.current - 2;
    this.setState({ current });
  };

  setAcknowledgement = (value) => {
    this.setState({ contact_acknowledgement: value });
  };

  setTransaction = (transaction) => {
    this.setState({ transaction }, () => this.next());
  };

  fetchTransaction = () => {
    const dealership = this.props.user.default_dealership;
    const { transaction } = this.state;
    getTransactionOne(dealership, transaction.id).then((resp) =>
      this.setState({ transaction: resp?.data }, () => this.next())
    );
  };
  fetchTransactionWithoutNext = () => {
    const dealership = this.props.user.default_dealership;
    const { transaction } = this.state;
    getTransactionOne(dealership, transaction.id).then((resp) =>
      this.setState({ transaction: resp.data })
    );
  };
  handleExit = (visible) => {
    this.setState({ pop_confirm: confirm_status.exit, visible });
  };
  get_message = () => {
    const { t } = this.props;
    let is_co_buyer = this.state.transaction
      ? this.state.transaction.is_co_buyer
      : false;
    const { pop_confirm } = this.state;
    if (pop_confirm === confirm_status.add) {
      return is_co_buyer
        ? t("coBuyerInformation.popModal.exitTitle")
        : t("personalInformation.popModal.addTitle");
    } else if (pop_confirm === confirm_status.edit) {
      return is_co_buyer
        ? t("coBuyerInformation.popModal.updatedTitle")
        : t("personalInformation.popModal.updatedTitle");
    } else {
      return is_co_buyer
        ? t("coBuyerInformation.popModal.exitTitle")
        : t("personalInformation.popModal.exitTitle");
    }
  };

  render() {
    const {
      current,
      transaction,
      privacy_agreed,
      contact_acknowledgement,
      isLoading,
    } = this.state;

    const steps = [
      {
        title: "",
        content: (
          <StepsOnePrivacyPolicy
            user={this.props.user}
            privacy_agreed={privacy_agreed}
            transaction={transaction}
            next={(values) => this.setTransaction(values)}
          />
        ),
      },
      {
        title: "",
        content: (
          <StepTwoContactAcknowledgement
            contact_acknowledgement={contact_acknowledgement}
            transaction={transaction}
            setAcknowledgement={(val) => this.setAcknowledgement(val)}
            success={() => this.fetchTransactionWithoutNext()}
            next={() => this.fetchTransaction()}
            prev={() => this.prev()}
          />
        ),
      },
      {
        title: "",
        content: (
          <StepThreeVerifyIdentity
            transaction={transaction}
            next={() => this.fetchTransaction()}
            prev={() => this.prev()}
            getUserIdInformation={this.getUserIdInformation}
            skipVerifyDocStep={() => this.skipVerifyDocStep()}
          />
        ),
      },
      {
        title: "",
        content: (
          <VerifyIdentity
            transaction={transaction}
            next={() => this.fetchTransaction()}
            prev={() => this.prev()}
            userIdInformation={this.state.userInformation}
          />
        ),
      },
      {
        title: "",
        content: (
          <StepsFiveAddressInformation
            transaction={transaction}
            next={() => this.fetchTransaction()}
            prev={() => this.backToVerifyDocStep()}
          />
        ),
      },
      {
        title: "",
        content: (
          <StepsSixPhotoUpload
            transaction={transaction}
            prev={() => this.prev()}
          />
        ),
      },
    ];

    const items = steps.map((item, index) => ({
      key: `transaction_${index}`,
      title: item.title,
    }));

    const shouldShowWelcome =
      this.state.showWelcome &&
      this.props.match.path !== routes.dashboard.transaction.transaction_widget;
    if (isLoading) {
      return (
        <React.Fragment>
          <CustomHeader />
          <Paper
            className={"welcome-logo"}
            sx={{
              height: "96px",
              background: "white",
              borderBottom: "1px solid #0000001F",
            }}
            elevation={0}
          >
            <img
              className="img-fluid"
              alt="welcome-logo"
              src={Image.eunifiLogo}
              style={{ height: "43px" }}
            />
          </Paper>
          <div
            className="container-fluid main-inner-fluid"
            style={{ paddingTop: "100px" }}
          >
            <div className="col-12 text-center spiner">
              <Spin size="large" />
            </div>
          </div>
        </React.Fragment>
      );
    }

    if (shouldShowWelcome) {
      return (
        <Welcome
          transaction={true}
          hideWelecome={() => this.setState({ showWelcome: false })}
        />
      );
    }
    return (
      <React.Fragment>
        <CustomHeader />
        <Paper
          className={"welcome-logo"}
          sx={{
            height: "96px",
            background: "black",
            borderBottom: "1px solid #0000001F",
          }}
          elevation={0}
        >
          <img
            className="img-fluid"
            alt="welcome-logo"
            src={Image.eunifiLogo}
            style={{ height: "43px" }}
          />
        </Paper>
        <div
          className="container-fluid main-inner-fluid"
          style={{ background: "#252525", paddingTop: "0px" }}
        >
          <div
            className="row login-main-row main-row-width main-transition-div mx-auto"
            style={{ padding: "0px" }}
          >
            <div className="col-12">
              <div
                className={`row mt-4 add-transition-div-main ${
                  this.state.fixed && "nav-fix-div"
                }`}
              >
                <div className="col-12 transition-info-div position-relative">
                  <Button
                    className="bg-transparent border-0 p-0 back-btn-main position-absolute"
                    onClick={() => this.handleExit(true)}
                  >
                    <span style={{ color: "#296B68", marginLeft: "16px" }}>
                      Cancel
                    </span>
                  </Button>
                  <span className="d-inline-block w-100 text-center">{`${
                    transaction && transaction.is_co_buyer
                      ? "Co-Buyer"
                      : "Preliminary"
                  } Information`}</span>
                </div>
              </div>
            </div>
            <div className="col-12 add-new-customer-div px-0 py-0 shadow-none">
              <Steps current={current} items={items} />
              <div className="steps-content" style={{ padding: "0 45px" }}>
                {steps[current].content}
              </div>
            </div>
          </div>
        </div>
        {
          <PopConfirm
            visible={this.state.visible}
            widget={true}
            user={this.props.user}
            custom_message={
              "Exiting will not complete the process, but you can resume by\
                                   selecting the transaction with the blue circle below."
            }
            show_custom={this.state.current !== 0}
            exit={this.state.pop_confirm === confirm_status.exit}
            handleExit={() => this.handleExit(false)}
          >
            {this.get_message()}
          </PopConfirm>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transactions_type: state.current_transactions_type,
  };
};

export default withTranslation()(connect(mapStateToProps)(AddTransaction));
