import React, { Component } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import {
  getTransactionOne,
  setupComplete,
  verifyHardCredit,
} from "../../controller/api/customerAPI";
import { Image as Images } from "../Images";
import { methods } from "../../controller/Global";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changeSetup } from "../../store/actions/promptAction";
import ApplicationDisclosure from "../modal/ApplicationDisclosure";
import PrivacyPolicy from "../privacy-policy-terms-conditions/PrivacyPolicy";
import SignatureCanvas from "react-signature-canvas";
import { withTranslation, Trans } from "react-i18next";
import "../../components/sample/canvas.css";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class SubmitApplication extends Component {
  signatureRef = React.createRef();
  formRef = React.createRef();
  recaptchaRef = React.createRef();

  state = {
    visible: false,
    checked: false,
    signature: null,
    btnDisable: true,
    formFilled: false,
    captcha: true,
    visiblePrivacyPolicy: false,
    visibleApplicationDisclosure: false,
    buttonLoading: false,
  };

  showCreditSigned = (visible) => {
    this.setState({
      visible: visible,
    });
  };
  showPrivacyPolicy = (visible) => {
    this.setState({
      visiblePrivacyPolicy: visible,
    });
  };
  showApplicationDisclosure = (visible) => {
    this.setState({
      visibleApplicationDisclosure: visible,
    });
  };

  handleChange = (e) => {
    this.setState({ checked: e.target.checked });
  };

  onFormChange = (allFields) => {
    if (allFields.every((item) => !!item.value))
      this.setState({ formFilled: true });
    else this.setState({ formFilled: false });
  };

  handleSubmit = (values) => {
    const { hard_credit } = this.props;
    this.setState({ buttonLoading: true });
    let form_data = new FormData();
    form_data.append("verification_code", values["verification_code"]);
    form_data.append(
      "signature",
      dataURLtoFile(this.state.signature, "signature.png")
    );

    verifyHardCredit(hard_credit.id, form_data)
      .then(() => {
        if (this.props.current_transaction.pre_qualification) {
          this.props.changeSetup(true);
          setupComplete(this.props.current_transaction.id)
            .then(() => {
              this.setState({
                buttonLoading: false,
              });
            })
            .catch(() => {
              this.setState({
                buttonLoading: false,
              });
            });
        }
        this.props.onSubmit();
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            buttonLoading: false,
          });
          Object.keys(err.response.data).forEach((e) => {
            message.error(`${e}: ${err.response.data[e]}`);
          });
        }
      });
  };

  handleSign = () => {
    this.setState({
      signature: this.signatureRef.current.toDataURL(),
      visible: false,
      formFilled: true,
    });
  };

  componentDidMount() {
    let { params } = this.props.match;
    if (params.method === methods.edit) {
      if (this.props.current_transaction && this.props.current_transaction.id) {
        const { current_transaction } = this.props;
        this.setState({
          signature: current_transaction.hard_credit_info.signature,
          formFilled: current_transaction.hard_credit_info.is_verified,
        });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.hard_credit_info.id !== parseInt(params.id)) {
              message.error("Invalid URL for updating");
              history.push(routes.dashboard.self);
            } else {
              this.setState({
                signature: res.data.hard_credit_info.signature,
                formFilled: res.data.hard_credit_info.is_verified,
              });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
    }
  }

  clearCanvas = () => {
    this.signatureRef.current.clear();
    this.setState({ btnDisable: true });
  };

  render() {
    const { visible, formFilled, signature, btnDisable, captcha } = this.state;
    const { hard_credit, t , user} = this.props;
    const defaultDealership = user?.available_dealerships?.find(
        (o) => o.id === user?.default_dealership
    )?.name;

    return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <div className="transition-information-heading w-100 font-weight-bold mb-2">
            {t('hardCreditForm.hcFormStepFour.title')}
          </div>
          <div
            className="small-heading-div w-100 mb-5"
            style={{ fontSize: "22px", lineHeight: "22px", fontWeight: "400" }}
          >
            {t('hardCreditForm.hcFormStepFour.subTitle')}
          </div>
          <div
            className=" mb-0 verify-identy-small-div font-weight-bold w-100"
            style={{ fontSize: "17px" }}
          >
            {t('hardCreditForm.hcFormStepFour.commitmentToPrivacy.title')}
          </div>
          <p className="font-weight-normal">
            {t('hardCreditForm.hcFormStepFour.commitmentToPrivacy.content')}{" "}
          </p>
          <div
            className="mb-0 verify-identy-small-div font-weight-bold w-100"
            style={{ fontSize: "17px" }}
          >
            {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.title')}
          </div>
          <p className="font-weight-normal">
            {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.acknowledgementOne')}{" "}
            <span
                onClick={() => this.showPrivacyPolicy(true)}
                className="font-weight-bold primary-text-color"
            >
                  {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.privacyPolicy')}
                </span>
            {" "}
            {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.and')}{" "}
            <span
                onClick={() => this.showApplicationDisclosure(true)}
                className="font-weight-bold primary-text-color cursor-pointer"
            >
                  {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.applicationDisclosure')}
                </span>
            {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.acknowledgementTwo')}
            {' '}{defaultDealership}{' '}
            {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.acknowledgementThree')}
          </p>
          <div className="row mx-0">
            <Form
                className="w-100 mt-3"
                onFinish={this.handleSubmit}
                ref={this.formRef}
                onFieldsChange={(changedFields, allFields) => {
                this.onFormChange(allFields);
              }}
            >
              {hard_credit.is_verified ? (
                <p>{t('hardCreditForm.hcFormStepFour.hardCreditAlreadyVerified')}</p>
              ) : (
                <>
                  <div className="col-12 col-sm-12 mt-5">
                    <p className="font-weight-bold">
                      {t('hardCreditForm.hcFormStepFour.acknowledgementAndConsent.signature')}:
                    </p>
                    <Form.Item
                      name="date"
                      label={""}
                      initialValue={this.state.signature}
                      rules={[
                        {
                          required: false,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label group-floating-pvc position-relative"
                      }
                    >
                      <Input disabled={true} />
                      <Button
                        onClick={() => this.setState({ visible: true })}
                        className={`upload-btn-modal ${
                          signature && "uploaded-btn-main"
                        } border-0 position-absolute d-flex align-items-center justify-content-center p-0 m-auto`}
                      >
                        <img
                          src={signature ? signature : Images.sign_btn_img}
                          alt=""
                          className="img-fluid"
                        />
                      </Button>
                    </Form.Item>
                  </div>
                </>
              )}
              <div className="steps-action flex-align-center justify-content-end w-100">
                <Button
                  disabled={this.state.buttonLoading}
                  style={{ marginRight: "11%" }}
                  onClick={() => this.props.prev()}
                >
                  {t('utils.back')}
                </Button>
                <Button
                  loading={this.state.buttonLoading}
                  htmlType="submit"
                  disabled={!formFilled}
                  type="primary"
                >
                  {hard_credit.is_verified ? t('utils.close') : t('utils.submit')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <Modal
          onCancel={() => this.setState({ visible: false })}
          title={t('utils.drawSignature')}
          onOk={() =>
            this.setState({
              signature: this.signatureRef.current.toDataURL(),
              visible: false,
            })
          }
          open={visible}
          className="main-modal draw-sign-modal footer-btn-document-modal"
          footer={[
            <Button
              key="cancel"
              onClick={() => this.setState({ visible: false })}
            >
              {t('utils.cancel')}
            </Button>,
            <Button
              key="submit"
              disabled={btnDisable}
              type="primary"
              onClick={() => this.handleSign()}
            >
              {t('utils.submit')}
            </Button>,
          ]}
        >
          <>
            <div className="signature-modal position-relative">
              <Button
                className="clear-btn-sign position-absolute p-0 font-weight-bold text-uppercase shadow-none"
                onClick={() => this.clearCanvas()}
              >
                Clear
              </Button>
              <SignatureCanvas
                onBegin={() => this.setState({ btnDisable: false })}
                ref={this.signatureRef}
                canvasProps={{
                  width: `500`,
                  height: `280`,
                  className: "sigCanvas",
                }}
              />
            </div>
          </>
        </Modal>
        <PrivacyPolicy visible={this.state.visiblePrivacyPolicy} onClose={() => this.showPrivacyPolicy(false)}/>
        <ApplicationDisclosure
          visible={this.state.visibleApplicationDisclosure}
          onClose={() => this.showApplicationDisclosure(false)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};



export default withTranslation()(connect(mapStateToProps, { changeSetup })(SubmitApplication));
