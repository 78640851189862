import React, { Component } from "react";
import { Image as Images } from "./Images";
import {
  loadMoreTransaction,
  transDealJacket,
} from "../controller/api/customerAPI";
import { Button } from "antd";
import SideBar from "./sidebar/SideBar";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import moment from "moment";
import { withTranslation } from "react-i18next";

const document_images = {
  pay_stub: Images.document_pay_stub,
  utility_bill: Images.document_utility_bills,
  privacy_policy: Images.document_other,
  identity_theft_prevention: Images.document_identity_theft_prevention,
  "pre-qual_application": Images.document_other,
  hard_credit_application: Images.document_other,
  ID_information: Images.document_other,
  id_information: Images.document_other,
  vehicle_for_trade_information: Images.document_other,
  contact_acknowledgement: Images.document_other,
  purchase_acknowledgement: Images.document_other,
  new_hard_credit_form_submission_acknowledgement: Images.document_other,
  "new_pre-qualification_form_submission_acknowledgement": Images.document_other,
  pre_qualification_certificate: Images.document_other,
  hard_credit_certificate: Images.document_other,
};

function get_image(key) {
  if (Object.keys(document_images).includes(key)) {
    return document_images[key];
  } else {
    return Images.document_other;
  }
}

class ViewDocuments extends Component {
  state = {
    data: [],
    next: null,
    fixed: false,
  };

  componentDidMount() {
    transDealJacket(this.props.match.params.trans_id).then((resp) => {
      this.setState({ data: resp.data.results, next: resp.data.next });
    });
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  handleLoadMore = () => {
    loadMoreTransaction(this.state.next).then((resp) => {
      this.setState({
        data: [...this.state.data, ...resp.data.results],
        next: resp.data.next,
      });
    });
  };

  render() {
    const { data, next } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        {/*<CustomHeader/>*/}
        <SideBar />
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12 mx-auto">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 transition-info-div position-relative">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => history.push(routes.dashboard.self)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('myDocumentCard.uploadDocument.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mx-auto deal-info-div upload-docs-div">
                      <ul className="list-inline mb-0">
                        {data.length > 0
                          ? data.map((item, index) => (
                              <li
                                key={`document_${index}`}
                                className="flex-align-center"
                              >
                                <img
                                  alt="img"
                                  className="img-fluid"
                                  src={get_image(item.document_type)}
                                />
                                <a
                                  className="text-capitalize text-dark"
                                  href={item.document}
                                  target={"_blank"}
                                  rel="noopener noreferrer">
                                  <span>
                                    {item.name
                                      ? item.name
                                      : `${item.document_type
                                          .split("_")
                                          .join(" ")}-${moment(
                                          item.created_at
                                        ).format("MM/DD/YYYY")}`}
                                  </span>
                                </a>
                              </li>
                            ))
                          : ""}
                      </ul>
                      {data.length > 0 && next && (
                        <div className="text-center w-100">
                          <Button
                            onClick={this.handleLoadMore}
                            className="load-more-btn"
                          >
                            Load More
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ViewDocuments);
