import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Select,
  Checkbox,
  Modal,
} from "antd";
import { states } from "../../controller/states";
import {
  addPreQualification,
  getTransactionOne,
  getZipcode,
  setupComplete,
  updatePreQualification,
} from "../../controller/api/customerAPI";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  methods,
  onSelectBlurred,
  onSelectFocused,
} from "../../controller/Global";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { Image as Images } from "../Images";
import { changeSetup } from "../../store/actions/promptAction";
import HiddenSsnInput from "../../controller/HiddenSsnInput";
import ApplicationDisclosure from "../modal/ApplicationDisclosure";
import HiddenDobInput from "../HiddenDobInput";
import PrivacyPolicy from "../privacy-policy-terms-conditions/PrivacyPolicy";
import {PhoneValidator, SsnValidator, AgeValidator} from "../../utils/validators";
import SignatureCanvas from "react-signature-canvas";
import "../../components/sample/canvas.css";
import { DateTime } from "luxon";
import { withTranslation, Trans } from "react-i18next";
import { getEnvValue } from "../../controller/Environment";


const { Option } = Select;
const moment = require("moment");

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class PersonalInformation extends Component {
  state = {
    value: 1,
    signature: null,
    btnDisable: true,
    formFilled: false,
    captcha: true,
    signaturePresent: null,
    created: null,
    visiblePrivacyPolicy: false,
    visibleApplicationDisclosure: false,
    selectedDate: utils().getToday(),
    matchZipCode: null,
    buttonLoading: false,
    phone: null,
    visible: false,
  };
  formRef = React.createRef();
  signatureRef = React.createRef();
  recaptchaRef = React.createRef();

  onDateChange = (value) => {
    this.setState({ selectedDate: value });
  };

  formatDate = () => {
    const { selectedDate } = this.state;
    return `${selectedDate.month}/${selectedDate.day}/${selectedDate.year}`;
  };

  showPrivacyPolicy = (visible) => {
    this.setState({
      visiblePrivacyPolicy: visible,
    });
  };
  showApplicationDisclosure = (visible) => {
    this.setState({
      visibleApplicationDisclosure: visible,
    });
  };
  handleSubmit = (values) => {
    let emp = true;
    this.setState({ buttonLoading: true });
    values["transaction"] = this.props.transaction;
    values.dob = `${values.dob.year}-${values.dob.month}-${values.dob.day}`;
    values.zip_code = values.zip_code.replace(/[^0-9]/g, "");
    // values.snn = values.ssn.replace(/[^0-9]/g, "");

    if (values.ssn.indexOf("-") !== -1) {
      values.ssn = values.ssn.replace(/[^0-9]/g, "");
    }

    if (values.zip_code === "") {
      message.error("Zip code should be in Numeric Digits Only");
      emp = false;
    }

    if (!values.suffix) {
      delete values.suffix;
    }
    let form_data = new FormData();
    for (let key in values) {
      if (values[key]) {
        form_data.append(key, values[key]);
      }
    }
    if (!this.state.signaturePresent)
      form_data.append(
        "signature",
        dataURLtoFile(this.state.signature, "signature.png")
      );

    if (emp) {
      if (this.state.created) {
        updatePreQualification(form_data, this.state.preQualificationId)
          .then((response) => {
            this.props.savePersonalInfo(response.data);
            this.setState({
              buttonLoading: false,
            });
            this.props.onSubmit();
          })
          .catch((err) => {
            this.setState({
              buttonLoading: false,
            });
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(err.response.data[e]);
              });
            }
          });
      } else {
        addPreQualification(form_data)
          .then((response) => {
            this.setState({
              buttonLoading: false,
            });
            this.props.savePersonalInfo(response.data);
            if (this.props.current_transaction.pre_qualification) {
              this.props.changeSetup(true);
              setupComplete(this.props.current_transaction.id)
                .then(() => {})
                .catch(() => {
                  this.setState({
                    buttonLoading: false,
                  });
                });
            }
            this.props.onSubmit();
          })
          .catch((err) => {
            this.setState({
              buttonLoading: false,
            });
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(err.response.data[e]);
              });
            }
          });
      }
    } else {
      this.setState({ buttonLoading: false });
    }
  };
  componentDidMount() {
    let { params } = this.props.match;
    var ssn_new;
    var ssn_new_two;
    var ssn_new_three;
    var ssn_new_four;

    if (params.method === methods.edit) {
      if (this.props.current_transaction && this.props.current_transaction.id) {
        const _dob = DateTime.fromISO(this.props.current_transaction.pre_qualification.dob)
        const dob = {
          day: _dob.day,
          month: _dob.month,
          year: _dob.year,
        };

        if (
          this.props.current_transaction?.pre_qualification &&
          this.props.current_transaction.pre_qualification?.ssn
        ) {
          if (
            this.props.current_transaction?.pre_qualification.ssn?.indexOf(
              "-"
            ) !== -1
          ) {
            ssn_new = this.props.current_transaction?.pre_qualification?.ssn;
          } else {
            ssn_new = this.props.current_transaction?.pre_qualification?.ssn
              ?.match(/\d*/g)
              .join("")
              .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
              .slice(1)
              .join("-");
          }
        }
        this.formRef.current.setFieldsValue({
          ...this.props.current_transaction.pre_qualification,
          dob: dob,
          ssn: ssn_new,
        });
        this.setState({
          created: this.props.current_transaction.pre_qualification.created,
          selectedDate: dob,
          preQualificationId:
            this.props.current_transaction.pre_qualification.id,
        });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.pre_qualification.id !== parseInt(params.id)) {
              message.error("Invalid URL for updating");
              history.push(routes.dashboard.self);
            } else {
              const _dob = DateTime.fromISO(res.data.pre_qualification.dob);
              const dob = {
                day: _dob.day,
                month: _dob.month,
                year: _dob.year,
              };
              if (res.pre_qualification.ssn) {
                // eslint-disable-next-line
                if (res.pre_qualification.ssn.indexOf("-") != -1) {
                  ssn_new_two = res.pre_qualification?.ssn;
                } else {
                  ssn_new_two = res.pre_qualification?.ssn
                    ?.match(/\d*/g)
                    .join("")
                    .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
                    .slice(1)
                    .join("-");
                }
              }
              this.formRef.current.setFieldsValue({
                ...res.data.pre_qualification,
                dob: dob,
                ssn: ssn_new_two,
              });
              this.setState({
                created: res.data.pre_qualification?.created,
                selectedDate: dob,
                preQualificationId: res.data?.pre_qualification?.id,
              });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
    }
    else {
      // else if (this.props.current_transaction) {
      if (this.props?.current_transaction?.pre_qualification) {
        const _dob = DateTime.fromISO(this.props.current_transaction.pre_qualification.dob);
        const dob = {
          day: _dob.day,
          month: _dob.month,
          year: _dob.year,
        };
        if (
          this.props.current_transaction.pre_qualification &&
          this.props.current_transaction.pre_qualification.ssn
        ) {
          if (
            this.props.current_transaction?.pre_qualification?.ssn?.indexOf(
              "-"
              // eslint-disable-next-line
            ) !== -1
          ) {
            ssn_new_three =
              this.props.current_transaction?.pre_qualification?.ssn;
          } else {
            ssn_new_three =
              this.props.current_transaction?.pre_qualification?.ssn
                ?.match(/\d*/g)
                .join("")
                .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
                .slice(1)
                .join("-");
          }
        }
        this.formRef.current.setFieldsValue({
          ...this.props.current_transaction.pre_qualification,
          dob: dob,
          ssn: ssn_new_three,
        });
      }
      else {
        if (this.props.current_transaction) {
          const {
            personal_information,
            address_information,
            hard_credit_info,
          } = this.props.current_transaction;
          if (personal_information) {
            const _dob = DateTime.fromISO(personal_information.dob);
            const dob = {
              day: _dob.day,
              month: _dob.month,
              year: _dob.year,
            };
            this.formRef.current.setFieldsValue({
              ...personal_information,
              dob: dob,
            });
            this.setState({
              selectedDate: dob,
            });
          }
          if (address_information) {
            this.formRef.current.setFieldsValue({
              address: address_information.address_1,
              city: address_information.city_1,
              state: address_information.state_1,
              zip_code: address_information.zip_code_1,
            });
          }
          if (hard_credit_info && hard_credit_info.ssn) {
            // eslint-disable-next-line
            if (hard_credit_info.ssn.indexOf("-") != -1) {
              ssn_new_four = hard_credit_info?.ssn;
            } else {
              ssn_new_four = hard_credit_info?.ssn
                ?.match(/\d*/g)
                .join("")
                .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
                .slice(1)
                .join("-");
            }
            this.formRef.current.setFieldsValue({
              ssn: ssn_new_four,
            });
          }
        }
      }
    }

    // Step-3 back btn press -> Step 2-- just-filled-details to be pre filled BELOW

    if (this.props.justfilled) {
      const _dob = DateTime.fromISO(this.props.customer_info.dob);
      const dob = {
        day: _dob.day,
        month: _dob.month,
        year: _dob.year,
      };
      this.formRef.current.setFieldsValue({
        ...this.props.customer_info,
        dob: dob,
      });

      this.setState({ selectedDate: dob });
    }
    // chekboxes and signature should be blank always (No need to prefill them)
    this.formRef.current.setFieldsValue({
      not_application_credit: null,
      received_privacy_notice: null,
      user_consent: null,
      sign: null,
    });
  }

  handleChange = (v) => {
    this.formRef.current.setFieldsValue({
      state: v,
    });
  };

  onFormChange = (allFields) => {
    let newFields = allFields.filter(
      (item) => item.name[0] !== "suffix" && item.name[0] !== "middle_name"
    );
    if (newFields.every((item) => !!item.value || item.value === 0))
      this.setState({ formFilled: true });
    else this.setState({ formFilled: false });
  };

  ssnValidator = (rule, value, callback) => {
    if (!SsnValidator(value)) {
      callback("Please input valid SSN");
      return;
    }
    callback();
  };

  dobValidator = (rule, value, callback) => {
    if (!AgeValidator(value)) {
      callback("Please input DOB between 16-95 years old");
      return;
    }
    callback();
  };

  phoneValidator = (rule, value, callback) => {
    if (PhoneValidator(value)) {
      callback("Exactly 10 digits are required");
      return;
    }
    callback();
  };

  onNumberOnlyChange = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9.,]").test(keyValue);
    // const isValidecimal = new RegExp(".").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };
  onChangeZip = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode = response.data;
        this.setState({ matchZipCode });
        if (matchZipCode) {
          this.formRef.current.setFieldsValue({
            city: matchZipCode.city,
            state: matchZipCode.state,
          });
        }
      });
    }
  };

  onBlurZip = (e) => {
    let value = e.target.value;
    const { matchZipCode } = this.state;
    if (matchZipCode && value.length === 3) {
      this.formRef.current.setFieldsValue({
        zip_code: `00${value}`,
      });
    } else if (matchZipCode && value.length === 4) {
      this.formRef.current.setFieldsValue({
        zip_code: `0${value}`,
      });
    }
  };

  handleSign = () => {
    this.setState({
      signature: this.signatureRef.current.toDataURL(),
      visible: false,
      formFilled: true,
    });
  };
  clearCanvas = () => {
    this.signatureRef.current.clear();
    this.setState({ btnDisable: true });
  };

  render() {
    const { signaturePresent } = this.state;
    const { t, user } = this.props;
    const defaultDealership = user?.available_dealerships?.find(
        (o) => o.id === user?.default_dealership
    )?.name;

    return (
        <div className="col-12 transition-information-div personal-info-outermost-div">
          <div className="transition-information-heading w-100 font-weight-bold mb-3">
            {t('preQualificationForm.title')}
          </div>

          <div className={'mb-5 application-info-subtext-div'}>
              {t('preQualificationForm.contentOne')}
            <br/>
            <br/>
              <Trans i18nKey="preQualificationForm.contentTwo"/>
          </div>

          <div className="row">
            <Form
                ref={this.formRef}
                className="w-100"
                onFieldsChange={(changedFields, allFields) => {
                  this.onFormChange(allFields);
                }}
                onFinish={this.handleSubmit}
            >
              <div className="row mx-0 mb-5">
                <div className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.firstNameLabel')} *
                  </label>
                  <Form.Item
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.middleNameLabel')}
                  </label>
                  <Form.Item
                      name="middle_name"
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.lastNameLabel')} *
                  </label>
                  <Form.Item
                      name="last_name"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div
                    className="col-12 col-sm-12 col-lg-6 select_floating"
                    id={"select_suffix"}
                >
                  <label className="font-weight-bold">
                    {t('preQualificationForm.suffixLabel')}
                  </label>
                  <Form.Item
                      name="suffix"
                      rules={[]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <Select
                        onFocus={() => onSelectFocused("select_suffix")}
                        onBlur={() => onSelectBlurred("select_suffix")}
                        placeholder={""}
                        suffixIcon={
                          <img
                              src={Images.down_arrow_select}
                              width={15}
                              alt="down arrow"
                              className="img-fluid"
                          />
                        }
                    >
                      <Select.Option
                          disabled
                          className="disbaled-optn-color"
                          value={null}
                      >
                        {t('preQualificationForm.suffixLabel')}
                      </Select.Option>
                      <Select.Option value={null}>None</Select.Option>
                      <Select.Option value="Sr">Sr</Select.Option>
                      <Select.Option value="Jr">Jr</Select.Option>
                      <Select.Option value="II">II</Select.Option>
                      <Select.Option value="III">III</Select.Option>
                      <Select.Option value="IV">IV</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-6" id={"select_date"}>
                  <label className="font-weight-bold">
                    {t('preQualificationForm.DOBLabel')} *
                  </label>
                  <Form.Item
                      name="dob"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {validator: this.dobValidator},
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second"
                      }
                  >
                    <HiddenDobInput value={this.value}/>
                  </Form.Item>
                </div>
                <div id={"ssn"} className="col-12 col-sm-12 col-lg-6">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.ssnLabel')} *
                  </label>
                  <Form.Item
                      name="ssn"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {validator: this.ssnValidator},
                      ]}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <HiddenSsnInput
                        placeholder=" "
                        value={this.value}
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.streetAddressLabel')} *
                  </label>
                  <Form.Item
                      name="address"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.suiteLabel')}
                  </label>
                  <Form.Item
                      name="suite"
                      label={""}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        placeholder=" "
                        className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.zipCodeLabel')} *
                  </label>
                  <Form.Item
                      name="zip_code"
                      label={""}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        // disabled={signaturePresent}
                        placeholder=" "
                        onChange={this.onChangeZip}
                        onBlur={this.onBlurZip}
                    />
                  </Form.Item>
                  <span>{t('preQualificationForm.zipHelperText')}</span>
                </div>
                <div className="col-12 col-sm-12 col-lg-4">
                  <label className="font-weight-bold">
                    {t('preQualificationForm.cityLabel')} *
                  </label>
                  <Form.Item
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={"group-floating-label group-floating-label-second"}
                  >
                    <Input
                        placeholder=" "
                        className="'inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div
                    className="col-12 col-sm-12 col-lg-4 select_floating"
                    id={"select_state"}
                >
                  <label className="font-weight-bold">
                    {t('preQualificationForm.stateLabel')} *
                  </label>
                  <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className={
                        "group-floating-label select-float group-floating-label-second position-relative"
                      }
                  >
                    <Select
                        onFocus={() => onSelectFocused("select_state")}
                        onBlur={() => onSelectBlurred("select_state")}
                        suffixIcon={
                          <img
                              src={Images.down_arrow_select}
                              width={15}
                              alt="down arrow"
                              className="img-fluid"
                          />
                        }
                        placeholder=""
                    >
                      {states.map((item) => (
                          <Option
                              key={item.abbreviation}
                              value={item.abbreviation}
                              className="text-capitalize"
                          >{`${item.name} (${item.abbreviation})`}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-12 mb-5">
                <div className="personal-info-strong w-100 our-commitment-div mb-2">
                  {t('preQualificationForm.commitmentPrivacy.title')}
                </div>
                <p className="font-weight-normal all-information-p mb-5">
                  {t('preQualificationForm.commitmentPrivacy.content')} {''}
                  <span
                      onClick={() => this.showPrivacyPolicy(true)}
                      className="font-weight-bold primary-text-color"
                  >
                  {t('preQualificationForm.privacyPolicy')}
                </span>
                </p>
                <div className="personal-info-strong w-100 acknowledgement-consent-div">
                  {t('preQualificationForm.acknowledgementAndConsent.title')}
                </div>
                <div className="transition-check-box w-100">
                  <Form.Item
                      name={"not_application_credit"}
                      valuePropName={"checked"}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {
                          validator: (_, value) =>
                              value
                                  ? Promise.resolve()
                                  : Promise.reject("Should accept agreement"),
                        },
                      ]}
                  >
                    <Checkbox disabled={signaturePresent}>
                      {t('preQualificationForm.acknowledgementAndConsent.acknowledgementOne')}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="transition-check-box w-100">
                  <Form.Item
                      name={"received_privacy_notice"}
                      valuePropName={"checked"}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {
                          validator: (_, value) =>
                              value
                                  ? Promise.resolve()
                                  : Promise.reject("Should accept agreement"),
                        },
                      ]}
                  >
                    <Checkbox disabled={signaturePresent}>
                      {t('preQualificationForm.acknowledgementAndConsent.acknowledgementTwo')}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="transition-check-box w-100">
                  <Form.Item
                      name={"user_consent"}
                      valuePropName={"checked"}
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                        {
                          validator: (_, value) =>
                              value
                                  ? Promise.resolve()
                                  : Promise.reject("Should accept agreement"),
                        },
                      ]}
                  >
                    <Checkbox disabled={signaturePresent}>
                      {t('preQualificationForm.acknowledgementAndConsent.acknowledgementThree')}{' '}
                      <span
                          onClick={() => this.showPrivacyPolicy(true)}
                          className="font-weight-bold primary-text-color"
                      >
                        {t('preQualificationForm.privacyPolicy')}
                    </span>{" "}
                      {t('preQualificationForm.and')}{" "}
                      <span
                          onClick={() => this.showApplicationDisclosure(true)}
                          className="font-weight-bold primary-text-color"
                      >
                      {t('preQualificationForm.applicationDisclosure')}
                    </span>
                      {t('preQualificationForm.acknowledgementAndConsent.acknowledgementFour')}
                      {' '}{defaultDealership}{' '}
                      {t('preQualificationForm.acknowledgementAndConsent.acknowledgementFive')}
                    </Checkbox>
                  </Form.Item>
                </div>
                {signaturePresent ? (
                    <h1>{`Application signed ${moment(this.state.created).format(
                        "MM/DD/YYYY"
                    )}`}</h1>
                ) : (
                    <div className="col-12 col-sm-12 signature-col">
                      <Form.Item
                          name="sign"
                          label={ t('preQualificationForm.acknowledgementAndConsent.signature')}
                          rules={[
                            {
                              required: false,
                              message: "this field is required",
                            },
                          ]}
                          className={
                            "group-floating-label group-floating-pvc position-relative"
                          }
                      >
                        <Input
                            className="prequal-sign-input"
                            style={{pointerEvents: "none"}}
                        />
                        <Button
                            onClick={() => this.setState({visible: true})}
                            className={`upload-btn-modal upload-btn-modal-signature  ${
                                this.state.signature && "uploaded-btn-main"
                            } border-0 position-absolute d-flex align-items-center justify-content-center p-0 m-auto`}
                        >
                          <img
                              src={
                                this.state.signature
                                    ? this.state.signature
                                    : Images.sign_btn_img
                              }
                              alt=""
                              className="img-fluid"
                          />
                        </Button>
                      </Form.Item>
                    </div>
                )}
              </div>
              {/*<div className="recaptcha-div">*/}
              {/*  <div className="col-12 col-6">*/}
              {/*    <ReCAPTCHA*/}
              {/*      ref={this.recaptchaRef}*/}
              {/*      sitekey={getEnvValue('REACT_APP_GOOGLE_RECAPTCHA_API_KEY')}*/}
              {/*      onChange={() => this.setState({ captcha: false })}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="steps-action flex-align-center justify-content-end w-100 personal-info-button-div">
                <Button
                    style={{marginRight: "4%"}}
                    onClick={this.props.close}
                >
                  {t('utils.close')}
                </Button>
                <Button
                    loading={this.state.buttonLoading}
                    disabled={!this.state.formFilled}
                    type="primary"
                    htmlType="submit"
                >
                  {signaturePresent ? t('utils.close') : t('utils.submit')}
                </Button>
              </div>
            </Form>
          </div>
          {/* ...step 3 ui modals.... */}

        <Modal
          onCancel={() => this.setState({ visible: false })}
          title={"Draw Signature"}
          onOk={() =>
            this.setState({
              signature: this.signatureRef.current.toDataURL(),
              visible: false,
            })
          }
          open={this.state.visible}
          className="main-modal draw-sign-modal footer-btn-document-modal"
          footer={[
            <Button
              key="cancel"
              onClick={() => this.setState({ visible: false })}
            >
              {t('utils.cancel')}
            </Button>,
            <Button
              key="submit"
              disabled={this.state.btnDisable}
              type="primary"
              onClick={() => this.handleSign()}
            >
              {t('utils.submit')}
            </Button>,
          ]}
        >
          <>
            <div className="signature-modal position-relative">
              <Button
                className="clear-btn-sign position-absolute p-0 font-weight-bold text-uppercase shadow-none"
                onClick={() => this.clearCanvas()}
              >
                {t('utils.clear')}
              </Button>
              <SignatureCanvas
                onBegin={() => this.setState({ btnDisable: false })}
                ref={this.signatureRef}
                canvasProps={{
                  width: `500`,
                  height: `280`,
                  className: "sigCanvas",
                }}
              />
            </div>
          </>
        </Modal>
         <PrivacyPolicy
          visible={this.state.visiblePrivacyPolicy}
          onClose={() => this.showPrivacyPolicy(false)}
        />
        <ApplicationDisclosure
          visible={this.state.visibleApplicationDisclosure}
          onClose={() => this.showApplicationDisclosure(false)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_transaction: state.current_transaction,
    user: state.user,
    prompts: state.prompt,
  };
};

export default withTranslation()(connect(mapStateToProps, {changeSetup})(withRouter(PersonalInformation)));
