import React, { Component } from "react";
import { Image as Images } from "../Images";
import { Button, Form, Input } from "antd";
import { NavLink } from "react-router-dom";
import { routes } from "../../controller/routes";
class SignUp extends Component {
  render() {
    return (
      <div className="container-fluid login-div-fluid h-100 flex-align-center-center">
        <div className="row login-main-row mx-auto">
          <div className="col-12 logo-div-main">
            <NavLink to={routes.dashboard.self} className="d-inline-block">
              <img
                src={Images.logo_main}
                alt="logo main"
                className="img-fluid"
              />
            </NavLink>
          </div>
          <div className="col-12 login-card-main sign-up-card bg-white">
            <h6 className="text-center">Sign Up</h6>
            <div className="login_form_design w-100">
              <Form>
                <Form.Item
                  name="Email"
                  label={""}
                  rules={[
                    {
                      required: true,
                      message: "this field is required",
                    },
                  ]}
                  className={"group-floating-label"}
                >
                  <Input
                    autoCapitalize={"off"}
                    placeholder="Email"
                    // suffix={<label className="floating-label" htmlFor="name">Email</label>}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    className="w-100 text-white border-0 font-weight-normal"
                  >
                    Next
                  </Button>
                </Form.Item>
                <div className="w-100 mt-2 text-center">
                  <p className="mb-0">
                    Already have an account?{" "}
                    <NavLink to={routes.login}>Login</NavLink>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
