import React, { Component } from "react";
import { Checkbox, Col, Modal, Row } from "antd";
import { withTranslation, Trans } from "react-i18next";


class TradeInfoAdded extends Component {
  state = {
    title: null,
    paid: null,
    paid_proof: null,
    checkedValues: []
  };

  componentDidMount() {
    if (this.props.tradeData) {
      let checkedValues = []
      if (this.props.tradeData.have_vehicle_title) checkedValues.push('have_vehicle_title');
      if (this.props.tradeData.is_vehicle_paid_off) checkedValues.push('is_vehicle_paid_off');
      if (this.props.tradeData.have_vehicle_paid_proof) checkedValues.push('have_vehicle_paid_proof');
      this.setState({ checkedValues});
    }
  }

  onChange = (checkedValues) => {
    this.setState({ checkedValues });
  };

  render() {
    const { t } = this.props;
    return (
      <Modal
        title={false}
        open={this.props.visible}
        onOk={() => {
          this.props.submitCheckBoxes(this.state.checkedValues);
          this.props.onClose();
        }}
        okButtonProps={{ style: { border: "none" } }}
        onCancel={this.props.onClose}
        className="main-modal close-btn-none"
        okText={t('utils.submit')}
        width="31%"
        centered
      >
        <div className="row modal-inner-row-data">
          <div className="col-12">
            <p className="mb-3 text-center font-weight-bold" style={{ color: '#000000' }}>
              {t('vehicleForTradeInformation.popModal.tradeInfoAddedTitle')}
            </p>
            <p className="small-text-size">
              <Trans i18nKey="vehicleForTradeInformation.popModal.tradeInfoAddedSubTitle" />
            </p>
          </div>
          <div className="col-12 check-box-div">
            <Checkbox.Group style={{ width: "100%" }} value={this.state.checkedValues} onChange={this.onChange}>
              <Row>
                <Col span={24}>
                  <Checkbox value="have_vehicle_title">
                    {t('vehicleForTradeInformation.popModal.questionOne')}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="is_vehicle_paid_off">
                    {t('vehicleForTradeInformation.popModal.questionTwo')}
                  </Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="have_vehicle_paid_proof">
                    {t('vehicleForTradeInformation.popModal.questionThree')}
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </div>
          <div className="col-12">
            <p className="small-text-size">
              <Trans i18nKey="vehicleForTradeInformation.popModal.tradeInfoAddedHelperText" />
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(TradeInfoAdded);
