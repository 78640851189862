import logo_main from "../assets/img/digital-deal-butler-logo.svg";
// import logo_small_header from "../assets/img/Logo1.svg";
import logo_small_header from "../assets/img/VIRTUAL-FI-LOGO.svg";
import eye_icon from "../assets/img/icons/eye.svg";
import screen_shot_1 from "../assets/img/screen-shot-1.svg";
import screen_shot_2 from "../assets/img/screen-shot-2.svg";
import user_icon from "../assets/img/icons/user-icon.svg";
import plus_icon_primary from "../assets/img/icons/plus-icon.svg";
import down_arrow_gray from "../assets/img/down-arrow.svg";
import upload_file_icon from "../assets/img/icons/upload-file.svg";
import pay_stubs_img from "../assets/img/pay-stubs.svg";
import document_icon_5 from "../assets/img/documenticon5.svg";
import document_icon_1 from "../assets/img/document_icon_1.svg";
import link_success_icon from "../assets/img/link-success-icon.svg";
import white_arrow_right from "../assets/img/icons/right-arrow-white.svg";
import black_arrow_right from "../assets/img/right-arrow-black.svg";
import graph_img from "../assets/img/graph.svg";
import down_arrow from "../assets/img/icons/down-Icon.svg";
import black_plus_icon from "../assets/img/icons/black-plus-icon.svg";
import RamseyLeafLogo from "../assets/img/ramsey_leafs_logo.svg";
import VFILogo from "../assets/img/VFILogo";
import VirtualLogo from "../assets/img/VirtualLogo";

import personal_info from "../assets/img/icons/personal-info.svg";

import pencil_icon from "../assets/img/icons/pencil.svg";
import star_icon from "../assets/img/icons/startButton.svg";
import co_buyer from "../assets/img/icons/co-byuer.svg";

import plus_white from "../assets/img/icons/Plus.svg";
import deal_info from "../assets/img/icons/deal_info.svg";

import car_icon from "../assets/img/icons/Car.svg";
import trade_vechile from "../assets/img/icons/trade-vechile.svg";
import staff from "../assets/img/icons/staff.svg";
import License_icon from "../assets/img/icons/License.svg";
import Insurance_icon from "../assets/img/icons/License.svg";
import check_green from "../assets/img/icons/check-green.svg";
import right_arrow_primary from "../assets/img/icons/Arrow.svg";
import form_1 from "../assets/img/icons/form-1.svg";
import form_2 from "../assets/img/icons/form-2.svg";
import upload_docs from "../assets/img/icons/upload_docs.svg";
import view_docs from "../assets/img/icons/view_docs.svg";
import lock_icon from "../assets/img/icons/Lock.svg";
import log_out_icon from "../assets/img/icons/log-out-btn.svg";
import user_icon_big from "../assets/img/icons/user-icon-big.svg";

import check_round_gray from "../assets/img/icons/check_circle_secondry.svg";
import check_round_primary from "../assets/img/icons/check_circle_primary.svg";
import plus_icon_primary_new from "../assets/img/icons/plus-icon-primary.svg";
import arrow_right_gray from "../assets/img/icons/arrow-right-gray.svg";
import sign_btn_img from "../assets/img/icons/sign-btn-img.svg";
import back_btn_black from "../assets/img/icons/Back.png";
import form from "../assets/img/icons/form.png";
import id from "../assets/img/icons/id-.png";
import regular_doc from "../assets/img/icons/regular_doc.png";

import info_gray_icon from "../assets/img/progress-bar/info-gray.svg";
import info_white_icon from "../assets/img/progress-bar/info-white.svg";
import id_gray_icon from "../assets/img/progress-bar/license-gray.svg";
import id_white_icon from "../assets/img/progress-bar/license-white.svg";
import forms_gray_icon from "../assets/img/progress-bar/widget-2-gray.svg";
import forms_white_icon from "../assets/img/progress-bar/widget-2-white.svg";
import docs_gray_icon from "../assets/img/progress-bar/regular-doc-gray.svg";
import docs_white_icon from "../assets/img/progress-bar/regular-doc-white.svg";
import trade_gray_icon from "../assets/img/progress-bar/trade-gray.svg";
import trade_white_icon from "../assets/img/progress-bar/trade-white.svg";
import purchase_gray_icon from "../assets/img/progress-bar/car-gray.svg";
import purchase_white_icon from "../assets/img/progress-bar/car-white.svg";
import deal_gray_icon from "../assets/img/progress-bar/money-gray.svg";
import deal_white_icon from "../assets/img/progress-bar/money-white.svg";
import co_byuer_gray_icon from "../assets/img/progress-bar/co-byuer-gray.svg";
import co_byuer_white_icon from "../assets/img/progress-bar/co-byuer-white.svg";

import dropdown_close_down from "../assets/img/icons/dropdown-closed-down.png";
import dropdown_open_up from "../assets/img/icons/dropdown-open-up.png";
import question_mark_icon_primary from "../assets/img/icons/question-mark-icon.svg";
import error_prompt_img from "../assets/img/icons/error-prompt-img.svg";
import check_icon_rounded from "../assets/img/icons/checked-rounded.svg";
import down_arrow_select from "../assets/img/icons/down-arrow-select.png";
import vehicle_for_trade from "../assets/img/icons/vechile-for-trade-small.png";
import arrow_left_primary from "../assets/img/icons/arrow-left-primary.png";
import welcome_logo from "../assets/img/logo-welcome.svg";
import tablet_img_black from "../assets/img/tablet-icon.svg";
import check_green_icon from "../assets/img/icons/check-green-icons.svg";
import cross_red from "../assets/img/icons/cross_red.svg";
import doc_privacy from "../assets/img/doc_privacy.svg";
import doc_contact from "../assets/img/doc_contact.svg";
import doc_hard from "../assets/img/doc_hard.svg";
import doc_id_info from "../assets/img/doc_id_info.svg";
import doc_identity_theft from "../assets/img/doc_identity_theft.svg";
import doc_pay_stub from "../assets/img/doc_pay_stub.svg";
import doc_preq from "../assets/img/doc_preq.svg";
import doc_trade_red from "../assets/img/doc_trade_reg.svg";
import doc_utilty_bill from "../assets/img/doc_utility_bill.svg";

import document_contact_acknowledgement from "../assets/img/icons/document_contact_acknowledgement.png";
import document_credit_forms from "../assets/img/icons/document_credit_forms.png";
import document_driver_license from "../assets/img/icons/document_driver_license.png";
import document_driver_test from "../assets/img/icons/document_driver_test.png";
import document_eunifi_forms from "../assets/img/icons/document_eunifi_forms.png";
import document_identity_theft_prevention from "../assets/img/icons/document_identity_theft_prevention.png";
import document_insurance_card from "../assets/img/icons/document_insurance_card.png";
import document_other from "../assets/img/icons/document_other.png";
import document_pay_stub from "../assets/img/icons/document_pay_stubs.png";
import document_privacy_notice from "../assets/img/icons/document_privacy_notice.png";
import document_purchase_acknowledgement from "../assets/img/icons/document_purchase_acknowledgement.png";
import document_upload from "../assets/img/icons/document_upload.png";
import document_utility_bills from "../assets/img/icons/document_utility_bills.png";
import document_view from "../assets/img/icons/document_view.png";


import message_box_primary_icon from "../assets/img/icons/message-box-primary.svg";
import doc_purchase_ack from "../assets/img/doc_purchase_ack.svg";
import not_active_yet_icon from "../assets/img/icons/not_active_yet_icon.svg";
import close_icon from "../assets/img/icons/Close.svg";
import unread_msg_box_icon from "../assets/img/icons/unread-msg-group.svg";
import cameraIcon from "../assets/img/icons/camera.svg";
import arrowCircle from "../assets/img/icons/circle-arrow-icon.svg";

import addressInformationIcon from "../assets/img/icons/address_information_icon.svg";
import closingFormsIcon from "../assets/img/icons/closing_forms_icon.svg";
import coBuyerIcon from "../assets/img/icons/co_buyer_icon.svg";
import creditFormsIcon from "../assets/img/icons/credit_forms_icon.svg";
import dealInformationIcon from "../assets/img/icons/deal_information_icon.svg";
import documentsIcon from "../assets/img/icons/documents_icon.svg";
import employmentIcon from "../assets/img/icons/employment_icon.svg";
import idIcon from "../assets/img/icons/id_icon.svg";
import personalInformationIcon from "../assets/img/icons/personal_information_icon.svg";
import preliminaryIcon from "../assets/img/icons/preliminary_icon.svg";
import staffIcon from "../assets/img/icons/staff_icon.svg";
import vehicleIcon from "../assets/img/icons/vehicle_icon.svg";
import eunifiLogo from '../assets/img/EunifiLogo.svg';

export let Image = {
  eunifiLogo,
  logo_main,
  message_box_primary_icon,
  dropdown_close_down,
  dropdown_open_up,
  question_mark_icon_primary,
  error_prompt_img,
  check_icon_rounded,
  vehicle_for_trade,
  arrow_left_primary,
  eye_icon,
  screen_shot_1,
  screen_shot_2,
  user_icon,
  logo_small_header,
  plus_icon_primary,
  down_arrow_gray,
  upload_file_icon,
  pay_stubs_img,
  document_icon_5,
  document_icon_1,
  link_success_icon,
  white_arrow_right,
  black_arrow_right,
  graph_img,
  personal_info,
  pencil_icon,
  co_buyer,
  plus_white,
  deal_info,
  car_icon,
  trade_vechile,
  staff,
  License_icon,
  Insurance_icon,
  check_green,
  cross_red,
  right_arrow_primary,
  form_1,
  form_2,
  upload_docs,
  view_docs,
  lock_icon,
  log_out_icon,
  user_icon_big,
  check_round_gray,
  check_round_primary,
  plus_icon_primary_new,
  arrow_right_gray,
  sign_btn_img,
  back_btn_black,
  form,
  id,
  regular_doc,
  info_gray_icon,
  info_white_icon,
  id_gray_icon,
  id_white_icon,
  forms_gray_icon,
  forms_white_icon,
  docs_gray_icon,
  docs_white_icon,
  trade_gray_icon,
  trade_white_icon,
  purchase_gray_icon,
  purchase_white_icon,
  deal_gray_icon,
  deal_white_icon,
  co_byuer_gray_icon,
  co_byuer_white_icon,
  down_arrow_select,
  welcome_logo,
  tablet_img_black,
  check_green_icon,
  doc_privacy,
  doc_contact,
  doc_hard,
  doc_id_info,
  doc_identity_theft,
  doc_pay_stub,
  doc_preq,
  doc_trade_red,
  doc_utilty_bill,
  document_contact_acknowledgement,
  document_credit_forms,
  document_driver_license,
  document_driver_test,
  document_eunifi_forms,
  document_identity_theft_prevention,
  document_insurance_card,
  document_other,
  document_pay_stub,
  document_privacy_notice,
  document_purchase_acknowledgement,
  document_upload,
  document_utility_bills,
  document_view,
  doc_purchase_ack,
  not_active_yet_icon,
  close_icon,
  unread_msg_box_icon,
  cameraIcon,
  arrowCircle,
  down_arrow,
  black_plus_icon,
  star_icon,
  addressInformationIcon,
  closingFormsIcon,
  coBuyerIcon,
  creditFormsIcon,
  dealInformationIcon,
  documentsIcon,
  employmentIcon,
  idIcon,
  personalInformationIcon,
  preliminaryIcon,
  staffIcon,
  vehicleIcon,
  VFILogo,
  VirtualLogo,
};
