import {
  UPDATE_PROMPT,
  RESET_PROMPT,
  CHANGE_SETUP,
} from "../actions/promptAction";

const initialState = {
  dealerInteracted: false,
  coBuyerInteracted: false,
  prequalInteracted: false,
  hardCreditInteracted: false,
  tradeInteracted: false,
  purchInteracted: false,
  docsInteracted: false,
  setupDone: false,
};

export const promptReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_PROMPT: {
      return { ...state, [payload]: true };
    }
    case RESET_PROMPT: {
      return { ...initialState };
    }
    case CHANGE_SETUP: {
      return { ...state, setupDone: payload };
    }
    default: {
      return state;
    }
  }
};
