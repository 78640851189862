import React, { useState, useEffect } from "react";
import { Button,  message } from "antd";
import { addLicense } from "../../controller/api/customerAPI";
import { connect } from "react-redux";
import WarningPopModal from "../modal/WarningPopModal";
import IdentityDocForm from "../Atoms/IdentityDocForm/IdentityDocForm";
import { DateTime } from "luxon";
import mime from "mime-types";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";

export const VerifyIdentity = ({
  next,
  prev,
  transaction,
  userIdInformation,
}) => {
  const formRef = React.createRef();

  const [selectedIssued, setSelectedIssued] = useState(null);
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [selectedDob, setSelectedDob] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { t, i18n } = useTranslation();


  const preFillForm = () => {
    const {
      license_front,
      license_back,
      expiry_date,
      dob,
      issued_date,
    } = userIdInformation;


    // if userIdInformation data have license img from scan fill form with scan data
    if (license_front && license_back) {
      setSelectedDob(dob);
      setSelectedExpiry(expiry_date);
      setSelectedIssued(issued_date);

      formRef.current.setFieldsValue({
        ...userIdInformation,
        expiry_date: moment(`${expiry_date?.month}/${expiry_date?.day}/${expiry_date?.year}`, "MM/DD/YYYY").isValid()
            ? moment(`${expiry_date?.month}/${expiry_date?.day}/${expiry_date?.year}`, "MM/DD/YYYY")
            : null,
        dob: moment(`${dob?.month}/${dob?.day}/${dob?.year}`, "MM/DD/YYYY").isValid()
            ? moment(`${dob?.month}/${dob?.day}/${dob?.year}`, "MM/DD/YYYY")
            : null,
        issued_date: moment(`${issued_date?.month}/${issued_date?.day}/${issued_date?.year}`, "MM/DD/YYYY").isValid()
            ? moment(`${issued_date?.month}/${issued_date?.day}/${issued_date?.year}`, "MM/DD/YYYY")
            : null,
      });
    } else {
      const { license } = transaction;

      const _dob = DateTime.fromISO(license.dob);
      const dob = {
        day: _dob.day,
        month: _dob.month,
        year: _dob.year,
      };
      const _expiry_date = DateTime.fromISO(license.dob);
      const expiry_date = {
        day: _expiry_date.day,
        month: _expiry_date.month,
        year: _expiry_date.year,
      };
      const _issue_date = DateTime.fromISO(license.dob);
      const issue_date = {
        day: _issue_date.day,
        month: _issue_date.month,
        year: _issue_date.year,
      };

      // doing-manipulations-in-height
      license.height = String(license.height);
      license.height = license.height.replace(".", "'");
      license.height = license.height.concat('"');

      const issued_date = license.issued_date ? issue_date : null;
      if (license.issued_date) {
        formRef.current.setFieldsValue({
          ...license,
          expiry_date: expiry_date,
          dob: dob,
          issued_date: issued_date,
        });
      } else {
        formRef.current.setFieldsValue({
          ...license,
          expiry_date: expiry_date,
          dob: dob,
        });
      }

      setSelectedDob(dob);
      setSelectedExpiry(expiry_date);
      setSelectedIssued(issued_date);
    }
  };

  const handleSubmit = () => {
    setBtnLoading(true);
    const values = formRef.current.getFieldsValue();
    // this when user want to skip form
    if (
      Object.keys(values).every(
        (item) =>
          values[item] === undefined ||
          values[item] === "" ||
          values[item] === null
      )
    ) {
      setBtnLoading(false);
      next();
    } else {
      // if both sides of ID also uploaded along with Info
      const { license_front, license_back, doc_verified } = userIdInformation;
      values.dob = `${values.dob.year}-${values.dob.month}-${values.dob.day}`;
      if (selectedExpiry)
        values.expiry_date = `${selectedExpiry.year}-${selectedExpiry.month}-${selectedExpiry.day}`;
      if (selectedIssued)
        values.issued_date = `${selectedIssued.year}-${selectedIssued.month}-${selectedIssued.day}`;
      formRef.current
        .validateFields()
        .then((values) => {
          let form_data = new FormData();
          license_front && form_data.append("license_front", license_front, `image1.${mime.extension(license_front.type)}`);
          license_back && form_data.append("license_back", license_back, `image2.${mime.extension(license_back.type)}`);
          // values.dob = `${values.dob.year}-${values.dob.month}-${values.dob.day}`;
          values.dob = `${selectedDob.year}-${selectedDob.month}-${selectedDob.day}`;
          values.expiry_date = `${selectedExpiry.year}-${selectedExpiry.month}-${selectedExpiry.day}`;
          if (selectedIssued) {
            values.issued_date = `${selectedIssued.year}-${selectedIssued.month}-${selectedIssued.day}`;
          }

          if (values.height) {
            // doing manipulations in height
            values.height = values.height.replace("'", ".");
            values.height = values.height.replace('"', "");
            values.height = Number(values.height);
          }

          for (let key in values) {
            values[key] && form_data.append(key, values[key]);
          }

          form_data.append("transaction", transaction.id);
          doc_verified && form_data.append("doc_verified", "TRUE");

          addLicense(form_data)
            .then(() => {
              setBtnLoading(false);
              next();
            })
            .catch((err) => {
              setBtnLoading(false);
              if (err.response) {
                Object.keys(err.response.data).forEach((e) => {
                  message.error(err.response.data[e]);
                });
              }
            })
            .finally(() => {
              setBtnLoading(false);
            });
        })
        .catch(() => {
          setBtnLoading(false);
        });
    }
  };

  useEffect(() => {
    preFillForm();
    setFirstLoad(true);
    setOpenPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad === false]);

  return (
    <div className="container-fluid">
      <div className="row login-main-row w-auto main-row-width mx-auto">
        <div className="col-12 add-new-customer-div p-0 shadow-none mt-0">
          <div className="row">
              <div className="col-12 transition-information-div mb-4">
                <div className="transition-information-heading w-100 font-weight-bold">
                  {t('transactionForm.verifyIdStep.title')}
                </div>
                <p className="font-weight-normal">
                  {t('transactionForm.verifyIdStep.subTitle')}
                </p>
                <>
                  <IdentityDocForm
                      formRef={formRef}
                      handleSubmit={handleSubmit}
                      selectedIssued={selectedIssued}
                      setSelectedIssued={setSelectedIssued}
                      selectedExpiry={selectedExpiry}
                      setSelectedExpiry={setSelectedExpiry}
                      selectedDob={selectedDob}
                      setSelectedDob={setSelectedDob}
                  />
                </>
              </div>
              <div className="steps-action flex-align-center justify-content-between w-100">
                <Button onClick={() => prev()}> {t('utils.back')}</Button>
                <Button
                  onClick={() => handleSubmit()}
                  loading={btnLoading}
                  type="primary"
                >
                  {t('utils.next')}
                </Button>
              </div>
          </div>
        </div>
      </div>
      <WarningPopModal
        title={""}
        subTitle={
          t('transactionForm.verifyIdStep.warningMessage.pleaseReviewYorInfo')
        }
        submitBtnTitle={"OK"}
        onOpen={openPopup}
        onSubmit={() => setOpenPopup(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(VerifyIdentity);
