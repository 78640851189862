import React, { Component } from "react";
import { connect } from "react-redux";
import {Button, message, Modal} from "antd";
import CoBuyerDashboard from "./CoBuyerDashboard";
import PersonalInformationDashboard from "./PersonalInformationDashboard";
import DealInformationDashboard from "./DealInformationDashboard";
import VehicleForPurchaseDashboard from "./VehicleForPurchaseDashboard";
import VehicleForTradeDashboard from "./VehicleForTradeDashboard";
import StaffDashboard from "./StaffDashboard";
import LicenceDashboard from "./LicenceDashboard";
import AddressInformationDashboard from "./AddressInformationDashboard";
import EmploymentInformationDashboard from "./EmploymentDashboard";
import DocumentDashboard from "./DocumentDashboard";
import FormsDashboard from "./FormsDashboard";
import CoBuyerInformation from "./CoBuyerInformation";
import { setCoBuyerViewAction } from "../store/actions/transactionAction";
import { setTutorial, showTutorial } from "../controller/localStorageHandler";
import { reverse } from "named-urls";
import { routes } from "../controller/routes";
import { checkCreationDate, methods } from "../controller/Global";
import PromptConfirm from "./modal/Prompt/PromptConfirm";
import { withRouter } from "react-router-dom";
import Graph from "./Graph";
import { changeSetup, updatePrompt } from "../store/actions/promptAction";
import { Image as Images } from "./Images";
import {
  DELIVERED,
  DISASSOCIATED,
  DISASSOCIATED_CO_BUYER,
  OUTSIDE_FINANCE_SOURCE,
  PAYING_CASH,
} from "../controller/enums";
import { cancelPromptAfterOneTime, getPersonalReferenceByCustomerId } from "../controller/api/customerAPI";
import PreRequisiteDashboard from "../components/PreRequisiteDashboard";
import PurchaseAcknowledgementDashboard from "../components/PurchaseAcknowledgementDashboard";
import InsuranceDashboard from "./InsuranceDashboard";
import {PersonalReferenceDashboard} from "./PersonalReferenceDashboard";
import { withTranslation } from "react-i18next";

function mapStateToProps(state) {
  return {
    viewCoBuyer: state.viewCoBuyer,
    current_transaction: state.current_transaction,
    prompts: state.prompt,
  };
}



class TransactionDetail extends Component {
  state = {
    active: null,
    pixel: 0,
    showOverlay: false,
    coBuyerInteracted: false,
    promptModal: false,
    heading: "",
    subHeading: "",
    okText: "",
    cancelText: null,
    bannerVisible: false,
    personalReference: null,
  };

  checkPromptVisibility = (is_co_buyer, status) => {
    if (is_co_buyer) {
      if (
        String(status) === DISASSOCIATED_CO_BUYER ||
        String(this.props.current_transaction.status) === DISASSOCIATED
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (String(this.props.current_transaction.status) === DISASSOCIATED) {
        return false;
      } else {
        return true;
      }
    }
  };
  onOk = () => {};
  onCancel = () => {};
  handleViewCoBuyer = (visible) => {
    this.props.setCoBuyerViewAction(visible);
  };
  setDynamicOk = (func) => {
    this.onOk = func;
  };

  setDynamicCancel = (func) => {
    this.onCancel = func;
  };

  getPersonalReference = async () => {
    const transaction = this.props.current_transaction;
    if (transaction.customer) {
      try {
        await getPersonalReferenceByCustomerId(transaction.customer).then((res) => {

          if (res.data.results) {
            console.log(res.data.results, ' get customer reference')
            const dataLength = res.data.results.length - 1;
            this.setState({ personalReference: res.data.results[dataLength]})
          }
        })
      } catch (error) {
        console.error('Error fetch personal reference data:', error);
        throw error;

      }
    }
  }

  async componentDidMount() {
    document.getElementsByTagName("body")[0].classList.add("bg-white-fi");
    await this.showPrompts();
    this.setState({ bannerVisible: true });
    await this.getPersonalReference();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.current_transaction !== prevProps.current_transaction) {
      await this.showPrompts();
    }
  }

  showPrompts = async () => {
    const transaction = this.props.current_transaction;
    const { is_co_buyer, status } = transaction;
    const promptVisible = this.checkPromptVisibility(is_co_buyer, status);
    const { t } = this.props;

    if (!transaction) {
      return;
    }
    if (!this.props?.login_prompt_status) {
      if (promptVisible) {
        const { prompts, updatePrompt } = this.props;
        if (!transaction.deal_info && !prompts.dealInteracted && !is_co_buyer) {
          let heading = t('dealInformation.title');
          let subHeading = (
            <h6>
              {t('dashboard.popModal.dealInfoContext')}
            </h6>
          );
          let okText = t('welcomePage.getStart');
          let func = async () => {
            await updatePrompt("dealInteracted");
            this.props.history.push(
              reverse(routes.dashboard.transaction.deal_info, {
                trans_id: transaction.id,
                method: methods.add,
              })
            );
          };
          await this.setDynamicOk(func);
          this.setState({ heading, subHeading, okText, promptModal: true });
        } else if (
          !transaction.co_buyer &&
          !prompts.coBuyerInteracted &&
          !is_co_buyer
        ) {
          let heading = t('dashboard.popModal.doYouHaveCoBuyer');
          let subHeading = (
            <h6>
              {t('dashboard.popModal.addCoBuyerPop')}
            </h6>
          );
          let okText = t('utils.yes');
          let cancelText = t('utils.no');
          let func = () => {
            updatePrompt("coBuyerInteracted");
            this.props.history.push(
              reverse(routes.dashboard.transaction.co_buyer, {
                trans_id: transaction.id,
                method: methods.add,
              })
            );
          };
          let cancelFunc = () => {
            updatePrompt("coBuyerInteracted");
            this.setState(
              {
                promptModal: false,
                heading: "",
                subHeading: "",
                okText: "",
                cancelText: "",
              },
              () => this.showPrompts()
            );
          };
          this.setDynamicOk(func);
          this.setDynamicCancel(cancelFunc);
          this.setState({
            heading,
            subHeading,
            okText,
            cancelText,
            promptModal: true,
          });
        } else if (
          transaction.dealership.pre_qual_activate &&
          (!transaction.pre_qualification ||
            !transaction.pre_qualification?.signature) &&
          !prompts.prequalInteracted &&
          !(
            transaction?.deal_info?.deal_type === PAYING_CASH ||
            transaction?.deal_info?.deal_type === OUTSIDE_FINANCE_SOURCE
          )
        ) {
          if (is_co_buyer && String(status) === DISASSOCIATED_CO_BUYER) {
            this.setState({ promptModal: false });
          } else {
            if (
              (!transaction.pre_qualification ||
                !transaction.pre_qualification?.signature) &&
              !prompts.prequalInteracted
            ) {
              let heading = t('preQualificationForm.title');
              let subHeading = (
                <h6>
                  {t('dashboard.popModal.preQualInfoContext')}
                </h6>
              );
              let okText = t('dashboard.popModal.start');
              let func = () => {
                updatePrompt("prequalInteracted");
                //eslint-disable-next-line
                {
                  transaction.pre_qualification
                    ? this.props.history.push(
                        reverse(
                          routes.dashboard.transaction.pre_qualification,
                          {
                            trans_id: transaction.id,
                            method: methods.edit,
                          }
                        )
                      )
                    : this.props.history.push(
                        reverse(
                          routes.dashboard.transaction.pre_qualification,
                          {
                            trans_id: transaction.id,
                            method: methods.add,
                          }
                        )
                      );
                }
              };
              this.setDynamicOk(func);
              this.setState({ heading, subHeading, okText, promptModal: true });
            }
          }
        } else if (
          (!transaction.hard_credit_info ||
            !transaction.hard_credit_info.signature) &&
          !prompts.hardCreditInteracted &&
          !(
            transaction?.deal_info?.deal_type === PAYING_CASH ||
            transaction?.deal_info?.deal_type === OUTSIDE_FINANCE_SOURCE
          )
        ) {
          if (is_co_buyer && String(status) === DISASSOCIATED_CO_BUYER) {
            this.setState({ promptModal: false });
          } else {
            let heading = t('hardCreditForm.title');
            let subHeading = (
              <h6>
                {t('dashboard.popModal.creditAppInfoContext')}
              </h6>
            );
            let okText = t('utils.start');
            let cancelText = t('utils.cancel');
            let func = () => {
              updatePrompt("hardCreditInteracted");
              //eslint-disable-next-line
              {
                transaction.hard_credit_info
                  ? this.props.history.push(
                      reverse(routes.dashboard.transaction.hard_credit, {
                        trans_id: transaction.id,
                        method: methods.edit,
                      })
                    )
                  : this.props.history.push(
                      reverse(routes.dashboard.transaction.hard_credit, {
                        trans_id: transaction.id,
                        method: methods.add,
                      })
                    );
              }
            };
            let cancelFunc = () => {
              updatePrompt("hardCreditInteracted");
              this.setState(
                {
                  promptModal: false,
                  heading: "",
                  subHeading: "",
                  okText: "",
                  cancelText: "",
                  saveTime: true,
                },
                () => this.showPrompts()
              );
            };
            this.setDynamicOk(func);
            this.setDynamicCancel(cancelFunc);
            this.setState({
              heading,
              subHeading,
              okText,
              cancelText,
              promptModal: true,
            });
          }
        } else if (
          !transaction.vehicle_for_trade &&
          !prompts.tradeInteracted
        ) {
          let heading = t('dashboard.popModal.will_you_trade_vehicle');
          let subHeading = (
            <h6>{t('dashboard.popModal.addVehicleSaveTime')}</h6>
          );
          let okText = t('utils.yes');
          let cancelText = t('utils.no');
          let func = () => {
            updatePrompt("tradeInteracted");
            this.props.history.push(
              reverse(routes.dashboard.transaction.vehicle_for_trade, {
                trans_id: transaction.id,
                method: methods.add,
              })
            );
          };
          let cancelFunc = () => {
            updatePrompt("tradeInteracted");
            this.setState(
              {
                promptModal: false,
                heading: "",
                subHeading: "",
                okText: "",
                cancelText: "",
              },
              () => this.showPrompts()
            );
          };
          this.setDynamicOk(func);
          this.setDynamicCancel(cancelFunc);
          this.setState({
            heading,
            subHeading,
            okText,
            cancelText,
            promptModal: true,
          });
        } else if (
          !transaction.vehicle_for_purchase &&
          !prompts.purchInteracted
        ) {
          let heading = t('dashboard.popModal.addVehicleForPurchase');
          let subHeading = (
            <h6 className="text-center">
              { t('dashboard.popModal.vehicleForPurchasePopContext')}
            </h6>
          );
          let okText = t('utils.add');
          let cancelText = t('utils.skip');
          let func = () => {
            updatePrompt("purchInteracted");
            this.props.history.push(
              reverse(routes.dashboard.transaction.vehicle_for_purchase, {
                trans_id: transaction.id,
                method: methods.add,
              })
            );
          };
          let cancelFunc = () => {
            updatePrompt("purchInteracted");
            this.setState(
              {
                promptModal: false,
                heading: "",
                subHeading: "",
                okText: "",
                cancelText: "",
              },
              () => this.showPrompts()
            );
          };
          this.setDynamicOk(func);
          this.setDynamicCancel(cancelFunc);
          this.setState({
            heading,
            subHeading,
            okText,
            cancelText,
            promptModal: true,
          });
        } else if (
          !prompts.docsInteracted
        ) {
          cancelPromptAfterOneTime({
            logging: true,
            customer: this.props?.user?.id,
          })
            .then(() => {})
            .catch(() => {});
          let heading = t('dashboard.popModal.dealerRequestedDoc');
          // eslint-disable-next-line
          let subHeading = <h6 />;
          let okText = t('utils.addNow');
          let cancelText = t('utils.no');
          let func = () => {
            updatePrompt("docsInteracted");
            this.props.history.push(
              reverse(routes.dashboard.transaction.upload_documents, {
                trans_id: transaction.id,
              })
            );
          };
          let cancelFunc = () => {
            updatePrompt("docsInteracted");
            this.setState(
              {
                promptModal: false,
                heading: "",
                subHeading: "",
                okText: "",
                cancelText: "",
              },
              () => this.showPrompts()
            );
          };
          this.setDynamicOk(func);
          this.setDynamicCancel(cancelFunc);
          this.setState({
            heading,
            subHeading,
            okText,
            cancelText,
            promptModal: true,
          });
        }
      }
    }
  };

  noScroll = () => {
    // window.scrollTo(0, this.state.pixel)
  };

  next = () => {
    let { active } = this.state;
    const transaction = this.props.current_transaction;
    const { is_co_buyer } = transaction;
    let coBuyerActive = {
      0: 1,
      1: 4,
    };
    let addPixel =
      active === 0
        ? 400
        : active === 1
        ? 150
        : active === 2
        ? 350
        : active === 3
        ? 750
        : 200;
    let coBuyerPixel = active === 0 ? 550 : active === 1 ? 1100 : 4000;

    this.setState(
      {
        active: is_co_buyer
          ? coBuyerActive[this.state.active]
          : this.state.active + 1,
        pixel: is_co_buyer
          ? this.state.pixel + coBuyerPixel
          : this.state.pixel + addPixel,
      },
      () => window.scrollTo(0, this.state.pixel)
    );
  };

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove("bg-white-fi");
  }


  restartTutorial = () => {
    window.addEventListener("scroll", this.noScroll);
    this.setState({ active: 0, showOverlay: true, pixel: 0 }, () => {
      window.scrollTo(0, this.state.pixel);
    });
  };
  checkDealTypeForCash = () => {
    const transaction = this.props?.current_transaction;
    const deal_info = transaction?.deal_info;
    if (deal_info) {
      if (
        deal_info.deal_type === PAYING_CASH ||
        deal_info.deal_type === OUTSIDE_FINANCE_SOURCE
      )
        return true;
      else return false;
    } else {
      return false;
    }
  };

  checkButtonsVisibility = (current_transaction) => {
    const isNintyDayOld = checkCreationDate(current_transaction);
    const { status, is_delivered, is_co_buyer } = current_transaction;
    if (is_co_buyer) {
      if (
        !isNintyDayOld ||
        String(status) === DISASSOCIATED_CO_BUYER ||
        String(status) === DISASSOCIATED ||
        String(status) === DELIVERED ||
        is_delivered === true
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        !isNintyDayOld ||
        String(status) === DISASSOCIATED ||
        String(status) === DELIVERED ||
        String(status) === DISASSOCIATED_CO_BUYER ||
        is_delivered === true
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  render() {
    const { viewCoBuyer, prompts, changeSetup , t } = this.props;
    const transaction = this.props.current_transaction;
    const { is_co_buyer, status } = transaction;
    const btnVisible = this.checkButtonsVisibility(transaction);


    if (viewCoBuyer) {
      return (
        <CoBuyerInformation
          unsetCoBuyerView={() => this.handleViewCoBuyer(false)}
          transaction={transaction}
        />
      );
    } else {
      return (
        <div className="row mx-0">
          <div onClick={this.restartTutorial} className="start-tutorial">
            ?
          </div>
          {/*{this.state.showOverlay && this.showTutorial()}*/}
          <div className="col-12 col-sm-12 col-lg-12 col-md-12 right-inner-main mt-0">
            <div className="r ow mx-0">
              <div className="col-12">
                <div
                    className={`row heading-fix-div ${
                        this.state.fixed && "nav-fix-div"
                    }`}
                >
                  <div className="col-12 transition-info-div position-relative">
                    <span className="d-inline-block font-weight-bold w-100 text-center">
                      {`${transaction?.personal_information?.first_name} ${
                          transaction?.personal_information?.middle_name || ""
                      } ${transaction?.personal_information?.last_name}`}
                      {is_co_buyer
                          ? String(status) === DISASSOCIATED_CO_BUYER ||
                          String(this.props.current_transaction.status) ===
                          DISASSOCIATED
                              ? "(Disassociated Co-buyer)"
                              : ""
                          : String(this.props.current_transaction.status) ===
                          DISASSOCIATED
                              ? "(Disassociated Buyer)"
                              : ""}
                    </span>
                  </div>
                </div>
              </div>
              {/* trying top banner */}
              {this.state?.bannerVisible ? (
                  <div className="col-12 banner-top-col">
                    <div className="banner-header-top">
                      <div className="green-strip-div"></div>
                      <div className="banner-main-content">
                      <span className="banner-main-content-span">
                        {t('utils.prefillContent')}
                      </span>
                        <button
                            className="banner-main-content-btn"
                            onClick={() => {
                              this.setState({bannerVisible: false});
                            }}
                        >
                          <img src={Images.close_icon} alt="close-icon"/>
                        </button>
                      </div>
                    </div>
                  </div>
              ) : (
                  ""
              )}
              <div className="col-12">
                <div className="row mx-0 graph-div-main">
                  <Graph transaction={transaction} active={this.state.active}/>
                  {this.state.active === 0 && (
                      <div className="overflow-fix-btn">
                        <div className={"toast-message"}>
                          {t('dashboard.popModal.trackYorProgress')}
                        </div>
                        <Button className="next-button" onClick={this.next}>
                          {t('utils.next')}
                        </Button>
                      </div>
                  )}
                </div>
              </div>
              {is_co_buyer && (
                  <div className="col-12">
                    <PersonalInformationDashboard
                        transaction={transaction.id}
                        is_co_buyer={is_co_buyer}
                        buyer_info={true}
                        current_transaction={transaction}
                        personal_info={transaction.buyer_personal_info}
                        btnVisible={btnVisible}
                    />
                  </div>
              )}
              <div
                  className={`col-12 ${
                      this.state.active === 1 && "visible-overlay"
                  }`}
              >
                <PersonalInformationDashboard
                    transaction={transaction.id}
                    is_co_buyer={is_co_buyer}
                    current_transaction={transaction}
                    co_buyer_status={status}
                    personal_info={transaction.personal_information}
                    btnVisible={btnVisible}
                />
              </div>
              <div
                  className={`col-12 ${
                      this.state.active === 1 && "visible-overlay"
                  }`}
              >
                <AddressInformationDashboard
                    transaction={transaction.id}
                    current_transaction={transaction}
                    is_co_buyer={is_co_buyer}
                    co_buyer_status={status}
                    address_info={transaction.address_information}
                    btnVisible={btnVisible}
                />
              </div>

              <div
                  className={`col-12 ${
                      this.state.active === 1 && "visible-overlay"
                  }`}
              >
                <EmploymentInformationDashboard
                    transaction={transaction.id}
                    current_transaction={transaction}
                    is_co_buyer={is_co_buyer}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>

              {this.state.active === 1 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.actionPersonalInfo')}
                    </div>
                    <Button className="next-button" onClick={this.next}>
                      {t('utils.next')}
                    </Button>
                  </div>
              )}

              {!is_co_buyer && (
                  <div
                      className={`col-12 ${
                          this.state.active === 2 && "visible-overlay"
                      }`}
                  >
                    <CoBuyerDashboard
                        current_transaction={transaction}
                        handleViewCoBuyer={() => this.handleViewCoBuyer(true)}
                        transaction={transaction.id}
                        co_buyer={transaction.co_buyer}
                        btnVisible={btnVisible}
                    />
                  </div>
              )}
              {!is_co_buyer && this.state.active === 2 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.addCoBuyer')}
                    </div>
                    <Button className="next-button" onClick={this.next}>
                      {t('utils.next')}
                    </Button>
                  </div>
              )}
              <div
                  className={`col-12 ${
                      this.state.active === 3 && "visible-overlay"
                  }`}
              >
                <DealInformationDashboard
                    transaction={transaction.id}
                    current_transaction={transaction}
                    is_co_buyer={is_co_buyer}
                    deal_info={transaction.deal_info}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>
              {this.state.active === 3 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.addYourDeal')}
                    </div>
                  </div>
              )}

              <div
                  className={`col-12 ${
                      this.state.active === 3 && "visible-overlay"
                  }`}
              >
                <VehicleForPurchaseDashboard
                    current_transaction={transaction}
                    transaction={transaction.id}
                    is_co_buyer={is_co_buyer}
                    vehicle_for_purchase={transaction.vehicle_for_purchase}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>
              {this.state.active === 3 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.addVehicleForPurchase')}
                    </div>
                  </div>
              )}

              <div
                  className={`col-12 ${
                      this.state.active === 3 && "visible-overlay"
                  }`}
              >
                <VehicleForTradeDashboard
                    current_transaction={transaction}
                    transaction={transaction.id}
                    is_co_buyer={is_co_buyer}
                    vehicle_for_trade={transaction.vehicle_for_trade}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>
              {this.state.active === 3 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.addVehicleForTrade')}
                    </div>
                    <Button className="next-button" onClick={this.next}>
                      {t('utils.next')}
                    </Button>
                  </div>
              )}

              <div className="col-12">
                <StaffDashboard
                    transaction={transaction.id}
                    current_transaction={transaction}
                    salesperson={transaction.salesperson}
                    sales_manager={transaction.sales_manager}
                    co_buyer_status={status}
                    fi_manager={transaction.fi_manager}
                />
              </div>
              <div className={'col-12'}>
                <PersonalReferenceDashboard
                    transaction={transaction.id}
                    btnVisible={btnVisible}
                    personalReference={this.state.personalReference}
                />
              </div>

              <div
                  className={`col-12 ${
                      this.state.active === 4 && "visible-overlay"
                  }`}
              >
                <LicenceDashboard
                    current_transaction={transaction}
                    transaction={transaction.id}
                    is_co_buyer={is_co_buyer}
                    license={transaction.license}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>

              <div
                  className={`col-12 ${
                      this.state.active === 4 && "visible-overlay"
                  }`}
              >
                <InsuranceDashboard
                    current_transaction={transaction}
                    transaction={transaction.id}
                    is_co_buyer={is_co_buyer}
                    insurance={transaction.insurance_object}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>

              {this.state.active === 4 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.addId')}
                    </div>
                  </div>
              )}

              <div
                  className={`col-12 ${
                      this.state.active === 4 && "visible-overlay"
                  }`}
              >
                <PreRequisiteDashboard
                    current_transaction={transaction}
                    is_co_buyer={is_co_buyer}
                    transaction={transaction.id}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>
              {this.state.active === 4 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.trackYourPrerequisite')}
                    </div>
                  </div>
              )}
              <div
                  className={`col-12 ${
                      this.state.active === 4 && "visible-overlay"
                  }`}
              >
                <FormsDashboard
                    current_transaction={transaction}
                    is_co_buyer={is_co_buyer}
                    transaction={transaction.id}
                    co_buyer_status={status}
                    checkDealTypeForCash={this.checkDealTypeForCash}
                    btnVisible={btnVisible}
                />
              </div>
              {this.state.active === 4 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.fillCreditForm')}
                    </div>
                  </div>
              )}
              {!is_co_buyer ? (
                  <div
                      className={`col-12 ${
                          this.state.active === 4 && "visible-overlay"
                      }`}
                  >
                    {!is_co_buyer ? (
                        <PurchaseAcknowledgementDashboard
                            current_transaction={transaction}
                            is_co_buyer={is_co_buyer}
                            transaction={transaction.id}
                            co_buyer_status={status}
                            btnVisible={btnVisible}
                        />
                    ) : (
                        ""
                    )}
                  </div>
              ) : (
                  ""
              )}
              {!is_co_buyer && this.state.active === 4 && (
                  <div className="overflow-fix-btn">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.trackClosingForm')}
                    </div>
                  </div>
              )}

              <div
                  className={`col-12 ${
                      this.state.active === 4 && "visible-overlay"
                  }`}
              >
                <DocumentDashboard
                    current_transaction={transaction}
                    is_co_buyer={is_co_buyer}
                    transaction={transaction.id}
                    co_buyer_status={status}
                    btnVisible={btnVisible}
                />
              </div>
              {this.state.active === 4 && (
                  <div className="overflow-fix-btn space-extra">
                    <div className={"toast-message"}>
                      {t('dashboard.popModal.uploadDocument')}
                    </div>
                    <Button
                        className={"next-button mt-3"}
                        onClick={() => {
                          setTutorial(false);
                          this.setState({showOverlay: false, active: null});
                          window.removeEventListener("scroll", this.noScroll);
                        }}
                    >
                      {t('utils.close')}
                    </Button>
                  </div>
              )}
            </div>
          </div>
          <PromptConfirm
              onCancel={this.onCancel}
              onOk={this.onOk}
              {...this.state}
              user={this.props.user}
              visible={this.state.showOverlay ? false : this.state.promptModal}
          />
          <Modal
              className="prompt-modal close-btn-none"
              footer={false}
              centered
              open={this.state.saveTime}
              // onCancel={""}
              title={""}
              onOk={""}
              width={"488px"}
          >
            <div className="row prompt-modal-row">
              <div className="col-12 text-center">
                <img
                  alt="icon question mark"
                  className="img-fluid"
                  src={Images.error_prompt_img}
                />
                <h4 style={{ color: "#EB5757" }}>{t('dashboard.popModal.saveTime')}</h4>
                <p>
                  {t('dashboard.popModal.completedCreditApp')}
                </p>
                <div className="row mx-0 align-items-center justify-content-between">
                  <Button onClick={() => this.setState({ saveTime: false })}>
                    {t('utils.close')}
                  </Button>
                  <Button
                    onClick={() =>
                      this.props.history.push(
                        reverse(routes.dashboard.transaction.hard_credit, {
                          trans_id: transaction.id,
                          method: methods.add,
                        })
                      )
                    }
                    type="primary"
                  >
                    {t('dashboard.popModal.start')}
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

const mapActionToProps = {
  setCoBuyerViewAction,
  updatePrompt,
  changeSetup,
};

export default withTranslation()(connect(
    mapStateToProps,
    mapActionToProps
)(withRouter(TransactionDetail)));
