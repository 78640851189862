import React, { Component, useRef, useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd";
import {
  addEmploymentInfo, getEmployments,
  getHardCredit,
  getZipcode,
  removeEmploymentInfo,
  updateEmploymentInfo,
} from "../../controller/api/customerAPI";
import { states } from "../../controller/states";
import { Image as Images } from "../Images";
import {
  methods,
  formatMoney,
  formatPhone,
  onNumberBlurred,
  onNumberFocused,
  onSelectBlurred,
  onSelectFocused, phoneValidationPattern,
} from "../../controller/Global";
import toWords from "../../utils/ToWords";
import { connect } from "react-redux";
import DollarInputField from "../DollarInputField";
import {useTranslation} from "react-i18next";
import i18next from 'i18next';

const { Option } = Select;

const address_title = [
  i18next.t('hardCreditForm.hcFormStepThree.addressTitle.current'),
  i18next.t('hardCreditForm.hcFormStepThree.addressTitle.previous'),
  i18next.t('hardCreditForm.hcFormStepThree.addressTitle.secondPrevious'),
];

export const EmploymentInformation = (props) => {

  const formRef = useRef(null);
  const [employments , setEmployments] = useState([undefined, undefined, undefined]);
  const [editEmployment, setEditEmployment] = useState(null);
  const [formFilled, setFormFilled] = useState(false);
  const [employmentErr, setEmploymentErr] = useState(false);
  const [matchZipCode, setMatchZipCode] = useState(null);
  const [employmentFine, setEmploymentFine] = useState(false);
  const [checkDuration, setCheckDuration] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disabledField, setDisabledField] = useState(false);
  const [hardCreditInfo, setHardCreditInfo] = useState(null);
  const [btnLoading, setbtnLoading] = useState(false);
  const [isEditEmployment, setIsEditEmployment] = useState(false);
  const [employmentStatus, setEmploymentStatus] = useState(null);
  const [methodOfPayment, setMethodOfPayment] = useState(null);
  const [fieldRequirement, setFieldRequirement] = useState(true);
  const [notRestrictionStatus, setNotRestrictionStatus] = useState([]);
  const { t, i18n } = useTranslation();

  const employmentStatusOptions = [
    { label: `${t('hardCreditForm.hcFormStepThree.options.unemployed')}`, value: "unemployed"},
    { label: `${t('hardCreditForm.hcFormStepThree.options.employed')}`, value: "employed"},
    { label: `${t('hardCreditForm.hcFormStepThree.options.self_employed')}`, value: "self_employed"},
    { label: `${t('hardCreditForm.hcFormStepThree.options.retired')}`, value: "retired"},
    { label: `${t('hardCreditForm.hcFormStepThree.options.student')}`, value: "student/apprentice"},
  ];



  const handleChange = (field, value) => {
    formRef.current.setFieldsValue({
      [field]: value,
    });

    if (field === "employment_status") {
      setEmploymentStatus(value)
      if (notRestrictionStatus.find((i) => i === value)) {
        setFieldRequirement(false);
            formRef.current.setFieldsValue({
              address: null,
              city: null,
              employer_name: null,
              occupation: null,
              phone: null,
              state: null,
              zip_code: null,
              education_level: null,
              duration_years: null,
              duration_months: null,
              type: null,
              other_income_source: null,
              other_income: null,
              typeOther: null,
              suite: null,
                method_of_payment: null,
            });
      } else {
        setFieldRequirement(true)
      }

    }
  };

  const updateEmployments = async () => {
    let _employments = []
    for (const employment of employments) {
      if (!employment) {
        continue;
      }

      delete employment["id"];
      const res = await addEmploymentInfo(employment)
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}: ${err.response.data[e]}`);
            });
          }
        })
        .finally(() => {
          buttonLoading(false);
        });

      _employments.push(res.data)
    }
    _employments.forEach(
        (employment, index) => {
            employments[index] = employment;
        }
    );
    setEmployments(employments)
  }

  const handFillForm = async () => {
    const { hard_credit, current_transaction } = props;

    if (current_transaction?.dealership?.hard_credit_employment_requirement_exclusion) {
      setNotRestrictionStatus(current_transaction?.dealership?.hard_credit_employment_requirement_exclusion);
    }
    if (
        props.match.params.method === methods.edit
    ) {
      const [hard_credit_info, employments_response] = await Promise.all([getHardCredit(hard_credit.id), getEmployments()])
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(`${e}: ${err.response.data[e]}`);
              });
            }
          })
          .finally(() => {
          });
      setHardCreditInfo(hard_credit_info.data);
      const employmentsData = employments_response.data.results;


      if (employmentsData?.length > 0) {
        employmentsData.forEach((employment) => {
          employments[employment.position] = employment;
        })

        setEmployments(employments);
        handleEdit(employments);
      }
    } else {
      const employments_response = await getEmployments()
      const employmentsData = employments_response.data.results;


      if (employmentsData?.length > 0 ) {
        employmentsData.forEach((employment) => {
          employments[employment.position] = employment;
        })

        setEmployments(employments);
      }
      if (employments[0] === undefined) {
        const employment_current_ref_info = current_transaction?.hard_credit_info?.employment_info
        if ( employment_current_ref_info && employment_current_ref_info.length > 0 ) {
          checkFormFill();

          employment_current_ref_info.forEach((employment) => {
                employments[employment.position] = employment;
                employments[employment.position]['hard_credit'] = props.hard_credit?.id;
              }
          );
          setEmployments(employments);
          await updateEmployments();
        }
      }
    }
    if (
        employments[0] !== undefined &&
        employments[1] !== undefined &&
        employments[2] !== undefined
    ) {
      setDisabledField(true);
    } else setDisabledField(false);
  }


  const updateEmploymentPosition = () => {
    if (editEmployment) {
      return employments.findIndex((o) => o && o.id === editEmployment);
    }
    return -1;
  };

  const onFormChange = (allFields) => {
    const currentPosition = employmentPosition();

    if (!fieldRequirement && Boolean(currentPosition === 0 || updateEmploymentPosition() === 0 ) ) {
      let checkFields = allFields.filter((i) => i.name[0] === "employment_status"
        || i.name[0] === "monthly_salary"
        || i.name[0] === "type")

      if (checkFields.find((i) => !i.value)) {
        setFormFilled(false);
      } else {
        setFormFilled(true);
      }
    } else {
      let newFields = allFields.filter(
        (item) =>
          item.name[0] !== "other_income" &&
          item.name[0] !== "other_income_source" &&
          item.name[0] !== "other_income_type" &&
          item.name[0] !== "duration_months" &&
          item.name[0] !== "duration_years" &&
          item.name[0] !== "suite"
      );
      if (errorMessage) {
        setFormFilled(false);
      } else {
        if (newFields.every((item) => !!item.value || item.value === 0))
          setFormFilled(true);
        else  setFormFilled(false);
      }
    }
  };

  const handleEdit = (data) => {
    setDisabledField(false);
    setIsEditEmployment(true);
    if (data.monthly_salary) {
      data.monthly_salary = data?.monthly_salary?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
    }
    if (data.other_income) {
      data.other_income = data?.other_income?.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
    }

    if (notRestrictionStatus.find((i) => i === data?.employment_status)) {
      setFieldRequirement(false);
    }

    formRef.current.setFieldsValue({ ...data });
    setEditEmployment(data.id);
    setFormFilled(true);
  };

  const employmentPosition = () => {
    return employments.findIndex((o) => o === undefined);
  };


  const buttonLoading = (value) => {
    setbtnLoading(value);
  };

  const onAddEmploymentInfo = (values) => {
    const position = employmentPosition();
    addEmploymentInfo(values)
      .then((resp) => {
        employments[position] = resp.data;
        setEmployments(employments);
        setEditEmployment(null);
      })
      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).forEach((e) => {
            message.error(`${e}: ${err.response.data[e]}`);
          });
        }
      })
      .finally(() => {
        buttonLoading(false);
      });
  };


  const onUpdateEmploymentInfo = (editEmployment, values) => {
    updateEmploymentInfo(editEmployment, values)
      .then((resp) => {
        const index = employments.findIndex(
            (obj) => obj && obj.id === resp.data.id
        );
        employments[index] = resp.data;
        setEmployments(employments);
        setEditEmployment(null);
      })
      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).forEach((e) => {
            message.error(`${e}: ${err.response.data[e]}`);
          });
        }
      })
      .finally(() => {
        buttonLoading(false);
      });
  };

  const handleSubmit = (values) => {

    buttonLoading(true);
    const position = employmentPosition();
    if (position !== -1) {
      values["position"] = position;
    }
    if (values.monthly_salary) {
      values.monthly_salary = values?.monthly_salary?.replace(/,/g, "");
    }
    if (values.other_income) {
      values.other_income = values?.other_income?.replace(/,/g, "");
    }
    if (
      values["duration_years"] ||
      values["duration_months"] ||
      values["duration_years"] === 0 ||
      values["duration_months"] === 0
    ) {
      values["duration_months"] = values["duration_months"] || 0;
      values["duration_years"] = values["duration_years"] || 0;
      if (props.hard_credit && props.hard_credit.id) {
        values["hard_credit"] = props.hard_credit.id;
      }
      if (props.is_edit || isEditEmployment) {
        if (editEmployment) {
          values["position"] = updateEmploymentPosition();
          onUpdateEmploymentInfo(editEmployment, values)
          if (
            employments[0] !== undefined &&
            employments[1] !== undefined &&
            employments[2] !== undefined
          ) {
            setDisabledField(true);
          } else setDisabledField(false);
        } else {
          if (position === -1) {
            message.error("Cannot add more than 3 addresses");
            buttonLoading(false);
          }

          values["is_current_employment"] = !position;
          onAddEmploymentInfo(values)
          const data = employments.filter((item, i) => i !== position);
          if (data[0] !== undefined && data[1] !== undefined) {
            setDisabledField(true)
          }
        }
      } else {
        if (position === -1) {
          message.error("Cannot add more than 3 addresses");
          buttonLoading(false);
        }

        values["is_current_employment"] = !position;
        onAddEmploymentInfo(values)

      }
      checkYearDuration(employments, values);
      formRef.current.resetFields();
      setEmploymentStatus(null)
    } else {
      if (notRestrictionStatus.find((i) => i === values.employment_status)) {
        if (editEmployment) {
          values["position"] = updateEmploymentPosition();
          values["duration_months"] = values["duration_months"] || 0;
          values["duration_years"] = values["duration_years"] || 0;

          onUpdateEmploymentInfo(editEmployment, values)
        } else {
          // add the employment info in case not present
          if (position === -1) {
            message.error("Cannot add more than 3 addresses");
            buttonLoading(false);
          }
          values["is_current_employment"] = true;
          values["duration_months"] = values["duration_months"] || 0;
          values["duration_years"] = values["duration_years"] || 0;

          values["hard_credit"] = props.hard_credit.id;
          values["employer_name"] = "";
          values["occupation"] = "";
          values["phone"] = "";

          if (position) values["is_current_employment"] = false;
          onAddEmploymentInfo(values)
        }
      } else {
        buttonLoading(false);
        setCheckDuration(true);
      }
      setEmploymentStatus(null);
    }
  };

  const checkYearDuration = (employments, values) => {
    const data = employments.filter((item, i) => i !== values.position);
    let totalYear = 0;
    let totalMonth = 0;
    data.forEach((item) => {
      if (item) {
        totalYear += item.duration_years;
        totalMonth += item.duration_months;
      }
    });
    totalYear += values.duration_years;
    totalMonth += values.duration_months;
    let totalTime = totalYear + totalMonth / 12;
  };

  const handleRemoveCard = (id, index) => {
    setDisabledField(false);
    removeEmploymentInfo(id).then((res) => {
      employments[index] = undefined;
      setEmployments(employments)
    });
  };


  const next = (total) => {
    const employment_requirement = props.current_transaction.dealership.hard_credit_employment_requirement;

    if (notRestrictionStatus.find((i) => i === employments[0]?.employment_status)) {
      props.next();
    } else {
      if (total < employment_requirement) {
        setEmploymentErr(true);
      } else {
        props.next();
      }

    }

  };

  const phoneNumberValidation = (rule, value, callback) => {
    if (value.toString().length < 10) {
      callback("Minimum digit should be 10");
    } else {
      callback();
    }
  };

  const onChangeZip = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode = response.data;
        setMatchZipCode(matchZipCode);
        if (matchZipCode) {
          formRef.current.setFieldsValue({
            city: matchZipCode.city,
            state: matchZipCode.state,
          });
          checkFormFill();
        }
      });
    }
  };

  const checkFormFill = () => {
    const formData = formRef.current.getFieldsValue();

    if (employments[0] === undefined) {
      if (!fieldRequirement && formData.monthly_salary) {
        setFormFilled(true);
      } else if (
          formData.address &&
          formData.employment_status &&
          formData.city &&
          formData.employer_name &&
          formData.occupation &&
          formData.phone &&
          formData.state &&
          formData.zip_code &&
          formData.monthly_salary &&
          formData.type
      ) {
        setFormFilled(true);
      } else {
        setFormFilled(false);
      }

    } else if (
        formData.address &&
        formData.city &&
        formData.employer_name &&
        formData.occupation &&
        formData.phone &&
        formData.state &&
        formData.zip_code
    ) {
      setFormFilled(true);
    } else {
      setFormFilled(false);
    }
  };

  const onBlurZip = (e) => {
    let value = e.target.value;
    if (matchZipCode && value.length === 3) {
      formRef.current.setFieldsValue({
        zip_code: `00${value}`,
      });
    } else if (matchZipCode && value.length === 4) {
      formRef.current.setFieldsValue({
        zip_code: `0${value}`,
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const handleDollarField = (value) => {
    if (value[0] === "$") {
      value = value?.replace("$", "");
      this.formRef.current.setFieldsValue({
        monthly_payment: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    }
    if (value === "0.0") {
      value = value + "0";
      if (value.toString().length > 5) {
        setErrorMessage(null);
      } else {
        setErrorMessage("enter a minimum of 5 digits amount" )
      }
      formRef.current.setFieldsValue({
        monthly_salary: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else {
      value = value?.toString();
      value = value?.replace(/\./g, "");
      value = value?.replace(/,/g, "");
      if (value[0] === "0" && value[1] === "0") {
        value = value.substring(2);
      }

      if (value) {
        if (value.length === 1) {
          value = "0.0" + value;
          if (value.toString().length > 5) {
            setErrorMessage(null);
          } else {
            setErrorMessage("enter a minimum of 5 digits amount");
          }
          formRef.current.setFieldsValue({
            monthly_salary: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else if (value.length === 2) {
          value = "0." + value;
          if (value.toString().length > 5) {
            setErrorMessage(null);
          } else {
            setErrorMessage("enter a minimum of 5 digits amount");
          }
          formRef.current.setFieldsValue({
            monthly_salary: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else {
          value = value.slice(0, -2) + "." + value.substr(-2);
          if (value[0] === "0") {
            value = value.substring(1);
          }
          if (value.toString().length > 5) {
            setErrorMessage(null);
          } else {
            setErrorMessage("enter a minimum of 5 digits amount");
          }
          formRef.current.setFieldsValue({
            monthly_salary: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        }
      }
    }
  };

  const enterOnlyNumber = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
    }
  };


  const EmploymentCard = ({ employment, index }) => {
    return (
        <div key={`employment_card_1`} className="col-12">
          <div className="row address-add-row add-address-row-second position-relative">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-9 col-12 col-md-9 col-lg-9">
                  <div className="row add-address-inner-row">
                    {employment.employment_status !== 'unemployed' &&
                        <div className="col-lg-12 col-sm-12 col-12 col-12 mb-2">
                          <h6>{t('hardCreditForm.hcFormStepThree.employerNameLabel')}:</h6>
                          <p className="mb-0 text-capitalize">
                            {employment.employer_name ? employment.employer_name : "-"}
                          </p>
                        </div>
                    }
                    {employment.employment_status !== 'unemployed' &&
                        <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                          <h6>{t('hardCreditForm.hcFormStepThree.occupationLabel')}:</h6>
                          <p className="mb-0 text-capitalize">
                            {employment.occupation ? employment.occupation : "-"}
                          </p>
                        </div>
                    }
                    {employment.employment_status !== 'unemployed' &&
                        <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                          <h6>{t('hardCreditForm.hcFormStepThree.phoneNumber')}:</h6>
                          <p className="mb-0">
                            {employment.phone ? formatPhone(employment.phone) : "-"}
                          </p>
                        </div>
                    }

                    {employment.employment_status !== 'unemployed' &&
                        <div className="col-lg-12 col-sm-12 col-md-12 col-12 mb-2">
                          <div className="row">
                            <div className="col-12">
                              <h6>{t('hardCreditForm.hcFormStepThree.employerAddressLabel')}:</h6>
                              <p className="mb-0 inp-form-wrap">

                                {employment.address && employment.city && employment.zip_code ?
                                    `${employment.address}, ${employment.suite ? `${employment.suite},` : ""} 
                                            ${employment.city}, ${employment.zip_code}`
                                    :
                                    "-"
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                    }


                    {employment.employment_status === 'unemployed' &&
                        <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                          <h6>{t('hardCreditForm.hcFormStepThree.howDoYouGetPaidLabel')} :</h6>
                          <p className="mb-0">
                            {employment.method_of_payment ? employment.method_of_payment : "-"}
                          </p>
                        </div>
                    }

                    {employment.employment_status === 'unemployed' &&
                        <div className="col-lg-6 col-sm-6 col-md-6 col-12 mb-2">
                          <div className="row">
                            <div className="col-12">
                              <h6>{t('hardCreditForm.hcFormStepThree.employmentStatusLabel')}:</h6>
                              <p className="mb-0 text-capitalize">
                                {employment.employment_status ? (
                                    employment.employment_status
                                        .split("_")
                                        .join(" ")
                                ) : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                    }

                    {index === 0 ? (
                        <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2"/>
                    ) : (
                        <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                          <h6>{t('hardCreditForm.hcFormStepThree.employmentDuration')}:</h6>
                          <p className="mb-0">{`${employment.duration_years || "-"} Yrs ${employment.duration_months || "-"} Mos`}</p>
                        </div>
                    )}


                    <div className="col-lg-4 col-sm-4 col-4 col-12 mb-2"/>
                    {index === 0 && (
                        <>
                          {employment.employment_status !== 'unemployed' &&
                              <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                                <h6>{t('hardCreditForm.hcFormStepThree.employmentStatusLabel')}:</h6>
                                <p className="mb-0 text-capitalize">
                                  {employment.employment_status ? (
                                      employment.employment_status
                                          .split("_")
                                          .join(" ")
                                  ) : "-"}
                                </p>
                              </div>
                          }

                          {employment.employment_status !== 'unemployed' &&
                              <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                                <h6>{t('hardCreditForm.hcFormStepThree.employmentDuration')}:</h6>
                                <p className="mb-0">{`${employment.duration_years ? employment.duration_years : "-"} Yrs 
                                    ${employment.duration_months ? employment.duration_months : "-"} Mos`}</p>
                              </div>
                          }


                          {employment.employment_status !== 'unemployed' &&
                              <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                                <h6>{t('hardCreditForm.hcFormStepThree.salary')}:</h6>
                                <p className="mb-0">
                                  {employment.monthly_salary ? formatMoney(employment.monthly_salary) : "-"}
                                </p>
                              </div>
                          }

                          {employment.employment_status !== 'unemployed' &&
                              <div className="col-lg-4 col-sm-4 col-4 col-12 mb-2">
                                <h6>{t('hardCreditForm.hcFormStepThree.type')}:</h6>
                                <p className="mb-0 text-capitalize">
                                  {employment.type ? (
                                      employment.type === "bi_weekly" ? "Bi-Weekly"
                                          : employment.type.split("_").join(" ")
                                  ) : "-"}
                                </p>
                              </div>
                          }

                          <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                            <h6>{t('hardCreditForm.hcFormStepThree.otherIncomeSourceLabel')}: </h6>
                            <p className="mb-0 text-capitalize">
                              {employment.other_income_source
                                  ? employment.other_income_source
                                  : "-"}
                            </p>
                          </div>

                          <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                            <h6>{t('hardCreditForm.hcFormStepThree.otherIncome')}:</h6>
                            <p className="mb-0">
                              {formatMoney(employment.other_income)
                                  ? formatMoney(employment.other_income)
                                  : "-"}
                            </p>
                          </div>
                          <div className="col-lg-6 col-sm-6 col-6 col-12 mb-2">
                            <h6>{t('hardCreditForm.hcFormStepThree.otherIncomeType')}:</h6>
                            <p className="mb-0 text-capitalize">
                              {employment.other_income_type
                                  ? employment.other_income_type
                                  : "-"}
                            </p>
                          </div>
                        </>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-dm-3 col-sm-3 col-12 edit-delete-div">
                  <Button
                      onClick={() => {
                        handleRemoveCard(employment.id, index)
                        scrollToTop();
                      }}
                      className="edit-delete-btn delete-btn"
                  >
                    {t('utils.remove')}
                  </Button>

                  <Button
                      className="mt-n5 edit-btn edit-delete-btn active"
                      onClick={() => {
                        scrollToTop();
                        handleEdit(employment);
                      }}
                  >
                    {t('utils.edit')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }


  const currentPosition = employmentPosition();
  const employment_requirement = props.current_transaction.dealership
      .hard_credit_employment_requirement
  let totalYear = 0;
  let totalMonth = 0;
  employments.forEach((item) => {
    if (item) {
      totalYear += item.duration_years;
      totalMonth += item.duration_months;
    }
  });
  let totalTime = totalYear + totalMonth / 12;
  const hardCreditEmploymentYearsInWords = toWords.convert(
      props.current_transaction.dealership
          .hard_credit_employment_requirement
  );


  useEffect(() => {
    handFillForm();
  }, [])


  return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <div className="transition-information-heading w-100 font-weight-bold mb-2">
            {t('hardCreditForm.hcFormStepThree.title')}
          </div>
          {props.current_transaction.dealership.hard_credit_employment_requirement !== 0 && (
              <div
                  className="small-heading-div w-100 mb-5"
                  style={{
                    fontSize: "22px",
                    lineHeight: "22px",
                    fontWeight: "400",
                  }}
              >
                {t('hardCreditForm.hcFormStepThree.contentOne')} <b>{hardCreditEmploymentYearsInWords}</b>{' '}
                {t('hardCreditForm.hcFormStepThree.contentTwo')}
              </div>
          )}
          <div className="mb-2 verify-identy-small-div font-weight-bold w-100">
            {
              address_title[
                  updateEmploymentPosition() !== -1
                      ? updateEmploymentPosition()
                      : currentPosition
                  ]
            }
          </div>
          <div className="row mx-0">
            <Form
                className="w-100 mt-3"
                ref={formRef}
                onFieldsChange={(changedFields, allFields) => {
                  onFormChange(allFields);
                }}
                onFinish={handleSubmit}
            >
              <div className="row">
                {(currentPosition === 0 || updateEmploymentPosition() === 0) && (
                    <div className="col-12 col-sm-12 col-lg-6">
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.howDoYouGetPaidLabel')}*</label>
                      <Form.Item
                          name="method_of_payment"
                          label={""}
                          className={
                            "group-floating-label group-floating-label-second"
                          }
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                      >
                        <Select
                            disabled={disabledField}
                            onFocus={() => onSelectFocused("method_of_payment")}
                            onBlur={() => onSelectBlurred("method_of_payment")}
                            suffixIcon={
                              <img
                                  src={Images.down_arrow_select}
                                  width={15}
                                  alt="down arrow"
                                  className="img-fluid"
                              />
                            }
                            placeholder=""
                            onChange={(value) => handleChange("method_of_payment", value)}
                        >
                          <Option
                              disabled
                              className="disbaled-optn-color"
                              value={null}
                          >
                            {t('hardCreditForm.hcFormStepThree.howDoYouGetPaidLabel')} *
                          </Option>
                          <Option value="cash">{t('hardCreditForm.hcFormStepThree.options.cash')}</Option>
                          <Option value="check">{t('hardCreditForm.hcFormStepThree.options.check')}</Option>
                        </Select>
                      </Form.Item>
                    </div>

                )}

                {(currentPosition === 0 || updateEmploymentPosition() === 0) && (
                    <div
                        className={`col-6 select_floating`}
                        id={"select_emp_status"}
                    >
                      <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.employmentStatusLabel')}*</label>
                      <Form.Item
                          name="employment_status"
                          rules={[
                            {
                              required: true,
                              message: "this field is required",
                            },
                          ]}
                          className={`select-float group-floating-label group-floating-label-second position-relative ${
                              editEmployment ? "has-value" : ""
                          }`}
                      >
                        <Select
                            disabled={disabledField}
                            onFocus={() => onSelectFocused("select_emp_status")}
                            onBlur={() => onSelectBlurred("select_emp_status")}
                            suffixIcon={
                              <img
                                  src={Images.down_arrow_select}
                                  width={15}
                                  alt="down arrow"
                                  className="img-fluid"
                              />
                            }
                            placeholder="Employment Status"
                            onChange={(value) =>
                                handleChange("employment_status", value)
                            }
                            options={employmentStatusOptions.map((option) => ({
                              label: option.label,
                              value: option.value
                            }))}
                        >
                        </Select>
                      </Form.Item>
                    </div>
                )}
                <>
                  {employmentStatus !== 'unemployed' &&
                      <div className="col-12">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.employerNameLabel')} {fieldRequirement ? '*' : ''}</label>
                        <Form.Item
                            name="employer_name"
                            rules={[
                              {
                                required: fieldRequirement,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              autoFocus
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div className="col-12 col-sm-12 col-lg-6">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.occupationLabel')} {fieldRequirement ? '*' : ''}</label>
                        <Form.Item
                            name="occupation"
                            rules={[
                              {
                                required: fieldRequirement,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div
                          id={"phone"}
                          className="col-12 col-sm-12 col-lg-6 phone-no"
                      >
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.employerPhoneNumberLabel')} {fieldRequirement ? '*' : ''}</label>
                        <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: fieldRequirement,
                                message: "this field is required",
                              },
                              {
                                pattern: phoneValidationPattern,
                                message: t('hardCreditForm.hcFormStepThree.warningMessage.tenMinimumRequired'),
                              },
                            ]}
                            className={
                              "group-floating-label select-float group-floating-label-second"
                            }
                        >
                          <InputNumber
                              formatter={(value) =>
                                  `${value}`
                                      .match(/\d*/g)
                                      .join("")
                                      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                      .slice(1)
                                      .join("-")
                                      .replace(/-*$/g, "")
                              }
                              parser={(value) => value.replace(/\$\s?|(-*)/g, "")}
                              onFocus={() => onNumberFocused("phone")}
                              onBlur={() => onNumberBlurred("phone")}
                              placeholder=""
                              disabled={disabledField}
                          />
                        </Form.Item>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div className="col-12">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.employerAddressLabel')} {fieldRequirement ? '*' : ''}</label>
                        <Form.Item
                            name="address"
                            rules={[
                              {
                                required: fieldRequirement,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div className="col-12">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.suiteLabel')}</label>
                        <Form.Item
                            name="suite"
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              placeholder=" "
                              rules={[
                                {
                                  required: fieldRequirement,
                                  message: "this field is required",
                                },
                              ]}
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.zipCodeLabel')} *</label>
                        <Form.Item
                            name="zip_code"
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              placeholder=" "
                              onChange={onChangeZip}
                              onBlur={onBlurZip}
                          />
                        </Form.Item>
                        <span className="subtext">{t('hardCreditForm.hcFormStepThree.zipHelperText')}</span>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div className="col-12 col-sm-12 col-lg-4">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.cityLabel')} *</label>
                        <Form.Item
                            name="city"
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div
                          className="col-12 col-sm-12 col-lg-4 select_floating"
                          id={"select_state"}
                      >
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.stateLabel')} *</label>
                        <Form.Item
                            name="state"
                            className={
                              "group-floating-label select-float group-floating-label-second position-relative"
                            }
                        >
                          <Select
                              disabled={disabledField}
                              onFocus={() => onSelectFocused("select_state")}
                              onBlur={() => onSelectBlurred("select_state")}
                              suffixIcon={
                                <img
                                    src={Images.down_arrow_select}
                                    width={15}
                                    alt="down arrow"
                                    className="img-fluid"
                                />
                              }
                              placeholder=""
                          >
                            {states.map((item) => (
                                <Option
                                    key={item.abbreviation}
                                    value={item.abbreviation}
                                >{`${item.name} (${item.abbreviation})`}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>}
                    {employmentStatus !== 'unemployed' &&
                        <div
                            id={"duration"}
                            className={`col-12 col-sm-12 col-lg-6`}
                        >
                          <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.durationYearLabel')} {fieldRequirement ? '*' : ''}</label>
                          <Form.Item
                              name="duration_years"
                              rules={[
                                {
                                  required: fieldRequirement,
                                  message: "this field is required",
                                },
                                {
                                  type: "number",
                                },
                              ]}
                              className={
                                "group-floating-label select-float group-floating-label-second"
                              }
                          >
                            <InputNumber
                                disabled={disabledField}
                                placeholder=""
                                onFocus={() => onNumberFocused("duration")}
                                onBlur={() => onNumberBlurred("duration")}
                            />
                          </Form.Item>
                          <span className="subtext subtext-update">
                        {t('hardCreditForm.hcFormStepThree.durationYearHelperText')}
                      </span>
                        </div>}
                  {employmentStatus !== 'unemployed' &&
                      <div
                          id={"duration-month"}
                          className={`col-12 col-sm-12 col-lg-6`}
                      >
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.durationMonthLabel')} </label>
                        <Form.Item
                            name="duration_months"
                            rules={[
                              {
                                required: false,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label select-float group-floating-label-second"
                            }
                        >
                          <InputNumber
                              disabled={disabledField}
                              max={12}
                              onFocus={() => onNumberFocused("duration-month")}
                              onBlur={() => onNumberBlurred("duration-month")}
                              placeholder=""
                          />
                        </Form.Item>
                        <span className="subtext subtext-update">
                        {t('hardCreditForm.hcFormStepThree.durationMonthHelperText')}
                      </span>
                      </div>
                  }
                  {employmentStatus !== 'unemployed' &&
                      <>
                    {(currentPosition === 0 || updateEmploymentPosition() === 0) && (
                        <div
                            id={"monthly_salary"}
                            className="col-12 col-sm-12 col-lg-6"
                        >
                          <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.grossIncomeLabel')} *</label>
                          <Form.Item
                              name="monthly_salary"
                              rules={[
                                {
                                  required: true,
                                  message: "this field is required",
                                },
                              ]}
                              className={`group-floating-label select-float group-floating-label-second price-field ${
                                  editEmployment ? "has-value" : ""
                              }`}
                          >
                            <Input
                                disabled={disabledField}
                                className="ant-input-number-input"
                                onFocus={() => onNumberFocused("monthly_salary")}
                                onBlur={() => onNumberBlurred("monthly_salary")}
                                placeholder="0.00"
                                onChange={(e) =>
                                    handleDollarField(e?.target?.value)
                                }
                                onKeyPress={enterOnlyNumber}
                            />
                          </Form.Item>
                          <span className="subtext subtext-update">
                          {t('hardCreditForm.hcFormStepThree.grossIncomeHelperText')}
                        </span>
                          {errorMessage && (
                              <div
                                  style={{
                                    color: "#EB5757",
                                    position: "absolute",
                                    bottom: "5px",
                                    fontSize: "12px",
                                    fontWeight: "400",
                                  }}
                              >
                                {errorMessage}
                              </div>
                          )}
                        </div>
                    )}
                      </>
                  }

                  {(employmentStatus !== 'unemployed' || currentPosition === 0 || updateEmploymentPosition() === 0) && (
                      <>
                        {employmentStatus !== 'unemployed' && (
                            <div
                                className="col-12 col-sm-12 col-lg-6 select_floating"
                                id={"type"}
                            >
                              <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.payFrequencyLabel')} *</label>
                              <Form.Item
                                  name="type"
                                  rules={[
                                    {
                                      required: true,
                                      message: "this field is required",
                                    },
                                  ]}
                                  className={`select-float group-floating-label group-floating-label-second position-relative ${
                                      editEmployment ? "has-value" : ""
                                  }`}
                              >
                                <Select
                                    disabled={disabledField}
                                    onFocus={() => onSelectFocused("type")}
                                    onBlur={() => onSelectBlurred("type")}
                                    suffixIcon={
                                      <img
                                          src={Images.down_arrow_select}
                                          width={15}
                                          alt="down arrow"
                                          className="img-fluid"
                                      />
                                    }
                                    placeholder=""
                                    onChange={(value) => handleChange("type", value)}
                                >
                                  <Option
                                      disabled
                                      className="disbaled-optn-color"
                                      value={null}
                                  >
                                    {"Weekly, Monthly, Yearly...*"}
                                  </Option>
                                  <Option value="weekly">
                                    {t('hardCreditForm.hcFormStepThree.options.weekly')}
                                  </Option>
                                  <Option value="bi_weekly">
                                    {t('hardCreditForm.hcFormStepThree.options.biWeekly')}
                                  </Option>
                                  <Option value="semi_monthly">
                                    {t('hardCreditForm.hcFormStepThree.options.semiWeekly')}
                                  </Option>
                                  <Option value={"annual"}>
                                    {t('hardCreditForm.hcFormStepThree.options.yearly')}
                                  </Option>
                                </Select>
                              </Form.Item>
                              <span className="subtext">{t('hardCreditForm.hcFormStepThree.payFrequencyHelperText')}</span>
                            </div>
                        )}
                      </>
                  )}
                  {(currentPosition === 0 || updateEmploymentPosition() === 0) && (
                      <div className="col-12 col-sm-12 col-lg-6">
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.otherIncomeSourceLabel')}</label>
                        <Form.Item
                            name="other_income_source"
                            label={""}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              disabled={disabledField}
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                        <span className="subtext subtext-update">
                         {t('hardCreditForm.hcFormStepThree.otherIncomeSourceHelperText')}
                        </span>
                      </div>
                  )}

                  {(currentPosition === 0 || updateEmploymentPosition() === 0) && (
                      <div
                          id={"other_income"}
                          className="col-12 col-sm-12 col-lg-6"
                      >
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.amountLabel')}</label>
                        <Form.Item
                            name="other_income"
                            className={`group-floating-label select-float group-floating-label-second price-field ${
                                editEmployment ? "has-value" : ""
                            }`}
                        >
                          <DollarInputField
                              disabled={disabledField}
                              name="other_income"
                              className="ant-input-number-input"
                              placeholder="0.00"
                              formRef={formRef}
                          />
                        </Form.Item>
                        <span className="subtext">
                          {t('hardCreditForm.hcFormStepThree.amountHelperText')}
                        </span>
                      </div>
                  )}

                  {(currentPosition === 0 || updateEmploymentPosition() === 0) && (
                      <div
                          className="col-12 col-sm-12 col-lg-12 select_floating"
                          id={"typeOther"}
                      >
                        <label className="font-weight-bold">{t('hardCreditForm.hcFormStepThree.monthAnnuallyLabel')}</label>
                        <Form.Item
                            name="other_income_type"
                            rules={[]}
                            className={`select-float group-floating-label group-floating-label-second position-relative ${
                                editEmployment ? "has-value" : ""
                            }`}
                        >
                          <Select
                              disabled={disabledField}
                              onFocus={() => onSelectFocused("typeOther")}
                              onBlur={() => onSelectBlurred("typeOther")}
                              suffixIcon={
                                <img
                                    src={Images.down_arrow_select}
                                    width={15}
                                    alt="down arrow"
                                    className="img-fluid"
                                />
                              }
                              placeholder=""
                              onChange={(value) =>
                                  handleChange("other_income_type", value)
                              }
                          >
                            <Option
                                disabled
                                className="disbaled-optn-color"
                                value={null}
                            >
                              {t('hardCreditForm.hcFormStepThree.monthAnnuallyLabel')}
                            </Option>
                            <Option value={"annual"}>{t('hardCreditForm.hcFormStepThree.options.annual')}</Option>
                            <Option value="monthly">{t('hardCreditForm.hcFormStepThree.options.monthly')}</Option>
                          </Select>
                        </Form.Item>
                        <span className="subtext">
                          {t('hardCreditForm.hcFormStepThree.monthAnnuallyHelperText')}
                        </span>
                      </div>
                  )}
                </>
                <div className="col-12">
                  <Button
                    htmlType="submit"
                    disabled={!formFilled || disabledField}
                    className={`add-prev-address font-weight-normal mt-5 ${
                      !formFilled && "in-active"
                    }`}
                  >
                    {editEmployment ?
                        t('hardCreditForm.hcFormStepThree.updateEmployment')
                        : t('hardCreditForm.hcFormStepThree.addEmployment')
                    }
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        {employments.map((employment, index) => (
          <div
            key={`employment_${index}`}
            className="col-12 p-0 address-filed-show-address-div"
          >
            <div className="row">
              <div className="col-12">
                <h5>
                  {index === 0
                    ? t('hardCreditForm.hcFormStepThree.current') + ' ' + t('hardCreditForm.hcFormStepThree.mandatory')
                    : index === 1
                    ? t('hardCreditForm.hcFormStepThree.previous')
                    : t('hardCreditForm.hcFormStepThree.secondPrevious')
                  }
                </h5>
              </div>
              <div className="col-12">
                {employment ? (
                    <EmploymentCard employment={employment} index={index} key={index} />
                ) : (
                  <div className="row address-add-row when-data-not-show d-inline-flex align-items-center ">
                    <h6 className="mb-0">
                      {index === 0
                        ? t('hardCreditForm.hcFormStepThree.currentAddressEmptyCardContent')
                        : index === 1
                        ? t('hardCreditForm.hcFormStepThree.previousAddressEmptyCardContent')
                        : t('hardCreditForm.hcFormStepThree.secondPreviousAddressEmptyCardContent')
                      }
                    </h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="steps-action flex-align-center justify-content-end w-100">
          <Button
            style={{ marginRight: "11%" }}
            onClick={() => props.prev()}
          >
            {t('utils.back')}
          </Button>
          <Button
            disabled={currentPosition === 0}
            type="primary"
            onClick={() => next(totalTime)}
          >
            {t('utils.next')}
          </Button>
        </div>
        <Modal
          open={employmentErr}
          onOk={() => setEmploymentErr(false)}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
              key="submit"
              type={"primary"}
              onClick={() => setEmploymentErr(false)}
            >
              {t('hardCreditForm.hcFormStepThree.addInfoModal.addInformation')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.error_prompt_img}
              />
              <p className={`font-weight-bold mb-2 text-color-red`}>
                {t('hardCreditForm.hcFormStepThree.addInfoModal.titleOne')}
                {employment_requirement}
                {t('hardCreditForm.hcFormStepThree.addInfoModal.titleTwo')}
              </p>
              <small className="d-inline-block w-100">
                {t('hardCreditForm.hcFormStepThree.addInfoModal.contentOne')}
                {employment_requirement}
                {t('hardCreditForm.hcFormStepThree.addInfoModal.contentTwo')}
              </small>
            </div>
          </div>
        </Modal>

        <Modal
          open={employmentFine}
          onOk={() => {
            setEmploymentStatus(false);
            props.next();
          }}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
              key="submit"
              type={"primary"}
              onClick={() => setEmploymentFine(false)}
            >
              {t('utils.addMore')}
            </Button>,
            <Button
              key="submit"
              type={"primary"}
              onClick={() => {
                setEmploymentFine(false);
                props.next();

              }}
            >
              {t('utils.next')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <p className={`font-weight-bold mb-2 text-color-blue`}>
                {employment_requirement === 0 ? t('hardCreditForm.hcFormStepThree.addMoreModal.timeCover')
                : `${t('hardCreditForm.hcFormStepThree.addMoreModal.notCover')} ${employment_requirement} 
                    ${t('hardCreditForm.hcFormStepThree.addMoreModal.years')} 
                `
                }
              </p>
              <small className="d-inline-block w-100">
                '{t('hardCreditForm.hcFormStepThree.addMoreModal.nextStepContent')} '
              </small>
            </div>
          </div>
        </Modal>
        <Modal
          open={checkDuration}
          onOk={() => setCheckDuration(false)}
          maskClosable={false}
          className={`main-modal exit-modal-back close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
              key="submit"
              type={"primary"}
              onClick={() => setCheckDuration(false)}
            >
              {t('hardCreditForm.hcFormStepThree.addInfoModal.addInformation')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.error_prompt_img}
              />
              <p className={`font-weight-bold mb-2 text-color-red`}>
                {t('hardCreditForm.hcFormStepThree.addInfoModal.enterAmount')}
              </p>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }


const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
  };
};

export default connect(mapStateToProps)(EmploymentInformation);
