import React, { useEffect, useState, useRef } from "react";
import { Image as Images } from "../Images";
import { Button, DatePicker, Form, Input, message, Modal, Table } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "../../components/sample/canvas.css";
import {
  signPrivacy,
  signPrivacyPolicy,
} from "../../controller/api/customerAPI";
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import {useTranslation} from "react-i18next";

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const StepsOnePrivacyPolicy = (props) => {
  const signatureRef = useRef({});
  const formRef = useRef();
  const [agreedValue, setAgreedValue] = useState(props.privacy_agreed);
  const [visible, setVisible] = useState(false);
  const [signatureValue, setSignatureValue] = useState(null);

  const dateSigned = moment().format("YYYY-MM-DD");
  const policyReceived = moment().format("YYYY-MM-DD");
  const [btnDisable, setBtnDisable] = useState(true);
  const [btnLoading, setBtnLoading] = useState();
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: (
        <div>
          {t('transactionForm.stepOne.table.headerOne')}
        </div>
      ),
      dataIndex: "reasons_info",
    },
    {
      title: (
        <div>
          {t('transactionForm.stepOne.table.headerTwo')}
        </div>
      ),
      dataIndex: "dealership_share",
    },
    {
      title: (
        <div>
          {t('transactionForm.stepOne.table.headerThree')}
        </div>
      ),
      dataIndex: "limit_sharing",
    },
  ];
  const data = [
    {
      key: "1",
      reasons_info: (
        <div className="row">
          <div className="col-12">
            <h6>{t('transactionForm.stepOne.table.rowOne.columnHeader')}</h6>
            {t('transactionForm.stepOne.table.rowOne.columnOne')}
          </div>
        </div>
      ),
      dealership_share: t('transactionForm.stepOne.table.yes'),
      limit_sharing: t('transactionForm.stepOne.table.no'),
    },
    {
      key: "2",
      reasons_info: (
        <div className="row">
          <div className="col-12">
            <h6>{t('transactionForm.stepOne.table.rowTwo.columnHeader')}</h6>
            {t('transactionForm.stepOne.table.rowTwo.columnOne')}
          </div>
        </div>
      ),
      dealership_share:  t('transactionForm.stepOne.table.yes'),
      limit_sharing: t('transactionForm.stepOne.table.no'),
    },
    {
      key: "3",
      reasons_info: (
        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">
              {t('transactionForm.stepOne.table.rowThree.columnHeader')}
            </h6>
          </div>
        </div>
      ),
      dealership_share:  t('transactionForm.stepOne.table.no'),
      limit_sharing:  t('transactionForm.stepOne.table.doNotShare'),
    },
    {
      key: "4",
      reasons_info: (
        <div className="row">
          <div className="col-12">
            <h6>{t('transactionForm.stepOne.table.rowFour.columnHeader')}</h6>
            {t('transactionForm.stepOne.table.rowFour.columnOne')}
          </div>
        </div>
      ),
      dealership_share: t('transactionForm.stepOne.table.no'),
      limit_sharing: t('transactionForm.stepOne.table.doNotShare'),
    },
    {
      key: "5",
      reasons_info: (
        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">{t('transactionForm.stepOne.table.rowFive.columnHeader')}</h6>
          </div>
        </div>
      ),
      dealership_share: t('transactionForm.stepOne.table.no'),
      limit_sharing: t('transactionForm.stepOne.table.doNotShare'),
    },
    {
      key: "6",
      reasons_info: (
        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">{t('transactionForm.stepOne.table.rowSix.columnHeader')}</h6>
          </div>
        </div>
      ),
      dealership_share: t('transactionForm.stepOne.table.no'),
      limit_sharing: t('transactionForm.stepOne.table.doNotShare'),
    },
  ];
  const wvd_columns = [
    {
      title: <div>{t('transactionForm.stepOne.wvdTable.title')}</div>,
      dataIndex: "what_we_do",
    },
    {
      title: "",
      dataIndex: "descriptions",
    },
  ];
  const def_columns = [
    {
      title: <div>{t('transactionForm.stepOne.defTable.title')}</div>,
      dataIndex: "definitions",
    },
    {
      title: "",
      dataIndex: "definitions_des",
    },
  ];

  const getDealershipName = () => {
    const dealership = props.user.available_dealerships.find(
      (o) => o.id === props.user.default_dealership
    );
    if (dealership) {
      return dealership.name;
    } else {
      return "dealershipx";
    }
  };

  const wvd_data = [
    {
      key: "a",
      what_we_do: (
        <div className="row">
          <div className="col-12">
            <h6>
              {t('transactionForm.stepOne.wvdTable.rowOne.columnHeaderOne')}
              {getDealershipName()}{' '}
              {t('transactionForm.stepOne.wvdTable.rowOne.columnHeaderTwo')}
            </h6>
          </div>
        </div>
      ),
      descriptions: t('transactionForm.stepOne.wvdTable.rowOne.context')
    },
    {
      key: "b",
      what_we_do: (
        <div className="row">
          <div className="col-12">
            <h6>
              {t('transactionForm.stepOne.wvdTable.rowTwo.columnHeaderOne')}
              {getDealershipName()}{' '}
              {t('transactionForm.stepOne.wvdTable.rowTwo.columnHeaderTwo')}
            </h6>
          </div>
        </div>
      ),
      descriptions: (
        <div className="row">
          <div className="col-12">
            {t('transactionForm.stepOne.wvdTable.rowTwo.context')}
            <ul className="list-inline mb-0">
              <li>• {t('transactionForm.stepOne.wvdTable.rowTwo.bulletOne')}</li>
              <li>• {t('transactionForm.stepOne.wvdTable.rowTwo.bulletTwo')}</li>
              <li>• {t('transactionForm.stepOne.wvdTable.rowTwo.bulletThree')}</li>
              <li>• {t('transactionForm.stepOne.wvdTable.rowTwo.bulletFour')}</li>
              <li>• {t('transactionForm.stepOne.wvdTable.rowTwo.bulletFive')}</li>
              <li>
                • {t('transactionForm.stepOne.wvdTable.rowTwo.bulletSix')}
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      key: "c",
      what_we_do: (
        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">{t('transactionForm.stepOne.wvdTable.rowThree.columnHeaderOne')}</h6>
          </div>
        </div>
      ),
      descriptions: (
        <div className="row">
          <div className="col-12">
            {t('transactionForm.stepOne.wvdTable.rowThree.context')}
            <ul className="list-inline mb-0">
              <li>
                • {t('transactionForm.stepOne.wvdTable.rowThree.bulletOne')}
              </li>
              <li>• {t('transactionForm.stepOne.wvdTable.rowThree.bulletTwo')}</li>
              <li>• {t('transactionForm.stepOne.wvdTable.rowThree.bulletThree')}</li>
              <li>
                • {t('transactionForm.stepOne.wvdTable.rowThree.bulletFour')}
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];
  const def_data = [
    {
      key: "p",
      definitions: (
        <div className="row">
          <div className="col-12">
            <h6>{t('transactionForm.stepOne.defTable.rowOne.columnHeader')}</h6>
          </div>
        </div>
      ),
      definitions_des: (
        <div>
          {t('transactionForm.stepOne.defTable.rowOne.context')}
          <ul className="list-inline mb-0">
            <li>• {getDealershipName()} {t('transactionForm.stepOne.defTable.rowOne.bulletOne')}</li>
          </ul>
        </div>
      ),
    },
    {
      key: "q",
      definitions: (
        <div className="row">
          <div className="col-12">
            <h6>{t('transactionForm.stepOne.defTable.rowTwo.columnHeader')}</h6>
          </div>
        </div>
      ),
      definitions_des: (
        <div className="row">
          <div className="col-12">
            {t('transactionForm.stepOne.defTable.rowTwo.context')}
            <ul className="list-inline mb-0">
              <li>
                • {getDealershipName()} {t('transactionForm.stepOne.defTable.rowTwo.bulletOne')}
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      key: "r",
      definitions: (
        <div className="row">
          <div className="col-12">
            <h6 className="mb-0">{t('transactionForm.stepOne.defTable.rowThree.columnHeader')}</h6>
          </div>
        </div>
      ),
      definitions_des: (
        <div className="row">
          <div className="col-12">
            {t('transactionForm.stepOne.defTable.rowThree.context')}
            <ul className="list-inline mb-0">
              <li>• {getDealershipName()} {t('transactionForm.stepOne.defTable.rowThree.bulletOne')}</li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setAgreedValue(props.privacy_agreed);
  }, [props.privacy_agreed]);

  const handleSubmit = (e) => {
    setBtnLoading(true);
    const { transaction } = props;
    e.preventDefault();
    if (
      transaction &&
      (transaction.is_co_buyer
        ? transaction.policy_signed_by_co_buyer
        : transaction.policy_signed)
    ) {
      props.next(props.transaction);

      setBtnLoading(false);
      return;
    }
    if (!dateSigned || !policyReceived || !signatureValue) {
      message.error("Please fill all the fields");

      setBtnLoading(false);
      return;
    }
    let form_data = new FormData();
    form_data.append("date_signed", dateSigned);
    form_data.append("policy_received", policyReceived);
    form_data.append(
      "signature",
      dataURLtoFile(signatureValue, "signature.png")
    );
    form_data.append("source_type", "ip");
    if (props.match.params.trans_id) {
      form_data.append("transaction", props.match.params.trans_id);
      signPrivacyPolicy(props.user.default_dealership, form_data)
        .then((resp) => {
          props.next(resp.data);

          setBtnLoading(false);
        })
        .catch(() => {
          message.error("Error while signing document");

          setBtnLoading(false);
        });
    } else {
      signPrivacy(props.user.default_dealership, form_data)
        .then((resp) => {
          props.next(resp.data);

          setBtnLoading(false);
        })
        .catch(() => {
          message.error("Error while signing document");

          setBtnLoading(false);
        });
    }
  };

  const clearCanvas = () => {
    signatureRef.current.clear();
    setBtnDisable(true);
  };

  const { transaction } = props;
  let website = props.user.available_dealerships.find(
    (i) => i.id === props.user.default_dealership
  ).dealership_website;
  return (
    <React.Fragment>
      <div className="col-12 transition-information-div step-one-main-div mb-4">
        <div className="transition-information-heading w-100 font-weight-bold">
          {t('transactionForm.stepOne.title')}
        </div>
        <div className="small-heading-div w-100 font-weight-bold">
          {t('transactionForm.stepOne.subTitle')}
        </div>
        <p className="font-weight-normal">
          {t('transactionForm.stepOne.context')}
        </p>
        <div className="row mx-0">
          <div className="col-12">
            <div className="row privacy-policy-code-row-main">
              <div className="col-12">
                <div className="row p-v-c-inner-row">
                  <div className="col-12 col-sm-3 col-md-3">
                    <div className="row align-items-center card-img-div-left">
                      <div className="col-12">
                        <h5 className="mb-0">{t('transactionForm.stepOne.fact')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-9 col-md-9">
                    <div className="row p-v-c-content-inner-right">
                      <div className="col-12">
                        <p className="mb-0">
                          {t('transactionForm.stepOne.factContextOne')}
                          {getDealershipName()}
                          {t('transactionForm.stepOne.factContextTwo')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row p-v-c-inner-row">
                  <div className="col-12 col-sm-3 col-md-3">
                    <div className="row align-items-center card-img-div-left card-img-div-left-second">
                      <div className="col-12">
                        <h5 className="mb-0">{t('transactionForm.stepOne.why')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-9 col-md-9">
                    <div className="row p-v-c-content-inner-right">
                      <div className="col-12">
                        <p className="mb-0">
                          {t('transactionForm.stepOne.whyContext')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row p-v-c-inner-row">
                  <div className="col-12 col-sm-3 col-md-3">
                    <div className="row align-items-center card-img-div-left card-img-div-left-second">
                      <div className="col-12">
                        <h5 className="mb-0">{t('transactionForm.stepOne.what')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-9 col-md-9">
                    <div className="row p-v-c-content-inner-right">
                      <div className="col-12">
                        <p className="mb-0">
                          {t('transactionForm.stepOne.whatContext')}:
                        </p>
                        <ul className="mb-0">
                          <li>{t('transactionForm.stepOne.whatBulletOne')}</li>
                          <li>{t('transactionForm.stepOne.whatBulletTwo')}</li>
                          <li>{t('transactionForm.stepOne.whatBulletThree')}</li>
                          <li>
                            {t('transactionForm.stepOne.whatBulletFour')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pvc-table-data-row-main">
                  <div className="col-12 p-0 main-table-div-inn">
                    <Table
                      className="common-table-pvc responsive-table table-responsive"
                      columns={columns}
                      dataSource={data}
                      size="middle"
                      rowKey={(record) => record.key}
                      pagination={false}
                    />
                  </div>
                </div>
                <div className="row p-v-c-inner-row pvc-inner-row align-items-center">
                  <div className="col-12">
                    <p className="font-weight-bold mb-0">
                      {t('transactionForm.stepOne.questions')}{" "}
                      <a
                        href={website ? website : "#"}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        {t('transactionForm.stepOne.dealershipWeb')}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row pvc-table-data-row-main">
                  <div className="col-12 p-0 main-table-div-inn main-table-div-inn-2">
                    <Table
                      className="common-table-pvc responsive-table table-responsive"
                      columns={wvd_columns}
                      dataSource={wvd_data}
                      size="middle"
                      rowKey={(record) => record.key}
                      pagination={false}
                    />
                  </div>
                </div>
                <div className="row pvc-table-data-row-main">
                  <div className="col-12 p-0 main-table-div-inn main-table-div-inn-2">
                    <Table
                      className="common-table-pvc responsive-table table-responsive"
                      columns={def_columns}
                      dataSource={def_data}
                      size="middle"
                      rowKey={(record) => record.key}
                      pagination={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row pvc-table-data-row-main pvc-data-form-row align-items-center">
                      <div className="col-12">
                        <h6 className="mb-0">{t('transactionForm.stepOne.otherImportantInfo')}</h6>
                      </div>
                    </div>
                    {!(
                      transaction &&
                      (transaction.is_co_buyer
                        ? transaction.policy_signed_by_co_buyer
                        : transaction.policy_signed)
                    ) ? (
                      <div className="row pvc-form-row">
                        <div className="col-12">
                          <Form ref={formRef}>
                            <div className="row">
                              <div
                                className="col-12 col-sm-6 p-0"
                                id={"select_date"}
                              >
                                <Form.Item
                                  name="policy_received"
                                  initialValue={moment()}
                                  label={"Received this notice on"}
                                  rules={[
                                    {
                                      required: false,
                                      message: "this field is required",
                                    },
                                  ]}
                                  className={
                                    "group-floating-label group-floating-pvc position-relative"
                                  }
                                >
                                  <DatePicker
                                    format={"MM/DD/YYYY"}
                                    popupClassName="selected-date"
                                    style={{ backgroundColor: "#fff" }}
                                    disabled={true}
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12 col-sm-6 pr-0">
                                <Form.Item
                                  name="date"
                                  label={"Signature"}
                                  initialValue={signatureValue}
                                  rules={[
                                    {
                                      required: false,
                                      message: "this field is required",
                                    },
                                  ]}
                                  className={
                                    "group-floating-label group-floating-pvc position-relative"
                                  }
                                >
                                  <>
                                    <Input style={{ pointerEvents: "none" }} />
                                    <Button
                                      onClick={() => setVisible(true)}
                                      className={`upload-btn-modal ${signatureValue && "uploaded-btn-main"
                                        } border-0 position-absolute d-flex align-items-center justify-content-center p-0 m-auto`}
                                    >
                                      <img
                                        src={
                                          signatureValue
                                            ? signatureValue
                                            : Images.sign_btn_img
                                        }
                                        alt=""
                                        className="img-fluid"
                                      />
                                    </Button>
                                  </>
                                </Form.Item>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    ) : (
                      <h3>{`${t('transactionForm.stepOne.policySigned')} ${moment(
                        transaction.policy_signed_at
                      ).format("MM/DD/YYYY")}`}</h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="steps-action flex-align-center justify-content-end w-100">
        <Button
          loading={btnLoading}
          disabled={!agreedValue}
          type="primary"
          onClick={handleSubmit}
        >
          {t('utils.next')}
        </Button>
      </div>
      <Modal
        onCancel={() => setVisible(false)}
        title={t('transactionForm.stepOne.drawSignature')}
        onOk={() => {
          setSignatureValue(signatureRef.current.toDataURL());
          setVisible(false);
        }}
        open={visible}
        className="main-modal draw-sign-modal footer-btn-document-modal"
        footer={[
          <Button
            onClick={() => {
              setVisible(false);
            }}
            key="1"
          >
            {t('utils.cancel')}
          </Button>,
          <Button
            disabled={btnDisable}
            type="primary"
            onClick={() => {
              setSignatureValue(signatureRef.current.toDataURL());
              setVisible(false);
            }}
            key="2"
          >
            {t('utils.submit')}
          </Button>,
        ]}
      >
        <>
          <div className="signature-modal position-relative">
            <Button
              className="clear-btn-sign position-absolute p-0 font-weight-bold text-uppercase shadow-none"
              onClick={() => clearCanvas()}
            >
              {t('utils.clear')}
            </Button>
            <SignatureCanvas
              onBegin={() => setBtnDisable(false)}
              ref={signatureRef}
              canvasProps={{
                width: `500`,
                height: `280`,
                className: "sigCanvas",
              }}
            />
          </div>
        </>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(withRouter(StepsOnePrivacyPolicy));
