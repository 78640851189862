import React, { useEffect, useState } from "react";
import { Get, Post } from "../../controller/headerIntercepter";
import { getAPIUrl } from "../../controller/Global";
import { Trulioo, event, Response } from "@trulioo/docv";
import "@trulioo/docv/style.css";
import { Button } from "antd";

export const TruliooIdentityVerification = (props: { match: { params: { id: any; }; }; }) => {
  const [isPending, setIsPending] = useState<null | boolean>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);

  useEffect(() => {
    Get(
      getAPIUrl("v3.identity-document.is_pending", { verification_request_id: props.match.params.id} as any),
      undefined,
      false)
      .then(res => setIsPending(true))
      .catch(() => setIsPending(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const startVerification = async () => {
    setLoading(true)
    const res = await Post(
      getAPIUrl("v3.identity-document.get_new_capture", {
        verification_request_id: props.match.params.id
      } as any),
      undefined,
      false
    );

    const workflowOption = Trulioo.workflow().setShortCode(res.data.shortCode).setRedirectUrl(document.URL);
    // Setup callbacks to get results and debugging errors
    const callbacks = new event.adapters.ListenerCallback({
      onComplete: async (success: Response.Success) => {
        console.info(`Verification Successful: ${success.transactionId} `);
        await Post(
          getAPIUrl("v3.identity-document.validate_transaction", { verification_request_id: props.match.params.id } as any),
          { "trulioo_transaction_id": success.transactionId },
          false);
        setComplete(true)
      },
      onError: (error: Response.Error) => {
        console.error(`Verification Failed with Error Code: ${error.code}, TransactionID: ${error.transactionId}, Reason: ${error.message}`);
      },
      onException: (exception: Response.Exception) => {
        console.error("Verification Failed with Exception:", exception);
      }
    });
    const callbackOption = Trulioo.event().setCallbacks(callbacks);
    Trulioo.initialize(workflowOption)
      .then(complete => {
        console.info("Initialize complete:", complete);
        Trulioo.launch("trulioo-sdk", callbackOption)
          .then(success => {
            console.info("Launch success:", success);
          });
      })
      .catch(error =>
        console.error("Error:", error)
      );
  };

  return (
    <>
      <div
        className={`justify-content-center align-items-center`}
        style={{ display: "grid", height: "100%" }}
      >
        {
          isPending && !complete&&
          <>
            <div>
              <h4>Identity-Document Verification</h4>
              <p className={'mb-5'}>Please click below to start the verification process</p>
              <ul>
                <li>Make sure to use your <b style={{fontWeight: 600}}>US Driver's License</b></li>
              </ul>
            </div>
            <Button onClick={() => startVerification()}
                    loading={loading}
                    type={'primary'}
                    className={'py-5 d-flex align-items-center justify-content-center'}>
              Start Verification
            </Button>
          </>
        }
        {
          isPending === false &&
          <h1 className={'text-center'}>Already Verified</h1>
        }
        {
          complete &&
          <h1 className={'text-center'}>Verification Completed</h1>
        }
      </div>
      {
        !complete &&
        <div
          className={`justify-content-center align-items-center`}
          style={{ display: "grid", height: "100%" }}
          id={"trulioo-sdk"}
        >
        </div>
      }
    </>
  );
};
export default TruliooIdentityVerification;
