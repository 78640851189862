// import { Input } from 'antd';
import React, { Component } from "react";
import { DatePicker } from "antd";
import moment from "moment";
// import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
// import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

export default class HiddenDobInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dobValue: null,
      maskedValue: null,
      counter: 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      if (this.state.counter === 1) {
        this.setState({ counter: this.state.counter + 1 });
        if (this.props.value) {
          let mask = {
            day: this.props.value.day,
            month: this.props.value.month,
            year: "XXXX",
          };
          this.setState({
            maskedValue: mask,
            dobValue: this.props.value,
            counter: this.state.counter + 1,
          });
        }
      }
    }
  }

  getRealValue = (e) => {
    this.setState({ maskedValue: null });
    if (this.state.dobValue) e.target.value = this.state.dobValue;
  };

  value() {
    return this.state.dobValue;
  }
  handleChange = (value) => {
    const updatedValue = {
      day: moment(value, 'MM/DD/YYYY').format('DD'),
      month: moment(value, 'MM/DD/YYYY').format('MM'),
      year: moment(value, 'MM/DD/YYYY').format('YYYY')
    }

    this.setState({ dobValue: updatedValue, maskedValue: updatedValue }, () =>
      this.props.onChange(updatedValue)
    );
  };

  formatDate = () => {
    const { maskedValue, dobValue } = this.state;
    if (maskedValue) {
      return `${maskedValue.month}/${maskedValue.day}/XXXX`;
    } else if (dobValue) {
      return `${dobValue.month}/${dobValue.day}/${dobValue.year}`;
    }
  };

  replaceYearWithX = (dateString) => {
    return dateString.replace(/^\d{4}/, 'XXXX');
  };

  formatMaskedYear = (date) => {
    if (!date) return "";

    const year = moment(date).format("YYYY");
    return moment(date).format(`MM/DD/YYYY`).replace(year, "XXXX");
  };


  render() {

    return (
      <div onFocus={(e) => this.getRealValue(e)}>
        <DatePicker
          value={
            this.state.maskedValue &&
              moment(`${this.state.maskedValue.month}/${this.state.maskedValue.day}/${this.state.maskedValue.year}`, "MM/DD/YYYY").isValid()
              ? moment(`${this.state.maskedValue.month}/${this.state.maskedValue.day}/${this.state.maskedValue.year}`, "MM/DD/YYYY")
              : this.state.dobValue &&
                moment(`${this.state.dobValue.month}/${this.state.dobValue.day}/${this.state.dobValue.year}`, "MM/DD/YYYY").isValid()
                ? moment(`${this.state.dobValue.month}/${this.state.dobValue.day}/${this.state.dobValue.year}`, "MM/DD/YYYY")
                : this.props.value &&
                  moment(`${this.props.value.month}/${this.props.value.day}/${this.props.value.year}`, "MM/DD/YYYY").isValid()
                  ? moment(`${this.props.value.month}/${this.props.value.day}/${this.props.value.year}`, "MM/DD/YYYY") : null
          }
          onChange={(e) => this.handleChange(e)}
          className="custom-today-day"
          popupClassName="selected-date"
          format={(value) => this.formatMaskedYear(value)}
          placeholder="Date of Birth *"
          allowClear={false}
        />
      </div>
    );
  }
}
