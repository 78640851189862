import { include } from "named-urls";

export const routes = {
  login: "/",
  signup: "/signup/",
  forgetPassword: "/forget-password/",
  logout: "/logout/",
  identityVerification: "/:id/identity-document-verification/",
  signature_sample: "/signature-sample/",
  signature_sample2: "/signature-sample-2/",
  reset_password: "/password/:type/:uid/:token/",
  dashboard: include("/dashboard", {
    self: "",
    add_transaction: "transaction/add/",
    transaction: include("/transaction/:trans_id/", {
      transaction_widget: "run-widget",
      personal_info: "personal-information/:id?/:method/",
      co_buyer: "co-buyer/:id?/:method/",
      address_info: "address-information/:id?/:method/",
      personal_reference_info: 'personal-reference-information/:id?/:method/',
      deal_info: "deal-information/:id?/:method",
      vehicle_for_trade: "vehicle-trade-info/:id?/:method",
      vehicle_for_purchase: "vehicle-purchase-info/:id?/:method",
      pre_qualification: "pre-qualification-application/:id?/:method",
      license: "license/:id?/:method",
      insurance: "insurance/:id?/:method",
      hard_credit: "hard-credit-application/:id?/:method",
      view_documents: "view-documents",
      upload_documents: "upload-documents/",
      message: "message/",
    }),
    warehouse: include("warehouse", {
      self: "",
      item: "item/:method/:id?/",
      inventory: "inventory/",
      container: include("container", {
        self: "",
        method: ":method/:id?/",
      }),
    }),
    sales: include("sales-operations", {
      self: "",
      work_order: include("work-order", {
        self: "",
        method: ":type/:method/:id?/",
      }),
    }),
    tech_support: "tech-support/",
  }),
  helpdesk: "/helpdesk/",
};
