import React, { Component } from "react";
import { Image as Images } from "./Images";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-i18next";

const PreRequisiteDashboard = (props) => {
    const { t, i18n } = useTranslation();
    const contactAcknowledgement = props?.current_transaction?.customer_phone_verified;
    const privacyPolicySigned = props?.current_transaction?.policy_signed;
    const identifyTheftPrevention = props?.current_transaction?.photo_consent_image;


    return (
      <React.Fragment>
        <div className="row all-info-home-details">
          <div className="col-12">
            <div className="all-heading-home row align-items-center px-3">
              <div className="profile-card flex-align-center mb-3">
                <div className={"icon-circle-transparent"}>
                  <img
                      alt="add icon"
                      className="img-fluid"
                      src={Images.creditFormsIcon}
                  />
                </div>
                <div className="profile-info">
                  <span className="license-text">
                    {t('preRequisiteFormsCard.title')}
                  </span>
                  <span className="license-status form-status">
                    {t('preRequisiteFormsCard.description')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row all-inner-details p-0">
              <div className="col-12">
                <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between border-bottom-gray">
                  <div className="text-details flex-align-center">
                    <img
                      src={Images.document_privacy_notice}
                      alt="license icon"
                      className="img-fluid"
                    />
                    <div className="db-second">
                      <span className="license-text d-inline-block w-100">
                        {t('preRequisiteFormsCard.privacyPolicy')}
                      </span>
                      <span className="license-status form-status d-inline-block w-100 ">
                        {t('preRequisiteFormsCard.acknowledgementDocument')}
                      </span>
                    </div>
                  </div>
                  <div className="ddb-right-main d-flex align-items-center">
                    <div className="db-right-inner w-100 ddb-right-inner-btn-arrow d-flex flex-row-reverse align-items-center justify-content-between">
                      {privacyPolicySigned ? (
                          <img
                              src={Images.check_green_icon}
                              alt="check green icon"
                              className="img-fluid"
                          />
                      ) : <></>}
                    </div>
                  </div>
                </div>
                <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between border-bottom-gray">
                  <div className="text-details flex-align-center">
                    <img
                      src={Images.document_contact_acknowledgement}
                      alt="license icon"
                      className="img-fluid"
                    />
                    <div className="db-second">
                      <span className="license-text d-inline-block w-100">
                        {t('preRequisiteFormsCard.contactAcknowledgement')}
                      </span>
                      <span className="license-status form-status d-inline-block w-100">
                        {t('preRequisiteFormsCard.acknowledgementDocument')}
                      </span>
                    </div>
                  </div>
                  <div className="ddb-right-main d-flex align-items-center">
                    <div className="db-right-inner w-100 ddb-right-inner-btn-arrow d-flex flex-row-reverse align-items-center justify-content-between">
                      {contactAcknowledgement ? (
                          <img
                              src={Images.check_green_icon}
                              alt="check green icon"
                              className="img-fluid"
                          />
                      ) : <></>}
                    </div>
                  </div>
                </div>

                <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between ">
                  <div className="text-details flex-align-center">
                    <img
                      src={Images.document_identity_theft_prevention}
                      alt="license icon"
                      className="img-fluid"
                    />
                    <div className="db-second">
                      <span className="license-text d-inline-block w-100 ">
                        {t('preRequisiteFormsCard.identityTheftPrevention')}
                      </span>
                      <span className="license-status form-status d-inline-block w-100">
                        {t('preRequisiteFormsCard.acknowledgementDocument')}
                      </span>
                    </div>
                  </div>
                  <div className="ddb-right-main d-flex align-items-center">
                    <div className="db-right-inner w-100 ddb-right-inner-btn-arrow d-flex flex-row-reverse align-items-center justify-content-between">
                      {identifyTheftPrevention ? (
                          <img
                              src={Images.check_green_icon}
                              alt="check green icon"
                              className="img-fluid"
                          />
                      ) : <></>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction };
};


export default connect(mapStateToProps)(PreRequisiteDashboard);
