import {
  getHistory,
  getTransaction,
  loadMoreHistory,
  loadMoreTransaction,
} from "../../controller/api/customerAPI";

export const FETCH_TRANSACTION = "FETCH_TRANSACTION";
export const LOAD_MORE_TRANSACTION = "LOAD_MORE_TRANSACTION";
export const SET_CURRENT_TRANSACTION = "SET_CURRENT_TRANSACTION";
export const SET_CO_BUYER_VIEW = "SET_CO_BUYER_VIEW";
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION";
export const FETCH_HISTORY = "FETCH_HISTORY";
export const LOAD_MORE_HISTORY = "LOAD_MORE_HISTORY";
export const SET_CURRENT_TRANSACTION_TYPE = "SET_CURRENT_TRANSACTION_TYPE";

export function fetchTransactionAction(dealership, filter_by_dealership=false) {
  return (dispatch) => {
    return getTransaction(dealership, {filter_by_dealership: filter_by_dealership})
      .then((response) => {
        dispatch({
          type: FETCH_TRANSACTION,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };
}

export function loadMoreTransactionAction(next_url) {
  return (dispatch) => {
    return loadMoreTransaction(next_url)
      .then((response) => {
        dispatch({
          type: LOAD_MORE_TRANSACTION,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };
}

export function setCurrentTransactionAction(data) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_TRANSACTION,
      payload: data,
    });
  };
}
export function setCurrentTransactionHistoryAction(data) {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_TRANSACTION_TYPE,
      payload: data,
    });
  };
}

export function updateTransactionAction(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRANSACTION,
      payload: data,
    });
  };
}

export function setCoBuyerViewAction(data) {
  return (dispatch) => {
    dispatch({
      type: SET_CO_BUYER_VIEW,
      payload: data,
    });
  };
}

export function fetchHistoryAction(dealership) {
  return (dispatch) => {
    return getHistory(dealership)
      .then((response) => {
        dispatch({
          type: FETCH_HISTORY,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };
}

export function loadMoreHistoryAction(next_url) {
  return (dispatch) => {
    return loadMoreHistory(next_url)
      .then((response) => {
        dispatch({
          type: LOAD_MORE_HISTORY,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  };
}
