import React, { Component } from "react";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import { routes } from "../controller/routes";
import { methods } from "../controller/Global";
import { Button } from "antd";
import { withTranslation } from "react-i18next";

const document_type = {
  driver_license: "Driver's License",
  state_id: "State Id",
};

class licenseDashboard extends Component {
  render() {
    const { license, transaction, btnVisible, t } = this.props;

    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row align-items-center px-3">
            <div className="col-md-11">
              <div className="profile-card flex-align-center mb-3">
                <div className={"icon-circle-transparent"}>
                  <img
                      alt="add icon"
                      className="img-fluid"
                      src={Images.idIcon}
                  />
                </div>
                <div className="profile-info">
                  <span className="license-text">{license ? t('IdInformation.title') : t('IdInformation.personalIdentification')}</span>
                </div>
              </div>
            </div>
            <div className={`col-md-1 mb-2 ${btnVisible && !license ? 'align-tem-right-right' : ''}`}>
              {btnVisible ? (
                  !license ? (
                      <Link
                          to={reverse(routes.dashboard.transaction.license, {
                            trans_id: transaction,
                            method: methods.add,
                          })}
                          className="bg-transparent border-0 shadow-none p-0"
                      >
                        <Button className={"btn-add"}>{t('utils.add')}</Button>
                      </Link>
                  ) : (
                      <Link
                          to={reverse(routes.dashboard.transaction.license, {
                            trans_id: transaction,
                            method: methods.edit,
                            id: license.id
                          })}
                          className="bg-transparent border-0 shadow-none p-0"
                      >
                        <Button className={"btn-update"}>{t('utils.update')}</Button>
                      </Link>
                  )
              ) : (
                  ""
              )}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row all-inner-details p-0">
            <div className="col-12">
                <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between">

                <div className="text-details flex-align-center">
                  <img
                    src={Images.document_driver_license}
                    alt="license icon"
                    className="img-fluid"
                  />
                  <div className="db-second">
                    <span
                      className={`license-text d-inline-block w-100 ${
                        license ? "text-capitalize" : ""
                      }`}
                    >
                      {license && license.document && license.document.length
                        ? document_type[license.document_type]
                        : t('IdInformation.noId')}
                    </span>
                    {license && license.document && license.document.length ? (
                      license.is_valid ? (
                        <span className="license-status d-inline-block w-100">
                          {t('IdInformation.valid')}
                        </span>
                      ) : (
                        <span className="license-status-invalid d-inline-block w-100">
                          {t('IdInformation.invalid')}
                        </span>
                      )
                    ) : (
                      <span className="license-status form-status">{t('IdInformation.idValidity')}</span>
                    )}
                  </div>
                </div>
                {license && license.document && license.document.length ? (
                  license.is_valid ? (
                    <div className="db-right-inner">
                      <img
                        src={Images.check_green}
                        alt="check_green icon"
                        className="img-fluid"
                      />
                    </div>
                  ) : (
                    <div className="db-right-inner">
                      <img
                        src={Images.cross_red}
                        alt="cross_red icon"
                        className="img-fluid"
                      />
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(licenseDashboard);
