import React, { useState, useEffect } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  requestOTP,
  requestOTPV3,
  verifyOTP,
  verifyOTPV3,
} from "../../controller/api/customerAPI";
import { withTranslation } from "react-i18next";

const OTP = (props) => {
  const formRef = React.createRef();

  const [formFilled, setFormFilled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpUUID, setOtpUUID] = useState(undefined);

  const useV1 = !props.useApiV3;
  const { t, phone_number, email, visible, onClose, otp, transaction, onVerified } = props;
  
  useEffect(()=> {
    visible && resendOTP();
  }, [visible]);

  const resendOTP = () => {
    if (useV1) {
      requestOTP({ phone_number: phone_number }).then((response) => {
        formRef.current.setFieldsValue({ otp: response.data.otp });
        message.success("OTP Send Successfully.");
      });
    } else {
      requestOTPV3({
        phone: phone_number,
        email: email,
      }).then((res) => {
        setOtpUUID(res.data?.id)
        message.success("OTP Send Successfully.");
      });
    }
  };

  const onFormChange = (allFields) => {
    setFormFilled(allFields.every((item) => !!item.value))
  };

  const handleSubmit = () => {
    setButtonLoading(true)
    formRef.current.validateFields().then((values) => {
      values["transaction"] = transaction;
      values["phone_number"] = phone_number;
      if (useV1) {
        verifyOTP(values)
          .then(() => {
            setButtonLoading(false)
            onVerified();
          })
          .catch(() => {
            setButtonLoading(false)
            onClose();
          });
      } else {
        verifyOTPV3({ otp: values["otp"], otp_uuid: otpUUID })
          .then(() => {
            setButtonLoading(false)
            onVerified();
          })
          .catch(() => {
            setButtonLoading(false)
          });
      }
    });
  };

  return (
    <Modal
      title={t("transactionForm.stepTwo.verifyCode")}
      open={visible}
      className="main-modal main-modal draw-sign-modal footer-btn-document-modal referral-modal"
      destroyOnClose={true}
      onCancel={() => onClose()}
      width="31%"
      centered
      footer={[
        <Button
          className="reset-button"
          style={{ opacity: buttonLoading ? "0.5" : "1" }}
          onClick={() => resendOTP()}
          type={""}
          key="1"
        >
          {t("utils.resend")}
        </Button>,
        <Button
          loading={buttonLoading}
          disabled={!formFilled}
          onClick={() => handleSubmit()}
          type={"primary"}
          key="2"
        >
          {t("utils.submit")}
        </Button>,
      ]}
    // okButtonProps={{disabled: !formFilled}}
    >
      <div className="col-12">
        <div className="login_form_design w-100">
          <p>{t("transactionForm.stepTwo.warningMessage.otpContext")}</p>
          <Form
            ref={formRef}
            onFieldsChange={(changedFields, allFields) => {
              onFormChange(allFields);
            }}
          >
            <Form.Item
              name="otp"
              label={""}
              initialValue={otp}
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
              className={"group-floating-label"}
            >
              <Input
                placeholder={t("transactionForm.stepTwo.oneTimeAccessCode")}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );

}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(OTP)));
