import React, {useState, useMemo, useEffect} from "react";
import {message, Radio} from "antd";
import WorkIcon from '@mui/icons-material/Work';
import { getEmployments} from "../controller/api/customerAPI";
import { useTranslation} from "react-i18next";
import get from 'lodash/get';


export const EmploymentInformationDashboard = (props) => {
  const { t, i18n } = useTranslation();
  const lng = navigator.language;
    const {
      current_transaction
    } = props;

    const [tabValue, setTabValue] = useState('0')
    const [ employmentsData, setEmploymentsData] = useState([]);

  const onChange = (key) => {
    setTabValue(key.target.value)
  };

  const getEmploymentsInfo = async () => {
    const employments = await getEmployments({
      customer: current_transaction.customer
    }).catch((err) => {
      if (err.response) {
        Object.keys(err.response.data).forEach((e) => {
          message.error(`${e}: ${err.response.data[e]}`);
        });
      }
    }).finally(() => {});
    setEmploymentsData(employments?.data?.results);
  }


  const moneyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
  }


  const currentSelectedEmployment = useMemo(() => {
    return employmentsData && employmentsData?.find((i) => i.position === Number(tabValue))
  }, [employmentsData, tabValue])


  useEffect(() => {
      getEmploymentsInfo().then();
  }, []);


    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row align-items-center px-2">
            <div className="col-md-11">
              <div className="profile-card flex-align-center mb-3">

                <div className={"icon-circle-transparent"}>
                  <WorkIcon  style={{ width: '24px', height: '24px', color: '#22746b'}}/>
                </div>
                <div className="profile-info">
                  <span className="license-text text-uppercase">{t('employmentInformation.title')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="d-flex justify-content-center w-100">
              <Radio.Group
                  value={tabValue}
                  onChange={onChange}
                  style={{
                    marginBottom: 16,
                  }}
                  size={'large'}
                  className={'green-button-group'}
              >
                <Radio.Button value="0">{t('employmentInformation.currentTab')}</Radio.Button>
                <Radio.Button value="1">{t('employmentInformation.previousTab')}</Radio.Button>
                <Radio.Button value="2">{t('employmentInformation.secondPreviousTab')}</Radio.Button>
              </Radio.Group>
            </div>
            <div className={`col-12 address-container`}>
              <div>
                <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                  { tabValue === '0' ? (
                      <>
                        <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                          {t('employmentInformation.howDoYouGetPaid')}
                        </li>
                        {currentSelectedEmployment?.method_of_payment ? (
                            <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                              {currentSelectedEmployment?.method_of_payment}
                            </li>
                        ) : (
                            <li>-</li>
                        )}
                        <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                          {t('employmentInformation.employmentStatus')}
                        </li>
                        {currentSelectedEmployment?.employment_status ? (
                            <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                              {currentSelectedEmployment?.employment_status}
                            </li>
                        ) : (
                            <li>-</li>
                        )}
                      </>
                  ) : ('')}

                  <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                    {t('employmentInformation.employerName')}
                  </li>
                  {currentSelectedEmployment?.employer_name ? (
                      <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                        {currentSelectedEmployment?.employer_name}
                      </li>
                  ) : (
                      <li>-</li>
                  )}
                  <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                    {t('employmentInformation.occupation')}
                  </li>
                  {currentSelectedEmployment?.occupation ? (
                      <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                        {currentSelectedEmployment?.occupation}
                      </li>
                  ) : (
                      <li>-</li>
                  )}
                  <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                    {t('employmentInformation.phoneNumber')}
                  </li>
                  {currentSelectedEmployment?.phone ? (
                      <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                        {formatPhoneNumber(currentSelectedEmployment?.phone)}
                      </li>
                  ) : (
                      <li>-</li>
                  )}
                  <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                    {t('employmentInformation.employerAddress')}
                  </li>
                  {currentSelectedEmployment?.address ? (
                      <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                        {`${get(currentSelectedEmployment, "address", "-")} ${
                            currentSelectedEmployment?.suite
                                ? currentSelectedEmployment?.suite
                                : " "
                        } ${get(currentSelectedEmployment, "city", "-")}, ${get(
                            currentSelectedEmployment,
                            "state",
                            "-"
                        )} ${get(currentSelectedEmployment, "zip_code", "-")}`}
                      </li>
                  ) : (
                      <li>-</li>
                  )}
                  <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                    {t('employmentInformation.employmentDuration')}
                  </li>
                  {currentSelectedEmployment?.duration_years ? (
                      <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                        {
                          !currentSelectedEmployment?.duration_years ||
                          (currentSelectedEmployment?.duration_years === 0 && currentSelectedEmployment?.duration_months === 0) ? '-' :
                              `${currentSelectedEmployment?.duration_years} Years ${currentSelectedEmployment?.duration_months} Months`
                        }
                      </li>
                  ) : (
                      <li>-</li>
                  )}

                  {tabValue === '0' ? (
                      <>
                        <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                          {t('employmentInformation.grossIncome')}
                        </li>
                        {currentSelectedEmployment?.monthly_salary ? (
                            <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                              {
                                    `${moneyFormatter.format(currentSelectedEmployment?.monthly_salary)}`
                              }
                            </li>
                        ) : (
                            <li>-</li>
                        )}
                        <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                          {t('employmentInformation.payFrequency')}
                        </li>
                        {currentSelectedEmployment?.type ? (
                            <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                              {currentSelectedEmployment.type}
                            </li>
                        ) : (
                            <li>-</li>
                        )}
                      </>
                  ) : ('')}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}


export default EmploymentInformationDashboard;
