import React, { Component } from "react";
import { Checkbox, Modal } from "antd";
import { Image as Images } from "../Images";
import { withTranslation, Trans } from "react-i18next";
import {connect} from "react-redux";

class NewPrequalificationModal extends Component {
  state = {
    checked: false,
  };
  onChange = () => {
    this.setState((prevState) => ({ checked: !prevState.checked }));
  };

  render() {
    let isPrequal = this.props.type === "prequal";
    const { t } = this.props;
    return (
      <Modal
        title={t('creditFormsCard.addNewFormPopModal.title')}
        open={this.props.visible}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        cancelText={t('utils.cancel')}
        okText={t('creditFormsCard.addNewFormPopModal.title')}
        okButtonProps={{ disabled: !this.state.checked }}
        className="main-modal main-modal draw-sign-modal footer-btn-document-modal referral-modal"
        destroyOnClose={true}
        width="31%"
        centered
      >
        <div className="new-forms-modal">
          <div className="text-details flex-align-center">
            <img
              src={isPrequal ? Images.form_1 : Images.form_2}
              alt="license icon"
              className="img-fluid"
            />
            <div className="db-second">
              <span className="license-text d-inline-block w-100 ">
                {isPrequal ? t('creditFormsCard.preQualification') : t('creditFormsCard.hardCredit')}
              </span>
              <span className="license-status form-status d-inline-block w-100">
                {t('creditFormsCard.applicationForm')}
              </span>
            </div>
          </div>
          <div className="forms-para">
            {t('creditFormsCard.addNewFormPopModal.contentOne')}
          </div>
          <Checkbox
            checked={this.state.checked}
            onChange={this.onChange}
            className="forms-para"
          >
            {t('creditFormsCard.addNewFormPopModal.acknowledgeContent')}
          </Checkbox>
        </div>
      </Modal>
    );
  }
}


export default withTranslation()(NewPrequalificationModal);
