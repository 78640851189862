import React, { Component } from "react";
import { Button, message, Radio } from "antd";
import {
  addDealInfo,
  getTransactionOne,
  updateDealInfo,
} from "../../controller/api/customerAPI";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  methods,
} from "../../controller/Global";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import {
  PAYING_CASH,
  FINANCE,
  OUTSIDE_FINANCE_SOURCE,
  LEASE,
  UNDECIDED_DEAL_TYPE,
  DEAL_TYPES_ARR,
} from "../../controller/enums";
import { withTranslation } from "react-i18next";

class DealInfoStepTwo extends Component {
  formRef = React.createRef();

  state = {
    deal_type: "finance",
    cashModalVisible: false,
    fieldData: "",
  };

  onChange = (e) => {
    this.setState({
      deal_type: e.target.value,
    });
  };

  componentDidMount() {
    let { params } = this.props.match;
    if (params.method === methods.edit) {
      if (
        this.props.current_transaction &&
        this.props.current_transaction.id &&
        this.props.current_transaction.deal_info
      ) {
        this.setState({ ...this.props.current_transaction.deal_info });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.deal_info.id !== parseInt(params.id)) {
              message.error("Invalid URL for updating");
              history.push(routes.dashboard.self);
            } else {
              this.setState({ ...res.data.deal_info });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
    }
  }

  checkDealType = (values) => {
    if (values?.deal_type === LEASE) {
      values.sales_price = null;
      values.monthly_payment =
        values?.monthly_payment?.replace(/,/g, "") || null;
      values.down_payment = values?.down_payment?.replace(/,/g, "") || null;
    } else if (values?.deal_type === FINANCE) {
      values.sales_price = values?.sales_price?.replace(/,/g, "") || null;
      values.monthly_payment = null;
      values.down_payment = values?.down_payment?.replace(/,/g, "") || null;
    } else if (values?.deal_type === PAYING_CASH) {
      values.monthly_payment = null;
      values.down_payment = null;
      values.sales_price = values?.sales_price?.replace(/,/g, "") || null;
    } else if (values?.deal_type === OUTSIDE_FINANCE_SOURCE) {
      values.monthly_payment = null;
      values.down_payment = null;
      values.sales_price = values?.sales_price?.replace(/,/g, "") || null;
    } else if (values?.deal_type === UNDECIDED_DEAL_TYPE) {
      values.sales_price = null;
      values.monthly_payment = null;
      values.down_payment = null;
    }
    return values;
  };
  handleSubmit = () => {
    let { params } = this.props.match;

    const updatedValues = this.checkDealType({
      deal_type: this.state.deal_type,
      deal_agreed: false,
      transaction: this.props.transaction
    });

    if (params.method === methods.edit) {
      updateDealInfo(updatedValues, params.id)
          .then(() => {
            this.props.onSubmit();
          })
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(`${e}: ${err.response.data[e]}`);
              });
            }
          });
    } else {
      addDealInfo(updatedValues)
          .then(() => this.props.onSubmit())
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(err.response.data[e]);
              });
            }
          });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="transition-information-heading w-100 font-weight-bold mb-3">
              {t('dealInformation.dealForm.formTitle')}
            </div>
            <div className="row mx-0 radio-find-us">
              <Radio.Group
                className="w-100"
                onChange={this.onChange}
                value={this.state.deal_type}
              >

                {DEAL_TYPES_ARR.map((deal, index) => {
                  return (
                    <Radio
                      className="w-100 flex-align-center px-3"
                      value={deal.key}
                      key={index}
                    >
                      {deal.value}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </div>
          <div className="col-12">
            <div className="steps-action flex-align-center deal-info-main justify-content-between mx-0 w-100">
              <Button onClick={() => this.props.back()}> {t('utils.back')}</Button>
              <Button type="primary" onClick={() => this.handleSubmit()}>
                {t('utils.submit')}
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};


export default withTranslation()(connect(mapStateToProps)(withRouter(DealInfoStepTwo)));
