import React, { SVGProps } from 'react';


export interface IconBaseProps extends React.SVGAttributes<SVGElement> {
    children?: React.ReactNode;
    color?: string;
    width?: string | number;
    height?: string | number;
}
const VirtualLogo = ({ width, height } : IconBaseProps ) => {
    return (
        <>
            <svg width={ width ?? "200"} height={ height ?? "200" }  viewBox="0 0 350 350" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/*<path d="M27.6824 428L16.5224 402.8H19.4024L29.8424 426.524H28.2584L38.7704 402.8H41.4704L30.3104 428H27.6824ZM58.0877 428V402.8H60.7517V428H58.0877ZM81.911 428V402.8H91.343C93.479 402.8 95.315 403.148 96.851 403.844C98.387 404.516 99.563 405.5 100.379 406.796C101.219 408.068 101.639 409.616 101.639 411.44C101.639 413.216 101.219 414.752 100.379 416.048C99.563 417.32 98.387 418.304 96.851 419C95.315 419.672 93.479 420.008 91.343 420.008H83.387L84.575 418.784V428H81.911ZM99.119 428L92.639 418.856H95.519L102.035 428H99.119ZM84.575 419L83.387 417.74H91.271C93.791 417.74 95.699 417.188 96.995 416.084C98.315 414.98 98.975 413.432 98.975 411.44C98.975 409.424 98.315 407.864 96.995 406.76C95.699 405.656 93.791 405.104 91.271 405.104H83.387L84.575 403.844V419ZM125.431 428V405.104H116.575V402.8H136.951V405.104H128.095V428H125.431ZM163.827 428.216C160.611 428.216 158.079 427.292 156.231 425.444C154.383 423.596 153.459 420.86 153.459 417.236V402.8H156.123V417.128C156.123 420.104 156.795 422.3 158.139 423.716C159.483 425.132 161.379 425.84 163.827 425.84C166.299 425.84 168.207 425.132 169.551 423.716C170.895 422.3 171.567 420.104 171.567 417.128V402.8H174.159V417.236C174.159 420.86 173.235 423.596 171.387 425.444C169.563 427.292 167.043 428.216 163.827 428.216ZM190.587 428L202.107 402.8H204.735L216.255 428H213.447L202.863 404.348H203.943L193.359 428H190.587ZM195.123 421.268L195.915 419.108H210.567L211.359 421.268H195.123ZM233.384 428V402.8H236.048V425.696H250.16V428H233.384Z" fill="#E0E0E0"/>
                <path d="M294.209 414.14H306.305V418.82H294.209V414.14ZM294.641 428H288.809V402.8H307.853V407.48H294.641V428ZM324.761 428V402.8H330.593V428H324.761Z" fill="#96CDA6"/>*/}
                <path d="M3.63535 306.126C-12.5789 256.659 20.565 53.4525 203.129 124.315C205.392 125.193 207.383 126.998 208.436 129.185C276.97 271.557 128.635 381.026 7.52992 310.717C5.72029 309.666 4.28711 308.115 3.63535 306.126Z" fill="url(#paint0_linear_36_4181)"/>
                <path d="M171.498 9.92671C97.4901 28.3551 -34.4429 170.218 104.123 327.162C106.336 329.669 109.803 330.946 113.09 330.329C245.27 305.516 296.487 119.396 179.814 12.1825C177.561 10.1121 174.467 9.18737 171.498 9.92671Z" fill="url(#paint1_linear_36_4181)"/>
                <path d="M349.452 302.244C345.696 250.559 270.362 104.874 124.746 170.049C121.503 171.501 119.229 174.775 119.023 178.322C110.248 328.984 260.556 362.949 345.291 310.132C348.021 308.43 349.685 305.453 349.452 302.244Z" fill="url(#paint2_linear_36_4181)"/>
                <defs>
                    <linearGradient id="paint0_linear_36_4181" x1="119.962" y1="214.994" x2="186.021" y2="493.854" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#182E2D"/>
                        <stop offset="1" stopColor="white"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_36_4181" x1="40.6603" y1="195.209" x2="390.777" y2="115.785" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#96CDA6"/>
                        <stop offset="1" stopColor="white"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_36_4181" x1="234.127" y1="153.569" x2="379.564" y2="442.908" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#182E2D"/>
                        <stop offset="1" stopColor="white"/>
                    </linearGradient>
                </defs>
            </svg>
            <h6 className={/*`${montserrat.className} ${styles.title}*/ 'mt-1 text-center text-3xl md:text-4xl font-medium leading-normal' /*`*/} style={{ color: '#E0E0E0', letterSpacing: '0.5em' }}>
                EUNI<span className={/*`${montserratBold.className} ${styles.fi_text}*/ 'font-bold leading-normal'/*`*/} style={{ color: '#96CDA6', fontWeight: 550 }}>FI</span>
            </h6>
        </>
    )
}

export default VirtualLogo;