import React, { Component } from "react";
import { Button, Steps } from "antd";
import PersonalInformation from "./PersonalInformation";
import SideBar from "../sidebar/SideBar";
import { Image as Images } from "../Images";
import { confirm_status, methods } from "../../controller/Global";
import PopConfirm from "../modal/PopConfirm";
import { withTranslation } from "react-i18next";
import {connect} from "react-redux";



class PreQualificaitonApplicationMain extends Component {
  state = {
    visible: false,
    fixed: false,
    pop_confirm: null,
    customer_info: null,
    justFilled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    let { params } = this.props.match;
    document.getElementsByTagName("body")[0].classList.add("bg-white-fi");
    if (params.method === methods.edit) {
      this.setState({ pop_confirm: confirm_status.edit });
    } else {
      this.setState({ pop_confirm: confirm_status.add });
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  get_message = () => {
    const { t } = this.props;
    const { pop_confirm } = this.state;
    if (pop_confirm === confirm_status.add) {
      return t('preQualificationForm.popModal.addTitle');
    } else if (pop_confirm === confirm_status.edit) {
      return t('preQualificationForm.popModal.updatedTitle');
    } else {
      return t('preQualificationForm.popModal.exitTitle');
    }
  };

  handleExit = (visible, exit = false) => {
    if (exit) {
      this.setState({ pop_confirm: confirm_status.exit, visible });
    } else {
      this.setState({ visible });
    }
  };
  saveSecondStepData = (data) => {
    this.setState({ customer_info: data });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12 mx-auto">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 position-relative transition-info-div">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => this.handleExit(true, true)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('preQualificationForm.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mx-auto add-new-customer-div trade-div-tab step-width-33">
                      <PersonalInformation
                          transaction={this.props.match.params.trans_id}
                          close={() => this.handleExit(true, true)}
                          savePersonalInfo={(data) => this.saveSecondStepData(data)}
                          justfilled={this.state.justFilled}
                          customer_info={this.state.customer_info}
                          onSubmit={() => this.handleExit(true)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PopConfirm
            visible={this.state.visible}
            user={this.props.user}
            exit={this.state.pop_confirm === confirm_status.exit}
            handleExit={() => this.handleExit(false)}
          >
            {this.get_message()}
          </PopConfirm>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(PreQualificaitonApplicationMain);