import React, { Component } from "react";
import { Button, Form, Input, message } from "antd";
import { Image as Images } from "../Images";
import { MDBInput } from "mdbreact";
import { addNotes, getNotes } from "../../controller/api/customerAPI";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import $ from "jquery";
import { Get } from "../../controller/headerIntercepter";
import { DISASSOCIATED, DISASSOCIATED_CO_BUYER } from "../../controller/enums";
import { checkCreationDate } from "../../controller/Global";

const getInitials = function (name) {
  let parts = name.split(" ");
  let initials = "";
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials;
};

class MessageView extends Component {
  state = {
    current: 0,
    notes: [],
    characterLength: 160,
  };
  formRef = React.createRef();
  backTransaction = () => {
    history.push(routes.dashboard.self);
  };

  componentDidMount() {
    this.fetchNotes();
    let _ = this;
    $(document).ready(function () {
      $(".message-system-chat-col").on("scroll", function () {
        if ($(this).scrollTop() === 0) {
          _.loadMoreData();
        }
      });
    });
  }

  loadMoreData = () => {
    if (this.state.next) {
      Get(this.state.next, { id: this.props.selected }).then((response) => {
        this.setState({
          chatInfo: [...this.state.chatInfo, ...response.data.results],
          loading: false,
          next: response.data.next,
        });
      });
    }
  };

  fetchNotes = () => {
    getNotes({ transaction: this.props.match.params.trans_id }).then(
      (response) => {
        this.setState({ notes: response.data.results });
        let area = document.getElementsByClassName("message-chat-main-row")[0];
        area.scrollTo(0, area.scrollHeight);
      }
    );
  };

  handleTextArea = (e) => {
    const length = e.target.value.length;
    const currentLength = 160 - length;
    if (currentLength >= 0) {
      this.setState({ characterLength: currentLength });
    } else {
      this.setState({ warningText: true });
    }
  };

  handleSubmit = (values) => {
    values["transaction"] = this.props.match.params.trans_id;
    addNotes(values).then(() => {
      this.formRef.current.resetFields();
      message.success("Send message successfully");
      this.fetchNotes();
    });
  };

  checkDisassociatedStatus = (current_transaction) => {
    if (
      String(current_transaction.status) === DISASSOCIATED ||
      String(current_transaction.status) === DISASSOCIATED_CO_BUYER
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { notes, characterLength, warningText } = this.state;
    const { current_transaction } = this.props.location.state;
    const enabledTemp = checkCreationDate(current_transaction);
    const btnVisible = this.checkDisassociatedStatus(current_transaction);
    return (
      <React.Fragment>
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="row login-main-row main-row-width main-transition-div mx-auto">
            <div className="col-12">
              <div
                className={`row heading-fix-div add-transition-div-main ${
                  this.state.fixed && "nav-fix-div"
                }`}
              >
                <div className="col-12 transition-info-div position-relative">
                  <Button
                    className="bg-transparent border-0 p-0 back-btn-main position-absolute"
                    onClick={this.backTransaction}
                  >
                    <div className="back-btn-img-div">
                      <img
                        alt=""
                        className="img-fluid back-btn-black"
                        src={Images.back_btn_black}
                      />
                      <img
                        alt=""
                        className="img-fluid back-btn-primary"
                        src={Images.arrow_left_primary}
                      />
                    </div>
                    <span>Back</span>
                  </Button>
                  <span className="d-inline-block w-100 text-center">
                    Message
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 add-new-customer-div pt-3 px-0 shadow-none">
              <div className="row transition-information-div mb-4">
                <div className="col-12">
                  <div className="row message-chat-main-row">
                    <div className="col-12">
                      <div className="row date-details-row">
                        <div className="col-12 text-center">
                          <span>January 12, 2021</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          {notes.map((item, index) => {
                            return item.created_by_type === "customer" ? (
                              <div key={index} className="row">
                                <div className="col-12">
                                  <div className="row chat-sender-row chat-rec-row">
                                    <div className="col-12">
                                      <div className="row mx-0 user-chat-details-row">
                                        <div className="col-12">
                                          <h6>{item.subject}</h6>
                                          <ul className="list-inline mb-0">
                                            {item.message}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div key={index} className="row chat-sender-row ">
                                <div className="col-12">
                                  <div className="row avatar-div-row">
                                    <div className="avatar-tag text-uppercase d-flex align-items-center justify-content-center rounded-circle">
                                      {getInitials(`${item.created_by}`)}
                                    </div>
                                    <div className="avatar-name">
                                      {item.created_by}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="row mx-0 user-chat-details-row">
                                    <div className="col-12">
                                      <h6>{item.subject}</h6>
                                      <ul className="list-inline mb-0">
                                        {item.message}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row tech-support-form tech-support-form-fixed msg-support">
                    <Form
                      ref={this.formRef}
                      className={"w-100 tech-support-form"}
                      onFinish={this.handleSubmit}
                    >
                      <div className="row">
                        <div className="col-12">
                          <Form.Item
                            name={"subject"}
                            rules={[
                              {
                                required: false,
                                message: "Please input your subject!",
                              },
                            ]}
                            className={
                              // btnVisible
                              // ?
                              `select-float group-floating-label group-floating-label-second position-relative ${
                                btnVisible
                                  ? enabledTemp
                                    ? ""
                                    : "disable-msg-click-text"
                                  : "disable-msg-click-text"
                              }`
                              // : "select-float group-floating-label group-floating-label-second position-relative disable-msg-click-text"
                            }
                          >
                            <Input
                              placeholder=" "
                              suffix={
                                <label
                                  className="floating-label"
                                  htmlFor="subject"
                                >
                                  Subject
                                </label>
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 message-div-main">
                          <Form.Item
                            name={"message"}
                            rules={[
                              {
                                required: true,
                                message: "Please input your message!",
                              },
                            ]}
                            className={
                              // btnVisible
                              //   ?
                              `select-float group-floating-label group-floating-label-second position-relative ${
                                btnVisible
                                  ? enabledTemp
                                    ? ""
                                    : "disable-msg-click-text"
                                  : "disable-msg-click-text"
                              }`
                              // : "select-float group-floating-label group-floating-label-second position-relative disable-msg-click-text"
                            }
                          >
                            <MDBInput
                              onChange={this.handleTextArea}
                              type="textarea"
                              maxLength={"160"}
                              label="Message"
                              rows="5"
                            />
                          </Form.Item>
                          <small
                            style={{
                              color: "#818E9B",
                              fontSize: "12px",
                              lineHeight: "22px",
                              position: "relative",
                              top: "-25px",
                            }}
                          >
                            {" "}
                            {characterLength} Characters Left
                          </small>
                          {warningText && (
                            <small
                              style={{
                                color: "red",
                                fontSize: "12px",
                                lineHeight: "22px",
                                position: "relative",
                                top: "-10px",
                              }}
                            >
                              {" "}
                              maximum 160 character
                            </small>
                          )}
                        </div>
                        <div className="col-12 text-right">
                          <Form.Item>
                            {btnVisible ? (
                              enabledTemp ? (
                                <Button
                                  type={"primary"}
                                  htmlType="submit"
                                  className="send-btn send-msg-btn"
                                >
                                  Send
                                </Button>
                              ) : (
                                ""
                              )
                            ) : (
                              " "
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MessageView;
