import React, { Component } from "react";
import { Image as Images } from "./Images";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";


class PurchaseAcknowledgementDashboard extends Component {
  state = {
    newPreq: false,
    hardCredit: false,
    fActive: false,
  };

  render() {
    const { current_transaction, t } = this.props;

    return (
      <React.Fragment>
        <div className="row all-info-home-details">
          <div className="col-12">
            <div className="all-heading-home row align-items-center px-3">
              <div className="profile-card flex-align-center mb-3">
                <div className={"icon-circle-transparent"}>
                  <img
                      alt="add icon"
                      className="img-fluid"
                      src={Images.closingFormsIcon}
                  />
                </div>
                <div className="profile-info">
                  <span className="license-text">{t('closingFormCard.title')} </span>
                  <span className="license-status form-status font-weight-normal">{t('closingFormCard.description')} </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row all-inner-details p-0">
              <div className="col-12">
                <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between">
                  <div className="text-details flex-align-center">
                    <img
                      src={Images.document_purchase_acknowledgement}
                      alt="license icon"
                      className="img-fluid"
                    />
                    <div className="db-second">
                      <span className="license-text d-inline-block w-100">
                        {t('closingFormCard.purchaseAcknowledgement')}
                      </span>
                      <span className="license-status form-status d-inline-block w-100">
                        {t('closingFormCard.purchaseForm')}
                      </span>
                    </div>
                  </div>
                  <div className="ddb-right-main d-flex align-items-center">
                    <div className="db-right-inner w-100 ddb-right-inner-btn-arrow d-flex flex-row-reverse align-items-center justify-content-between">
                      <img
                        src={
                          current_transaction.purchase_acknowledgement
                            ?.signature
                            ? Images.check_green_icon
                            : Images.not_active_yet_icon
                        }
                        alt="check green icon"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction };
};


export default withTranslation()(connect(mapStateToProps)(PurchaseAcknowledgementDashboard));
