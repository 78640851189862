import React, { Component } from "react";
import { Modal } from "antd";
import {getAPIUrl} from "../../controller/Global";

class ApplicationDisclosure extends Component {
  render() {
    return (
      <React.Fragment>
        <Modal
            title={"Application Disclosure"}
            footer={false}
            centered
            open={this.props.visible}
            onOk={this.props.onClose}
            onCancel={this.props.onClose}
            closable={false}
            width={"600px"}
            className="privacy-policy-modal"
        >
          <iframe src={getAPIUrl('application_disclosure')} style={{width: '100%', height: '70vh'}}>
          </iframe>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ApplicationDisclosure;
