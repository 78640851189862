import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import TransactionDetail from "./TransactionDetail";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import { reverse } from "named-urls";
import SideBar from "./sidebar/SideBar";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import {
  setCurrentTransactionAction,
  setCurrentTransactionHistoryAction,
} from "../store/actions/transactionAction";
import Welcome from "./Welcome";
import { checkCreationDate } from "../controller/Global";
import VFILoginLogo from "../assets/img/VFILogo";
import { withTranslation, Trans, useTranslation } from "react-i18next";

const Dashboard = (props) => {
  const [toggle, setToggle] = useState(false)
  const { t, i18n } = useTranslation();


  const changeToggle = () => {
    setToggle(!toggle)
  };

 const getTransactionDetail = () => {
    const { current_transaction, current_transactions_type } = props;
    const isNintyDayOld = checkCreationDate(current_transaction);
    let userVisited = false;
    if(!current_transaction?.is_co_buyer && current_transaction?.photo_consent_image !== null || current_transaction?.is_co_buyer && current_transaction.co_buyer_photo_consent_image !== null) {
      userVisited = true
    }
    if (current_transaction) {
      if (
        (current_transaction?.is_co_buyer &&
          !current_transaction?.seen_by_co_buyer) ||
        (!current_transaction?.is_co_buyer &&
          !current_transaction?.seen_by_customer)
      ) {
        //condition for visible dashboard  without filling  6 steps in history transaction
        if (current_transactions_type === "history" || !isNintyDayOld || userVisited)
        {
          return (
            <TransactionDetail
              changeToggle={changeToggle}
              login_prompt_status={props?.user?.login_prompt_status}
              user={props?.user}
            />
          );
        } else {
          history.push(
            reverse(routes.dashboard.transaction.transaction_widget, {
              trans_id: current_transaction.id,
            })
          );
        }
      } else {
        return (
          <TransactionDetail
            changeToggle={changeToggle}
            login_prompt_status={props?.user?.login_prompt_status}
            user={props?.user}
          />
        );
      }
    } else {
      return (
        <div className="no-transition-select h-100 d-flex align-items-center justify-content-center text-center">
          <div className="row">
            <div className="col-12">
              <Link to={routes.dashboard.self} className="d-inline-block">
                <VFILoginLogo />
              </Link>
            </div>
            <div className="col-12">
              <p className="mb-0">
                  {t('dashboard.dashboardContent')}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("dashboard-main");
    const preferenceLanguage = props.user.preference_language
    i18n.changeLanguage(preferenceLanguage).then(r => {
    });

  }, []);


    if (!props?.user?.last_login && props?.user?.default_dealership) {
      return <Welcome />;
    }
    return (
      <React.Fragment>
        {/*<CustomHeader/>*/}
        <div className="container-fluid main-common-div h-100 dashboard-main-inner-frist">
          <div className="container p-0 h-100">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <SideBar toggle={toggle} />
                {props?.user?.available_dealerships?.length && (
                  <div className="right-side-div-main h-100">
                    {getTransactionDetail()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }


const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
    transactions: state.transactions.transactions,
    current_transactions_type: state.current_transactions_type,
  };
};

export default connect(mapStateToProps, {
  setCurrentTransactionAction,
  setCurrentTransactionHistoryAction,
})(Dashboard);
