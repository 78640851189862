import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import {
  addInsurance,
  getInsurance,
  updateInsurance,
} from "../controller/api/customerAPI";
import { connect } from "react-redux";
import SideBar from "./sidebar/SideBar";
import { Image as Images } from "./Images";
import { confirm_status, methods } from "../controller/Global";
import PopConfirm from "./modal/PopConfirm";

import SendLink from "./Atoms/SendLink/SendLink.tsx";
import mime from "mime-types";
import CaptureInsuranceDoc from "./Atoms/CaptureDoc/CaptureInsuranceDoc";
import InsuranceDocForm from "./Atoms/IdentityDocForm/InsuranceDocForm";
import {useTranslation} from "react-i18next";
import moment from "moment";

export const InsuranceAdd = (props) => {
  const formRef = React.createRef();
  const [btnLoading, setBtnLoading] = useState(false);
  const [hideSidebar, setHideSideBar] = useState(false);
  const [onRevokeObjectURL, setOnRevokeObjectURL] = useState(false);
  const [selectedEffective, setSelectedEffective] = useState(null);
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [popConfirm, setPopConfirm] = useState("");
  const [popVisible, setPopVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const lng = navigator.language;

  const [insuranceInfo, setInsuranceInfo] = useState({
    id: null,
    insuranceImage: null,
    expiration_date: {
      day: null,
      month: null,
      year: null,
    },
    effective_date: {
      day: null,
      month: null,
      year: null,
    },
    policy_number: null,
    provider: null,
  });



  useEffect(() => {
    if (props.match.params.method === methods.edit) {
      getInsurance(props.match.params.trans_id)
        .then((response) => {
          const insurance = response.data.results[0];
          const expirationDate = new Date(insurance.expiration_date);
          const effectiveDate = new Date(insurance.effective_date);

          setInsuranceInfo({
            id: insurance.id,
            insuranceImage: insurance.front_image,
            expiration_date: {
              day: expirationDate.getUTCDate(),
              month: expirationDate.getUTCMonth() + 1,
              year: expirationDate.getUTCFullYear(),
            },
            effective_date: {
              day: effectiveDate.getUTCDate(),
              month: effectiveDate.getUTCMonth() + 1,
              year: effectiveDate.getUTCFullYear(),
            },
            policy_number: insurance.policy_number,
            provider: insurance.provider,
          });
        })
        .catch(() => {
          message.error("Error adding insurance, please try again.");
        });
    }
  }, []);

  const preFillForm = () => {
    if (insuranceInfo.provider !== null) {
      const expiration_date = moment(`${insuranceInfo.expiration_date?.month}/${insuranceInfo.expiration_date?.day}/${insuranceInfo.expiration_date?.year}`, "MM/DD/YYYY").isValid()
        ? moment(`${insuranceInfo.expiration_date?.month}/${insuranceInfo.expiration_date?.day}/${insuranceInfo.expiration_date?.year}`, "MM/DD/YYYY")
        : null
      const effective_date = moment(`${insuranceInfo.effective_date?.month}/${insuranceInfo.effective_date?.day}/${insuranceInfo.effective_date?.year}`, "MM/DD/YYYY").isValid()
        ? moment(`${insuranceInfo.effective_date?.month}/${insuranceInfo.effective_date?.day}/${insuranceInfo.effective_date?.year}`, "MM/DD/YYYY")
        : null

      setSelectedExpiry({
        year: insuranceInfo.expiration_date?.year,
        month: insuranceInfo.expiration_date?.month,
        day: insuranceInfo.expiration_date?.day
      });
      setSelectedEffective({
        year: insuranceInfo.effective_date?.year,
        month: insuranceInfo.effective_date?.month,
        day: insuranceInfo.effective_date?.day
      });
      formRef.current.setFieldsValue({
        effective_date: effective_date,
        expiration_date: expiration_date,
        policy_number: insuranceInfo.policy_number,
        provider: insuranceInfo.provider,
      });
    }
  };

  const handleSubmit = () => {
    const { insuranceImage } = insuranceInfo;
    const { params } = props.match;

    formRef.current
      .validateFields()
      .then((values) => {
        let form_data = new FormData();
        if (moment(`${selectedExpiry?.month}/${selectedExpiry?.day}/${selectedExpiry?.year}`, "MM/DD/YYYY").isValid()) {
         values.expiration_date = new Date(
            `${selectedExpiry.year}-${selectedExpiry.month}-${selectedExpiry.day}`
          ).toISOString();
        }
        if (moment(`${selectedEffective?.month}/${selectedEffective?.day}/${selectedEffective?.year}`, "MM/DD/YYYY").isValid()) {
          values.effective_date = new Date(
            `${selectedEffective.year}-${selectedEffective.month}-${selectedEffective.day}`
          ).toISOString();
        }

        if (insuranceImage) {
          if (typeof insuranceImage !== "string") {
            form_data.append(
              "front_image",
              insuranceImage,
              `image1.${mime.extension(insuranceImage.type)}`
            );
          }
        }

        for (let key in values) {
          values[key] && form_data.append(key, values[key]);
        }

        form_data.append("transaction", params.trans_id);
        if (insuranceImage) {
          if (params.method === methods.edit) {
            updateInsurance(form_data, insuranceInfo.id).then(() => {
              setBtnLoading(false);
              setPopConfirm(confirm_status.edit);
              handleExit(true, false);
            });
          } else {
            addInsurance(form_data)
              .then(() => {
                setBtnLoading(false);
                setPopConfirm(confirm_status.add);
                handleExit(true, false);
              })
              .catch((err) => {
                setBtnLoading(false);
                if (err.response) {
                  Object.keys(err.response.data).forEach((e) => {
                    message.error(err.response.data[e]);
                  });
                }
              })
              .finally(() => {
                setBtnLoading(false);
                setOnRevokeObjectURL(true);
              });
          }
        } else {
          message.error("Error adding insurance, please try again.");
          setBtnLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setBtnLoading(false);
      });
  };

  const handleExit = (visible, exit = false) => {
    if (exit) {
      setPopConfirm(confirm_status.exit);
      setPopVisible(visible);
    } else {
      setPopVisible(visible);
    }
  };
  const get_message = () => {
    if (popConfirm === confirm_status.add) {
      return t('insuranceInformation.popModal.addTitle');
    } else if (popConfirm === confirm_status.edit) {
      return t('insuranceInformation.popModal.updatedTitle');
    } else {
      return t('insuranceInformation.popModal.exitTitle');
    }
  };

  useEffect(() => {
    preFillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceInfo]);

  return (
    <React.Fragment>
      {hideSidebar ? null : <SideBar />}

      <div className="container-fluid mt-66 main-inner-fluid">
        <div className="container">
          <div className="row mx-0 h-100 dashboard-main-inner-row">
            <div className="col-12 h-100 dashboard-inner-div p-0">
              <div className="right-side-div-main h-100">
                <div className="row mx-0">
                  <div className="col-12">
                    <div className="row heading-fix-div">
                      <div className="col-12 position-relative transition-info-div">
                        <Button
                          className="bg-transparent position-absolute border-0 p-0 back-btn-main"
                          onClick={() => handleExit(true, true)}
                        >
                          <div className="back-btn-img-div">
                            <img
                              alt=""
                              className="img-fluid back-btn-black"
                              src={Images.back_btn_black}
                            />
                            <img
                              alt=""
                              className="img-fluid back-btn-primary"
                              src={Images.arrow_left_primary}
                            />
                          </div>
                          <span>{t('utils.back')}</span>
                        </Button>
                        <span className="d-inline-block w-100 text-center">
                          {t('insuranceInformation.insuranceCard')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 add-new-customer-div mt-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="col-12 transition-information-div ">
                          <div className="transition-information-heading-container">
                            <div className="transition-information-heading font-weight-bold">
                              {t('insuranceInformation.insuranceInformationForm.title')}
                            </div>
                            {props?.current_transaction?.dealership.id && (
                              <SendLink
                                transId={props.match.params.trans_id}
                                dealershipId={
                                  props.current_transaction.dealership.id
                                }
                              />
                            )}
                          </div>

                          <p className="font-weight-normal">
                            {t('insuranceInformation.insuranceInformationForm.content')}
                          </p>

                          <div className="mg-20 mb-5">
                            <CaptureInsuranceDoc
                              setHideSideBar={setHideSideBar}
                              insuranceInfo={insuranceInfo}
                              setInsuranceInfo={setInsuranceInfo}
                              onRevokeObjectURL={onRevokeObjectURL}
                            />
                          </div>
                          <InsuranceDocForm
                            formRef={formRef}
                            handleSubmit={handleSubmit}
                            selectedEffective={selectedEffective}
                            setSelectedEffective={setSelectedEffective}
                            selectedExpiry={selectedExpiry}
                            setSelectedExpiry={setSelectedExpiry}
                          />
                        </div>
                        <div className="steps-action flex-align-center justify-content-end w-100">
                          <Button
                            loading={btnLoading}
                            htmlType="submit"
                            type="primary"
                            onClick={() => handleSubmit()}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopConfirm
        visible={popVisible}
        user={props.user}
        exit={popConfirm === confirm_status.exit}
        handleExit={() => handleExit(false)}
      >
        {get_message()}
      </PopConfirm>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
  };
};

export default connect(mapStateToProps)(InsuranceAdd);
