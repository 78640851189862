import React, {useEffect, useState} from "react";
import ScannerBorder from "../ScannerBorder/ScannerBorder";
import {Image as Images} from "../../Images";
import WarningPopModal from "../../modal/WarningPopModal";
import Camera from "../Camera/Camera";
import {Post} from "../../../controller/headerIntercepter";
import {getAPIUrl} from "../../../controller/Global";
import {message} from "antd";
import {useFilePicker} from "use-file-picker";
import {DateTime} from "luxon";


export type CaptureDocProps = {
    userInformation: any,
    setUserInformation: (agr: any) => void,
    setValidAddress: (arg: any) => void,
    onRevokeObjectURL: any,
    setHideSideBar?: (arg: boolean) => void,
    licenseFrontExist?: string,
    licenseBackExist?: string
    setIsLoading?: (arg: boolean) => void,
}

export const CaptureDoc = ({
   userInformation,
   setUserInformation,
   setValidAddress,
   onRevokeObjectURL,
   setHideSideBar,
   licenseFrontExist,
   licenseBackExist,
   setIsLoading,
} : CaptureDocProps) => {

    const [openFrontFileSelector, ] = useFilePicker({
        accept: 'image/*',
        multiple: false,
        readAs: 'ArrayBuffer',
        validators: [
            {
                validateBeforeParsing: async (config, plainFiles) => new Promise((res) => res()),
                validateAfterParsing: async (config, file, reader)=>  {
                    setUserInformation({
                        ...userInformation,
                        license_front: file,
                    })
                    setLicenseFront(file)
                    return  new Promise((res) => res());
                }
            }
        ]
    });
    const [openBackFileSelector, ] = useFilePicker({
        accept: 'image/*',
        multiple: false,
        readAs: 'ArrayBuffer',
        validators: [
            {
                validateBeforeParsing: async (config, plainFiles) => new Promise((res) => res()),

                validateAfterParsing: async (config, file, reader) => {
                    setUserInformation({
                        ...userInformation,
                        license_back: file,
                    })
                    setLicenseBack(file)
                    return  new Promise((res) => res());
                }
            }
        ]
    });

    const [licenseFront, setLicenseFront] = useState<Blob | undefined>();
    const [licenseBack, setLicenseBack] = useState<Blob | undefined>();
    const [openScanDocCamera, setOpenScanDocCamera] = useState<boolean>(false);
    const [warningModal, setWarningModal] = useState<boolean>(false);
    const [failedScanWarning, setFailedScanWarning] = useState<boolean>(false);

    const [openCameraFrontId, setOpenCameraFrontId] = useState<boolean>(false);
    const [openCameraBackId, setOpenCameraBackId] = useState<boolean>(false)

    const b64toBlob = (dataURI: string) => {
        let byteString = atob(dataURI.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    }

    const onGetCaptureImage = (image: string, type: string) => {

        const blobImag = b64toBlob(image)
       if (type === "frontId") {
           setLicenseFront(blobImag)
       } else {
           setLicenseBack(blobImag)
       }
    }


    const onGetUserInformationFromImageCapture = () => {
        if (licenseFront && licenseBack) {
            const form_data = new FormData();
            if (licenseFront) form_data.append("front", licenseFront, "image.jpeg" );
            if (licenseBack) form_data.append("back", licenseBack, "image.jpeg");

            setIsLoading && setIsLoading(true);

            Post(getAPIUrl("ocr_scan"), form_data)
                .then((response) => {
                    const res = response.data;
                    const height = `${Math.floor(res.height/12)}'${res.height%12}"`;

                    const formatDob = DateTime.fromFormat(res.dob,'MM/dd/yyyy');
                    const formatExpiryDate = DateTime.fromFormat(res.expiry_date,'MM/dd/yyyy');
                    const formatIssuedDate = DateTime.fromFormat(res.issued_date,'MM/dd/yyyy');
                    setUserInformation({
                        ...res,
                        height,
                        license_back: licenseBack,
                        license_front: licenseFront,
                        expiry_date: {
                            day: formatExpiryDate.day,
                            month: formatExpiryDate.month,
                            year: formatExpiryDate.year,
                        },
                        dob: {
                            day: formatDob.day,
                            month: formatDob.month,
                            year: formatDob.year,
                        },
                        issued_date: {
                            day: formatIssuedDate.day,
                            month: formatIssuedDate.month,
                            year: formatIssuedDate.year,
                        },
                    })
                    let zip = res.zip_code;
                    if (zip !== null && zip.substring(5,9) === '0000') {
                        zip = zip.substring(0,5)
                    }
                    setValidAddress({
                        city: res.city ?? null,
                        address: res.address ?? null,
                        zip_code: zip,
                        state: res.state ?? null,
                    })

                    setIsLoading && setIsLoading(false);

                })
                .catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).forEach((e) => {
                            message.error(err.response.data[e]);
                        });
                    }
                    setIsLoading && setIsLoading(false);
                })
        }
    }

    useEffect(() => {

        if (setHideSideBar) {
            setHideSideBar(openScanDocCamera)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openScanDocCamera, setHideSideBar])

    useEffect(() => {
        if (licenseFront && licenseBack){

            URL.revokeObjectURL(URL.createObjectURL(licenseFront));
            URL.revokeObjectURL(URL.createObjectURL(licenseBack));

            setUserInformation({
                license_back: licenseBack,
                license_front: licenseFront,
            })

            onGetUserInformationFromImageCapture()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onRevokeObjectURL, licenseFront, licenseBack])
    const frontCameraClicked = () => {
        openFrontFileSelector()
    }
    const backCameraClicked = () => {
        openBackFileSelector()
    }

    return (
        <>
            <div className="choose-file-div choose-file-div-file row">
                <div className="col-12 col-sm-6">
                    {licenseFrontExist && !licenseFront ? (
                        <div className="imageShowCase">
                            <img
                                src={ licenseFrontExist }
                                alt="license icon"
                                className="img-fluid"
                                width={"300px"}
                                onClick={() => frontCameraClicked()}
                            />
                        </div>
                    ) : licenseFront ? (
                        <div className="imageShowCase">
                            <img
                                src={URL.createObjectURL(licenseFront)}
                                alt="license icon"
                                className="img-fluid"
                                width={"300px"}
                                onClick={() => frontCameraClicked()}
                            />
                        </div>
                        ) : (
                        <ScannerBorder>
                            <div
                                className="d-flex flex-column justify-content-center align-items-center"
                                onClick={() => frontCameraClicked()}
                            >
                                <div className={'text-uppercase font-weight-bold camera-context'}>
                                    Click here
                                </div>
                                <div className={'camera-context mb-2'}>to capture</div>
                                <img
                                    src={Images.cameraIcon}
                                    alt="license icon"
                                    className="img-fluid mb-3"
                                    style={{ width: '24px' }}
                                />
                                <div
                                    className={'text-uppercase font-weight-bold'}
                                    style={{color: 'rgba(41, 107, 104, 1)'}}
                                >
                                    Front
                                </div>
                            </div>
                        </ScannerBorder>
                    )
                    }
                    <Camera
                        isOpen={openCameraFrontId}
                        onClose={() => {
                            setOpenCameraFrontId(false)
                            setOpenScanDocCamera(false)
                        }}
                        getImageCapture={(img: string) => onGetCaptureImage(img, "frontId")}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    {licenseBackExist && !licenseBack ? (
                            <div className="imageShowCase">
                                <img
                                    src={licenseBackExist}
                                    alt="license icon"
                                    className="img-fluid"
                                    onClick={() => backCameraClicked() }
                                />
                            </div>
                        ) : licenseBack ? (
                                <div className="imageShowCase">
                                    <img
                                        src={URL.createObjectURL(licenseBack)}
                                        alt="license icon"
                                        className="img-fluid"
                                        onClick={() => backCameraClicked() }
                                    />
                                </div>
                            ) : (
                        <ScannerBorder>
                            <div
                                className="d-flex flex-column justify-content-center align-items-center"
                                onClick={() => backCameraClicked()}
                            >
                                <div className={'text-uppercase font-weight-bold camera-context'}>
                                    Click here
                                </div>
                                <div className={'camera-context mb-2'}>to capture</div>
                                <img
                                    src={Images.cameraIcon}
                                    alt="license icon"
                                    className="img-fluid mb-3"
                                    style={{ width: '24px' }}
                                />
                                <div
                                    className={'text-uppercase font-weight-bold'}
                                    style={{color: 'rgba(41, 107, 104, 1)'}}
                                >
                                    Back
                                </div>
                            </div>
                        </ScannerBorder>
                    )

                    }
                    <Camera
                        isOpen={openCameraBackId}
                        onClose={() => {
                            setOpenCameraBackId(false)
                            setOpenScanDocCamera(false)
                        }}
                        getImageCapture={(img: string) => onGetCaptureImage(img, "backId")}
                    />
                </div>
            </div>
            <WarningPopModal
                title={"Before uploading a photo of your ID, please read below:"}
                subTitle={
                    " Please place your ID on flat and dark surface, capture the image without flash, and make sure the entire ID card is in the image (do not crop the edges)"
                }
                submitBtnTitle={"Scan ID"}
                onOpen={warningModal}
                onSubmit={() => {
                    setWarningModal(false)
                    setOpenScanDocCamera(true)

                }}
            />
            <WarningPopModal
                title={"Could not extract information!"}
                subTitle={" Please try again later"}
                submitBtnTitle={"OK"}
                onOpen={failedScanWarning}
                onSubmit={() => {
                    setFailedScanWarning(false)
                }}
            />
        </>
    );
};

export default CaptureDoc;


