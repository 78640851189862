import {
  FETCH_HISTORY,
  FETCH_TRANSACTION,
  LOAD_MORE_HISTORY,
  LOAD_MORE_TRANSACTION,
  SET_CO_BUYER_VIEW,
  SET_CURRENT_TRANSACTION,
  SET_CURRENT_TRANSACTION_TYPE,
  UPDATE_TRANSACTION,
} from "../actions/transactionAction";

export const fetchTransactionReducer = (
  state = { transactions: [], next: null },
  { type, payload }
) => {
  switch (type) {
    case FETCH_TRANSACTION: {
      return { transactions: [...payload.results], next: payload.next };
    }
    case LOAD_MORE_TRANSACTION: {
      let { transactions } = state;
      return {
        transactions: [...transactions, ...payload.results],
        next: payload.next,
      };
    }

    case UPDATE_TRANSACTION: {
      let { transactions, next } = state;
      let trans_index = transactions.findIndex((o) => o.id === payload.id);
      transactions[trans_index] = payload;
      return { transactions: [...transactions], next: next };
    }
    default: {
      return state;
    }
  }
};

export const setCurrentTransactionReducer = (
  state = null,
  { type, payload }
) => {
  switch (type) {
    case SET_CURRENT_TRANSACTION: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
export const setCurrentTransactionHistoryReducer = (
  state = null,
  { type, payload }
) => {
  switch (type) {
    case SET_CURRENT_TRANSACTION_TYPE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
export const setCoBuyerViewReducer = (state = false, { type, payload }) => {
  switch (type) {
    case SET_CO_BUYER_VIEW: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const setHistoryReducer = (
  state = { history: [], next: null },
  { type, payload }
) => {
  switch (type) {
    case FETCH_HISTORY: {
      return { history: [...payload.results], next: payload.next };
    }
    case LOAD_MORE_HISTORY: {
      let { history } = state;
      return { history: [...history, ...payload.results], next: payload.next };
    }
    default: {
      return state;
    }
  }
};
