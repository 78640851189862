import React, { Component } from "react";
import { Image as Images } from "./Images";
import {
  DISASSOCIATED,
  DISASSOCIATED_CO_BUYER,
  DELIVERED,
} from "../controller/enums.js";

class Graph extends Component {
  checkTransacDiassociateStatus = (current_transaction) => {
    if (!current_transaction.is_co_buyer) {
      if (
        String(current_transaction.status) === DISASSOCIATED ||
        String(current_transaction.status) === DISASSOCIATED_CO_BUYER
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        String(current_transaction.status) === DISASSOCIATED ||
        String(current_transaction.status) === DISASSOCIATED_CO_BUYER
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  checkDeliveredStatus = (transaction) => {
    if (transaction.is_co_buyer) {
      if (
        transaction.status === DELIVERED ||
        transaction.is_delivered === true
      ) {
        return true;
      } else return false;
    } else {
      if (
        transaction.status === DELIVERED ||
        transaction.is_delivered === true ||
        transaction.purchase_acknowledgement
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  checkGraphPart = (transaction, Allbtnvisible, name) => {
    switch (name) {
      case "Forms":
        if (
          (transaction?.pre_qualification?.signature &&
            transaction.hard_credit_info?.signature) ||
          Allbtnvisible
        ) {
          return "active";
        } else {
          return;
        }
      case "ID":
        if (transaction?.license?.document?.length || Allbtnvisible) {
          return "active";
        } else {
          return;
        }

      default:
        if (transaction || Allbtnvisible) {
          return "active";
        } else {
          return;
        }
    }
  };

  render() {
    const { transaction, active, co_buyer_info } = this.props;
    const btnVisible = this.checkTransacDiassociateStatus(transaction);
    const Allbtnvisible = this.checkDeliveredStatus(transaction);
    if (!transaction.is_co_buyer && !co_buyer_info) {
      return (
        <div className="col-12 text-center graph-div-inner">
          <div
            className={`progress-bar-main-section ${
              active === 0 && "visible-overlay visible-overlay-rounded"
            }`}
          >
            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-1 ${this.checkGraphPart(
                      transaction.personal_information,
                      Allbtnvisible,
                      "Info"
                    )}`
                  : `progress-bar-sec progress-bar-sec-1 disassociated-gray ${
                      transaction.personal_information && "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.info_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.info_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Info</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-2 ${this.checkGraphPart(
                      transaction,
                      Allbtnvisible,
                      "ID"
                    )}`
                  : `progress-bar-sec progress-bar-sec-2 disassociated-gray ${
                      transaction.license && "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.id_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.id_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">ID</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-3 ${this.checkGraphPart(
                      transaction,
                      Allbtnvisible,
                      "Forms"
                    )}`
                  : `progress-bar-sec progress-bar-sec-3 disassociated-gray ${
                      transaction?.pre_qualification?.signature &&
                      transaction?.hard_credit_info?.signature &&
                      "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.forms_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.forms_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Forms</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-4 ${
                      transaction.is_co_buyer
                        ? this.checkGraphPart(
                            transaction.seen_by_co_buyer,
                            Allbtnvisible,
                            "Documents"
                          )
                        : this.checkGraphPart(
                            transaction.seen_by_customer,
                            Allbtnvisible,
                            "Documents"
                          )
                    }`
                  : `progress-bar-sec progress-bar-sec-4 disassociated-gray ${
                      transaction.is_co_buyer
                        ? transaction.seen_by_co_buyer
                          ? "active"
                          : ""
                        : transaction.seen_by_customer
                        ? "active"
                        : ""
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.docs_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.docs_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Documents</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-5 ${this.checkGraphPart(
                      transaction.vehicle_for_trade,
                      Allbtnvisible,
                      "Trade"
                    )}`
                  : `progress-bar-sec progress-bar-sec-5 disassociated-gray ${
                      transaction.vehicle_for_trade && "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.trade_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.trade_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Trade</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-6 ${this.checkGraphPart(
                      transaction.vehicle_for_purchase,
                      Allbtnvisible,
                      "Purchase"
                    )}`
                  : `progress-bar-sec progress-bar-sec-6 disassociated-gray ${
                      transaction.vehicle_for_purchase && "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.purchase_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.purchase_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Purchase</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-7 ${this.checkGraphPart(
                      transaction.deal_info,
                      Allbtnvisible,
                      "Deal"
                    )}`
                  : `progress-bar-sec progress-bar-sec-7 disassociated-gray ${
                      transaction.deal_info && "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.deal_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.deal_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Deal</h6>
                </div>
              </div>
            </div>

            <div
              className={
                btnVisible
                  ? `progress-bar-sec progress-bar-sec-8 ${this.checkGraphPart(
                      transaction.co_buyer,
                      Allbtnvisible,
                      "Co-Buyer"
                    )}`
                  : `progress-bar-sec progress-bar-sec-8 disassociated-gray ${
                      transaction.co_buyer && "active"
                    }`
              }
            >
              <div className="row mx-0">
                <div className="col-12 text-center">
                  <div className="icon-img-main">
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon gray-icon"
                      src={Images.co_byuer_gray_icon}
                    />
                    <img
                      alt="info-icon"
                      className="img-fluid main-icon white-icon"
                      src={Images.co_byuer_white_icon}
                    />
                  </div>
                  <h6 className="mb-0">Co-Buyer</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <div className="row mx-0 graph-div-main">
            <div className="col-12 text-center graph-div-inner">
              <div
                className={`progress-bar-main-section progress-bar-main-co-buyer ${
                  active === 0 && "visible-overlay visible-overlay-rounded"
                }`}
              >
                <div
                  className={
                    btnVisible
                      ? `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-1 ${
                          transaction &&
                          this.checkGraphPart(
                            transaction.personal_information,
                            Allbtnvisible,
                            "Info"
                          )
                        }`
                      : `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-1 disassociated ${
                          transaction.personal_information && "active"
                        }`
                  }
                >
                  <div className="row mx-0">
                    <div className="col-12 text-center">
                      <div className="icon-img-main icon-img-co-buyer-main">
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon gray-icon"
                          src={Images.info_gray_icon}
                        />
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon white-icon"
                          src={Images.info_white_icon}
                        />
                      </div>
                      <h6 className="mb-0">Info</h6>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    btnVisible
                      ? `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-2 ${
                          transaction &&
                          this.checkGraphPart(transaction, Allbtnvisible, "ID")
                        }`
                      : `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-2 disassociated ${
                          transaction.license && "active"
                        }`
                  }
                >
                  <div className="row mx-0">
                    <div className="col-12 text-center">
                      <div className="icon-img-main icon-img-co-buyer-main">
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon gray-icon"
                          src={Images.id_gray_icon}
                        />
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon white-icon"
                          src={Images.id_white_icon}
                        />
                      </div>
                      <h6 className="mb-0">ID</h6>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    btnVisible
                      ? `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-3 ${this.checkGraphPart(
                          transaction,
                          Allbtnvisible,
                          "Forms"
                        )}`
                      : `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-3 disassociated ${
                          transaction.pre_qualification &&
                          transaction.pre_qualification.signature &&
                          transaction.hard_credit_info &&
                          transaction.hard_credit_info.signature &&
                          "active"
                        }`
                  }
                >
                  <div className="row mx-0">
                    <div className="col-12 text-center">
                      <div className="icon-img-main icon-img-co-buyer-main">
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon gray-icon"
                          src={Images.forms_gray_icon}
                        />
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon white-icon"
                          src={Images.forms_white_icon}
                        />
                      </div>
                      <h6 className="mb-0">Forms</h6>
                    </div>
                  </div>
                </div>

                <div
                  className={
                    btnVisible
                      ? `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-4 ${
                          transaction &&
                          this.checkGraphPart(
                            transaction.seen_by_co_buyer,
                            Allbtnvisible,
                            "Documents"
                          )
                        }`
                      : `progress-bar-sec progress-bar-sec-co-buyer progress-bar-sec-4 disassociated ${
                          transaction.seen_by_co_buyer && "active"
                        }`
                  }
                >
                  <div className="row mx-0">
                    <div className="col-12 text-center">
                      <div className="icon-img-main icon-img-co-buyer-main">
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon gray-icon"
                          src={Images.docs_gray_icon}
                        />
                        <img
                          alt="info-icon"
                          className="img-fluid main-icon white-icon"
                          src={Images.docs_white_icon}
                        />
                      </div>
                      <h6 className="mb-0">Documents</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Graph;
