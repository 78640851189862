import React, { Component } from "react";
import { Button, Checkbox, message, Upload } from "antd";
import {
  updateTransaction,
  UpdateTransactionSeen,
} from "../../controller/api/customerAPI";
import TransactionAddConfirmation from "./TransactionAddConfirmation";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {withTranslation} from "react-i18next";

class StepsSixPhotoUpload extends Component {
  state = {
    profile_photo: null,
    checked: false,
    loading: false,
  };

  onChange = () => this.setState({ checked: !this.state.checked });

  handleChange = (info, state) => {
    this.setState({ [state]: info.file });
  };

  handleSubmit = () => {
    const { profile_photo } = this.state;
    let form_data = new FormData();
    if (this.props.transaction.is_co_buyer) {
      profile_photo &&
        form_data.append("co_buyer_photo_consent_image", profile_photo);
    } else {
      profile_photo && form_data.append("photo_consent_image", profile_photo);
    }
    this.setState({ loading: true });
    if (profile_photo) {
      const { transaction } = this.props;
      updateTransaction(
        this.props.user.default_dealership,
        transaction.id,
        form_data
      )
        .then(() => {
          UpdateTransactionSeen(
            this.props.user.default_dealership,
            transaction.id
          ).then(() => {
            this.setState({ visible: true, loading: false });
          });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
            this.setState({ loading: false });
          }
        });
    }
  };

  render() {
    const { checked, loading } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="col-12 transition-information-div mb-4">
          <div className="transition-information-heading w-100 font-weight-bold mb-3">
            { t('transactionForm.stepFive.title')}
          </div>
          <div className="mb-2 verify-identy-small-div w-100">
            { t('transactionForm.stepFive.subTitle')}
          </div>
          <div className="transition-check-box w-100 mt-3">
            <Checkbox checked={checked} onChange={this.onChange}>
              { t('transactionForm.stepFive.contextOne')}
              <br/>
              <div className="mt-4">
                { t('transactionForm.stepFive.contextTwo')}
              </div>
            </Checkbox>
          </div>
          <div className="choose-file-div w-100 margin-bottom-45">
            <Upload
                onChange={(obj) => this.handleChange(obj, "profile_photo")}
                disabled={!checked}
                multiple={false}
                beforeUpload={() => false}
                // fileList={this.state.registration_front}
            >
              <Button className="text-uppercase">{ t('transactionForm.stepFive.upload')}</Button>
            </Upload>
          </div>
          <TransactionAddConfirmation
              is_co_buyer={this.props.transaction.is_co_buyer}
              match={this.props.match}
              visible={this.state.visible}
          />
        </div>
        <div className="steps-action flex-align-center justify-content-between w-100">
        <Button onClick={() => this.props.prev()}>{ t('utils.back')}</Button>
          <Button
            loading={loading}
            disabled={
              !this.state.profile_photo ||
              this.state.profile_photo.status === "removed" ||
              !checked
            }
            onClick={() => this.handleSubmit()}
            type="primary"
          >
            { t('utils.submit')}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};


export default withTranslation()(connect(mapStateToProps, { UpdateTransactionSeen })(withRouter(StepsSixPhotoUpload)));