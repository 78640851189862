import React, { useRef, useCallback, useState } from "react";
import { Modal } from "antd";
import Webcam from "react-webcam";
import "../BlinkIdScanner/css/styles.css"
import {Image as Images} from "../../Images";



export type CameraProps = {
    isOpen: boolean,
    onClose: () => void,
    getImageCapture: (arg: string) => void
}


export const Camera = ({ isOpen, onClose, getImageCapture} : CameraProps ) => {
    const videoRef = useRef<Webcam | null >(null);
    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

    const videoConstraints = {
        width: { min: 1000, ideal: 1920, max: 1920 },
        facingMode: FACING_MODE_USER
    };


    const capture = useCallback(
        () => {
            const imageSrc = videoRef.current?.getScreenshot();
            if (imageSrc) {
                getImageCapture(imageSrc);
                onClose()
            }


            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [videoRef]);

    const handleSwitchCam = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <Modal
                onCancel={onClose}
                title={""}
                onOk={() => {}}
                width={"1000"}
                open={isOpen}
                className="prompt-modal mx-0"
                footer={false}
                centered
            >
                <>
                    <Webcam
                        audio={false}
                        ref={videoRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            ...videoConstraints,
                            facingMode
                        }}
                        screenshotQuality={1}
                        imageSmoothing={false}
                    />
                    <div className="cam-btn-group">
                        <button className="capture-button"  onClick={capture}></button>
                        <button className="switch-cam-btn" onClick={handleSwitchCam}><img
                            alt=""
                            className="img-fluid back-btn-primary"
                            src={Images.arrowCircle}
                        />
                        </button>
                    </div>
                </>
            </Modal>
        </>
    );
};

export default Camera;