import React, { Component } from "react";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import { routes } from "../controller/routes";
import { methods } from "../controller/Global";
import { Image as Images } from "./Images";
import { connect } from "react-redux";
import { Button } from "antd";
import { history } from "../controller/history";
import NewPrequalificationModal from "./modal/newPrequalificationModal";
import { withTranslation, Trans } from "react-i18next";


class FormsDashboard extends Component {
  state = {
    newPreq: false,
    hardCredit: false,
    fActive: false,
  };

  activefunc = (fa) => {
    this.setState({ fActive: fa });
  };
  getMethod = (transaction) => {
    if (!transaction.pre_qualification) {
      return methods.add;
    } else if (
      transaction.pre_qualification &&
      !transaction.pre_qualification.signature
    ) {
      return methods.edit;
    } else if (
      !transaction.pre_qualification &&
      !transaction.pre_qualification.signature
    ) {
      return methods.add;
    }
  };

  render() {
    const { current_transaction, btnVisible, t } = this.props;
    const isCashOrOutFinance = this.props.checkDealTypeForCash();
    return (
      <React.Fragment>
        <div className="row all-info-home-details">
          <div className="col-12">
            <div className="all-heading-home row align-items-center px-3">
                <div className="profile-card flex-align-center mb-3">
                    <div className={"icon-circle-transparent"}>
                        <img
                            alt="add icon"
                            className="img-fluid"
                            src={Images.creditFormsIcon}
                        />
                    </div>
                    <div className="profile-info">
                        <span className="license-text">{t('creditFormsCard.title')}</span>
                    </div>
                </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row all-inner-details p-0">
              <div className="col-12">
                {current_transaction.dealership.pre_qual_activate ? (
                  !current_transaction.pre_qualification ||
                  !current_transaction.pre_qualification.signature ? (
                    <Link
                      to={reverse(
                        routes.dashboard.transaction.pre_qualification,
                        {
                          trans_id: current_transaction.id,
                          method: this.getMethod(current_transaction),
                        }
                      )}
                      className={
                        `row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between border-bottom-gray ${
                          btnVisible
                            ? !isCashOrOutFinance
                              ? ""
                              : "disable-pre-qualification-fill"
                            : "disable-pre-qualification-fill"
                        }`
                      }
                    >
                      <div className="text-details flex-align-center">
                        <img
                          src={Images.document_credit_forms}
                          alt="license icon"
                          className="img-fluid"
                        />

                        <div className="db-second">
                          <span className="license-text d-inline-block w-100">
                              {t('creditFormsCard.preQualification')}
                          </span>
                          <span className="license-status form-status d-inline-block w-100">
                              {t('creditFormsCard.applicationForm')}
                          </span>
                        </div>
                      </div>
                      <div className="ddb-right-main d-flex align-items-center">
                        <div
                          style={{ cursor: "pointer" }}
                          className="db-right-inner db-right-inner-btn ml-auto"
                        >
                          {btnVisible ? (
                            !isCashOrOutFinance ? (
                              <img
                                src={Images.arrow_right_gray}
                                alt="right_arrow_primary icon"
                                className="img-fluid"
                              />
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between border-bottom-gray">
                      <div className="text-details flex-align-center">
                        <img
                          src={Images.document_credit_forms}
                          alt="license icon"
                          className="img-fluid"
                        />
                        <div className="db-second">
                          <span className="license-text d-inline-block w-100">
                              {t('creditFormsCard.preQualification')}
                          </span>
                          <span className="license-status form-status d-inline-block w-100">
                              {t('creditFormsCard.applicationForm')}
                          </span>
                        </div>
                      </div>
                      <div className="ddb-right-main d-flex align-items-center">
                        <div className="db-right-inner w-100 ddb-right-inner-btn-arrow d-flex flex-row-reverse align-items-center justify-content-between">
                          {current_transaction.pre_qualification && (
                            <img
                              src={Images.check_green_icon}
                              alt="check green icon"
                              className="img-fluid"
                            />
                          )}
                          {btnVisible ? (
                            !isCashOrOutFinance ? (
                              <Button
                                onClick={() => this.setState({ newPreq: true })}
                                className="add-new-btn text-uppercase border-0"
                              >
                                  {t('creditFormsCard.addNew')}
                              </Button>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <Link
                    className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between border-bottom-gray qual-not-active"
                  >
                    <div className="text-details flex-align-center">
                      <img
                        src={Images.document_credit_forms}
                        alt="license icon"
                        className="img-fluid"
                      />

                      <div className="db-second">
                        <div className="prequal-not-active-feature">
                          {t('creditFormsCard.notActivated')}
                        </div>
                        <span className="license-text d-inline-block w-100">
                            {t('creditFormsCard.preQualification')}
                        </span>
                        <span className="license-status form-status d-inline-block w-100 ">
                          {t('creditFormsCard.applicationForm')}
                        </span>
                      </div>
                    </div>
                    <div className="ddb-right-main d-flex align-items-center">
                      <div
                        style={{ cursor: "pointer" }}
                        className="db-right-inner db-right-inner-btn ml-auto"
                      >
                        <img
                          src={Images.arrow_right_gray}
                          alt="right_arrow_primary icon"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </Link>
                )}
                {!current_transaction.hard_credit_info ||
                (current_transaction.hard_credit_info &&
                  !current_transaction.hard_credit_info.is_verified) ? (
                  <Link
                    to={
                      current_transaction.hard_credit_info
                        ? reverse(routes.dashboard.transaction.hard_credit, {
                            trans_id: current_transaction.id,
                            id: current_transaction.hard_credit_info.id,
                            method: methods.edit,
                          })
                        : reverse(routes.dashboard.transaction.hard_credit, {
                            trans_id: current_transaction.id,
                            method: methods.add,
                          })
                    }
                    className={
                      `row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between pb-0 ${
                        btnVisible
                          ? !isCashOrOutFinance
                            ? ""
                            : "disable-hard-credit-fill"
                          : "disable-hard-credit-fill"
                      }`
                    }
                  >
                    <div className="text-details flex-align-center">
                      <img
                        src={Images.document_credit_forms}
                        alt="license icon"
                        className="img-fluid"
                      />
                      <div className="db-second">
                        <span className="license-text d-inline-block w-100">
                          {t('creditFormsCard.hardCredit')}
                        </span>
                        <span className="license-status form-status d-inline-block w-100">
                          {t('creditFormsCard.applicationForm')}
                        </span>
                      </div>
                    </div>
                    <div className="ddb-right-main d-flex align-items-center">
                      <div className="db-right-inner db-right-inner-btn ml-auto">
                        {btnVisible ? (
                          !isCashOrOutFinance ? (
                            <img
                              src={Images.arrow_right_gray}
                              alt="right_arrow_primary icon"
                              className="img-fluid"
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="row ml-0 card-details-home icon-alignment card-details-second flex-align-center-between pb-0">
                    <div className="text-details flex-align-center">
                      <img
                        src={Images.document_credit_forms}
                        alt="license icon"
                        className="img-fluid"
                      />
                      <div className="db-second">
                        <span className="license-text d-inline-block w-100">
                              {t('creditFormsCard.hardCredit')}
                        </span>
                        <span className="license-status form-status d-inline-block w-100">
                          {t('creditFormsCard.applicationForm')}
                        </span>
                      </div>
                    </div>
                    <div className="ddb-right-main d-flex align-items-center">
                      <div className="db-right-inner w-100 ddb-right-inner-btn-arrow d-flex flex-row-reverse align-items-center justify-content-between">
                        {current_transaction.hard_credit_info &&
                          current_transaction.hard_credit_info.is_verified && (
                            <img
                              src={Images.check_green_icon}
                              alt="check green icon"
                              className="img-fluid"
                            />
                          )}
                        {btnVisible ? (
                          !isCashOrOutFinance ? (
                            <Button
                              onClick={() => {
                                current_transaction.hard_credit_info &&
                                !current_transaction.hard_credit_info
                                  .is_verified
                                  ? history.push(
                                      reverse(
                                        routes.dashboard.transaction
                                          .hard_credit,
                                        {
                                          trans_id: current_transaction.id,
                                          id: current_transaction
                                            .hard_credit_info.id,
                                          method: methods.edit,
                                        }
                                      )
                                    )
                                  : this.setState({ hardCredit: true });
                              }}
                              className="add-new-btn text-uppercase border-0"
                            >
                                {t('creditFormsCard.addNew')}
                            </Button>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <NewPrequalificationModal
          visible={this.state.newPreq}
          type={"prequal"}
          onOk={() =>
            history.push(
              reverse(routes.dashboard.transaction.pre_qualification, {
                trans_id: current_transaction.id,
                method: methods.add,
              })
            )
          }
          onCancel={() => {
            this.setState({ newPreq: false });
          }}
        />
        <NewPrequalificationModal
          visible={this.state.hardCredit}
          type={"hardCredit"}
          onOk={() =>
            history.push(
              reverse(routes.dashboard.transaction.hard_credit, {
                trans_id: current_transaction.id,
                method: methods.add,
              })
            )
          }
          onCancel={() => {
            this.setState({ hardCredit: false });
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction };
};


export default withTranslation()(connect(mapStateToProps)(FormsDashboard));
