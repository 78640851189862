export const spanish = {
    translation: {
        greeting: {
            hello: '¡Hola!'
        },
        utils: {
            yes: 'Sí',
            addNow: 'Sí, agregar ahora',
            no: 'No',
            back: 'Atrás',
            next: 'Siguiente',
            save: 'Ahorrar',
            update: 'Actualizar',
            submit: 'Envíar',
            add: 'Agregar',
            addNew: 'Agregar Nuevo',
            addMore: 'Agregar Más',
            cancel: 'Cancelar',
            close: 'Cerrar',
            clear: 'Limpiar',
            continue: 'Continuar',
            edit: 'Editar',
            remove: 'Eliminar',
            exitBtn: 'Sí, salír',
            skip: 'Saltar',
            resend: 'Reenviar',
            send: 'Enviar',
            sendRequest: 'Enviar petición',
            drawSignature: 'Dibujar firma',
            start: 'Comenzar',
            will_not_save: 'No se guardará nada de tu progreso',
            prefillContent: 'Su nombre completo, correo electrónico y número de teléfono celular se ' +
                'completan automáticamente',
            loadMore: 'Carga más',
            warningMessage: {
                InvalidURL: 'Enlace Inválido',
                fetchingError: 'Error al obtener los detalles de la transacción',
                inputRequired: "Este campo es obligatorio",
                minimumDigit: 'El dígito mínimo debe ser 10',
            }
        },
        personalInformation: {
            title: 'Información Personal',
            buyerTitle: 'Información del comprador',
            name: 'Nombre',
            phone: 'Teléfono',
            email: 'Correo Electrónico',
            firstNameLabel: 'Primer Nombre',
            middleNameLabel: 'Segundo Nombre',
            lastNameLabel: 'Apellido',
            suffixLabel: 'Sufijo',
            DOBLabel: 'Fecha de Nacimiento (mm/dd/yyyy)',
            cellPhoneNumberLabel: 'Teléfono Celular',
            popModal: {
                addTitle: '¡Información personal agregada!',
                updatedTitle: '¡Información personal guardada!',
                exitTitle: '¿Salir de agregar tu información?',
            }
        },
        addressInformation: {
            title: 'Información del Domicilio',
            address: 'Dirección',
            housingStatus: 'Estado de la Vivienda',
            timeAtAddress: 'Tiempo en la dirección',
            monthlyPayment: 'Salario Mensual',
            currentAddress: 'Dirección Actual',
            registerToAddress: 'Regístrese en la dirección',
            register_to_address_is_the_same_current_address: 'La dirección a registrar es mi dirección actual',
            streetAddressLabel: 'Dirección Completa',
            suiteLabel: 'Suite, Apt #',
            zipCodeLabel: 'Código Postal',
            zipHelperText: 'El Código Postal rellena la ciudad y el estado',
            cityLabel: 'Ciudad',
            stateLabel: 'Estado',
            current: 'Actual',
            previous: 'Anterior',
            secondPrevious: 'Previo a la Anterior',
            popModal: {
                addTitle: '¡Información de Dirección Agregada!',
                updatedTitle: '¡Información de Dirección Actualizada!',
                exitTitle: '¿Salir de agregar tu información?',
            }
        },
        employmentInformation: {
            title: 'Información de Empleo',
            howDoYouGetPaid: '¿Cómo recibes el pago?',
            employmentStatus: 'Estado de Empleo',
            employerName: 'Nombre del Empleador',
            occupation: 'Ocupación',
            phoneNumber: 'Teléfono',
            employerAddress: 'Dirección del Empleador',
            employmentDuration: 'Duración de Empleo',
            grossIncome: 'Ingreso Neto',
            payFrequency: 'Frecuencia de pago',
            currentTab: 'Actual',
            previousTab: 'Anterior',
            secondPreviousTab: 'Previo a la Anterior',
        },
        coBuyerInformation: {
            title: 'Co-Comprador',
            addCoBuyer: 'Agregar co-comprador',
            coBuyerInformation: 'Información del co-comprador',
            coBuyerFormStepOne: {
                formTitle: 'Paso 1: Enlace de invitación para el co-comprador',
                contentOne: 'El siguiente paso le solicita que ingrese el nombre y apellido, el correo ' +
                    'electrónico y el número de teléfono celular de su cocomprador.',
                contentTwo: 'Luego, el sistema generará y enviará automáticamente un enlace de invitación ' +
                    'por correo electrónico para que inicien el proceso.',
                contentThree: '¡Es <strong>Simple, Seguro y Confiable!</strong>'
            },
            coBuyerFormStepTwo: {
                formTitle: 'Paso 2: Información del Co-Comprador',
                firstNameLabel: 'Nombre(s)',
                lastNameLabel: 'Apellido',
                emailLabel: 'Correo Electrónico',
                cellPhoneNumberLabel: 'Teléfono Celular',
                contentOne: 'Al hacer clic en <strong>Enviar solicitud</strong>, le otorga al concesionario ' +
                    'o a su representante autorizado su consentimiento para enviar un correo electrónico al co-comprador en su nombre.',
                contentTwo: '<strong>Tenga en cuenta:</strong>Tenga en cuenta: Por motivos de seguridad, ' +
                    'toda la información confidencial del comprador y co-comprador se conserva por separado.'
            },
            popModal: {
                addTitle: '¡Se agregó información del cocomprador!',
                updatedTitle: '¡Información del cocomprador guardada!',
                exitTitle: '¿Salir de agregar la información de su cocomprador?',
                removeTitle: '¿Está seguro de que desea eliminar a su cocomprador?',
                removeSubTitle: 'No puedes deshacer esta acción.',
                inputAnotherEmail: 'Introduzca otra dirección de correo electrónico',
                warningMessage: 'No puedes agregarte como cocomprador',
                emailSentToCoBuyerMessage: 'Se ha enviado un correo electrónico al correo electrónico proporcionado para finalizar la configuración.',
                returnToTransactionButton: 'Volver a la transacción',
            }
        },
        dealInformation: {
            title: 'Información de la Oferta',
            dealType: 'Tipo de Oferta',
            dealForm: {
                formTitle: '¿Será arrendamiento, financiación o efectivo?',
                dealInfoChoice: {
                    lease: 'Alquiler',
                    finance: 'Finanzas',
                    cash: 'Efectivo',
                    outsideFinance: 'Fuente de Financiación Externa',
                    undecided: 'Indeciso',
                },
            },
            popModal: {
                addTitle: '¡Información de la oferta agregada!',
                updatedTitle: '¡Información de la oferta actualizada!',
                exitTitle: '¿Salir de agregar información de la oferta?',
            }
        },
        vehicleForPurchaseInformation: {
            title: 'Vehículo para Compra',
            vin: 'Número de Identificación del Vehículo',
            stockNo: 'Número de Existencias',
            make: 'Marca',
            model: 'Modelo',
            year: 'Año',
            trim: 'Siglas',
            condition: 'Condición',
            conditionOption: {
                selected: 'Seleccione una condición',
                new: 'Nuevo',
                preOwned: 'Usado',
            },
            vehicleForPurchaseForm: {
                vehicleForPurchaseFormStepOne: {
                    formTitle: 'Paso 1: ¿Conoce el número de inventario de vehículos del concesionario?',
                    subTitle: 'De lo contrario, déjelo en blanco y haga clic en Siguiente para continuar.',
                    stockNumber: 'Número de inventario',
                },
                vehicleForPurchaseFormStepTwo: {
                    formTitle: 'Paso 2: Información del vehículo',
                    subTitle: 'Verifique la siguiente información. Si no se obtuvieron resultados a través del número de stock, ' +
                        'ingrese la descripción del vehículo que desea comprar.',
                    vehicleInformation: 'Información del Vehículo',
                    conditionChoice: {
                        select: 'Seleccione una Condición',
                        new: 'Nuevo',
                        preowned: 'Seminuevo',
                    }
                },
            },
            popModal: {
                addTitle: '¡Vehículo para Compra, agregado!',
                updatedTitle: '¡Vehículo para Compra, actualizado!',
                exitTitle: '¿Salir de añadir un vehículo para compra?',
            }
        },
        vehicleForTradeInformation: {
            title: 'Vehículo para comercio',
            make: 'Marca',
            model: 'Modelo',
            year: 'Año',
            tradeRegistration: 'Registro comercial',
            vehicleForTradeForm: {
                vehicleForTradeFormStepOne: {
                    formTitle: 'Paso 1: Información comercial',
                    subTitle: '¿Tiene el registro de su vehículo a la mano?',
                },
                vehicleForTradeFormStepTwo: {
                    formTitle: 'Paso 2: Información del vehículo',
                    subTitle: 'Por favor ingrese o verifique la información a continuación:',
                    vinLabel: 'Número de Identificación del Vehículo',
                    makeLabel: 'Marca',
                    modelLabel: 'Modelo',
                    yearLabel: 'Año',
                    mileageLabel: 'Millaje',
                    areYouTheRegisteredOwner: '¿Es usted el propietario registrado?',
                    nameOfRegisteredOwnerLabel: 'Nombre del propietario registrado',
                    relationshipToTheRegisteredOwnerLabel: 'Relación con el propietario registrado',
                    stateLabel: 'Estado',
                },
                warningMessage: {
                    successfulAdd: '¡Vehículo para el comercio agregado con éxito!',
                    successfulUpdate: 'Vehículo para el comercio actualizado con éxito!',
                },
            },
            popModal: {
                exitTitle: '¿Salir de agregar información comercial?',
                tradeInfoAddedTitle: '¡Información comercial agregada!',
                tradeInfoAddedSubTitle: 'Ahora puede llamar a su prestamista y obtener el monto de ' +
                    '<strong>liquidación de su operación</strong>.',
                questionOne: '¿Tiene el título del vehículo?',
                questionTwo: '¿El vehículo está pagado?',
                questionThree: '¿Tiene prueba de que el vehículo está pagado?',
                tradeInfoAddedHelperText: '<strong>Recuerde</strong>: Puede cargar una imagen de cualquier documento ' +
                    'más tarde, ¡incluido el título del vehículo!'
            }
        },
        staffInformation: {
            title: 'Personal',
            dealerRep: 'Representante del Distribuidor',
            salesManager: 'Gerente de Ventas',
            fiManager: 'Gerente de Finanzas y Seguros',
        },
        personalReferenceInformation: {
            title: 'Referencia Personal',
            name: 'Nombre Completo',
            relationship: 'Relación',
            address: 'Dirección',
            phone: 'Teléfono',
            personalReferenceForm: {
                content: 'Por favor rellene el siguiente formulario',
                firstNameLabel: 'Primer Nombre',
                middleNameLabel: 'Segundo Nombre',
                lastNameLabel: 'Apellido',
                relationshipLabel: 'Relación',
                cellPhoneNumberLabel: 'Teléfono Celular',
                addressLabel: 'Dirección',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Código Postal',
                zipHelperText: 'El Código Postal rellena la ciudad y el estado',
                cityLabel: 'Ciudad',
                stateLabel: 'Estado',
            },
            popModal: {
                addTitle: '¡Referencia personal agregada!',
                updatedTitle: '¡Referencia personal actualizada!',
                exitTitle: '¿Salir de añadir tu información?',
            }
        },
        IdInformation: {
            title: 'Identificación',
            personalIdentification: 'Identificación Personal',
            driverLicense: 'Licencia de Conducir',
            stateId: 'Identificación del Estado',
            noDriverLicense: 'Sin Licencia de Conducir ni Identificación Estatal',
            valid: 'Válido',
            invalid: 'Inválido',
            idValidity: 'Validez de Identificación',
            noId: "Sin Licencia de Conducir ni Identificación Estatal",
            IdInformationForm: {
                Title: 'Información de Identificación',
                contentOne: 'Cargue fotos de su licencia de conducir o de su identificación emitida por el estado.',
                contentTwo: 'Su identificación se utiliza para identificarle y para detectar fraude. La ' +
                    'imagen se almacena de forma segura. ' +
                    'Además de por la seguridad de su información personal, también puede ser usado para la ' +
                    'financiación y el registro de su vehículo de motor.',
                selectIdTypeLabel: 'Seleccione Tipo de Identificación',
                idNumberLabel: 'Número de Identificación',
                stateLabel: 'Estado',
                dobLabel: 'Fecha de Nacimiento',
                idIssuedDateLabel: 'Fecha de Expedición de tu Identificación',
                idExpirationDateLabel: 'Fecha de Vencimiento de tu Identificación',
                firstNameLabel: 'Primer Nombre',
                middleNameLabel: 'Segundo Nombre',
                lastNameLabel: 'Apellido',
                suffixLabel: 'Sufijo',
                streetAddressLabel: 'Dirección Completa',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Código Postal',
                cityLabel: 'Ciudad',
                sexLabel: 'Sexo',
                heightLabel: 'Altura',
                eyeColorLabel: 'Color de los Ojos',
                zipHelperText: 'El Código Postal rellena la ciudad y el estado',
                idTypeChoice: {
                    captureId: 'Capturar ID',
                    driverLicense: 'Licencia de Conducir',
                    stateId: 'ID emitida por el estado',
                    otherId: 'Otro ID',
                },
                genderChoice: {
                    male: 'Masculino',
                    female: 'Femenino',
                    x: 'X',
                }
            }
        },
        insuranceInformation: {
            title: 'Seguro',
            insuranceCard: 'Tarjeta de seguro',
            noInsurance: 'No se agregó ningún seguro',
            insuranceFrom: 'Seguro de',
            valid: 'Vigente',
            expired: 'Caducado',
            insuranceInformationForm: {
                title: 'Información del Seguro',
                content: 'Por favor complete la información en los campos a continuación.',
                providerLabel: 'Proveedor',
                policyNumberLabel: 'Número de Póliza',
                effectiveDateLabel: 'Fecha de Vigencia',
                expirationDateLabel: 'Fecha de Vencimiento',
            },
            popModal: {
                addTitle: '¡Seguro agregado!',
                updatedTitle: '¡Seguro actualizado!',
                exitTitle: '¿Salir de agregar información del seguro?',
            }
        },
        preRequisiteFormsCard: {
            title: 'Formularios de Prerrequisitos',
            description: 'Descripción',
            privacyPolicy: 'Política de Privacidad',
            contactAcknowledgement: 'Contacto de Reconocimiento',
            identityTheftPrevention: 'Prevención del Robo de Identidad',
            acknowledgementDocument: 'Documento de Acuse de Recibo',
        },
        creditFormsCard: {
            title: 'Formularios de Crédito',
            addNew: 'Agregar Nuevo',
            notActivated: 'Característica no Activada',
            preQualification: 'Precalificación',
            hardCredit: 'Crédito Duro',
            applicationForm: 'Formulario de Aplicación',
            addNewFormPopModal: {
                title: 'Agregar Nuevo Formulario de Aplicación',
                contentOne: 'Esta función le brinda la opción de enviar un nuevo formulario de solicitud. ' +
                    'Los campos se completarán previamente con cualquier información ingresada previamente ' +
                    'para que usted pueda editarla o modificarla.',
                acknowledgeContent:
                    'Estoy modificando mi solicitud original debido a un error u omisión por mi parte. ' +
                    'Hago esto por mi propia voluntad y entiendo que esta información puede ser utilizada para ' +
                    'tomar una decisión crediticia por parte de un banco u otra entidad similar.',
            }

        },
        preQualificationForm: {
            title: 'Solicitud de Precalificación',
            contentOne: 'Esto le permite al concesionario ver su puntaje crediticio y verificar con precisión qué ' +
                'programas están disponibles para su compra.',
            contentTwo: '<strong>Nota:</strong> Esta acción no afectará su puntaje crediticio y no se considera una consulta ' +
                'exhaustiva en su expediente crediticio.',
            firstNameLabel: 'Primer Nombre',
            middleNameLabel: 'Segundo Nombre',
            lastNameLabel: 'Apellido',
            suffixLabel: 'Sufijo',
            DOBLabel: 'Fecha de Nacimiento (mm/dd/yyyy)',
            ssnLabel: 'SSN o ITIN',
            streetAddressLabel: 'Dirección Completa',
            suiteLabel: 'Suite, Apt #',
            zipCodeLabel: 'Código Postal',
            zipHelperText: 'El Código Postal rellena la ciudad y el estado',
            cityLabel: 'Ciudad',
            stateLabel: 'Estado',
            privacyPolicy: 'Política de Privacidad.',
            applicationDisclosure: 'Divulgación de la aplicación',
            and: 'y',
            commitmentPrivacy: {
                title: 'Nuestro Compromiso con la Privacidad',
                content: 'Toda la información almacenada en nuestra base de datos es segura y estrictamente confidencial. ' +
                    'Su información personal y crediticia solo se utilizará para cumplir con su solicitud y ' +
                    'de acuerdo con nuestra',
            },
            acknowledgementAndConsent: {
                title: 'Reconocimiento y Consentimiento',
                acknowledgementOne: 'Entiendo que esta no es una solicitud de crédito y ' +
                    'que la presentación no afectará la puntuación de crédito.',
                acknowledgementTwo: 'Reconozco que recibí un aviso de privacidad (firmado al momento ' +
                    'de la creación de la transacción)',
                acknowledgementThree: 'Al hacer clic en el botón "Enviar" y proporcionar una firma electrónica ' +
                    'a continuación, confirma que ha leído y comprendido la' ,
                acknowledgementFour: 'y usted proporciona instrucciones escritas para',
                acknowledgementFive: 'para obtener información de Experian y/o TransUnion y/o Equifax únicamente para solicitar una precalificación para crédito.',

                signature: 'Firma',
            },
            popModal: {
                addTitle: '¡Solicitud de Precalificación Enviada!',
                updatedTitle: '¡Solicitud de Precalificación Actualizada!',
                exitTitle: '¿Salir de Solicitud de Precalificación?',
            }
        },
        hardCreditForm: {
            title: 'Aplicación a Crédito Duro',
            hcFormStepOne: {
                title: 'Paso 1: Información Básica',
                content: 'Completar una solicitud de crédito le permite al concesionario enviar su ' +
                    'solicitud de crédito a las instituciones financieras para obtener la aprobación ' +
                    'del préstamo. ' +
                    'Si el concesionario presenta la solicitud de crédito, se considerará una consulta de crédito.',
                firstNameLabel: 'Primer Nombre',
                middleNameLabel: 'Segundo Nombre',
                lastNameLabel: 'Apellido',
                suffixLabel: 'Sufijo',
                DOBLabel: 'Fecha de Nacimiento (mm/dd/yyyy)',
                ssnLabel: 'SSN o ITIN',
                homePhoneNumberLabel: 'Teléfono',
                cellPhoneNumberLabel: 'Télefono Celular',
            },
            hcFormStepTwo: {
                title: 'Paso 2: Información del Domicilio',
                content_one: 'Se requieren sus ',
                content_two: ' años anteriores de vivienda,',
                which_address_are_you_using: '¿Está utilizando la dirección actual o se registra para ' +
                    'obtener la dirección de esta aplicación de crédito duro?',
                optional: '(Opcional)',
                mandatory: '(Obligatorio)',
                current: 'Actual',
                currentAddress: 'Direccion actual',
                registerToAddress: 'Regístrese en la dirección',
                currentAddressEmptyCardContent: 'Su dirección actual se mostrará aquí.',
                previous: 'Anterior',
                previousAddressEmptyCardContent: 'Su dirección anterior se mostrará aquí.',
                secondPrevious: 'Previo a la anterior',
                secondPreviousAddressEmptyCardContent: 'Su dirección previa a la anterior se mostrará aquí.',
                streetAddressLabel: 'Dirección Completa',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Código Postal',
                zipHelperText: 'El Código Postal rellena la ciudad y el estado',
                cityLabel: 'Ciudad',
                stateLabel: 'Estado',
                housingStatusLabel: 'Estado de la Vivienda',
                monthlyPaymentLabel: 'Salario Mensual',
                durationYearLabel: 'Duración en Año(s)',
                durationYearHelperText: '¿Cuántos años llevas viviendo en esta dirección?',
                durationMonthLabel: 'Duración en Mes(es)',
                durationMonthHelperText: '¿Cuántos meses llevas viviendo en esta dirección?',
                timeAtAddress: 'Tiempo en la Dirección',
                updateAddress: 'Actualizar Dirección',
                addAddress: 'Añadir Dirección',
                addInformation: 'Añadir Información',
                addInformationModal: {
                    title_one: 'Las direcciones que agregó no cubren ',
                    title_two: ' años de vivienda',
                    content_one: 'Agregue la primera y/o segunda dirección anterior para cubrir ',
                    content_two: ' años de vivienda.'
                },
                addMoreInformationModal: {
                    title: 'Las direcciones que agregó cubren los requisitos de vivienda.',
                    title_one: 'Las direcciones que agregó cubren ',
                    title_two: ' años de vivienda',
                    content: `"Por favor, toque "Siguiente" para pasar al siguiente paso. ` +
                        `De lo contrario, toque "agregar más" para agregar más información."`
                },
                addTimeInformationModal: {
                    content: 'Por favor ingrese una cantidad en años y/o meses'
                },
                warningMessage: {
                    cannotAdd: 'No se pueden agregar más de 3 direcciones',
                    fiveMinimumRequired: 'Ingrese una cantidad mínima de 5 dígitos'
                },
                options: {
                    rent: 'Rentar',
                    ownMortgage: 'Hipoteca Propia',
                    ownFree: 'Propio Libre y Claro',
                    other: 'Otro',
                    livingWithRelatives: 'Vivir con Familiares',
                    doNotPayRent: 'No Pago Alquiler'
                },
                addressTitle: {
                    current: 'Actual (obligatorio)',
                    previous: 'Anterior (opcional)',
                    secondPrevious: 'Segundo anterior (opcional)'
                }
            },
            hcFormStepThree: {
                title: 'Paso 3: Información de Empleo',
                contentOne: 'Anterior',
                contentTwo: 'Se requieren años de empleo.',
                optional: '(Opcional)',
                mandatory: '(Obligatorio)',
                current: 'Actual',
                currentAddressEmptyCardContent: 'Su dirección actual se mostrará aquí.',
                previous: 'Anterior',
                previousAddressEmptyCardContent: 'Su dirección anterior se mostrará aquí.',
                secondPrevious: 'Previa a la Anterior',
                secondPreviousAddressEmptyCardContent: 'Su dirección previa a la anterior se mostrará aquí.',
                howDoYouGetPaidLabel: '¿Cómo recibes el pago?',
                employmentStatusLabel: 'Estado de Empleo',
                employerNameLabel: 'Nombre del Empleador',
                occupationLabel: 'Ocupación',
                employerPhoneNumberLabel: 'Teléfono del Empleador',
                phoneNumber: 'Número de Teléfono',
                employmentDuration: 'Duración del Empleo',
                salary: 'Salario',
                type: 'Tipo',
                otherIncome: 'Otros Ingresos',
                otherIncomeType: 'Otro Tipo de Ingresos',
                employerAddressLabel: 'Dirección del Empleador',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Código Postal',
                zipHelperText: 'El Código Postal rellena la ciudad y el estado',
                cityLabel: 'Ciudad',
                stateLabel: 'Estado',
                durationYearLabel: 'Duración en Año(s)',
                durationYearHelperText: 'Ingrese la cantidad de años que ha estado trabajando en este empleo',
                durationMonthLabel: 'Duración en Mes(es)',
                durationMonthHelperText: 'Ingrese la cantidad de meses que ha estado trabajando en este empleo',
                grossIncomeLabel: 'Ingreso Neto',
                grossIncomeHelperText: 'Sus ganancias totales antes de impuestos u otras deducciones',
                payFrequencyLabel: 'Frecuencia de Pago',
                payFrequencyHelperText: '¿Con qué frecuencia te pagan?',
                otherIncomeSourceLabel: 'Otra fuente de ingresos',
                otherIncomeSourceHelperText: '¿Tiene otro trabajo/fuente de ingresos que le gustaría ' +
                    'agregar?',
                amountLabel: 'Cantidad',
                amountHelperText: '¿Cuánto gana en su otra fuente de ingresos?',
                monthAnnuallyLabel: 'Mensualmente, Anualmente...',
                monthAnnuallyHelperText: '¿Con qué frecuencia le pagan con su otra fuente de ingresos?',
                updateEmployment: 'Actualizar Empleo',
                addEmployment: 'Agregar Empleo',
                addInfoModal: {
                    addInformation: 'Agregar Información',
                    titleOne: 'La información de empleo que agregó no cubre',
                    titleTwo: 'años de empleo',
                    contentOne: 'Por favor agregue el primer y/o segundo empleo anterior para cubrir',
                    contentTwo: 'años de empleo',
                    enterAmount: 'Por favor ingrese una cantidad en años y/o meses',
                },
                addMoreModal: {
                    timeCover: "El historial laboral que agregó cubre los requisitos de empleo.",
                    notCover: 'El historial laboral que agregó cubre',
                    year: 'Años',
                    nextStepContent: 'Presione \'Siguiente \' para pasar al siguiente paso. ' +
                        'De lo contrario, presione \' agregar más \' para agregar más información.',
                },
                warningMessage: {
                    cannotAdd: 'No se pueden agregar más de 3 direcciones',
                    fiveMinimumRequired: 'Ingrese una cantidad mínima de 5 dígitos',
                    tenMinimumRequired: '"El dígito mínimo debe ser 10"',
                },
                options: {
                    unemployed: 'Desempleados',
                    employed: 'Empleados',
                    self_employed: 'Autónomo',
                    retired: 'Jubilado',
                    student: 'Estudiante/Aprendiz',
                    cash: 'Dinero en Efectivo',
                    check: 'Cheque',
                    weekly: 'Semanalmente',
                    biWeekly: 'Quincenal',
                    semiWeekly: 'Semi Mensual',
                    yearly: 'Anualmente',
                    annual: 'Anual',
                    monthly: 'Mensualmente',
                },
                addressTitle: {
                    current: 'Empleo actual (obligatorio)',
                    previous: 'Anterior (opcional)',
                    secondPrevious: 'Segundo anterior (opcional)'
                }
            },
            hcFormStepFour: {
                title: 'Paso 4: Presentar la Solicitud',
                subTitle: 'Por favor lea la información a continuación antes de aceptar.',
                commitmentToPrivacy: {
                    title: 'Nuestro compromiso con la privacidad',
                    content: 'Toda la información almacenada en nuestra base de datos es segura y ' +
                        'estrictamente confidencial. ' +
                        'Su información personal y crediticia solo se utilizará para cumplir con su ' +
                        'solicitud y de acuerdo con nuestra Política de Privacidad.'
                },
                acknowledgementAndConsent: {
                    title: 'Reconocimiento y Consentimiento',
                    acknowledgementOne: 'Al hacer clic en el botón "Enviar" y proporcionar una firma electrónica ' +
                        'a continuación, confirma que ha leído y comprendido la' ,
                    acknowledgementTwo: 'y usted proporciona instrucciones escritas para.',
                    acknowledgementThree: ',para obtener información de Experian y/o TransUnion y/o Equifax para solicitar una precalificación para crédito',
                    privacyPolicy: 'Política de Privacidad.',
                    applicationDisclosure: 'Divulgación de la aplicación',
                    and: 'y',
                    signature: 'Firma'
                },
            },
            popModal: {
                addTitle: '¡Solicitud de Crédito Enviada!',
                updatedTitle: '¡Solicitud de Crédito Actualizada!',
                exitTitle: '¿Salir de la solicitud de crédito?',
            }
        },
        closingFormCard: {
            title: 'Formularios de Cierre',
            description: 'Descripción',
            purchaseAcknowledgement: 'Acuse de Compra',
            purchaseForm: 'Formulario de Compra'
        },
        myDocumentCard: {
            title: 'Mi Documento',
            uploadDocument: {
                title: 'Subir Documentos',
                payStub: 'Comprobante de Pago',
                utilityBill: 'Factura de Servicios Públicos',
                identityTheftPrevention: 'Prevención del Robo de Identidad',
                other: 'Otro(s)'
            },
            viewDocument: 'Ver Documentos'
        },
        welcomePage: {
            welcomeText: 'Seleccionar inglés como idioma predeterminado cambiará la plataforma y los PDF específicos a inglés, ¿confirmar?',
            title: '¡Bienvenido!',
            subTitle: 'Estos son los pasos que seguirás para tener una cómoda compra de vehículo :',
            bulletOne: 'Complete todos los campos de información solicitados',
            bulletTwo: 'Firme electrónicamente el formulario de consentimiento de contacto electrónico (permite al personal\n' +
                'del concesionario llamarlo, enviarle un correo electrónico o enviarle un mensaje de texto).',
            bulletThree: 'Tome una foto de su licencia de conducir',
            bulletFour: 'Cargue cualquier documento de respaldo que pueda ser necesario.',
            content: '<strong>Firmas Electrónicas.</strong> Al hacer clic en \"Empecemos\" a continuación, el usuario \n' +
                'acepta que este consentimiento y cualquier otro documento que se entregará en relación con\n' +
                'esta solicitud pueden firmarse electrónicamente, y que cualquier\n' +
                'firma electrónica que aparezca en las solicitudes u otros documentos\n' +
                'es la misma que las firmas manuscritas para los fines de validez,\n' +
                'exigibilidad y admisibilidad.',
            getStart: 'Empecemos'

        },
        transactionForm: {
            stepOne: {
                title: 'Paso 1: Revise y reconozca nuestra Política de Privacidad',
                subTitle: 'Nuestro compromiso con la privacidad.',
                dealershipWeb: 'Sitio Web del Concesionario',
                questions: '¿Preguntas?',
                policySigned: 'Póliza firmada',
                drawSignature: 'Dibujar firma',
                otherImportantInfo: 'Otra Información Importante',
                context: 'Toda la información almacenada en nuestra base de datos es segura y estrictamente\n' +
                    '          confidencial. Su información personal y crediticia solo se utilizará\n' +
                    '          para cumplir con su solicitud y de acuerdo con nuestra Política de Privacidad\n' +
                    '          a continuación.',
                fact: 'Hechos',
                factContextOne: '¿Qué hacemos',
                factContextTwo: 'con tu información personal?',
                why: '¿Por qué?',
                whyContext:'Las empresas financieras eligen cómo comparten su\n' +
                    'información personal. La ley federal otorga a los consumidores el\n' +
                    'derecho de limitar parte del intercambio, pero no todo. La ley federal\n' +
                    'también nos exige que le digamos cómo recopilamos, compartimos\n' +
                    'y protegemos su información personal. Lea\n' +
                    'atentamente este aviso para comprender lo que hacemos.',
                what: '¿Qué?',
                whatContext: 'Los tipos de información personal que recopilamos y\n' +
                    'compartimos dependen del producto o servicio que tenga con\n' +
                    'nosotros. Esta información puede incluir',
                whatBulletOne: 'Número de Seguro Social e ingresos',
                whatBulletTwo: 'Saldos de cuentas e historial de pagos',
                whatBulletThree: 'Historial de crédito y puntaje de crédito',
                whatBulletFour: 'Cuando ya no sea nuestro cliente, continuaremos compartiendo\n' +
                    'su información como se describe en este aviso.',
                table: {
                    headerOne: 'Razones por las que podemos compartir su información personal',
                    headerTwo: '¿El concesionario comparte?',
                    headerThree: '¿Puede usted limitar este intercambio?',
                    yes: 'Sí',
                    no: 'No',
                    doNotShare: 'Nosotros no compartimos',
                    rowOne: {
                        columnHeader: 'Para nuestros propósitos comerciales cotidianos –',
                        columnOne: 'Por ejemplo, para procesar su transacción, mantener su(s) cuenta(s),\n' +
                            'responder a órdenes judiciales e investigaciones legales, o informar a las\n' +
                            'agencias de crédito.',
                    },
                    rowTwo: {
                        columnHeader: 'Para nuestros fines de marketing –',
                        columnOne: 'Para ofrecerle los servicios de nuestros productos',
                    },
                    rowThree: {
                        columnHeader: 'Por nuestro marketing conjunto con otras empresas financieras',
                    },
                    rowFour: {
                        columnHeader: 'Para los fines comerciales diarios de nuestros afiliados –',
                        columnOne: 'Información sobre tus transacciones y experiencias',
                    },
                    rowFive: {
                        columnHeader: 'Para que nuestros afiliados le comercialicen',
                    },
                    rowSix: {
                        columnHeader: 'Para que los no afiliados le comercialicen',
                    },
                },
                wvdTable: {
                    title: 'Que hacemos',
                    rowOne: {
                        columnHeaderOne: '¿Cómo ',
                        columnHeaderTwo: 'protegen mi información personal?',
                        context: 'To protect your personal information from unauthorized ' +
                            'access and use, we use security measures that comply with federal law.' +
                            '  These measures include computer safeguards, secured files, and buildings.',
                    },
                    rowTwo: {
                        columnHeaderOne: '¿Cómo ',
                        columnHeaderTwo: 'recopilan mi información?',
                        context: 'Recopilamos su información personal, por ejemplo, cuando usted',
                        bulletOne: 'Nos muestra su licencia de conducción',
                        bulletTwo: 'Nos paga con cheque',
                        bulletThree: 'Solicita un préstamo',
                        bulletFour: 'Solicita una financiación',
                        bulletFive: 'Proporcionar información de la cuenta',
                        bulletSix: 'También recopilamos su información personal de otros, como\n' +
                            '                agencias de crédito, afiliados u otras empresas.',
                    },
                    rowThree: {
                        columnHeaderOne: '¿Por qué no puedo limitar todo el uso compartido?',
                        context: 'La ley federal le otorga el derecho de limitar únicamente:',
                        bulletOne: 'Compartir para los fines comerciales cotidianos de los afiliados –\n' +
                            'información sobre su solvencia',
                        bulletTwo: 'Que los afiliados utilicen su información para comercializarle',
                        bulletThree: 'Compartir para que los no afiliados le comercialicen',
                        bulletFour: 'Las leyes estatales y las empresas individuales pueden otorgarle derechos\n' +
                            'adicionales para limitar el intercambio.',

                    },
                },
                defTable: {
                    title: 'Definiciones',
                    rowOne: {
                        columnHeader: 'Afiliados',
                        context: 'Sociedades relacionadas por propiedad o control común. Ellos pueden\n' +
                            'ser compañías financieras o no financieras.',
                        bulletOne: 'no tiene afiliados'
                    },
                    rowTwo: {
                        columnHeader: 'No afiliados',
                        context: 'Sociedades relacionadas por propiedad o control común. Ellos pueden\n' +
                            'ser compañías financieras o no financieras.',
                        bulletOne: 'no compartir con los no afiliados para que\n' +
                            'puedan comercializarle.'
                    },
                    rowThree: {
                        columnHeader: 'Comercialización Conjunta',
                        context: 'Un acuerdo formal entre compañías financieras no afiliadas que ' +
                            'juntas le comercializan productos o servicios financieros.',
                        bulletOne: 'no comercializa conjuntamente.'
                    },
                }
            },
            stepTwo: {
                title: 'Paso 2: Acuse de recibo de contacto',
                contextOne: 'En un esfuerzo por recopilar y procesar información de forma segura y rápida, ' +
                    'necesitamos su permiso para comunicarnos con usted en sus dispositivos personales.',
                contextTwo: 'Al enviar mi número de celular a continuación, doy mi consentimiento para recibir ' +
                    'mensajes de texto, correos electrónicos y llamadas telefónicas que pueden grabarse y/o enviarse' +
                    '  utilizando equipos o software de marcación automática de esta Compañía y sus afiliados.' +
                    ' Entiendo que puedo cancelar mi suscripción en cualquier momento. Usted acepta pagar las ' +
                    'tarifas de datos y mensajes de texto de su proveedor de servicios móviles, si corresponde.',
                cellPhone: 'Teléfono Celular',
                verifyCode: 'Código de verificación',
                oneTimeAccessCode: 'Código de acceso único',
                warningMessage: {
                    phoneError: 'Se produjo algún error, verifique el número.',
                    phoneRequired: 'El télefono celular es requerido',
                    minimumDigit: 'El dígito mínimo debe ser 10',
                    otpContext: 'Ingrese el código de acceso enviado por mensaje de texto a su número de teléfono y luego\n' +
                        '              haga clic/toque Enviar. Si no recibió el mensaje de texto, haga\n' +
                        '              clic/toque Reenviar.'
                },
            },
            stepThree: {
                title: 'Paso 3: Captura de identificación',
                context: 'Su identificación se utiliza para identificación y para detectar fraude.\n' +
                    'La imagen se almacena de forma segura. También se puede utilizar para el financiamiento y ' +
                    'registro de vehículos de su vehículo.',
                sendToPhone: 'Enviar al Teléfono',
                idTypeChoice: {
                    captureId: 'Capturar ID',
                    driverLicense: 'Licencia de Conducir',
                    stateId: 'ID emitida por el estado',
                    otherId: 'Otro ID',
                    skipForNow: 'Saltar por ahora'
                }

            },
            verifyIdStep: {
                title: 'Validar información de Identificación',
                subTitle: 'Por favor valide la información a continuación',
                warningMessage: {
                    pleaseReviewYorInfo: 'Revise la información capturada para asegurarse de que todo sea correcto. ' +
                        'Si alguna información necesita ser correcta, actualice la información.'
                },
            },
            stepFour: {
                title: 'Paso 4: Información de Dirección',
                subTitle: 'Por favor, rellene el formulario de abajo.',
                streetAddressLabel: 'Dirección Completa',
                addressLabel: 'Dirección',
                suiteLabel: 'Suite, Apt #',
                zipCodeLabel: 'Código Postal',
                zipHelperText: 'El Código Postal rellena la ciudad y el estado',
                cityLabel: 'Ciudad',
                stateLabel: 'Estado',
                address: 'Dirección',
                registerToAddress: 'Regístrese en la Dirección',
                registerToAddressSameAsCurrentAddress: 'La dirección de registro es la misma que mi ' +
                    'dirección actual',
                warningMessage: {
                    pleaseReviewYorInfo: 'Revise la información capturada para asegurarse de que todo sea correcto. ' +
                        'Si alguna información necesita ser correcta, actualice la información.'
                },
            },
            stepFive: {
                title: 'Paso 5: Prevención del Robo de Identidad',
                subTitle: 'Lea la información a continuación antes de cargar una foto de su rostro',
                contextOne: ' Al enviar mi fotografía, acepto que el Concesionario pueda usar\n' +
                    ' la fotografía/imagen proporcionada solo para fines legales, en este caso,\n' +
                    ' para identificar, procesar y proteger información para comprar un\n' +
                    ' automóvil.',
                contextTwo: 'Toda la información proporcionada es mía y ha sido preparada basándose\n' +
                    ' únicamente en mis representaciones. Nadie más me ha ayudado\n' +
                    ' con la información proporcionada y puede usarse en mi solicitud de crédito\n' +
                    ' si así lo decido.',
                upload: 'Subir',
                loadMore: 'Carga más',
                popModal: {
                    coBuyerAddedTitle: '¡Información del cocomprador agregada exitosamente!',
                    buyerAddedTitle: '¡Información de la transacción completada exitosamente!',
                    addedSubTitle: 'Haga clic en Continuar para obtener una guía visual del panel de la aplicación.'
                }
            },
        },
        dashboard: {
            dashboardContent: 'Seleccione una transacción a la izquierda para ver la información de la transacción.',
            techSupport: 'Apoyo Técnico',
            updateEmail: 'Actualizar correo electrónico',
            resetEmail: 'Restablecer la contraseña',
            signOut: 'Cerrar Sesión',
            currentEmail: 'Correo electrónico actual',
            newEmail: 'Nuevo Email',
            password: 'Contraseña',
            popModal: {
                dealInfoContext: 'En el siguiente paso, se le pedirá que responda algunas preguntas\n' +
                    ' sobre esta transacción.',
                disassociatedCoBuyer: 'Cocomprador disociado',
                trackYorProgress: 'Realice un seguimiento del progreso de su transacción',
                actionPersonalInfo: 'Ver o actualizar su información personal.',
                addCoBuyer: 'Agregue un cocomprador (si tiene uno)',
                doYouHaveCoBuyer: '¿Tiene un cocomprador?',
                addCoBuyerPop: ' Agregar su información de cocomprador ahora (si tiene una) le ahorrará tiempo en el concesionario.',
                addYourDeal: 'Agregue la información de su oferta.',
                addVehicleForPurchase: 'Agregue un vehículo para comprar.',
                addVehicleForTrade: 'Agregue un vehículo para el comercio.',
                addId: 'Agregue su identificación (licencia de conducir o identificación estatal).',
                trackYourPrerequisite: 'Realice un seguimiento de sus formularios de requisitos previos.',
                fillCreditForm: 'Complete, firme y envíe sus formularios de crédito.',
                trackClosingForm: 'Realice un seguimiento de su formulario de cierre.',
                uploadDocument: ' Cargue y vea los documentos de respaldo (recibos de pago, facturas de servicios públicos, etc.).',
                saveTime: '¡Ahorre tiempo más tarde!',
                completedCreditApp: 'Si no proporciona una solicitud de crédito completa ahora, el concesionario no ' +
                    'puede presentar una solicitud de crédito a las instituciones financieras.',
                start: 'Comenzar',
                preQualInfoContext: 'El siguiente paso le permite al distribuidor verificar con precisión qué ' +
                    'programas están disponibles para su compra.',
                creditAppInfoContext: 'Ahora consigamos su aprobación. En el siguiente paso, le proporcionará al ' +
                    'concesionario una solicitud de crédito.',
                will_you_trade_vehicle: '¿Estarás intercambiando un vehículo?',
                addVehicleSaveTime: '¡Ahorre tiempo más tarde! Agregue la información de su vehículo.',
                vehicleForPurchasePopContext: 'Proporcionar la información del vehículo ahora ayudará al concesionario ' +
                    'a buscar en múltiples inventarios, brindándole opciones adicionales para comparar.',
                dealerRequestedDoc: '¿Un representante del distribuidor ha solicitado algún documento en este momento?',
            }
        },
        techSupport: {
            title: 'Apoyo técnico',
            contentOne: 'como podemos ayudarte?',
            contentTwo: 'Estamos aquí para responder sus preguntas sobre Eunifi.',
            subjectLabel: 'Someter',
            messagePlaceHolder: 'Mensaje',
            messageTextHelper: 'Personajes restantes',
            messageWarningText: 'máximo 200 caracteres',

        }
    }
};