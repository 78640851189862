import React from "react";
import { Button, Modal } from "antd";
import { Image as Images } from "../Images";
export const SuccessPopModal = ({
        title,
        onSubmit,
        subTitle,
        submitBtnTitle,
        onOpen,
    }) => {
    return (
        <Modal
            width={"600px"}
            open={onOpen}
            className="prompt-modal close-btn-none"
            footer={false}
            centered
        >
            <div className="row prompt-modal-row">
                <div className="col-12 text-center">
                    <img
                        alt="icon question mark"
                        className="img-fluid"
                        src={Images.check_icon_rounded}
                    />

                    <h4 style={{ color: "rgba(41, 107, 104, 1)" }}>{title}</h4>
                    <p>{subTitle}</p>
                    <div className="row mx-0 align-items-center justify-content-between">
                        <Button onClick={onSubmit} type={"primary"} className="w-100">
                            {submitBtnTitle}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default SuccessPopModal;