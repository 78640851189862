import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { routes } from "../../controller/routes";
import { Image as Images } from "../Images";
import { Button, Form, Input, message, Popover, Select, Spin } from "antd";
import { history } from "../../controller/history";
import {
  fetchHistoryAction,
  fetchTransactionAction,
  loadMoreHistoryAction,
  loadMoreTransactionAction,
  setCoBuyerViewAction,
  setCurrentTransactionAction,
  updateTransactionAction,
  setCurrentTransactionHistoryAction,
} from "../../store/actions/transactionAction";
import {
  applyReferral,
  ChangePassword,
  getTransactionOne,
  ValidatePassword,
  ChangeEmail,
  getTransactionHistory,
} from "../../controller/api/customerAPI";
import {
  changeDefaultDealership,
  fetchProfileAction,
} from "../../store/actions/authAction";
import { resetPrompt } from "../../store/actions/promptAction";
import { toggleSidebarAction } from "../../store/actions/toggleAction";
import { DISASSOCIATED, DISASSOCIATED_CO_BUYER } from "../../controller/enums";
import NintyDaysExceedModal from "../modal/NintyDaysExceedModal";
import {checkCreationDate, transaction_status} from "../../controller/Global";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";


const { Option } = Select;

function mapStateToProps(state) {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
    transactions: state.transactions.transactions,
    next: state.transactions.next,
    historyTransaction: state.history.history,
    historyNext: state.history.next,
    sidebar: state.sidebar,
    current_transactions_type: state.current_transactions_type,
  };
}

const moment = require("moment");
const states = {
  check_password: "check_password",
  change_password: "change_password",
  email: "email",
  change_email: "change_email",
};

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.FormRef = React.createRef();
    this.wrapperRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      referral: false,
      password_state: states.email,
      btnLoading: false,
      visible: false,
      progressTransactionsLoading: false,
      historyTransactionsLoading: false,
      btnLoadingHistory: false,
    };
  }

  async componentDidMount() {
    this.props.fetchProfileAction();
    document.addEventListener("mousedown", this.handleClickOutside);
    const {
      user,
      current_transaction,
      transactions,
      historyTransaction,
      current_transactions_type,
    } = this.props;
    // var el = this.scrollRef.current;
    // SimpleScrollbar.initEl(el);
    if (!transactions.length) {
      this.props?.user?.available_dealerships?.length &&
        (await this.props.fetchTransactionAction(user.default_dealership, true));
    }
    if (current_transactions_type === "inprogress") {
      if (current_transaction) {
        getTransactionOne(user.default_dealership, current_transaction.id).then(
          (resp) => {
            this.props.updateTransactionAction(resp.data);
            this.props.setCurrentTransactionAction(resp.data);
          }
        );
      }
      // }
    } else {
      if (current_transaction) {
        getTransactionHistory(
          user.default_dealership,
          current_transaction.id
        ).then((resp) => {
          this.props.updateTransactionAction(resp.data);
          this.props.setCurrentTransactionAction(resp.data);
        });
      }
    }
    if (!historyTransaction.length)
      this.props?.user?.available_dealerships?.length &&
        (await this.props.fetchHistoryAction(user.default_dealership));
    if (!this.props.user.default_dealership) {
      this.referralShow(true);
    }

    // checking-any-transaction-active
    let current_dealership = this.props?.user?.available_dealerships?.filter(
      (x) => x.id === this.props?.user?.default_dealership
    );
    this.setState({
      is_active_transaction:
        current_dealership?.length &&
        current_dealership[0]?.is_active_transaction,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.props.sidebar.showSidebar &&
      event.target.offsetParent &&
      event.target.offsetParent.id !== "btn-menu"
    ) {
      this.props.toggleSidebarAction();
    }
  }

  changeTransaction = (current_transaction, transaction_history) => {
    this.props.setCurrentTransactionHistoryAction(transaction_history);
    this.props.setCurrentTransactionAction(current_transaction);
    this.props.setCoBuyerViewAction(false);
    this.props.resetPrompt();
    if (this.props.match.path !== "/transaction/:trans_id/license/:id?/:method") {
      history.push(routes.dashboard.self);
    }
  };

  handleLoadMoreTransactions = async () => {
    this.setState({ btnLoading: true });
    await this.props.loadMoreTransactionAction(this.props.next);
    this.setState({ btnLoading: false });
  };

  handleLoadMoreHistory = async () => {
    this.setState({ btnLoadingHistory: true });
    await this.props.loadMoreHistoryAction(this.props.historyNext);
    this.setState({ btnLoadingHistory: false });
  };

  referralShow = (visible) => {
    this.setState({ referral: visible });
  };

  CheckPassword = () => {
    this.FormRef.current.validateFields().then((values) => {
      ValidatePassword(values)
        .then(() => {
          this.setState({ password_state: states.change_password });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}:${err.response.data[e]}`);
            });
          }
          this.setState({ password_state: states.email });
        });
    });
  };

  PasswordChange = () => {
    this.FormRef.current.validateFields().then((values) => {
      ChangePassword(values)
        .then(() => {
          message.success("password updated successfully.");
          this.setState({ password_state: states.email });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}:${err.response.data[e]}`);
            });
          }
          this.setState({ password_state: states.email });
        });
    });
  };

  EmailChange = () => {
    this.FormRef.current.validateFields().then((values) => {
      //manual validation
      // if (values.password_email.indexOf("@") === -1) {
      //   message.error("Please Enter Valid Email")
      // }
      // else {
      ChangeEmail({ email: values.new_email, password: values.password_email })
        .then(() => {
          message.success("Email updated successfully.");
          this.setState({ password_state: states.email });
          this.props.fetchProfileAction();
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}:${err.response.data[e]}`);
            });
          }
          this.setState({ password_state: states.email });
          this.props.fetchProfileAction();
        });
      // }
    });
  };

  changePasswordState = (password_state) => {
    this.FormRef.current.resetFields();
    this.setState({ password_state });
  };

  updateLoadingState = (statename, value) => {
    this.setState({ [statename]: value });
  };

  handleChange = (values) => {
    this.updateLoadingState("progressTransactionsLoading", true);
    this.updateLoadingState("historyTransactionsLoading", true);
    applyReferral({ code: values })
      .then(async (response) => {
        await this.props.changeDefaultDealership(response.data.dealership);
        await this.props.setCurrentTransactionAction(null);
        await this.props.fetchTransactionAction(response.data.dealership, true);
        this.updateLoadingState("progressTransactionsLoading", false);
        await this.props.fetchHistoryAction(response.data.dealership);
        this.updateLoadingState("historyTransactionsLoading", false);
        history.push(routes.dashboard.self);
        // checking-any-transaction-active
        let current_dealership =
          this.props?.user?.available_dealerships?.filter(
            (x) => x.id === this.props?.user?.default_dealership
          );
        this.setState({
          is_active_transaction:
            current_dealership.length &&
            current_dealership[0]?.is_active_transaction,
        });
      })
      .catch(() => {
        message.error("Error while applying referral code");
      });
  };
  handleAddTransaction = () => {
    let is_active_transaction = this.state.is_active_transaction;
    if (is_active_transaction) {
      this.setState({ visible: true });
    } else {
      this.props.toggleSidebarAction();
      history.push(routes.dashboard.add_transaction);
    }
  };

  render() {
    const { user, t } = this.props;
    const { password_state } = this.state;
    const content = (
      <div className="row account-details-row">
        <div className="col-12 text-center">
          <img
            src={Images.user_icon_big}
            alt="user-icon"
            className="img-fluid"
          />
          <h6>{`${user.first_name} ${user.last_name}`}</h6>
        </div>
        <div className="col-12">
          <Form ref={this.FormRef} className="w-100">
            <div className="row">
              {password_state !== states.email ? (
                password_state === states.change_email ? (
                  <div className="col-12 input-min-height">
                    <span className="change-email-spans">{t('dashboard.currentEmail')}</span>
                    <Input
                      disabled
                      value={user?.email}
                      className="change-email-disable"
                    />
                    <span className=" position-absolute d-flex m-auto lock-icon-span change-email-lock-span">
                      <img
                        src={Images.lock_icon}
                        alt="eye icon"
                        className="img-fluid lock-icon change-email-lock"
                      />
                    </span>

                    <span className="change-email-spans">{t('dashboard.newEmail')}</span>
                    <Form.Item
                      name="new_email"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label change-password-label  mb-2 position-relative change-email-label"
                    >
                      <Input placeholder={""} defaultValue={""} />
                    </Form.Item>
                    <span className="change-email-spans">{t('dashboard.password')}</span>
                    <Form.Item
                      name="password_email"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label change-password-label  mb-2 position-relative change-email-label"
                    >
                      <Input.Password placeholder={" "} defaultValue={""} />
                    </Form.Item>

                  </div>
                ) : password_state === states.check_password ? (
                  <div className="col-12 input-min-height">
                    <Form.Item
                      name="old_password"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label change-password-label  mb-2 position-relative"
                    >
                      <Input.Password placeholder={"Old Password"} />
                    </Form.Item>
                  </div>
                ) : (
                  <div className="col-12 input-min-height">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label change-password-label  mb-2 position-relative"
                    >
                      <Input.Password placeholder={"New Password"} />
                    </Form.Item>
                    <Form.Item
                      name="confirm_password"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label change-password-label  mb-2 position-relative"
                    >
                      <Input.Password placeholder={"Confirm Password"} />
                    </Form.Item>
                  </div>
                )
              ) : (
                <div className="col-12 input-min-height">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "this field is required",
                      },
                    ]}
                    className="group-floating-label mb-2 position-relative"
                  >
                    <Input disabled value={user?.email} />
                    <span className="eye-tag-show position-absolute d-flex m-auto lock-icon-span">
                      <img
                        src={Images.lock_icon}
                        alt="eye icon"
                        className="img-fluid lock-icon"
                      />
                    </span>
                  </Form.Item>
                </div>
              )}
              {password_state !== states.email ? (
                password_state === states.change_email ? (
                  <div className="col-12 account-btn-div">
                    <Button
                      type="primary"
                      onClick={() => this.EmailChange()}
                      className="flex-align-center border-0 w-100"
                    >
                      {t('dashboard.updateEmail')}
                    </Button>
                    <Button
                      onClick={() => this.changePasswordState(states.email)}
                      className="flex-align-center border-0 w-100"
                    >
                      {t('utils.cancel')}
                    </Button>
                  </div>
                ) : password_state === states.check_password ? (
                  <div className="col-12 account-btn-div">
                    <Button
                      onClick={() => this.changePasswordState(states.email)}
                      className="flex-align-center border-0 w-100"
                    >
                      {t('utils.cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.CheckPassword()}
                      className="flex-align-center border-0 w-100"
                    >
                      {t('utils.next')}
                    </Button>
                  </div>
                ) : (
                  <div className="col-12 account-btn-div">
                    <Button
                      onClick={() => this.changePasswordState(states.email)}
                      className="flex-align-center border-0 w-100"
                    >
                      {t('utils.cancel')}
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.PasswordChange()}
                      className="flex-align-center border-0 w-100"
                    >
                      {t('utils.save')}
                    </Button>
                  </div>
                )
              ) : (
                <div className="col-12 account-btn-div">
                  <Button
                    onClick={() => history.push(routes.dashboard.tech_support)}
                    className="flex-align-center border-0 w-100"
                  >
                    {t('dashboard.techSupport')}
                  </Button>
                  <Button
                    onClick={() =>
                      this.changePasswordState(states.change_email)
                    }
                    className="flex-align-center border-0 w-100"
                  >
                    {t('dashboard.updateEmail')}
                  </Button>
                  <Button
                    onClick={() =>
                      this.changePasswordState(states.check_password)
                    }
                    className="flex-align-center border-0 w-100"
                  >
                    {/* Change Password */}
                    {t('dashboard.resetEmail')}
                  </Button>
                  <Link style={{ textDecoration: "none" }} to={routes.logout}>
                    <Button className="flex-align-center-between border-0 w-100">
                      {t('dashboard.signOut')}
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    );

    const {
      current_transaction,
      transactions,
      next,
      historyTransaction,
      historyNext,
    } = this.props;

    return (
      <div
        ref={this.wrapperRef}
        className={`left-side-data position-fixed h-100 ${
          this.props.sidebar?.showSidebar && "class-for-responsive"
        }`}
      >
        <div className="sidebar-class-inner">
          <div className="row m-0">
            <div className="col-12 sidebar-header">
              <div className="row">
                <div className="col-12">
                  <div className="logo-header-main d-flex align-items-center justify-content-between">
                    <Link to={routes.dashboard.self} className="d-inline-block">
                      <Images.VFILogo/>
                    </Link>

                    <div className="user-dropdown-div">
                      <Popover
                        onOpenChange={(e) =>
                          !e
                            ? this.setState({ password_state: states.email })
                            : ""
                        }
                        placement="bottom"
                        overlayClassName="account-popup-main"
                        content={content}
                        title=""
                        trigger="click"
                      >
                        <Button
                          className="ant-dropdown-link p-0 h-auto bg-transparent border-0 shadow-none"
                          onClick={(e) => e.preventDefault()}
                        >
                          <div className="circular-frame text-uppercase ">
                            <span className="profile-text">{`${user.first_name?.charAt(0)}${user.last_name?.charAt(0)}`}</span>
                          </div>
                        </Button>
                      </Popover>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 mb-3">
                  <div className="w-100">
                    <div className="row">
                      <div className="col-md-12">
                          {user?.available_dealerships?.length > 0 &&
                              user?.default_dealership && (
                                  <div className="user-select-div-sidebar-new select-dealership-name d-flex align-items-center justify-content-center">
                                    <Select
                                        // dropdownClassName={"dealership-name-dropdown"}
                                        popupClassName={"dealership-name-dropdown"}
                                        suffixIcon={
                                          <div className="dropdown-icon">
                                            <img
                                                src={Images.down_arrow}
                                                width={15}
                                                className="img-fluid close-down-icon"
                                                alt="down arrow"
                                            />
                                          </div>
                                        }
                                        defaultValue={
                                          user?.available_dealerships?.find(
                                              (o) => o.id === user?.default_dealership
                                          )?.code
                                        }
                                        onChange={this.handleChange}
                                    >
                                      {user?.available_dealerships?.map((item, index) => (
                                          <Option
                                              key={`dealerships_${index}`}
                                              value={item?.code}
                                          >
                                            {item?.name}
                                          </Option>
                                      ))}
                                    </Select>
                                  </div>
                              )}
                    </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-12 ">
              <div className="w-100 sidebar-all-details-div">
              <div style={{ position: "relative" }}>
                {this.state.progressTransactionsLoading && (
                  <Spin tip="Loading..."></Spin>
                )}
                <ul className="list-inline mb-0" style={{ height : '100%', overflowY: 'scroll'}}>
                  {transactions?.map((transaction, index) => {
                    if(transaction.status !== transaction_status.pushed) {
                      return <li key={`transaction_${index}`} className="border-bottom-gray">
                        <Button
                            onClick={() => {
                              this.props.toggleSidebarAction();
                              this.changeTransaction(transaction, "inprogress");
                            }}
                            className={`flex-align-center-between break-text-class w-100 border-0
                       ${
                                current_transaction &&
                                transaction.id === current_transaction?.id &&
                                "active"
                            }
                       ${
                                checkCreationDate(transaction)
                                    ? transaction?.is_co_buyer
                                        ? !transaction.seen_by_co_buyer
                                            ? "seen-transaction"
                                            : ""
                                        : !transaction.seen_by_customer
                                            ? "seen-transaction"
                                            : ""
                                    : ""
                            }
                       `}
                        >
                          <div className="profile-info">
                        <span>
                          {`${index + 1}. 
                          ${
                              transaction?.buyer_personal_info?.first_name && transaction?.buyer_personal_info?.last_name ?
                                  `${transaction?.buyer_personal_info?.first_name} ${transaction?.buyer_personal_info?.last_name}`
                                  : '-'
                          } `}
                        </span>
                            <span className="license-status form-status font-weight-normal">
                          {/*This is a message preview...*/}
                              {`${transaction.vehicle_for_purchase ? `${transaction.vehicle_for_purchase.make || "-"}
                               ${transaction.vehicle_for_purchase.model || "-"} 
                               ${transaction.vehicle_for_purchase.year || "-"}`
                                  : moment(transaction.created_at).format("MMM Do, YYYY")} 
                            ${transaction.is_co_buyer ? String(transaction.status) === DISASSOCIATED ||
                                  String(transaction.status) === DISASSOCIATED_CO_BUYER
                                      ? "(Disassociated-Cobuyer)"
                                      : "(co-buyer)"
                                  : String(transaction.status) === DISASSOCIATED
                                      ? "(Disassociated-Buyer)"
                                      : ""
                              }`}
                        </span>
                          </div>
                          <div className="arrow-right-div">
                            <img
                                alt="arrow right"
                                className="img-fluid"
                                src={Images.black_arrow_right}
                            />
                          </div>
                        </Button>
                      </li>
                    }
                  })}
                </ul>
                {transactions?.length > 0 && next && (
                    <div className="text-center w-100">
                      <Button
                          onClick={this.handleLoadMoreTransactions}
                          loading={this.state.btnLoading}
                          className="load-more-btn"
                      >
                        Load More
                        {t('utils.loadMore')}
                      </Button>
                    </div>
                )}
              </div>
              </div>
            </div>
          </div>

        </div>
        <NintyDaysExceedModal
            visible={this.state.visible}
            onClose={() => {
              this.setState({visible: false});
            }}
        />
      </div>
    );
  }
}

export const mapActionToProps = {
  loadMoreTransactionAction,
  fetchTransactionAction,
  setCurrentTransactionAction,
  setCoBuyerViewAction,
  updateTransactionAction,
  fetchHistoryAction,
  loadMoreHistoryAction,
  changeDefaultDealership,
  resetPrompt,
  toggleSidebarAction,
  fetchProfileAction,
  setCurrentTransactionHistoryAction,
};

export default withTranslation()(connect(mapStateToProps, mapActionToProps)(withRouter(SideBar)));
