import React from 'react';


/*export interface IconBaseProps extends React.SVGAttributes<SVGElement> {
    children?: React.ReactNode;
    size?: string | number;
    color?: string;
}*/

const VFILoginLogo = () => {
    return (
        <>
            <h6 className={'mt-2 text-center text-3xl md:text-4xl font-medium leading-normal'}
                style={{ color: '#959595', letterSpacing: '0.5em', fontSize: '1.2rem'}}>
                EUNI<span className={'font-bold leading-normal'} style={{ color: '#96CDA6', fontWeight: 550 }}>FI</span>
            </h6>
        </>

    )
}


export default VFILoginLogo;