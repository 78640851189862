import React, { Component } from "react";
import { Image as Images } from "../Images";
import { Button, Form, Input, message, Popover, Select } from "antd";
import { Link, withRouter } from "react-router-dom";
import { routes } from "../../controller/routes";
import "./css/header.css";
import { connect } from "react-redux";
// import ReferralCode from "../login-data/ReferralCode";
import {
  applyReferral,
  ChangePassword,
  ValidatePassword,
} from "../../controller/api/customerAPI";
import { changeDefaultDealership } from "../../store/actions/authAction";
import { history } from "../../controller/history";
import {
  fetchTransactionAction,
  setCurrentTransactionAction,
} from "../../store/actions/transactionAction";

const { Option } = Select;

const states = {
  check_password: "check_password",
  change_password: "change_password",
  email: "email",
};

class CustomHeader extends Component {
  FormRef = React.createRef();
  state = {
    referral: false,
    password_state: states.email,
  };
  referralShow = (visible) => {
    this.setState({ referral: visible });
  };

  componentDidMount() {
    if (!this.props.user.default_dealership) {
      this.referralShow(true);
    }
  }

  CheckPassword = () => {
    this.FormRef.current.validateFields().then((values) => {
      ValidatePassword(values)
        .then(() => {
          this.setState({ password_state: states.change_password });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}:${err.response.data[e]}`);
            });
          }
          this.setState({ password_state: states.email });
        });
    });
  };

  PasswordChange = () => {
    this.FormRef.current.validateFields().then((values) => {
      ChangePassword(values)
        .then(() => {
          message.success("password updated successfully.");
          this.setState({ password_state: states.email });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}:${err.response.data[e]}`);
            });
          }
          this.setState({ password_state: states.email });
        });
    });
  };

  changePasswordState = (password_state) => {
    this.FormRef.current.resetFields();
    this.setState({ password_state });
  };

  handleChange = (values) => {
    applyReferral({ code: values })
      .then(async (response) => {
        await this.props.changeDefaultDealership(response.data.dealership);
        await this.props.setCurrentTransactionAction(null);
        await this.props.fetchTransactionAction(response.data.dealership);
        history.push(routes.dashboard.self);
      })
      .catch(() => {
        message.error("Error while applying referral code");
      });
  };

  render() {
    const { user } = this.props;
    const { password_state } = this.state;
    const content = (
      <div className="row account-details-row">
        <div className="col-12 text-center">
          <img
            src={Images.user_icon_big}
            alt="user-icon"
            className="img-fluid"
          />
          <h6>{`${user.first_name} ${user.last_name}`}</h6>
        </div>
        <div className="col-12">
          <Form ref={this.FormRef} className="w-100">
            <div className="row">
              {password_state !== states.email ? (
                password_state === states.check_password ? (
                  <div className="col-12 input-min-height">
                    <Form.Item
                      name="old_password"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label mb-2 position-relative"
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                ) : (
                  <div className="col-12 input-min-height">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label mb-2 position-relative"
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="confirm_password"
                      rules={[
                        {
                          required: true,
                          message: "this field is required",
                        },
                      ]}
                      className="group-floating-label mb-2 position-relative"
                    >
                      <Input.Password />
                    </Form.Item>
                  </div>
                )
              ) : (
                <div className="col-12 input-min-height">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "this field is required",
                      },
                    ]}
                    className="group-floating-label mb-2 position-relative"
                  >
                    <Input disabled value={user.email} />
                    <span className="eye-tag-show position-absolute d-flex m-auto">
                      <img
                        src={Images.lock_icon}
                        alt="eye icon"
                        className="img-fluid"
                      />
                    </span>
                  </Form.Item>
                </div>
              )}
              {password_state !== states.email ? (
                password_state === states.check_password ? (
                  <div className="col-12 account-btn-div">
                    <Button
                      onClick={() => this.changePasswordState(states.email)}
                      className="flex-align-center border-0 w-100"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.CheckPassword()}
                      className="flex-align-center border-0 w-100"
                    >
                      Next
                    </Button>
                  </div>
                ) : (
                  <div className="col-12 account-btn-div">
                    <Button
                      onClick={() => this.changePasswordState(states.email)}
                      className="flex-align-center border-0 w-100"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.PasswordChange()}
                      className="flex-align-center border-0 w-100"
                    >
                      Next
                    </Button>
                  </div>
                )
              ) : (
                <div className="col-12 account-btn-div">
                  <Button
                    onClick={() => this.referralShow(true)}
                    className="flex-align-center border-0 w-100"
                  >
                    Switch Dealership
                  </Button>
                  <Button
                    onClick={() =>
                      this.changePasswordState(states.check_password)
                    }
                    className="flex-align-center border-0 w-100"
                  >
                    Change Password
                  </Button>
                  <Link to={routes.logout}>
                    <Button className="flex-align-center-between border-0 w-100">
                      Sign Out{" "}
                      <img
                        src={Images.log_out_icon}
                        alt="sign out"
                        className="img-fluid"
                      />
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        {this.props.match.url === routes.dashboard.add_transaction && (
          <div className={"add-transaction"}>
            <Link to={routes.dashboard.self}>
              <img alt="" src={Images.logo_small_header} height={50} />
            </Link>
          </div>
        )}

        <div className="main-header-div position-fixed bg-white">
          <div className="row mx-0 header-inner-row h-100 align-items-center justify-content-end">
            <div className="header-right-main d-flex align-items-center">
              {user.available_dealerships.length > 0 &&
                user.default_dealership && (
                  <div className="select-dealership-name">
                    <Select
                      defaultValue={
                        user.available_dealerships.find(
                          (o) => o.id === user.default_dealership
                        ).code
                      }
                      onChange={this.handleChange}
                    >
                      {user.available_dealerships.map((item, index) => (
                        <Option key={`dealerships_${index}`} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}

              <div className="user-dropdown-div">
                <Popover
                  onOpenChange={(e) =>
                    !e ? this.setState({ password_state: states.email }) : ""
                  }
                  placement="bottom"
                  overlayClassName="account-popup-main"
                  content={content}
                  title=""
                  trigger="click"
                >
                  <Button
                    className="ant-dropdown-link h-auto bg-transparent border-0 shadow-none"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      src={Images.user_icon}
                      alt="user-icon"
                      className="img-fluid"
                    />
                  </Button>
                </Popover>
              </div>
            </div>
          </div>
          {/* <ReferralCode
            visible={this.state.referral}
            onClose={() => this.referralShow(false)}
          /> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
const mapActionToProps = {
  changeDefaultDealership,
  setCurrentTransactionAction,
  fetchTransactionAction,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(CustomHeader));
