// import {login} from "../../controller/AuthService";
// import {logout, setRefreshToken, setUserToken} from "../../controller/localStorageHandler";
// import {history} from "../../controller/history";
// import {routes} from "../../controller/routes";

export const UPDATE_PROMPT = "UPDATE_PROMPT";
export const CHANGE_SETUP = "CHANGE_SETUP";
export const RESET_PROMPT = "RESET_PROMPT";

export function updatePrompt(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROMPT,
      payload: data,
    });
  };
}

export function changeSetup(data) {
  return (dispatch) => {
    dispatch({
      type: CHANGE_SETUP,
      payload: data,
    });
  };
}

export function resetPrompt() {
  return (dispatch) => {
    dispatch({
      type: RESET_PROMPT,
    });
  };
}
