import React , { useState } from "react";
import {getAPIUrl} from "../../../controller/Global";
import {Post} from "../../../controller/headerIntercepter";
import {useTranslation} from "react-i18next";
import SuccessPopModal from "../../modal/SuccessPopModal";

export type SendLinkProps = {
    transId: number,
    dealershipId: number
}
export const SendLink = ({transId, dealershipId}: SendLinkProps) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onClick = (e: any) => {
        Post(getAPIUrl("v3.transaction.send_web_portal_link",
            {
                dealership: dealershipId,
                id: transId
            } as any), {
          url: document.URL
        }).then((res) => {
            setIsOpen(true);
        });
    }

    return (
        <>
            <div className="send-link-btn" onClick={onClick}>
                {t('transactionForm.stepThree.sendToPhone')}
            </div>
            <SuccessPopModal
                title={"A link has been sent to your phone number, please follow the steps to add ID"}
                subTitle={
                    ""
                }
                submitBtnTitle={"OK"}
                onOpen={isOpen}
                onSubmit={() => {
                    setIsOpen(false);
                }}
            />
        </>
    );
};

export default SendLink;