import React, { Component } from "react";
import {Button, Checkbox, Form, Input, message, Radio, Select} from "antd";
import {
  addAddressInfo,
  getTransactionOne,
  getZipcode,
  updateAddressInfo,
} from "../controller/api/customerAPI";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import { states } from "../controller/states";
import {
  confirm_status,
  methods,
  onSelectBlurred,
  onSelectFocused,
} from "../controller/Global";
import { connect } from "react-redux";
import SideBar from "./sidebar/SideBar";
import PopConfirm from "./modal/PopConfirm";
import { Image as Images } from "./Images";
import {withTranslation} from "react-i18next";

const { Option } = Select;

class CustomerAddressInfo extends Component {
  formRef = React.createRef();

  state = {
    value: "",
    use_current_address: true,
    pop_confirm: null,
    visible: false,
    fixed: false,
    formFilled: false,
    matchZipCode: null,
    matchZipCode1: null,
  };
  onChange = (e) => {
    const formData = this.formRef.current.getFieldsValue()

    this.setState({
      use_current_address: e.target.checked,
    });

    if (e.target.checked === false) {
      this.formRef.current.setFieldsValue({
        address_2: '',
        city_2: '',
        state_2: '',
        suite_2: '',
        zip_code_2: ''
      });
    } else {
      this.setState({ formFilled: true });
      this.formRef.current.setFieldsValue({
        address_2: formData.address_1 ? formData.address_1 : '',
        city_2: formData.city_1 ? formData.city_1 : '',
        state_2: formData.state_1 ? formData.state_1 : '',
        suite_2: formData.suite_1 ? formData.suite_1 : '',
        zip_code_2: formData.zip_code_1 ? formData.zip_code_1 : '',
      });
    }
  };

  handleSubmit = (values) => {
    const { params } = this.props.match;
    values["register_vehicle"] = this.state.use_current_address;
    values["transaction"] = this.props.match.params.trans_id;
    if (values.register_vehicle === true) {
      values = {
        ...values,
        address_2: values.address_1,
        city_2: values.city_1,
        state_2: values.state_1,
        suite_2: values.suite_1,
        zip_code_2: values.zip_code_1,
      };
    }
    if (params.method === methods.edit) {
      updateAddressInfo(values, params.id)
        .then(() => {
          this.setState({ visible: true });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
          }
        });
    } else {
      addAddressInfo(values)
        .then(() => this.setState({ visible: true }))
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
          }
        });
    }
  };
  handleStateChange = (val, field) => {
    this.formRef.current.setFieldsValue({ [field]: val });
  };

  onFormChange = (allFields) => {
    let newFields = allFields.filter(
      (item) => item.name[0] !== "suite_1" && item.name[0] !== "suite_2"
    );
    if (newFields.every((item) => !!item.value)) {
      this.setState({ formFilled: true });
    } else this.setState({ formFilled: false });
  };

  componentDidMount() {
    const { params } = this.props.match;
    const { t } = this.props;
    if (params.method === methods.edit) {
      if (this.props.current_transaction && this.props.current_transaction.id) {
        this.formRef.current.setFieldsValue({
          ...this.props.current_transaction.address_information,
        });
        this.setState({
          use_current_address:
            this.props.current_transaction.address_information.register_vehicle,
          formFilled: true,
        });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.address_information.id !== parseInt(params.id)) {
              message.error(t('utils.warningMessage.InvalidURL'));
              history.push(routes.dashboard.self);
            } else {
              this.formRef.current.setFieldsValue({
                ...res.data.address_information,
              });

              this.setState({
                use_current_address:
                  res.data.address_information.register_vehicle,
                formFilled: true,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              Object.keys(err.response.data).forEach((e) => {
                message.error(err.response.data[e]);
              });
            }
          });
      }
      this.setState({ pop_confirm: confirm_status.edit });
    } else {
      this.setState({ pop_confirm: confirm_status.add });
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  handleExit = (visible) => {
    this.setState({ pop_confirm: confirm_status.exit, visible });
  };

  get_message = () => {
    const { pop_confirm } = this.state;
    const { t } = this.props;
    if (pop_confirm === confirm_status.add) {
      return t('addressInformation.popModal.addTitle');
    } else if (pop_confirm === confirm_status.edit) {
      return t('addressInformation.popModal.updatedTitle');
    } else {
      return t('addressInformation.popModal.exitTitle');
    }
  };

  onChangeZip = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode = response.data;
        this.setState({ matchZipCode });
        if (matchZipCode) {
          this.formRef.current.setFieldsValue({
            city_1: matchZipCode.city,
            state_1: matchZipCode.state,
          });
          this.formRef.current.validateFields();
        }
      });
    }
  };

  onBlurZip = (e) => {
    let value = e.target.value;
    const { matchZipCode } = this.state;
    if (matchZipCode && value.length === 3) {
      this.formRef.current.setFieldsValue({
        zip_code_1: `00${value}`,
      });
    } else if (matchZipCode && value.length === 4) {
      this.formRef.current.setFieldsValue({
        zip_code_1: `0${value}`,
      });
    }
  };

  onChangeZip1 = (e) => {
    let value = e.target.value;
    if (value.length >= 3) {
      const data = { zip_code: value };
      getZipcode(data).then((response) => {
        const matchZipCode1 = response.data;
        this.setState({ matchZipCode1 });
        if (matchZipCode1) {
          this.formRef.current.setFieldsValue({
            city_2: matchZipCode1.city,
            state_2: matchZipCode1.state,
          });
        }
      });
      this.formRef.current.validateFields();
    }
  };

  onBlurZip1 = (e) => {
    let value = e.target.value;
    const { matchZipCode1 } = this.state;
    if (matchZipCode1 && value.length === 3) {
      this.formRef.current.setFieldsValue({
        zip_code_2: `00${value}`,
      });
    } else if (matchZipCode1 && value.length === 4) {
      this.formRef.current.setFieldsValue({
        zip_code_2: `0${value}`,
      });
    }
  };


  render() {
    const { formFilled } = this.state;
    const { current_transaction, t } = this.props;

    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid main-inner-fluid mt-66">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className={"col-12 h-100 dashboard-inner-div p-0"}>
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 position-relative transition-info-div">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => this.handleExit(true)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>

                          <span className="d-inline-block w-100 text-center">
                            {t('addressInformation.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 deal-info-div">
                      <div className="transition-information-heading w-100 font-weight-bold mb-5">
                        {t('addressInformation.currentAddress')}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Form
                              ref={this.formRef}
                              className={"w-100"}
                              onFieldsChange={(changedFields, allFields) => {
                                this.onFormChange(allFields);
                              }}
                              onFinish={this.handleSubmit}
                          >
                            <div className="row mb-5">
                              <div className="col-12">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('addressInformation.streetAddressLabel')} *
                                </label>
                                <Form.Item
                                    name="address_1"
                                    label={""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "this field is required",
                                      },
                                    ]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      placeholder=" "
                                      className="inp-form-wrap"
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('addressInformation.suiteLabel')}
                                </label>
                                <Form.Item
                                    name="suite_1"
                                    label={""}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      placeholder=" "
                                      className="inp-form-wrap"
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('addressInformation.zipCodeLabel')} *
                                </label>
                                <Form.Item
                                    name="zip_code_1"
                                    label={""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "this field is required",
                                      },
                                    ]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      placeholder=" "
                                      onChange={this.onChangeZip}
                                      onBlur={this.onBlurZip}
                                  />
                                </Form.Item>
                                <span className="subtext">{t('addressInformation.zipHelperText')}</span>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-4 ">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('addressInformation.cityLabel')} *
                                </label>
                                <Form.Item
                                    name="city_1"
                                    label={""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "this field is required",
                                      },
                                    ]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      placeholder=" "
                                      className="inp-form-wrap"
                                  />
                                </Form.Item>
                              </div>
                              <div
                                  className="col-12 col-sm-12 col-lg-4 select_floating"
                                  id={"select_state_1"}
                              >
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('addressInformation.stateLabel')} *
                                </label>
                                <Form.Item
                                    name="state_1"
                                    rules={[
                                      {
                                        required: true,
                                        message: "this field is required",
                                      },
                                    ]}
                                    className={
                                      "group-floating-label select-float  group-floating-label-second position-relative"
                                    }
                                >
                                  <Select
                                      onFocus={() =>
                                          onSelectFocused("select_state_1")
                                      }
                                      onBlur={() =>
                                          onSelectBlurred("select_state_1")
                                      }
                                      suffixIcon={
                                        <img
                                            src={Images.down_arrow_select}
                                            width={15}
                                            alt="down arrow"
                                            className="img-fluid"
                                        />
                                      }
                                      placeholder=""
                                  >
                                    {states.map((item, index) => (
                                        <Option
                                            key={`state_${index}`}
                                            value={item.abbreviation}
                                            className="inp-form-wrap"
                                        >{`${item.name} (${item.abbreviation})`}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            {current_transaction &&
                                !current_transaction.is_co_buyer && (
                                    <>
                                      <div className="personal-info-strong w-100 our-commitment-div mb-2">
                                        {t('addressInformation.registerToAddress')}
                                      </div>
                                      <div className="transition-check-box w-100 mb-5">
                                          <Checkbox
                                              onChange={this.onChange}
                                              defaultChecked={
                                                this.props?.current_transaction?.address_information?.register_vehicle ?
                                                    this.props?.current_transaction?.address_information?.register_vehicle :
                                                    false
                                              }
                                          >
                                            {t('addressInformation.register_to_address_is_the_same_current_address')}
                                          </Checkbox>
                                      </div>
                                          <div className="row">
                                            <div className="col-12">
                                              <label
                                                  className="font-weight-bold"
                                              >
                                                {t('addressInformation.streetAddressLabel')}
                                              </label>
                                              <Form.Item
                                                  name="address_2"
                                                  label={""}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "this field is required",
                                                    },
                                                  ]}
                                                  className={
                                                    "group-floating-label group-floating-label-second"
                                                  }
                                              >
                                                <Input
                                                    placeholder=" "
                                                    className="inp-form-wrap"
                                                />
                                              </Form.Item>
                                            </div>
                                            <div className="col-12">
                                              <label
                                                  className="font-weight-bold"
                                              >
                                                {t('addressInformation.suiteLabel')}
                                              </label>
                                              <Form.Item
                                                  name="suite_2"
                                                  label={""}
                                                  className={
                                                    "group-floating-label group-floating-label-second"
                                                  }
                                              >
                                                <Input
                                                    placeholder=" "
                                                    className="inp-form-wrap"
                                                />
                                              </Form.Item>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-4 zip-inp">
                                              <label
                                                  className="font-weight-bold"
                                              >
                                                {t('addressInformation.zipCodeLabel')}
                                              </label>
                                              <Form.Item
                                                  name="zip_code_2"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "this field is required",
                                                    },
                                                  ]}
                                                  className={
                                                    "group-floating-label group-floating-label-second"
                                                  }
                                              >
                                                <Input
                                                    placeholder=" "
                                                    onChange={this.onChangeZip1}
                                                    onBlur={this.onBlurZip1}
                                                />
                                              </Form.Item>
                                              <span className="subtext">{t('addressInformation.zipHelperText')}</span>
                                            </div>
                                            <div className="col-12 col-sm-12 col-lg-4">
                                              <label
                                                  className="font-weight-bold"
                                              >
                                                {t('addressInformation.cityLabel')} *
                                              </label>
                                              <Form.Item
                                                  name="city_2"
                                                  label={""}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: "this field is required",
                                                    },
                                                  ]}
                                                  className={
                                                    "group-floating-label group-floating-label-second"
                                                  }
                                              >
                                                <Input
                                                    placeholder=" "
                                                    className="inp-form-wrap"
                                                />
                                              </Form.Item>
                                            </div>
                                            <div
                                                className="col-12 col-sm-12 col-lg-4 select_floating"
                                                id={"select_state"}
                                            >
                                              <label
                                                  className="font-weight-bold"
                                              >
                                                {t('addressInformation.stateLabel')}
                                              </label>
                                              <Form.Item
                                                  name="state_2"
                                                  rules={[
                                                    {
                                                      required: false,
                                                      message: "this field is required",
                                                    },
                                                  ]}
                                                  className={
                                                    " select-float group-floating-label group-floating-label-second position-relative"
                                                  }
                                              >
                                                <Select
                                                    onFocus={() =>
                                                        onSelectFocused("select_state")
                                                    }
                                                    onBlur={() =>
                                                        onSelectBlurred("select_state")
                                                    }
                                                    suffixIcon={
                                                      <img
                                                          src={Images.down_arrow_select}
                                                          width={15}
                                                          alt="down arrow"
                                                          className="img-fluid"
                                                      />
                                                    }
                                                    placeholder=""
                                                >
                                                  {states.map((item, index) => (
                                                      <Option
                                                          key={`state_${index}`}
                                                          value={item.abbreviation}
                                                          className="inp-form-wrap"
                                                      >{`${item.name} (${item.abbreviation})`}</Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </div>
                                          </div>
                                    </>
                                )}
                            <div className="col-12 add-new-customer-div footer-customer-div p-0 shadow-none">
                              <div className="steps-action flex-align-center justify-content-end w-100">
                                <Button
                                    disabled={!formFilled}
                                    htmlType="submit"
                                    type="primary"
                                >
                                  {t('utils.save')}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopConfirm
            visible={this.state.visible}
            user={this.props.user}
            exit={this.state.pop_confirm === confirm_status.exit}
            handleExit={() => this.handleExit(false)}
        >
          {this.get_message()}
        </PopConfirm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};

export default withTranslation()(connect(mapStateToProps)(CustomerAddressInfo));
