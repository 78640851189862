import React, { Component } from "react";
import { Input } from "antd";
// import {onNumberBlurred, onNumberFocused} from "./Global";

class HiddenSsnInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validSsnEntered: false,
      ssnValue: null,
      maskedValue: null,
      counter: 1,
    };
    this.ssnInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      if (this.state.counter === 1) {
        this.setState({ counter: this.state.counter + 1 });
        if (this.props.value?.length > 9) {
          let value = `${this.props.value}`.match(/\d*/g).join("");
          let mask = value ? "XXX-XX-" + value.slice(5, 9) : "";
          this.setState({ maskedValue: mask, counter: this.state.counter + 1 });
        }
      }
    }
  }

  maskedSsnInput = (value) => {
    const values = value.replace("-", "");
    const myvalue = values.replace("-", "");
    if (myvalue.length > 4) {
      let lastFour = myvalue.substr(myvalue.length - 4);
      let mask = "X";
      let maskvaluelength = myvalue.length - 4;
      let maskvalue = mask.repeat(maskvaluelength);
      maskvalue = maskvalue + lastFour;
      // let rightvalue=maskvalue.replace(/^\d{3}-\d{2}-\d{4}$/g)
      let rightvalue =
        maskvalue.substring(0, 3) +
        "-" +
        maskvalue.substring(3, 5) +
        "-" +
        maskvalue.substring(5, maskvalue.length);
      return rightvalue;
    } else {
      return value;
    }
  };

  isValid() {
    return this.state.validSsnEntered;
  }

  getRealValue = (e) => {
    if (this.state.ssnValue) e.target.value = this.state.ssnValue;
  };
  // eslint-disable-next-line
  isValidSsn(value) {
    return true;
  }

  value() {
    return this.state.ssnValue;
  }
  // eslint-disable-next-line
  handleFocus = (e) => {
    this.setState({ maskedValue: null });
  };

  handleChange = (e) => {
    let value = e.target.value;
    value = `${value}`
      .match(/\d*/g)
      .join("")
      .match(/(\d{0,3})(\d{0,2})(\d{0,4})/)
      .slice(1)
      .join("-")
      .replace(/-*$/g, "");
    this.setState({ ssnValue: value }, () => this.props.onChange(value));
  };

  _onBlur(e) {
    const entered = e.target.value;
    if (this.isValidSsn(entered)) {
      // Implement yourself
      this.setState({ validSsnEntered: true, ssnValue: entered });
      let masked = this.maskedSsnInput(entered);
      this.setState({ maskedValue: masked });
    } else {
      this.setState({ validSsnEntered: false, ssnValue: undefined });
    }
  }

  render() {
    return (
      <Input
        placeholder={this.props.placeholder}
        suffix={this.props.suffix}
        maxLength={11}
        value={
          this.state.maskedValue || this.state.ssnValue || this.props.value
        }
        ref={this.ssnInput}
        onChange={(e) => this.handleChange(e)}
        onBlur={(e) => this._onBlur(e)}
        onFocus={(e) => this.getRealValue(e)}
        onFocusCapture={(e) => this.handleFocus(e)}
      />
    );
  }
}

export default HiddenSsnInput;
