import React, { useEffect } from "react";
import Axios from "axios";
import { Image as Images } from "../../Images";
import { DatePicker, Form, Input, Select } from "antd";
import {
    onNumberBlurred,
    onNumberFocused,
    onSelectBlurred,
    onSelectFocused,
} from "../../../controller/Global";
import { states } from "../../../controller/states";
import MakeHeightArray from "../../HeightArray";
import { getEnvValue } from "../../../controller/Environment";
import moment from "moment";
import {useTranslation} from "react-i18next";



export const IdentityDocForm = ({ handleSubmit, formRef, selectedIssued, setSelectedIssued, selectedExpiry, setSelectedExpiry, selectedDob, setSelectedDob }) => {

    const { Option } = Select;
    const Heights = MakeHeightArray();
    const { t, i18n } = useTranslation();
    const lng = navigator.language;

    const formatDates = (label) => {
        if (label === "dob" && selectedDob?.day && selectedDob?.month && selectedDob?.year) {
            return `${selectedDob.month}/${selectedDob.day}/${selectedDob.year}`;
        } else if (label === "issued_date" && selectedIssued?.day && selectedIssued?.month && selectedIssued?.year) {
            return `${selectedIssued.month}/${selectedIssued.day}/${selectedIssued.year}`;
        } else if (label === "expiry_date" && selectedExpiry?.day && selectedExpiry?.month && selectedExpiry?.year) {
            return `${selectedExpiry.month}/${selectedExpiry.day}/${selectedExpiry.year}`;
        } else {
            return "";
        }
    };

    const onDateChanges = (value, label) => {
        const updatedValue = {
            day: moment(value, 'MM/DD/YYYY').format('DD'),
            month: moment(value, 'MM/DD/YYYY').format('MM'),
            year: moment(value, 'MM/DD/YYYY').format('YYYY')
        }
        if (label === "issued_date") {
            setSelectedIssued(updatedValue);
        } else if (label === "expiry_date") {
            setSelectedExpiry(updatedValue);
        } else if (label === "dob") {
            setSelectedDob(updatedValue);
        } else {
        }
    };
    const getAddress = async (postalCode) => {
        const API_KEY = getEnvValue('REACT_APP_GOOGLE_ADDRESS_VALIDATION_API_KEY');

        if (postalCode !== "" && postalCode !== null) {
            const addressParam = {
                address: {
                    regionCode: "US",
                    addressLines: [
                        postalCode.toString()
                    ],
                },
                enableUspsCass: true,
            };

            const res = await Axios.post(
                `https://addressvalidation.googleapis.com/v1:validateAddress?key=${API_KEY}`,
                addressParam
            )
            const address = res.data.result.address.postalAddress

            if ('administrativeArea' in address) {
                formRef.current.setFieldsValue({
                    city: address.locality,
                    state: address.administrativeArea,
                });
            }
        }
    }

    const onChangeZip = (e) => {
        let value = e.target.value;
        if (value.length >= 5) {
            getAddress(value)
        }
    };




    return (
        <>
            <Form ref={formRef} onFinish={handleSubmit}>
                <div className="row">
                    <div
                        className="choose-file-div col-12 col-sm-12 col-md-6 select_floating"
                        id={"select_id"}
                    >
                        <label className="font-weight-bold">{t('IdInformation.IdInformationForm.selectIdTypeLabel')} *</label>
                        <Form.Item
                            name="document_type"
                            className={
                                "select-float group-floating-label group-floating-label-second position-relative"
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Select
                                onFocus={() => onSelectFocused("select_id")}
                                onBlur={() => onSelectBlurred("select_id")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder=""
                            >
                                <Select.Option
                                    disabled
                                    className="disbaled-optn-color"
                                    value={null}
                                >
                                    {t('IdInformation.IdInformationForm.selectIdTypeLabel')}
                                </Select.Option>
                                <Select.Option value={"driver_license"}>
                                    {t('IdInformation.IdInformationForm.idTypeChoice.driverLicense')}
                                </Select.Option>
                                <Select.Option value={"state_id"}>
                                    {t('IdInformation.IdInformationForm.idTypeChoice.stateId')}
                                </Select.Option>
                                <Select.Option value={"other"}>
                                    {t('IdInformation.IdInformationForm.idTypeChoice.otherId')}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className=" choose-file-div col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">{t('IdInformation.IdInformationForm.idNumberLabel')} *</label>
                        <Form.Item
                            name="id_number"
                            label={""}
                            className={
                                "group-floating-label group-floating-label-second position-relative"
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder=" "
                            />
                        </Form.Item>
                    </div>

                    <div
                        className="col-12 col-sm-12 col-md-6 select_floating"
                        id={"id_state"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.stateLabel')} *
                        </label>
                        <Form.Item
                            name="id_state"
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                            className={
                                "group-floating-label select-float group-floating-label-second position-relative"
                            }
                        >

                            <Select
                                onFocus={() => onSelectFocused("id_state")}
                                onBlur={() => onSelectBlurred("id_state")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder=""
                            >
                                {states.map((item) => (
                                    <Option
                                        key={item.abbreviation}
                                        value={item.abbreviation}
                                    >{`${item.name} (${item.abbreviation})`}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6" id={"dob"}>
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.dobLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "group-floating-label select-float group-floating-label-second position-relative"
                            }
                            name="dob"
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <DatePicker
                                allowClear={false}
                                value={selectedDob &&
                                    moment(`${selectedDob?.month}/${selectedDob?.day}/${selectedDob?.year}`, "MM/DD/YYYY").isValid()
                                    ? moment(`${selectedDob?.month}/${selectedDob?.day}/${selectedDob?.year}`, "MM/DD/YYYY")
                                    : null}
                                format={"MM/DD/YYYY"}
                                onFocus={() => onNumberFocused("dob")}
                                onBlur={() => onNumberBlurred("dob")}
                                onChange={(e) => onDateChanges(e, "dob")}
                                popupClassName="selected-date"

                            />
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6 select_floating"
                        id={"issued_date"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.idIssuedDateLabel')}
                        </label>
                        <Form.Item
                            className={
                                " group-floating-label select-float group-floating-label-second position-relative"
                            }
                            name="issued_date"
                            rules={[]}
                        >
                            <DatePicker
                                allowClear={false}
                                value={selectedIssued &&
                                    moment(`${selectedIssued.month}/${selectedIssued.day}/${selectedIssued.year}`, "MM/DD/YYYY").isValid()
                                    ? moment(`${selectedIssued.month}/${selectedIssued.day}/${selectedIssued.year}`, "MM/DD/YYYY")
                                    : null}
                                format={"MM/DD/YYYY"}
                                onFocus={() => onNumberFocused("issued_date")}
                                onBlur={() => onNumberBlurred("issued_date")}
                                onChange={(e) => onDateChanges(e, "issued_date")}
                                popupClassName="selected-date"

                            />
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6"
                        id={"select_date"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.idExpirationDateLabel')}
                        </label>
                        <Form.Item
                            name="expiry_date"
                            className={
                                "group-floating-label select-float group-floating-label-second position-relative"
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >

                            <DatePicker
                                allowClear={false}
                                value={selectedExpiry &&
                                    moment(`${selectedExpiry.month}/${selectedExpiry.day}/${selectedExpiry.year}`, "MM/DD/YYYY").isValid()
                                    ? moment(`${selectedExpiry.month}/${selectedExpiry.day}/${selectedExpiry.year}`, "MM/DD/YYYY")
                                    : null}
                                format={"MM/DD/YYYY"}
                                onFocus={() => onNumberFocused("expiry_date")}
                                onBlur={() => onNumberBlurred("expiry_date")}
                                onChange={(e) => onDateChanges(e, "expiry_date")}
                                popupClassName="selected-date"

                            />
                        </Form.Item>
                        <span className="subtext"></span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.firstNameLabel')} *
                        </label>
                        <Form.Item
                            name="first_name"
                            className={
                                "group-floating-label group-floating-label-second position-relative text-capitalize"
                            }
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.middleNameLabel')}
                        </label>
                        <Form.Item
                            name="middle_name"
                            label={""}
                            className={
                                "group-floating-label group-floating-label-second position-relative"
                            }
                            rules={[]}
                        >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.lastNameLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "group-floating-label group-floating-label-second position-relative"
                            }
                            name="last_name"
                            label={""}
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6 select_floating"
                        id={"select_suffix"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.suffixLabel')}
                        </label>
                        <Form.Item
                            className={
                                "select-float group-floating-label group-floating-label-second position-relative"
                            }
                            name="suffix"
                            rules={[]}
                        >
                            <Select
                                onFocus={() => onSelectFocused("select_suffix")}
                                onBlur={() => onSelectBlurred("select_suffix")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder={""}
                            >
                                <Select.Option
                                    disabled
                                    className="disbaled-optn-color"
                                    value={null}
                                >
                                    {"Suffix"}
                                </Select.Option>
                                <Select.Option value={"None"}>None</Select.Option>
                                <Select.Option value="Sr">Sr</Select.Option>
                                <Select.Option value="Jr">Jr</Select.Option>
                                <Select.Option value="II">II</Select.Option>
                                <Select.Option value="III">III</Select.Option>
                                <Select.Option value="IV">IV</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.streetAddressLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "group-floating-label group-floating-label-second position-relative"
                            }
                            name="address"
                            label={""}
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.suiteLabel')}
                        </label>
                        <Form.Item
                            name="suite_2"
                            label={""}
                            className={
                                "group-floating-label group-floating-label-second"
                            }
                        >
                            <Input
                                autoFocus
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 zip-inp">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.zipCodeLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "group-floating-label group-floating-label-second position-relative"
                            }
                            name="zip_code"
                            label={""}
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Input
                                onChange={(e) => onChangeZip(e)}
                                placeholder=" "
                            />
                        </Form.Item>
                        <span className="subtext">{t('IdInformation.IdInformationForm.zipHelperText')}</span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.cityLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "group-floating-label group-floating-label-second position-relative"
                            }
                            name="city"
                            label={""}
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder=" "
                                className="inp-form-wrap"
                            />
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6 select_floating"
                        id={"select_state"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.stateLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "select-float group-floating-label group-floating-label-second position-relative"
                            }
                            name="state"
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Select
                                onFocus={() => onSelectFocused("select_state")}
                                onBlur={() => onSelectBlurred("select_state")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder=""
                            >
                                {states.map((item) => (
                                    <Option
                                        key={item.abbreviation}
                                        value={item.abbreviation}
                                    // className="text-capitalize"
                                    >{`${item.name} (${item.abbreviation})`}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6 select_floating"
                        id={"select_sex"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.sexLabel')} *
                        </label>
                        <Form.Item
                            className={
                                "select-float group-floating-label group-floating-label-second position-relative"
                            }
                            name="sex"
                            rules={[
                                {
                                    required: true,
                                    message: "this field is required",
                                },
                            ]}
                        >
                            <Select
                                onFocus={() => onSelectFocused("select_sex")}
                                onBlur={() => onSelectBlurred("select_sex")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder={""}
                                allowClear={true}
                            >
                                <Select.Option
                                    disabled
                                    className="disbaled-optn-color"
                                    value={null}
                                >
                                    {"Sex"}
                                </Select.Option>
                                <Select.Option value="male">{t('IdInformation.IdInformationForm.genderChoice.male')}</Select.Option>
                                <Select.Option value="female">{t('IdInformation.IdInformationForm.genderChoice.female')}</Select.Option>
                                <Select.Option value="x">{t('IdInformation.IdInformationForm.genderChoice.x')}</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6"
                        id={"select_height"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.heightLabel')}
                        </label>
                        <Form.Item
                            className={
                                " select-float group-floating-label group-floating-label-second position-relative"
                            }
                            name="height"
                            rules={[]}
                        >
                            <Select
                                onFocus={() => onSelectFocused("select_height")}
                                onBlur={() => onSelectBlurred("select_height")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder={""}
                            >
                                <Select.Option
                                    disabled
                                    className="disbaled-optn-color"
                                    value={null}
                                >
                                    {"Height"}
                                </Select.Option>
                                {Heights.map((height, index) => {
                                    return (
                                        <Select.Option value={height} key={index}>
                                            {height}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </div>
                    <div
                        className="col-12 col-sm-12 col-md-6 select_floating"
                        id={"select_eye_color"}
                    >
                        <label className="font-weight-bold">
                            {t('IdInformation.IdInformationForm.eyeColorLabel')}
                        </label>
                        <Form.Item
                            className={
                                "select-float group-floating-label group-floating-label-second position-relative"
                            }
                            name="eye_color"
                            rules={[]}
                        >
                            <Input
                                onFocus={() => onSelectFocused("select_eye_color")}
                                onBlur={() => onSelectBlurred("select_eye_color")}
                                suffixIcon={
                                    <img
                                        src={Images.down_arrow_select}
                                        width={15}
                                        alt="down arrow"
                                        className="img-fluid"
                                    />
                                }
                                placeholder={""}
                                className="inp-form-wrap"
                            ></Input>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default IdentityDocForm;
