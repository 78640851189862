import React, { Component } from "react";
import { Input } from "antd";
import { onNumberBlurred, onNumberFocused } from "../controller/Global";

export default class DollarInputField extends Component {
  state = {
    messsage: null,
  };
  handleDollarField = (value, name) => {
    if (value[0] === "$") {
      value = value?.replace("$", "");
      this.props.formRef.current.setFieldsValue({
        [name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    }
    if (value === "0.0") {
      value = value + "0";
      this.props.formRef.current.setFieldsValue({
        [name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      });
    } else {
      value = value?.toString();
      value = value?.replace(/\./g, "");
      value = value?.replace(/,/g, "");
      if (value[0] === "0" && value[1] === "0") {
        value = value.substring(2);
      }

      if (value) {
        if (value.length === 1) {
          value = "0.0" + value;
          this.props.formRef.current.setFieldsValue({
            [name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else if (value.length === 2) {
          value = "0." + value;
          this.props.formRef.current.setFieldsValue({
            [name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        } else {
          value = value.slice(0, -2) + "." + value.substr(-2);
          if (value[0] === "0") {
            value = value.substring(1);
          }
          this.props.formRef.current.setFieldsValue({
            [name]: value.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          });
        }
      }
    }
  };
  enterOnlyNumber = (event) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    // const isValidecimal = new RegExp(".").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

  render() {
    return (
      <div>
        <Input
          className={this.props.className}
          onFocus={() => onNumberFocused(this.props.name)}
          onBlur={() => onNumberBlurred(this.props.name)}
          placeholder={this.props.placeholder}
          onChange={(e) =>
            this.handleDollarField(e?.target?.value, this.props?.id)
          }
          onKeyPress={this.enterOnlyNumber}
          value={this.props.value}
        />
      </div>
    );
  }
}
