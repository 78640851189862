import React, { Component } from "react";
import { Button, message, Radio } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { methods } from "../../controller/Global";
import { getTransactionOne } from "../../controller/api/customerAPI";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";

class DealInfoStepOne extends Component {
  state = {
    value: this.props.deal_agreed,
  };

  componentDidMount() {
    let { params } = this.props.match;
    if (params.method === methods.edit)
      if (this.props.current_transaction && this.props.current_transaction.id) {
        this.setState({
          value: this.props.current_transaction.deal_info.deal_agreed,
        });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.deal_info.id !== parseInt(params.id)) {
              message.error("Invalid URL for updating");
              history.push(routes.dashboard.self);
            } else {
              this.setState({ value: res.data.deal_info.deal_agreed });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 ">
            <div className="transition-information-heading w-100 font-weight-bold mb-3">
              Have you agreed to a deal?
            </div>
            <div className="row mx-0 radio-find-us">
              <Radio.Group
                className="w-100"
                onChange={this.onChange}
                value={this.state.value}
              >
                <Radio className="w-100 flex-align-center px-3" value={true}>
                  Yes
                </Radio>
                <Radio className="w-100 flex-align-center px-3" value={false}>
                  No
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="steps-action deal-info-main flex-align-center justify-content-end w-100">
            <Button
              type="primary"
              onClick={() => this.props.next(this.state.value)}
            >
              Next
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};
export default withRouter(connect(mapStateToProps)(DealInfoStepOne));
