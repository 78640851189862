import React, { useEffect, useState } from "react";
import ScannerBorder from "../ScannerBorder/ScannerBorder";
import { Image as Images } from "../../Images";
import WarningPopModal from "../../modal/WarningPopModal";
import Camera from "../Camera/Camera";
import { useFilePicker } from "use-file-picker";

export type CaptureDocProps = {
  insuranceInfo: any;
  setInsuranceInfo: (agr: any) => void;
  onRevokeObjectURL: any;
  setHideSideBar?: (arg: boolean) => void;
};

export const CaptureInsuranceDoc = ({
  insuranceInfo,
  setInsuranceInfo,
  onRevokeObjectURL,
  setHideSideBar,
}: CaptureDocProps) => {
  const [openInsuranceFileSelector] = useFilePicker({
    accept: "image/*",
    multiple: false,
    readAs: "ArrayBuffer",
    validators: [
      {
        validateBeforeParsing: async (config, plainFiles) =>
          new Promise((res) => res()),
        validateAfterParsing: async (config, file, reader) => {
          setInsuranceInfo({
            ...insuranceInfo,
            insuranceImage: file,
          });
          setInsuranceImage(file);
          return new Promise((res) => res());
        },
      },
    ],
  });

  const [insuranceImage, setInsuranceImage] = useState<Blob | undefined>();
  const [openScanDocCamera, setOpenScanDocCamera] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [failedScanWarning, setFailedScanWarning] = useState<boolean>(false);

  const [openCameraFrontId, setOpenCameraFrontId] = useState<boolean>(false);

  const b64toBlob = (dataURI: string) => {
    let byteString = atob(dataURI.split(",")[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  };

  const onGetCaptureImage = (image: string) => {
    const blobImag = b64toBlob(image);
    setInsuranceImage(blobImag);
  };

  useEffect(() => {
    if (setHideSideBar) {
      setHideSideBar(openScanDocCamera);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openScanDocCamera, setHideSideBar]);

  useEffect(() => {
    if (insuranceImage) {
      URL.revokeObjectURL(URL.createObjectURL(insuranceImage));

      setInsuranceInfo({
        ...insuranceInfo,
        insuranceImage: insuranceImage,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRevokeObjectURL, insuranceImage]);
  const frontCameraClicked = () => {
    if (true) {
      openInsuranceFileSelector();
    } else {
      setOpenCameraFrontId(true);
      setOpenScanDocCamera(true);
    }
  };

  return (
    <>
      <div className="choose-file-div choose-file-div-file row">
        <div className="col-12 col-sm-6 mx-auto">
          <p className="subTitle">Tap to open camera</p>

          {insuranceImage || insuranceInfo.insuranceImage ? (
            <img
              src={
                insuranceImage
                  ? URL.createObjectURL(insuranceImage)
                  : insuranceInfo.insuranceImage
              }
              alt="insurance icon"
              className="img-fluid"
              width={"300px"}
              onClick={() => frontCameraClicked()}
            />
          ) : (
            <ScannerBorder>
              <img
                src={Images.cameraIcon}
                alt="license icon"
                className="img-fluid"
                onClick={() => frontCameraClicked()}
              />
            </ScannerBorder>
          )}
          <Camera
            isOpen={openCameraFrontId}
            onClose={() => {
              setOpenCameraFrontId(false);
              setOpenScanDocCamera(false);
            }}
            getImageCapture={(img: string) => onGetCaptureImage(img)}
          />
        </div>
      </div>
      <WarningPopModal
        title={"Before uploading a photo of your insurance, please read below:"}
        subTitle={
          "Please place your insurance on flat and dark surface, capture the image without flash, and make sure the entire ID card is in the image (do not crop the edges)"
        }
        submitBtnTitle={"Scan Insurance"}
        onOpen={warningModal}
        onSubmit={() => {
          setWarningModal(false);
          setOpenScanDocCamera(true);
        }}
      />
      <WarningPopModal
        title={"Could not extract information!"}
        subTitle={" Please try again later"}
        submitBtnTitle={"OK"}
        onOpen={failedScanWarning}
        onSubmit={() => {
          setFailedScanWarning(false);
        }}
      />
    </>
  );
};

export default CaptureInsuranceDoc;
