import React, { Component } from "react";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { Modal } from "antd";
import { getTransactionOne } from "../../controller/api/customerAPI";
import { connect } from "react-redux";
import {
  fetchTransactionAction,
  setCurrentTransactionAction,
  updateTransactionAction,
} from "../../store/actions/transactionAction";
import { Image as Images } from "../Images";
import { clearTutorial } from "../../controller/localStorageHandler";
import { withTranslation } from "react-i18next";

class TransactionAddConfirmation extends Component {
  handleClose = () => {
    if (
      this.props.match.path === routes.dashboard.transaction.transaction_widget
    ) {
      getTransactionOne(
        this.props.user.default_dealership,
        this.props.match.params.trans_id
      ).then((resp) => {
        this.props.updateTransactionAction(resp.data);
        this.props.setCurrentTransactionAction(resp.data);
        clearTutorial();
        history.push(routes.dashboard.self);
      });
    } else {
      this.props
        .fetchTransactionAction(this.props.user.default_dealership)
        .then(() => {
          clearTutorial();
          history.push(routes.dashboard.self);
        });
    }
  };

  render() {
    let { is_co_buyer, t } = this.props;
    return (
      <Modal
        title={false}
        open={this.props.visible}
        onOk={() => this.handleClose()}
        onCancel={() => this.handleClose()}
        className="main-modal close-btn-none add-transition-confirm"
        okText={t('utils.continue')}
        width="31%"
        centered
      >
        <div className="row modal-inner-row-data">
          <div className="col-12 text-center">
            <img
              src={Images.check_icon_rounded}
              className="img-fluid"
              alt="check icon"
            />
            <p className="mb-0 mt-1 font-weight-bold">
              {is_co_buyer
                ? t('transactionForm.stepFive.popModal.coBuyerAddedTitle')
                : t('transactionForm.stepFive.popModal.buyerAddedTitle')
              }
            </p>
            <small>
              {t('transactionForm.stepFive.popModal.addedSubTitle')}
            </small>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapActionToProps = {
  updateTransactionAction,
  setCurrentTransactionAction,
  fetchTransactionAction,
};
export default withTranslation()(connect(
    mapStateToProps,
    mapActionToProps
)(TransactionAddConfirmation));
