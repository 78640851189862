import React, { Component } from "react";
import { Image as Images } from "../Images";
import { Button, Form, message, Modal } from "antd";
// import {Input} from "antd";
import { NavLink } from "react-router-dom";
import { routes } from "../../controller/routes";
import { resetPassword } from "../../controller/AuthService";
import { history } from "../../controller/history";
import TextField from "@mui/material/TextField";

class ResetPassword extends Component {
  state = {
    success: false,
  };
  handleSubmit = (values) => {
    const { params } = this.props.match;
    values["uid"] = params.uid;
    values["token"] = params.token;
    resetPassword(values)
      .then(() => {
        this.setState({ success: true });
      })
      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).forEach((e) => {
            message.error(`${e}: ${err.response.data[e]}`);
          });
        }
      });
  };

  render() {
    return (
      <div className="container-fluid login-div-fluid py-5">
        <div className="row login-main-row mx-auto py-3">
          <div className="col-12 logo-div-main">
            <NavLink to={routes.dashboard.self} className="d-inline-block">
              <img
                src={Images.logo_small_header}
                alt="logo main"
                className="img-fluid logo-small"
              />
            </NavLink>
          </div>
          <div className="col-12 login-card-main bg-white h-auto">
            <h6 className="font-weight-bold text-center">
              {this.props.match.params.type === "new" ? "Create" : "Reset"}{" "}
              Password
            </h6>
            <div className="login_form_design w-100">
              <Form onFinish={this.handleSubmit}>
                {/*<Form.Item name="email" label={""} rules={[{*/}
                {/*  required: true,*/}
                {/*  message: 'this field is required'*/}
                {/*}]} className={"group-floating-label position-relative"}>*/}

                {/*  <Input*/}
                {/*    placeholder="Email"*/}
                {/*    // suffix={<label className="floating-label" htmlFor="name">Email</label>}*/}
                {/*  />*/}
                {/*  <span className="eye-tag-show position-absolute d-flex m-auto">*/}
                {/*                        <img src={Images.eye_icon} alt="eye icon" className="img-fluid"/>*/}
                {/*                   </span>*/}
                {/*</Form.Item>*/}
                <Form.Item
                  name="password"
                  label={""}
                  rules={[
                    {
                      required: true,
                      message: "this field is required",
                    },
                  ]}
                  className={"group-floating-label position-relative"}
                >
                  <TextField
                    autoCapitalize={"off"}
                    className={"w-100"}
                    id="outlined-basic"
                    label="Password"
                    type="password"
                    variant="outlined"
                  />
                </Form.Item>

                <Form.Item
                  name="confirm_password"
                  label={""}
                  rules={[
                    {
                      required: true,
                      message: "this field is required",
                    },
                  ]}
                  className={"group-floating-label position-relative"}
                >
                  <TextField
                    autoCapitalize={"off"}
                    className={"w-100"}
                    id="outlined-basic"
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                  />
                </Form.Item>
                <Form.Item className="mb-0">
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="w-100 text-white border-0 font-weight-normal"
                  >
                    {this.props.match.params.type === "new"
                      ? "Create"
                      : "Reset"}{" "}
                    Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <Modal
          title={false}
          open={this.state.success}
          onOk={() => history.push(routes.logout)}
          onCancel={() => history.push(routes.logout)}
          className="main-modal close-btn-none add-transition-confirm"
          okText="Login"
          width="31%"
          centered
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                src={Images.check_icon_rounded}
                className="img-fluid"
                alt="check icon"
              />
              <p className="mb-0 mt-1 font-weight-bold">
                {this.props.match.params.type === "new"
                  ? "Password Creation Successful!"
                  : "Reset Password Successful!"}
              </p>
              <small>
                {this.props.match.params.type === "new"
                  ? "You can now sign in with your password."
                  : "You can now sign in with your new password."}
              </small>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ResetPassword;
