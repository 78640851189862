import React, { Component } from "react";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import { reverse } from "named-urls";
import { routes } from "../controller/routes";
import { methods } from "../controller/Global";
import { Button } from "antd";
import { withTranslation } from "react-i18next";
import get from 'lodash/get';



class VehicleForPurchaseDashboard extends Component {
  render() {
    const { vehicle_for_purchase, transaction, is_co_buyer, btnVisible, t } =
      this.props;
    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row flex-align-center-between px-3">
            <div className="col-md-11">
            <div className="profile-card flex-align-center mb-3">

              <div className={"icon-circle-transparent"}>
                <img
                    alt="add icon"
                    className="img-fluid"
                    src={Images.vehicleIcon}
                />
              </div>

              <div className="profile-info">
                <span className="license-text text-uppercase">{t('vehicleForPurchaseInformation.title')}</span>
              </div>
            </div>
            </div>
            <div className={`col-md-1 mb-2 ${!is_co_buyer && btnVisible && !vehicle_for_purchase ? 'align-tem-right-right' : ''}`}>

            {!is_co_buyer &&
              (btnVisible ? (
                <>
                  {vehicle_for_purchase ? (
                    <Link
                      to={reverse(
                        routes.dashboard.transaction.vehicle_for_purchase,
                        {
                          trans_id: transaction,
                          method: methods.edit,
                          id: vehicle_for_purchase.id,
                        }
                      )}
                      className="bg-transparent border-0 shadow-none p-0"
                    >
                      <Button className={"btn-update"}>{t('utils.update')}</Button>
                    </Link>
                  ) : (
                    <Link
                      to={reverse(
                        routes.dashboard.transaction.vehicle_for_purchase,
                        {
                          trans_id: transaction,
                          method: methods.add,
                        }
                      )}
                      className="bg-transparent border-0 shadow-none p-0"
                    >
                      <Button className="btn-add">{t('utils.add')}</Button>
                    </Link>
                  )}
                </>
              ) : (
                ""
              ))}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="col-12">
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('vehicleForPurchaseInformation.vin')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-uppercase">
                  {get(vehicle_for_purchase, "vin_number", "-")}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('vehicleForPurchaseInformation.stockNo')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-uppercase">
                  {get(vehicle_for_purchase, "stock_number", "-")}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('vehicleForPurchaseInformation.make')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text text-capitalize">
                  {get(vehicle_for_purchase, "make", "-")}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text text-capitalize">
                  {t('vehicleForPurchaseInformation.model')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {get(vehicle_for_purchase, "model", "-")}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('vehicleForPurchaseInformation.year')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {get(vehicle_for_purchase, "year", "-")}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('vehicleForPurchaseInformation.trim')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {get(vehicle_for_purchase, "trim", "-")}
                </li>
              </ul>
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                  {t('vehicleForPurchaseInformation.condition')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {get(vehicle_for_purchase, "condition", "-")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(VehicleForPurchaseDashboard);
