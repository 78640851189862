import React, { useEffect, useState } from "react";
import { Button, message, Select, Spin } from "antd";
import { connect } from "react-redux";
import { fetchProfileAction } from "../store/actions/authAction";
import { Image } from "./Images";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import {
  fetchTransactionAction,
  setCurrentTransactionAction,
} from "../store/actions/transactionAction";
import { Backdrop, Paper } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { updateCustomerInfoByID } from "../controller/api/customerAPI";
import InfoPopModal from "./modal/InfoPopModel";

const Welcome = (props) => {
  const { t, i18n } = useTranslation();
  const currentUsedLanguage = i18n.language;
  const [welcomeState, setWelcomeState] = useState({
    openPopup: false,
    isLoading: false,
    defaultLanguage: "en",
  });
  const preferenceLanguageEnabledValue =
    props?.transactions[0]?.dealership?.preference_language_enabled;

  const onChangeCustomerPreferenceLanguage = async (value) => {
    setWelcomeState((prev) => ({ ...prev, openPopup: false, isLoading: true }));
    const userID = props?.user?.id;
    const updatePreferenceLanguage = {
      preference_language: value,
    };

    if (userID) {
      try {
        await updateCustomerInfoByID(updatePreferenceLanguage, userID).then(
          (res) => {
            console.log(res);
          }
        );
        i18n.changeLanguage(value).then((r) => {
          setWelcomeState((prev) => ({ ...prev, openPopup: false, isLoading: false }));
        });
      } catch (error) {
        console.error("Error updating personal reference:", error);
        Object.keys(error.response.data).forEach((e) => {
          message.error(error.response.data[e]);
        });
      }
    }
  };

  useEffect(() => {
    props?.fetchTransactionAction(props.user.default_dealership);
    document.getElementsByTagName("body")[0].classList.add("bg-white-fi");
    document
      .getElementsByTagName("body")[0]
      .classList.add("menu-button-remove");

    if (
      props?.user?.preference_language &&
      props?.user?.preference_language !== i18n.language
    ) {
      setWelcomeState((prev) => ({
        ...prev,
        isLoading: true,
        defaultLanguage: props?.user?.preference_language,
      }));
      i18n.changeLanguage(props?.user?.preference_language).then((r) => {
        setWelcomeState((prev) => ({ ...prev, isLoading: false }));
      });
    }
  }, []);

  return (
    <>
      <Paper
        className={"welcome-logo"}
        sx={{ height: "96px", borderBottom: "1px solid #0000001F" }}
        elevation={0}
      >
        <img
          className="img-fluid"
          alt="welcome-logo"
          src={Image.eunifiLogo}
          style={{ height: "43px" }}
        />
      </Paper>
      <div className={"welcome-container"}>
        <div className={"welcome-content"}>
          <p className="welcome-heading">{t("welcomePage.title")} </p>
          <p>{t("welcomePage.subTitle")}</p>
          <ol className="p-3">
            <li>{t("welcomePage.bulletOne")}</li>
            <li>{t("welcomePage.bulletTwo")}</li>
            <li>{t("welcomePage.bulletThree")}</li>
            <li>{t("welcomePage.bulletFour")}</li>
          </ol>
          <div style={{ backgroundColor: "#F2F2F2", padding: "16px 14px" }}>
            <p>
              <Trans i18nKey="welcomePage.content" />
            </p>
          </div>
        </div>
        <div className={"welcome-btn mt-5"}>
          {preferenceLanguageEnabledValue ? (
            <div>
              <Select
                style={{
                  width: 120,
                  position: "absolute",
                }}
                onChange={(value) => {
                  setWelcomeState((prev) => ({
                    ...prev,
                    openPopup: true,
                    defaultLanguage: value,
                  }));
                }}
                value={welcomeState.defaultLanguage}
                options={[
                  {
                    value: "en",
                    label: "English",
                  },
                  {
                    value: "es",
                    label: "Spanish",
                  },
                ]}
              ></Select>
              <LanguageIcon
                style={{
                  position: "relative",
                  top: "3px",
                  left: "8px",
                }}
              />
            </div>
          ) : (
            <></>
          )}

          {props.transaction ? (
            <Button type={"primary"} onClick={() => props.hideWelecome()}>
              {t("welcomePage.getStart")}
            </Button>
          ) : (
            <Button
              type={"primary"}
              onClick={() => {
                props.fetchProfileAction();
                props.setCurrentTransactionAction(
                  props.transactions.sort((transactionOne, transactionTwo) => {
                    var transOneCreated = new Date(transactionOne.created);
                    var transTwoCreated = new Date(transactionTwo.created);
                    return transOneCreated - transTwoCreated;
                  })[0]
                );
                history.push(routes.dashboard.self);
              }}
            >
              {t("welcomePage.getStart")}
            </Button>
          )}
        </div>
      </div>
      <InfoPopModal
        title={t("welcomePage.welcomeText")}
        subTitle={""}
        submitBtnTitle={t("utils.yes")}
        cancelBtnTitle={t("utils.cancel")}
        onOpen={welcomeState.openPopup}
        onSubmit={() => {
          onChangeCustomerPreferenceLanguage(welcomeState.defaultLanguage);
        }}
        onClose={() => {
          setWelcomeState((prev) => ({
            ...prev,
            openPopup: false,
            defaultLanguage: currentUsedLanguage,
          }));
        }}
      />
      <Backdrop open={welcomeState.isLoading}>
        <div
          className="container-fluid main-inner-fluid"
          style={{ paddingTop: "100px" }}
        >
          <div className="col-12 text-center spiner">
            <Spin size="large" />
          </div>
        </div>
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    transactions: state.transactions.transactions,
  };
};

export default connect(mapStateToProps, {
  fetchProfileAction,
  fetchTransactionAction,
  setCurrentTransactionAction,
})(Welcome);
