import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Upload } from "antd";
import { buyer_type } from "../../controller/Global";
import { addDocument } from "../../controller/api/customerAPI";
import { connect } from "react-redux";

class DocumentUpload extends Component {
  forRef = React.createRef();

  state = {
    doc: null,
    FileList: [],
  };

  handleChange = (doc) => {
    doc.onSuccess();
    this.setState({ doc: doc.file });
  };

  handleRemove = () => {
    this.setState({ doc: null });
  };

  handleSubmit = () => {
    this.forRef.current.validateFields().then((values) => {
      const { is_co_buyer } = this.props.current_transaction;
      let form_data = new FormData();
      form_data.append("transaction", this.props.current_transaction.id);
      form_data.append("customer", this.props.user.id);
      form_data.append("document_type", values.document_type);
      form_data.append("document", this.state.doc);
      form_data.append(
        "buyer_type",
        is_co_buyer ? buyer_type.co_buyer : buyer_type.primary_buyer
      );
      addDocument(form_data)
        .then(() => {
          this.props.onClose();
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(`${e}: ${err.response.data[e]}`);
            });
          }
        });
    });
  };

  handleData = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    this.setState({ FileList: fileList });
  };

  render() {
    return (
      <Modal
        title={"Upload Document"}
        open={this.props.visible}
        onOk={() => this.handleSubmit()}
        onCancel={this.props.onClose}
        className="main-modal footer-btn-document-modal draw-sign-modal upload-docs-modal"
        okText="Upload"
        width="31%"
        cancelText="Cancel"
        centered
      >
        <div className="row modal-inner-row-data">
          <div className="col-12">
            <Form ref={this.forRef} className="w-100">
              <div className="row">
                <div className="col-12">
                  <Form.Item
                    name="document_type"
                    label={""}
                    rules={[
                      {
                        required: true,
                        message: "this field is required",
                      },
                    ]}
                    className={"group-floating-label mb-0"}
                  >
                    <Input
                      placeholder="Ex. Mortgage, credit card history, etc."
                      className="inp-form-wrap"
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Upload
                    multiple={false}
                    customRequest={(obj) => this.handleChange(obj)}
                    onRemove={this.handleRemove}
                    className="upload-doc-btn"
                    fileList={[...this.state.FileList]}
                    onChange={this.handleData}
                  >
                    <Button>
                      {/*<img src={Images.upload_file_icon} alt="upload_icon" className="img-fluid"/>*/}
                      Browse
                    </Button>
                  </Upload>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_transaction: state.current_transaction,
    user: state.user,
  };
};

export default connect(mapStateToProps)(DocumentUpload);
