import React from "react";
import { Button, Modal } from "antd";
import { Image as Images } from "../Images";
import {withTranslation} from "react-i18next";
export const InfoPopModal = ({
  title,
  onSubmit,
  subTitle,
  submitBtnTitle = 'Yes',
  cancelBtnTitle = 'No',
  onOpen,
    onClose
}) => {
  return (
    <Modal
      onCancel={""}
      title={""}
      onOk={""}
      width={"600px"}
      open={onOpen}
      className={`main-modal exit-modal-back`}
      centered
      footer={[
        <Button key="back" onClick={onClose}>
          {cancelBtnTitle}
        </Button>,
        <Button
            key="submit"
            type={"danger"}
            onClick={onSubmit}
        >
          {submitBtnTitle}
        </Button>,
      ]}
    >
      <div className="row prompt-modal-row">
        <div className="col-12 text-center">
          <img
            alt="icon question mark"
            className="img-fluid"
            src={Images.error_prompt_img}
          />
          <h4 style={{ color: "#296b68" }}>{title}</h4>
          <h5>{subTitle}</h5>
        </div>
      </div>
    </Modal>
  );
};
export default withTranslation()(InfoPopModal);
