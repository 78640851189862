import React, { Component } from "react";
import { Button, Steps } from "antd";
import UploadRegistration from "./UploadRegistration";
import VehicleInformation from "./VehicleInformation";
import SideBar from "../sidebar/SideBar";
import PopConfirm from "../modal/PopConfirm";
import { Image as Images } from "../Images";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class TradeRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      registration_front: [],
      visible: false,
      fixed: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  setFiles = ({ registration_front }) => {
    this.setState({ registration_front }, () => this.next());
  };

  handleExit = (visible) => {
    this.setState({ visible });
  };

  render() {
    const steps = [
      {
        title: "",
        content: (
          <UploadRegistration
            registration_front={this.state.registration_front}
            next={(values) => this.setFiles(values)}
          />
        ),
      },
      {
        title: "",
        content: (
          <VehicleInformation
            transaction={this.props.match.params.trans_id}
            registration_front={this.state.registration_front}
            back={() => this.prev()}
          />
        ),
      },
    ];

    const items = steps.map((item, index) => ({
      key: `trade_${index}`,
      title: item.title,
    }));

    const { current, visible } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid main-inner-fluid mt-66">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 transition-info-div position-relative">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => this.handleExit(true)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span> {t('utils.back')}</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('vehicleForTradeInformation.tradeRegistration')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 add-new-customer-div trade-div-tab">
                      <Steps current={current} items={items} />
                      <div className="steps-content">
                        {steps[current].content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopConfirm
          visible={visible}
          user={this.props.user}
          exit={true}
          handleExit={() => this.handleExit(false)}
        >
          {t('vehicleForTradeInformation.popModal.exitTitle')}
        </PopConfirm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};


export default withTranslation()(connect(mapStateToProps)(TradeRegistration));
