import React from "react";
import { Button, Modal } from "antd";
import { Image as Images } from "../Images";
export const WarningPopModal = ({
  title,
  onSubmit,
  subTitle,
  submitBtnTitle,
  onOpen,
}) => {
  // @Som TODO make it reuseable component for alert pop modal
  return (
    <Modal
      // onCancel={""}
      title={""}
      onOk={""}
      width={"600px"}
      open={onOpen}
      // className="prompt-modal close-btn-none pay-cash-confirm-modal ninty-days-exceed-modal"
      className="prompt-modal close-btn-none"
      footer={false}
      centered
    >
      <div className="row prompt-modal-row">
        <div className="col-12 text-center">
          <img
            alt="icon question mark"
            className="img-fluid"
            src={Images.error_prompt_img}
          />

          <h4 style={{ color: "#EB5757" }}>{title}</h4>
          <p>{subTitle}</p>
          <div className="row mx-0 align-items-center justify-content-between">
            <Button onClick={onSubmit} type={"primary"} className="w-100">
              {submitBtnTitle}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default WarningPopModal;
