import moment from "moment";


function AgeValidator (value){
	let date_of_birthday = moment(`${value.year}-${value.month}-${value.day}`, 'YYYY-MM-DD')
	let age = moment().diff(date_of_birthday, 'years')
	if (!(age >= 16 && age <= 95)) {
		return false
	}
	return true
}

function SsnValidator (value) {
	const isNotAllSameDigits = !/^(\d)\1+$/.test(value.replace(/-/g, ""));
	const isNotNineDigitSequence = !/(012345678|123456789)/.test(value.replace(/-/g, ""));

	if (!(isNotAllSameDigits && isNotNineDigitSequence)) {
		return false
	}
	return true
}

function PhoneValidator (value){
	if (value && value.length !== 10) return false
	return true
}

export {PhoneValidator, SsnValidator, AgeValidator}