import React, { Component } from "react";
import { Button, message, Steps } from "antd";
import AddressInformation from "./AddressInformation";
import EmploymentInformation from "./EmploymentInformation";
import SubmitApplication from "./SubmitApplication";
import BasicInformation from "./BasicInformation";
import { connect } from "react-redux";
import {
  getHardCredit,
  getTransactionOne,
} from "../../controller/api/customerAPI";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { setCurrentTransactionAction } from "../../store/actions/transactionAction";
import SideBar from "../sidebar/SideBar";
import { confirm_status, methods } from "../../controller/Global";
import PopConfirm from "../modal/PopConfirm";
import { Image as Images } from "../Images";
import { withTranslation, Trans } from "react-i18next";

class HardCreditApplicationMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      hard_credit: null,
      visible: false,
      pop_confirm: null,
      fixed: false,
    };
  }

  componentDidMount() {
    let { params } = this.props.match;
    if (params.method === methods.edit) {
      this.setState({ pop_confirm: confirm_status.edit });
    } else {
      this.setState({ pop_confirm: confirm_status.add });
    }
    if (this.props.current_transaction && this.props.current_transaction.id) {
      console.log(this.props.current_transaction);
      this.setState({
        hard_credit: this.props.current_transaction.hard_credit_info,
      });
    } else {
      getTransactionOne(this.props.user.default_dealership, params.trans_id)
        .then((res) => {
          if (res.data.hard_credit_info.id !== parseInt(params.id)) {
            message.error("Invalid Hard Credit Info.");
            history.push(routes.dashboard.self);
          } else {
            this.props.setCurrentTransactionAction(res.data);
            this.setState({ hard_credit: res.data.hard_credit_info });
          }
        })
        .catch(() => {
          message.error("Error while fetching transaction details");
          history.push(routes.dashboard.self);
        });
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  fetchHardCredit = () => {
    const { hard_credit } = this.state;
    getHardCredit(hard_credit.id).then((resp) => {
      this.setState({ hard_credit: resp.data }, () => this.next());
    });
  };

  setHardCredit = (hard_credit) => {
    this.setState({ hard_credit }, () => this.fetchHardCredit());
  };

  handleExit = (visible, exit = false) => {
    if (exit) {
      this.setState({ pop_confirm: confirm_status.exit, visible });
    } else {
      this.setState({ visible });
    }
  };

  get_message = () => {
    const { pop_confirm } = this.state;
    const { t } = this.props;
    if (pop_confirm === confirm_status.add) {
      return t('hardCreditForm.popModal.addTitle');
    } else if (pop_confirm === confirm_status.edit) {
      return t('hardCreditForm.popModal.updatedTitle');
    } else {
      return t('hardCreditForm.popModal.exitTitle');
    }
  };

  render() {
    const { t } = this.props;
    const { current, hard_credit } = this.state;
    const { params } = this.props.match;
    const steps = [
      {
        title: "",
        content: (
          <BasicInformation
            hard_credit={hard_credit}
            trans_id={params.trans_id}
            next={(data) => this.setHardCredit(data)}
            prev={() => this.prev()}
            match={this.props.match}
          />
        ),
      },
      {
        title: "",
        content: (
          <AddressInformation
            trans_id={params.trans_id}
            hard_credit={hard_credit}
            next={() => this.fetchHardCredit()}
            prev={() => this.prev()}
            match={this.props.match}
            is_edit={this.props.match.params.method === methods.edit}
          />
        ),
      },
      {
        title: "",
        content: (
          <EmploymentInformation
            trans_id={params.trans_id}
            hard_credit={hard_credit}
            next={() => this.fetchHardCredit()}
            prev={() => this.prev()}
            match={this.props.match}
            is_edit={this.props.match.params.method === methods.edit}
          />
        ),
      },
      {
        title: "",
        content: (
          <SubmitApplication
            onSubmit={() => this.handleExit(true)}
            trans_id={params.trans_id}
            hard_credit={hard_credit}
            prev={() => this.prev()}
            match={this.props.match}
          />
        ),
      },
    ];

    const items = steps.map((item, index) => ({
      key: `hard_credit_${index}`,
      title: item.title,
    }));

    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 position-relative transition-info-div">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => this.handleExit(true, true)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('hardCreditForm.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 add-new-customer-div hard-credit-col-main">
                      <Steps current={current} items={items} />
                      <div className="steps-content">
                        {steps[current].content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopConfirm
          visible={this.state.visible}
          user={this.props.user}
          exit={this.state.pop_confirm === confirm_status.exit}
          handleExit={() => this.handleExit(false)}
        >
          {this.get_message()}
        </PopConfirm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
  };
};

const mapActionToProps = {
  setCurrentTransactionAction,
};

export default withTranslation()(connect(
    mapStateToProps,
    mapActionToProps
)(HardCreditApplicationMain));
