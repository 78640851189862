import { combineReducers } from "redux";
import { LOGOUT_SUCCESS } from "../actions/authAction";
import { userLoginReducer } from "./authReducer";
import {
  fetchTransactionReducer,
  setCoBuyerViewReducer,
  setCurrentTransactionReducer,
  setHistoryReducer,
  setCurrentTransactionHistoryReducer,
} from "./transactionReducer";
import { promptReducer } from "./promptReducer";
import { sideBarReducer } from "./sidebarReducer";

const allReducers = combineReducers({
  user: userLoginReducer,
  transactions: fetchTransactionReducer,
  current_transaction: setCurrentTransactionReducer,
  viewCoBuyer: setCoBuyerViewReducer,
  history: setHistoryReducer,
  prompt: promptReducer,
  sidebar: sideBarReducer,
  current_transactions_type: setCurrentTransactionHistoryReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
