import React, { useEffect } from "react";
import { DatePicker, Form, Input } from "antd";
import { onNumberBlurred, onNumberFocused } from "../../../controller/Global";
import {useTranslation} from "react-i18next";
import moment from 'moment';

export const InsuranceDocForm = ({
  handleSubmit,
  formRef,
  selectedEffective,
  setSelectedEffective,
  selectedExpiry,
  setSelectedExpiry,
}) => {

  const { t, i18n } = useTranslation();
  const lng = navigator.language;
  const formatDates = (label) => {
    if (label === "effective_date" && selectedEffective) {
      return `${selectedEffective.month}/${selectedEffective.day}/${selectedEffective.year}`;
    } else if (label === "expiration_date" && selectedExpiry) {
      return `${selectedExpiry.month}/${selectedExpiry.day}/${selectedExpiry.year}`;
    } else {
      return "";
    }
  };

  const onDateChanges = (value, label) => {
    const updatedValue = {
      day: moment(value, 'MM/DD/YYYY').format('DD'),
      month: moment(value, 'MM/DD/YYYY').format('MM'),
      year: moment(value, 'MM/DD/YYYY').format('YYYY')
    }
    if (label === "effective_date") {
      setSelectedEffective(updatedValue);
    } else if (label === "expiration_date") {
      setSelectedExpiry(updatedValue);
    }
  };


  return (
    <>
      <Form ref={formRef} onFinish={handleSubmit}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6">
            <label className="font-weight-bold">
              {t('insuranceInformation.insuranceInformationForm.providerLabel')} *
            </label>
            <Form.Item
                className={
                  "group-floating-label group-floating-label-second position-relative"
                }
                name="provider"
                label={""}
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
            >
              <Input
                  placeholder=" "
                  className="inp-form-wrap"
              />
            </Form.Item>
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <label className="font-weight-bold">
              {t('insuranceInformation.insuranceInformationForm.policyNumberLabel')} *
            </label>
            <Form.Item
                className={
                  "group-floating-label group-floating-label-second position-relative"
                }
                name="policy_number"
                label={""}
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
            >
              <Input
                  placeholder=" "
                  className="inp-form-wrap"
              />
            </Form.Item>
          </div>
          <div
              className="col-12 col-sm-12 col-md-6 select_floating"
              id={"effective_date"}
          >
            <label className="font-weight-bold">
              {t('insuranceInformation.insuranceInformationForm.effectiveDateLabel')} *
            </label>
            <Form.Item
                className={
                  "group-floating-label select-float group-floating-label-second position-relative"
                }
                name="effective_date"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
            >
              <DatePicker
                allowClear={false}
                value={selectedEffective &&
                  moment(`${selectedEffective?.month}/${selectedEffective?.day}/${selectedEffective?.year}`, "MM/DD/YYYY").isValid()
                  ? moment(`${selectedEffective?.month}/${selectedEffective?.day}/${selectedEffective?.year}`, "MM/DD/YYYY")
                  : null}
                format={"MM/DD/YYYY"} onFocus={() => onNumberFocused("effective_date")}
                onBlur={() => onNumberBlurred("effective_date")}
                onChange={(e) => onDateChanges(e, "effective_date")}
                popupClassName="selected-date"
              />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-12 col-md-6" id={"select_date"}>
            <label className="font-weight-bold">
              {t('insuranceInformation.insuranceInformationForm.expirationDateLabel')} *
            </label>
            <Form.Item
                name="expiration_date"
                className={
                  "group-floating-label select-float group-floating-label-second position-relative"
                }
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
            >

              <DatePicker
                allowClear={false}
                value={selectedExpiry &&
                  moment(`${selectedExpiry?.month}/${selectedExpiry?.day}/${selectedExpiry?.year}`, "MM/DD/YYYY").isValid()
                  ? moment(`${selectedExpiry?.month}/${selectedExpiry?.day}/${selectedExpiry?.year}`, "MM/DD/YYYY")
                  : null}
                onFocus={() => onNumberFocused("expiration_date")}
                popupClassName="selected-date"
                onBlur={() => onNumberBlurred("expiration_date")}
                onChange={(e) => onDateChanges(e, "expiration_date")}
              />
            </Form.Item>
            <span className="subtext"></span>
          </div>
        </div>
      </Form>
    </>
  );
};

export default InsuranceDocForm;
