import { CHANGE_DEALERSHIP, LOGIN_SUCCESS } from "../actions/authAction";

export const userLoginReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS: {
      return { ...payload };
    }
    case CHANGE_DEALERSHIP: {
      return { ...state, default_dealership: payload };
    }
    default: {
      return state;
    }
  }
};
