import React, { Component } from "react";
import {getAPIUrl} from "../../controller/Global";
import {Modal} from "antd";

class PrivacyPolicy extends Component {
  render() {
    return (
      <>
          <Modal
              title={"Privacy Policy"}
              footer={false}
              centered
              open={this.props.visible}
              onOk={this.props.onClose}
              onCancel={this.props.onClose}
              closable={false}
              width={"600px"}
              className="privacy-policy-modal"
          >
              <iframe src={getAPIUrl('privacy_policy')} style={{width: '100%', height: '70vh'}}>
              </iframe>
          </Modal>
      </>
    );
  }
}

export default PrivacyPolicy;
