import React, { Component } from "react";
import { Button, Steps } from "antd";
import CoBuyer from "./CoBuyer";
import AddCoBuyer from "./AddCoBuyer";
import SideBar from "../sidebar/SideBar";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { Image as Images } from "../Images";
import { withTranslation } from "react-i18next";

class CoBuyerMain extends Component {
  state = {
    fixed: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  render() {
    const { t } = this.props;
    const { current } = this.state;
    const steps = [
      {
        title: "",
        content: <CoBuyer next={this.next} />,
      },
      {
        title: "",
        content: <AddCoBuyer prev={this.prev} />,
      },
    ];

    const items = steps.map((item, index) => ({
      key: `co_buyer_step_${index}`,
      title: item.title,
    }));

    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 transition-info-div position-relative">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => history.push(routes.dashboard.self)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('coBuyerInformation.addCoBuyer')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 add-new-customer-div trade-div-tab">
                      <Steps current={current} items={items} />

                      <div className="steps-content">
                        {steps[current].content}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(CoBuyerMain);
