import React, { Component } from "react";
import { Button, Form, Input, message } from "antd";
import { Image as Images } from "../Images";
import { confirm_status } from "../../controller/Global";
import PopConfirm from "../modal/PopConfirm";
import MessageSentModal from "./MessageSentModal";
import { createTechQuery } from "../../controller/api/customerAPI";
import { MDBInput } from "mdbreact";
import { connect } from "react-redux";
import { routes } from "../../controller/routes";
import { withTranslation } from "react-i18next";

class TechSupportMain extends Component {
  state = {
    visible: false,
    fixed: false,
    current: 0,
    pop_confirm: false,
    visibleMessage: false,
    characterLength: 200,
    buttonLoading: false,
  };
  formRef = React.createRef();
  handleExit = (visible) => {
    this.setState({ pop_confirm: confirm_status.exit, visible });
  };
  showMessageSent = (visible) => {
    this.setState({
      visibleMessage: visible,
    });
  };
  handleTextArea = (e) => {
    const length = e.target.value.length;
    const currentLength = 200 - length;
    if (currentLength >= 0) {
      this.setState({ characterLength: currentLength });
    } else {
      this.setState({ warningText: true });
    }
  };
  handleTech = () => {
    this.showMessageSent(false);
    this.props.history.push(routes.dashboard.self);
  };
  handleSubmit = (values) => {
    values.customer = this.props?.user?.id;
    this.setState({ buttonLoading: true });
    createTechQuery(values)
      .then(() => {
        this.showMessageSent(true);
      })
      .catch(() => {
        message.error("something went wrong");
        this.setState({
          buttonLoading: false,
        });
      });
  };

  render() {
    const { characterLength, warningText } = this.state;
    const {t} = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="row login-main-row main-row-width main-transition-div mx-auto">
            <div className="col-12">
              <div
                className={`row heading-fix-div add-transition-div-main ${
                  this.state.fixed && "nav-fix-div"
                }`}
              >
                <div className="col-12 transition-info-div position-relative">
                  <Button
                    className="bg-transparent border-0 p-0 back-btn-main position-absolute"
                    onClick={() => this.handleExit(true)}
                  >
                    <div className="back-btn-img-div">
                      <img
                        alt=""
                        className="img-fluid back-btn-black"
                        src={Images.back_btn_black}
                      />
                      <img
                        alt=""
                        className="img-fluid back-btn-primary"
                        src={Images.arrow_left_primary}
                      />
                    </div>
                    <span>{t('utils.back')}</span>
                  </Button>
                  <span className="d-inline-block w-100 text-center">
                    {t('techSupport.title')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 add-new-customer-div px-0 shadow-none">
              <div className="row transition-information-div mb-4">
                <div className="col-12">
                  <div className="row help-div-heading">
                    <div className="col-12 text-center">
                      <h5>{t('techSupport.contentOne')}</h5>
                      <h6>
                        {t('techSupport.contentTwo')}
                      </h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mx-0 tech-support-form">
                      <Form
                        ref={this.formRef}
                        className={"w-100 tech-support-form"}
                        onFinish={this.handleSubmit}
                      >
                        <div className="row">
                          <div className="col-12">
                            <Form.Item
                              name={"subject"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your subject!",
                                },
                              ]}
                              className="select-float group-floating-label group-floating-label-second position-relative"
                            >
                              <Input
                                placeholder=" "
                                suffix={
                                  <label
                                    className="floating-label"
                                    htmlFor="subject"
                                  >
                                    {t('techSupport.subjectLabel')}
                                  </label>
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className="col-12 message-div-main">
                            <Form.Item
                              name={"message"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your message!",
                                },
                              ]}
                              className="select-float group-floating-label group-floating-label-second position-relative"
                            >
                              <MDBInput
                                onChange={this.handleTextArea}
                                type="textarea"
                                maxLength={"200"}
                                label={t('techSupport.messagePlaceHolder')}
                                rows="5"
                              />
                            </Form.Item>
                            <small
                              style={{
                                color: "#818E9B",
                                fontSize: "12px",
                                lineHeight: "22px",
                                position: "relative",
                                top: "-25px",
                              }}
                            >
                              {" "}
                              {characterLength} {t('techSupport.messageTextHelper')}
                            </small>
                            {warningText && (
                              <small
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  lineHeight: "22px",
                                  position: "relative",
                                  top: "-10px",
                                }}
                              >
                                {" "}
                                {t('techSupport.messageWarningText')}
                              </small>
                            )}
                          </div>
                          <div className="col-12 text-right">
                            <Button
                              loading={this.state.buttonLoading}
                              type={"primary"}
                              htmlType="submit"
                              className="send-btn"
                            >
                              {t('utils.send')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopConfirm
          visible={this.state.visible}
          custom_message={
            "Exiting will not complete the process, but you can resume by\
                             selecting the transaction with the blue circle below."
          }
          show_custom={this.state.current !== 0}
          exit={this.state.pop_confirm === confirm_status.exit}
          handleExit={() => this.handleExit(false)}
        >
          Exit adding your tech support information?
        </PopConfirm>
        <MessageSentModal
          visible={this.state.visibleMessage}
          handleOk={() => this.handleTech()}
          primaryHeading={"Message Sent!"}
          secondaryHeading={"We’ll get back to you as soon as possible."}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default withTranslation()(connect(mapStateToProps)(TechSupportMain));
