import React, { Component } from "react";
import { Button, Form, Input, message, Spin } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onNumberBlurred,
  onNumberFocused,
} from "../../controller/Global";
import {
  getStockData,
  getTransactionOne,
} from "../../controller/api/customerAPI";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { withTranslation } from "react-i18next";

class VehiclePurchaseStepOne extends Component {
  formRef = React.createRef();

  state = {
    loading: false,
  };

  handleSubmit = () => {
    this.formRef.current.validateFields().then((values) => {
      const { current_transaction } = this.props;
      if (values["stock_number"]) {
        values["stock_number"] = values.stock_number.toUpperCase();
        this.setState({ loading: true });
        getStockData(
          current_transaction.dealership.id,
          values["stock_number"],
          current_transaction.id
        )
          .then((res) => {
            this.setState({ loading: false });
            if (res.data) {
              this.props.next(res.data);
            } else {
              this.props.next({ stock: values["stock_number"] });
            }
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data["error"]);
              this.setState({ loading: false });
              this.props.next({ stock: values["stock_number"] });
            }
          });
      } else {
        this.props.next({ stock: null });
      }
    });
  };

  componentDidMount() {
    if (!this.props.current_transaction) {
      history.push(routes.dashboard.self);
      return;
    }
    if (this.props.vehicle_purchase_data) {
      this.formRef.current.setFieldsValue({
        stock_number: this.props.vehicle_purchase_data?.stock,
      });
    } else {
      let { params } = this.props.match;
      if (this.props.current_transaction && this.props.current_transaction.id) {
        this.formRef.current.setFieldsValue({
          ...this.props.current_transaction.vehicle_for_purchase,
        });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.vehicle_for_purchase.id !== parseInt(params.id)) {
              message.error("Invalid URL for updating");
              history.push(routes.dashboard.self);
            } else {
              this.formRef.current.setFieldsValue({
                ...res.data.vehicle_for_purchase,
              });
            }
          })
          .catch(() => {
            message.error("Error while fetching transaction details");
            history.push(routes.dashboard.self);
          });
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="col-12 transition-information-div">
          <Spin spinning={this.state.loading} tip={"fetching Vehicle Data..."}>
            <div className="transition-information-heading w-100 font-weight-bold mb-2">
              {t('vehicleForPurchaseInformation.vehicleForPurchaseForm.vehicleForPurchaseFormStepOne.formTitle')}
            </div>
            <p className="font-weight-normal">
              {t('vehicleForPurchaseInformation.vehicleForPurchaseForm.vehicleForPurchaseFormStepOne.subTitle')}
            </p>
            <div className="row">
              <Form ref={this.formRef} className="w-100">
                <div className="row mx-0">
                  <div id={"stock_no"} className="col-12">
                    <label className="font-weight-bold">
                      {t('vehicleForPurchaseInformation.vehicleForPurchaseForm.vehicleForPurchaseFormStepOne.stockNumber')}
                    </label>
                    <Form.Item
                        name="stock_number"
                        className={
                          "group-floating-label select-float group-floating-label-second"
                        }
                    >
                      <Input
                          onFocus={() => onNumberFocused("stock_no")}
                          onBlur={() => onNumberBlurred("stock_no")}
                          placeholder=""
                          className="inp-form-wrap"
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
            <div className="steps-action flex-align-center deal-info-main justify-content-end w-100">
              <Button onClick={() => this.handleSubmit()} type="primary">
              {t('utils.next')}
              </Button>
            </div>
          </Spin>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};

export default withTranslation()(connect(mapStateToProps)(withRouter(VehiclePurchaseStepOne)));
