import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isLoggedIn } from "./localStorageHandler";
import { Button } from "antd";
import { connect } from "react-redux";
import { toggleSidebarAction } from "../store/actions/toggleAction";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <>
            <Button
              id={"btn-menu"}
              className="toggle-btn-main bg-transparent border-0 p-0 shadow-none"
              onClick={() => {
                rest.toggleSidebarAction();
              }}
            >
              Menu
            </Button>
            <Component {...props} {...rest} />
          </>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};
const mapStateToProps = (state) => {
  return {
    sidebar: state.sidebar,
  };
};
export default connect(mapStateToProps, { toggleSidebarAction })(PrivateRoute);
