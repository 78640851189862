import Axios from "axios";
import { getEnvValue } from "../controller/Environment";
import {
  getRefreshToken,
  getUserToken,
  setUserToken,
} from "./localStorageHandler";
import { routes } from "./routes";
import { history } from "./history";

export const axios = Axios.create({
  baseURL: getEnvValue("REACT_APP_API_URL"),
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log("error");
    const originalRequest = error.config;

    // if (error.response.status === 401 && originalRequest.url === 'http://13.232.130.60:8081/v1/auth/token') {
    //     router.push('/login');
    //     return Promise.reject(error);
    // }

    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();
      return axios
        .post("/api/v1/auth/api/v1/token/refresh/", {
          refresh: refreshToken,
        })
        .then(async (res) => {
          if (res.status === 200) {
            await setUserToken(res.data.access);
            originalRequest.headers["Authorization"] =
              "Bearer " + getUserToken();
            return axios(originalRequest);
          }
        })
        .catch((e) => {
          console.log(e, "refresh error");
          history.push(routes.logout);
        });
    }
    return Promise.reject(error);
  }
);
