import React, { Component } from "react";
import { Button, message, Modal, Upload } from "antd";
import { Image as Images } from "./Images";
import DocumentUpload from "./modal/DocumentUpload";
import { buyer_type } from "../controller/Global";
import {
  addDocument,
  addDocumentIdentity,
  getTransactionOne,
  removeDocument,
} from "../controller/api/customerAPI";
import { connect } from "react-redux";
import SideBar from "./sidebar/SideBar";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import { setCurrentTransactionAction } from "../store/actions/transactionAction";
import { withTranslation } from "react-i18next";

class UploadDocuments extends Component {
  state = {
    pay_stub: [],
    utility_bill: [],
    other: null,
    visible: false,
    fixed: false,
    visibleSuccess: false,
    theftFile: [],
  };
  showDocumentUpload = (visible) => {
    this.setState({
      visible: visible,
    });
  };

  showPopConfirm = (visible) => {
    this.setState({
      visibleSuccess: visible,
    });
  };

  componentDidMount() {
    const { params } = this.props.match;
    if (!this.props.current_transaction) {
      getTransactionOne(this.props.user.default_dealership, params.trans_id)
        .then((res) => {
          this.props.setCurrentTransactionAction(res.data);
        })
        .catch(() => {
          message.error("Error while fetching transaction details");
          history.push(routes.dashboard.self);
        });
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });
  }

  handleIdentityChange = (info) => {
    const file = {
      uid: info.file.uid,
      status: info.file.status,
      name: info.file.name,
    };
    this.setState({ theftFile: [file] });
    let form_data = new FormData();
    form_data.append("transaction", this.props.match.params.trans_id);
    form_data.append("image", info.file);
    addDocumentIdentity(form_data).then(() => {
      this.showPopConfirm(true);
    });
  };

  handleChange = (state, info) => {
    const { is_co_buyer } = this.props.current_transaction;
    let form_data = new FormData();
    form_data.append("transaction", this.props.match.params.trans_id);
    form_data.append("customer", this.props.user.id);
    form_data.append("document_type", state);
    form_data.append("document", info.file);
    form_data.append(
      "buyer_type",
      is_co_buyer ? buyer_type.co_buyer : buyer_type.primary_buyer
    );
    addDocument(form_data).then((resp) => {
      let new_file = {
        uid: info.file.uid,
        status: info.file.status,
        name: info.file.name,
        id: resp.data.id,
        url: resp.data.document,
        document_type: state,
      };
      let old_state = this.state[state];
      this.setState({ [state]: [...old_state, new_file] });
      this.showPopConfirm(true);
    });
  };

  handleRemove = (val) => {
    removeDocument(val.id).then(() => {
      this.setState({ [val.document_type]: [] });
    });
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    const { pay_stub, utility_bill, theftFile } = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 transition-info-div position-relative">
                          <Button
                            className="bg-transparent border-0 position-absolute p-0 back-btn-main"
                            onClick={() => history.push(routes.dashboard.self)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>Back</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('myDocumentCard.uploadDocument.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 deal-info-div upload-docs-div">
                      <div className="row">
                        <div className="col-12">
                          <div className="choose-file-div-second w-100">
                            <Upload
                                multiple={false}
                                customRequest={(obj) =>
                                    this.handleChange("pay_stub", obj)
                                }
                                onRemove={this.handleRemove}
                                fileList={pay_stub}
                            >
                              <Button className="document-upload-btn">
                                <img
                                    alt=""
                                    className="left-img"
                                    src={Images.document_pay_stub}
                                />
                                  Pay Stub
                                {t('myDocumentCard.uploadDocument.payStub')}
                                <img
                                    alt=""
                                    className="right-img"
                                    src={Images.arrow_right_gray}
                                />
                              </Button>
                            </Upload>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="choose-file-div-second w-100">
                          <Upload
                              multiple={false}
                              fileList={utility_bill}
                              customRequest={(obj) =>
                                this.handleChange("utility_bill", obj)
                              }
                              onRemove={this.handleRemove}
                            >
                            <Button className="document-upload-btn">
                              <img
                                  alt=""
                                  className="left-img"
                                  src={Images.document_utility_bills}
                              />
                              {t('myDocumentCard.uploadDocument.utilityBill')}
                              <img
                                  alt=""
                                  className="right-img"
                                  src={Images.arrow_right_gray}
                              />
                            </Button>
                          </Upload>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="choose-file-div-second w-100 theft-upload">
                            <Upload
                              multiple={false}
                              fileList={theftFile}
                              showUploadList={true}
                              customRequest={(obj) =>
                                this.handleIdentityChange(obj)
                              }
                            >
                              <Button className="document-upload-btn">
                                <img
                                    alt=""
                                    className="left-img"
                                    src={Images.document_identity_theft_prevention}
                                />
                                {t('myDocumentCard.uploadDocument.identityTheftPrevention')}
                                <img
                                    alt=""
                                    className="right-img"
                                    src={Images.arrow_right_gray}
                                />
                              </Button>
                            </Upload>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="choose-file-div-second w-100">
                            <Button
                                className="document-upload-btn"
                                onClick={() => this.showDocumentUpload(true)}
                            >
                              <img
                                  alt=""
                                  className="left-img"
                                  src={Images.document_other}
                              />
                              {t('myDocumentCard.uploadDocument.other')}
                              <img
                                  alt=""
                                  className="right-img"
                                  src={Images.arrow_right_gray}
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DocumentUpload
          visible={this.state.visible}
          onClose={() => this.showDocumentUpload(false)}
        />
        <Modal
          title={false}
          open={this.state.visibleSuccess}
          maskClosable={false}
          className={`main-modal exit-modal-back 'close-btn-none`}
          width="33.889%"
          centered
          footer={[
            <Button
              className="w-100"
              key="submit"
              type={"primary"}
              onClick={() => this.setState({ visibleSuccess: false })}
            >
              OK
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.check_icon_rounded}
              />
              <p className={`font-weight-bold text-capitalize mb-2`}>
                Document added successfully!
              </p>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};

export default withTranslation()(connect(mapStateToProps, { setCurrentTransactionAction })(UploadDocuments));
