import React, { Component } from "react";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import { routes } from "../controller/routes";
import { reverse } from "named-urls";
import { methods } from "../controller/Global";
import { Button, message, Modal } from "antd";
import {
  getTransactionOne,
  removeCoBuyer,
} from "../controller/api/customerAPI";
import { connect } from "react-redux";
import {
  fetchTransactionAction,
  setCurrentTransactionAction,
} from "../store/actions/transactionAction";
import { history } from "../controller/history";
import { withTranslation } from "react-i18next";

class CoBuyerDashboard extends Component {
  state = {
    deleteModal: false,
  };

  handleRemove = () => {
    const { current_transaction } = this.props;

    removeCoBuyer(
      current_transaction.dealership.id,
      current_transaction.id
    ).then((response) => {
      message.success("Co buyer removed successfully.");
      getTransactionOne(
        current_transaction.dealership.id,
        response.data.id
      ).then((res) => {
        this.props.setCurrentTransactionAction(res.data);
        this.props.fetchTransactionAction(current_transaction.dealership.id);
        history.push(routes.dashboard.self);
      });
    });
  };

  render() {
    const { co_buyer, btnVisible, t } = this.props;
    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row flex-align-center-between px-4">
            <div className="profile-card flex-align-center mb-3">

              <div className={"icon-circle-transparent"}>
                <img
                    alt="add icon"
                    className="img-fluid"
                    src={Images.coBuyerIcon}
                />
              </div>
              <div className="profile-info">
                <span className="license-text text-uppercase">{t('coBuyerInformation.title')}</span>
              </div>
            </div>
            {co_buyer ? (
              ""
            ) : (
              <Link
                to={reverse(routes.dashboard.transaction.co_buyer, {
                  trans_id: this.props.transaction,
                  method: methods.add,
                })}
                className={
                  btnVisible
                    ? "bg-transparent border-0 shadow-none p-0 inp-form-wrap"
                    : "bg-transparent border-0 shadow-none p-0 disable-cobuyer-add inp-form-wrap"
                }
              >
                {btnVisible ? <Button className={"btn-add"}>{t('utils.add')}</Button> : ""}
              </Link>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="col-12">
              <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                <li className="list-inline-item m-0 content-options-weight float-left heading-name-text mt-1">
                  {t('coBuyerInformation.title')}
                </li>
                <li className="list-inline-item m-0 font-weight-normal float-left details-text">
                  {co_buyer ? (
                    <React.Fragment>
                      {btnVisible ? (
                        <Button
                          className="cobuyer-name mt-2 inp-form-wrap"
                          onClick={() => this.props.handleViewCoBuyer(true)}
                        >
                          {`${co_buyer.first_name} ${co_buyer.last_name}`}
                        </Button>
                      ) : (
                        <Button
                          className="cobuyer-name mt-2 inp-form-wrap"
                        >
                          {`${co_buyer.first_name} ${co_buyer.last_name}`}
                        </Button>
                      )}
                      {btnVisible ? (
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => this.setState({ deleteModal: true })}
                        >
                          {t('utils.remove')}
                        </span>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ) : (
                    "-"
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.deleteModal}
          onOk={() => this.handleRemove()}
          onCancel={() => this.setState({ deleteModal: false })}
          title={false}
          maskClosable={false}
          className={`main-modal exit-modal-back`}
          width="33.889%"
          centered
          footer={[
            <Button
              key="back"
              onClick={() => this.setState({ deleteModal: false })}
            >
              {t('utils.cancel')}
            </Button>,
            <Button
              key="submit"
              type={"danger"}
              onClick={() => {
                this.handleRemove();
                this.setState({ deleteModal: false });
              }}
            >
              {t('utils.remove')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.error_prompt_img}
              />
              <p
                className={`font-weight-bold text-capitalize mb-2 text-color-red`}
              >
                {t('coBuyerInformation.popModal.removeTitle')}
              </p>
              <small className="d-inline-block w-100">
                {t('coBuyerInformation.popModal.removeSubTitle')}
              </small>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_transaction: state.current_transaction,
  };
};


export default withTranslation()(connect(mapStateToProps, {
  setCurrentTransactionAction,
  fetchTransactionAction,
})(CoBuyerDashboard));
