import React from "react";
import "./css/scannerBorder.css";

export const ScannerBorder = ({ children }) => {
  return (
    <div className="content">
      <div className="innerBorder">{children}</div>
    </div>
  );
};

export default ScannerBorder;
