import { Router } from "react-router-dom";
import { history } from "./controller/history";
import "./App.css";
import "./assets/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./assets/css/custom.css";
import "../src/components/add-customer/css/add-customer.css";
import "../src/components/login-data/css/login-signup.css";
import "../src/components/login-data/scss/form.scss";
import "../src/components/transaction-information/css/transaction.css";
import "./components/modal/scss/modal.scss";
import "mdbreact/dist/css/mdb.css";

import "jquery";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import LoginForm from "./components/login-data/LoginFormMain";
import { routes } from "./controller/routes";
import LogOut from "./components/Logout";
import SignUp from "./components/login-data/SignUp";
import ForgotPassword from "./components/login-data/ForgotPassword";
import React, { Component } from "react";
import ResetPassword from "./components/login-data/ResetPassword";
import { PublicRoute } from "./controller/PublicPath";
import PrivateRoute from "./controller/PrivatePath";
import Dashboard from "./components/Dashboard";
import AddTransaction from "./components/add-customer/AddTransaction";
import DealInfoMain from "./components/deal-information/DealInfoMain";
import CustomerPersonalInfo from "./components/CustomerPersonalInfo";
import CustomerAddressInfo from "./components/CustomerAddressInfo";
import PersonalReferenceInfo from './components/PersonalReferenceInfo';
import CoBuyerMain from "./components/add-co-buyer/CoBuyerMain";
import VehiclePurchaseMain from "./components/vehicle-for-purchase/VehiclePurchaseMain";
import TradeRegistration from "./components/trade-registration/TradeRegistration";
import LicenceAdd from "./components/LicenceAdd";
import PreQualificaitonApplicationMain from "./components/prequalificaiton-application/PreQualificaitonApplicationMain";
import HardCreditApplicationMain from "./components/hard-credit-application/HardCreditApplicationMain";
import ViewDocuments from "./components/ViewDocuments";
import UploadDocuments from "./components/UploadDocuments";
import SignatureSample from "./components/sample/SignatureSample";
import * as Sentry from "@sentry/react";
import "./assets/css/responsive.css";
import { connect } from "react-redux";
import { toggleSidebarAction } from "./store/actions/toggleAction";
import ScrollToTop from "./controller/ScrollToTop";
import PrivacyPolicy from "./components/privacy-policy-terms-conditions/PrivacyPolicy";
import TermsConditions from "./components/privacy-policy-terms-conditions/TermsConditions";
import TechSupportMain from "./components/tech-support/TechSupportMain";
import MessageView from "./components/tech-support/MessageView";
import { getEnvValue } from "./controller/Environment";
// import IdentityVerification from "./components/verification/IdentityVerification";
import TruliooIdentityVerification from "./components/verification/TruliooIdentityVerification";
import HelpDeskPage from "./components/HelpDeskPage";
import { BrowserTracing } from "@sentry/tracing";
import {InsuranceAdd} from "./components/InsuranceAdd";


Sentry.init({
  dsn: getEnvValue("REACT_APP_SENTRY_DNS"),
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
});

class App extends Component {
  state = {
    error: null,
    eventId: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    return (
      <div className="App text-left">
        <Router history={history}>
          <div className="h-100">
            <ScrollToTop>
              <PublicRoute
                exact
                path={routes.helpdesk}
                component={HelpDeskPage}
              />
              <PublicRoute
                restricted={false}
                path={routes.identityVerification}
                component={TruliooIdentityVerification}
              />
              <PublicRoute
                restricted={true}
                exact
                path={routes.login}
                component={LoginForm}
              />
              <PublicRoute
                restricted={true}
                exact
                path={routes.signup}
                component={SignUp}
              />
              <PublicRoute
                restricted={true}
                exact
                path={routes.forgetPassword}
                component={ForgotPassword}
              />
              <PublicRoute
                exact
                path={routes.reset_password}
                component={ResetPassword}
              />
              <PublicRoute
                restricted={true}
                exact
                path={routes.signature_sample}
                component={SignatureSample}
              />
              <PublicRoute
                restricted={true}
                exact
                path={"/privacy-policy/"}
                component={PrivacyPolicy}
              />
              <PublicRoute
                restricted={true}
                exact
                path={"/terms-conditions/"}
                component={TermsConditions}
              />
              {/*<PublicRoute restricted={true} exact path={routes.signature_sample2} component={SignatureSample2}/>*/}
              <PrivateRoute
                exact
                path={routes.dashboard.self}
                component={Dashboard}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.add_transaction}
                component={AddTransaction}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.transaction_widget}
                component={AddTransaction}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.deal_info}
                component={DealInfoMain}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.personal_info}
                component={CustomerPersonalInfo}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.address_info}
                component={CustomerAddressInfo}
              />
              <PrivateRoute
                  exact
                  path={routes.dashboard.transaction.personal_reference_info}
                  component={PersonalReferenceInfo}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.co_buyer}
                component={CoBuyerMain}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.vehicle_for_purchase}
                component={VehiclePurchaseMain}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.vehicle_for_trade}
                component={TradeRegistration}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.license}
                component={LicenceAdd}
              />
              <PrivateRoute
                  exact
                  path={routes.dashboard.transaction.insurance}
                  component={InsuranceAdd}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.pre_qualification}
                component={PreQualificaitonApplicationMain}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.hard_credit}
                component={HardCreditApplicationMain}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.view_documents}
                component={ViewDocuments}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.upload_documents}
                component={UploadDocuments}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.tech_support}
                component={TechSupportMain}
              />
              <PrivateRoute
                exact
                path={routes.dashboard.transaction.message}
                component={MessageView}
              />
              <PrivateRoute
                restricted={true}
                exact
                path={routes.logout}
                component={LogOut}
              />
            </ScrollToTop>
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sidebar: state.sidebar,
  };
};
export default connect(mapStateToProps, { toggleSidebarAction })(App);
