import { Image as Images } from "../Images";
import React, { Component } from "react";
import { Button, Modal } from "antd";

class MessageSentModal extends Component {
  render() {
    return (
      <div>
        <Modal
          title={false}
          open={this.props.visible}
          onOk={() => this.props.handleOk()}
          onCancel={() => this.props.handleOk()}
          maskClosable={false}
          className="main-modal exit-modal-back close-btn-none"
          width="33.889%"
          centered
          footer={[
            <Button
              type={"primary"}
              onClick={() => this.props.handleOk()}
              key="1"
            >
              OK
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.check_icon_rounded}
              />
              <p className="font-weight-bold text-capitalize mb-2">
                {this.props.primaryHeading}
              </p>
              <small className="d-inline-block w-100">
                {this.props.secondaryHeading}
              </small>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MessageSentModal;
