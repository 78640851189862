import React, { Component } from "react";
import { Button, Checkbox, Form, InputNumber } from "antd";
import { connect } from "react-redux";
import { requestOTP } from "../../controller/api/customerAPI";
import { Image as Images } from "../Images";
import OTP from "../login-data/OTP";
import { phoneValidationPattern } from "../../controller/Global";
import {formatPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from 'react-phone-number-input/input'
import { withTranslation, Trans } from "react-i18next";
import { addCountryCode } from "../../utils/PhoneFormat";

class StepTwoContactAcknowledgement extends Component {
  state = {
    input: false,
    otpVisible: false,
    error: false,
    phone_number: this.props.transaction?.is_co_buyer
      ? this.props.transaction?.co_buyer_phone ||
        this.props.transaction?.customer_phone ||
        this.props.user.phone
      : this.props.transaction?.customer_phone || this.props.user?.phone,
    number_verified: false,
    otp: null,
  };
  formRef = React.createRef();

  componentDidMount() {
    this.setState({ number_verified: this.isPhoneVerified() });

    const countryCode = '+1';
    const phoneNumberWithCountryCode = addCountryCode(this.state.phone_number, countryCode);
    this.setState({phone_number: phoneNumberWithCountryCode});
  }

  onChange = () => {
    if (!this.props.contact_acknowledgement) {
      const data = { phone_number: parsePhoneNumber(this.state.phone_number).nationalNumber };
      requestOTP(data)
        .then((response) =>
          this.setState({ otpVisible: true, otp: response.data.otp })
        )
        .catch(() => {
          this.setState({ error: true });
          this.props.setAcknowledgement(!this.props.contact_acknowledgement);
        });
    }
    this.props.setAcknowledgement(!this.props.contact_acknowledgement);
  };

  closeOTP = () => {
    this.setState({ otpVisible: false });
    this.props.setAcknowledgement(!this.props.contact_acknowledgement);
  };

  setPhoneNumber = () => {
    this.formRef.current.validateFields().then((values) => {
      this.setState({
        phone_number: values["phone_number"].toString(),
        input: false,
      });
    });
  };

  checkDisabled = () => {
    const { input, number_verified } = this.state;
    return input || number_verified;

  };

  isPhoneVerified = () => {
    const { transaction } = this.props;
    if (transaction.is_co_buyer) {
      return transaction.co_buyer_phone_verified;
    } else {
      return transaction.customer_phone_verified;
    }
  };


  render() {
    const { input, error, number_verified, otp } = this.state;
    let agreed = this.props.contact_acknowledgement;

    const { t } = this.props;
    return (
      <React.Fragment>
        <Form ref={this.formRef}>
          <div className="col-12 transition-information-div mb-4">
            <div className="transition-information-heading w-100 font-weight-bold">
              {t('transactionForm.stepTwo.title')}
            </div>
            <p>
              {t('transactionForm.stepTwo.contextOne')}
            </p>
            <p>
              {" "}
              {t('transactionForm.stepTwo.contextTwo')}
            </p>
            <div className="cell-phone-number-main">
              <div className="cell-phone-img">
                <img
                  alt=""
                  className="img-fluid"
                  src={Images.tablet_img_black}
                />
              </div>
              <div className="cell-phone-content">
                <h6>{t('transactionForm.stepTwo.cellPhone')}</h6>
                <div className="d-flex align-items-center">
                  <div className="transition-check-box mt-0 w-40">
                    <Checkbox
                      checked={this.isPhoneVerified() ? true : agreed}
                      disabled={this.checkDisabled()}
                      onChange={() => this.onChange()}
                    >
                      {input ? (
                        <>
                          <Form.Item
                            initialValue={this.state?.phone_number}
                            name={"phone_number"}
                            rules={[
                              {
                                required: true,
                                message: t('transactionForm.stepTwo.warningMessage.phoneRequired'),
                              },
                              {
                                pattern: new RegExp(/^\+1\d{10,}(?:,\d{10,})*$/),
                                message: t('transactionForm.stepTwo.warningMessage.minimumDigit'),
                              },
                            ]}
                          >
                                <PhoneNumberInput
                                    country="US"
                                onChange={(value) => {
                                }}
                              />
                          </Form.Item>
                        </>
                      ) : (
                          <>
                            {formatPhoneNumber(this.state.phone_number)}
                          </>
                      )}
                    </Checkbox>
                  </div>
                  {!number_verified &&
                    (!input ? (
                      <Button
                        className="edit-btn"
                        disabled={this.props.contact_acknowledgement}
                        onClick={() =>
                          this.setState({
                            input: true,
                            error: false,
                          })
                        }
                      >
                        {t('utils.edit')}
                      </Button>
                    ) : (
                      <Button
                        className="save-btn text-uppercase"
                        onClick={() => this.setPhoneNumber()}
                      >
                        {t('utils.save')}
                      </Button>
                    ))}
                </div>
                {error && (
                  <small style={{ color: "white", backgroundColor: "red" }}>
                    {t('transactionForm.stepTwo.warningMessage.phoneError')}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="steps-action flex-align-center justify-content-between w-100">
            <Button
              style={{ marginRight: "11%" }}
              onClick={() => this.props.prev()}
            >
              {t('utils.back')}
            </Button>
            <Button
              disabled={!number_verified}
              onClick={() => this.props.next()}
              type="primary"
            >
              {t('utils.next')}
            </Button>
          </div>
        </Form>
        <OTP
          onClose={() => this.closeOTP()}
          otp={otp}
          onVerified={() => this.props.next()}
          transaction={this.props.transaction.id}
          phone_number={this.state.phone_number && parsePhoneNumber(this.state.phone_number)?.nationalNumber}
          visible={this.state.otpVisible}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};


export default withTranslation()(connect(mapStateToProps)(StepTwoContactAcknowledgement));