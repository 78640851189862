import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Spin } from "antd";
import { Image as Images } from "./Images";
import PersonalInformationDashboard from "./PersonalInformationDashboard";
import AddressInformationDashboard from "./AddressInformationDashboard";
import { getCobuyerTransaction } from "../controller/api/customerAPI";
import Graph from "./Graph";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import { reverse } from "named-urls";
import { withTranslation } from "react-i18next";

// eslint-disable-next-line
function mapStateToProps(state) {
  return {};
}

class CoBuyerInformation extends Component {
  state = {
    co_buyer_transaction: null,
    loading: true,
  };

  componentDidMount() {
    const { transaction } = this.props;
    getCobuyerTransaction(transaction.dealership.id, transaction.id).then(
      (resp) => {
        this.setState({ co_buyer_transaction: resp.data, loading: false });
      }
    );
  }

  render() {
    const transaction = this.state.co_buyer_transaction;
    const current_transaction = this.props.transaction;
    const { t } = this.props;

    if (this.state.loading) {
      return (
        <Spin className="h-100 w-100 d-flex align-items-center justify-content-center" />
      );
    } else {
      return (
        <div className="row mx-0">
          <div className="col-12 col-sm-12 col-lg-12 col-md-12 right-inner-main">
            <div className="row mx-0">
              <div className="col-12">
                <div
                  className={`row heading-fix-div ${
                    this.state.fixed && "nav-fix-div"
                  }`}
                >
                  <div className="col-12 transition-info-div position-relative">
                    <Button
                      className="bg-transparent border-0 p-0 position-absolute back-btn-main"
                      onClick={() => this.props.unsetCoBuyerView()}
                    >
                      <div className="back-btn-img-div">
                        <img
                          alt=""
                          className="img-fluid back-btn-black"
                          src={Images.back_btn_black}
                        />
                        <img
                          alt=""
                          className="img-fluid back-btn-primary"
                          src={Images.arrow_left_primary}
                        />
                      </div>
                      <span>
                        {t('utils.back')}
                      </span>
                    </Button>
                    <span className="d-inline-block font-weight-bold w-100 text-center">
                      {t('coBuyerInformation.coBuyerInformation')}
                    </span>
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: reverse(
                            routes.dashboard.transaction.message,
                            {
                              trans_id: transaction.id,
                            }
                          ),
                          state: { current_transaction: current_transaction },
                        })
                      }
                      className="msg-btn position-absolute bg-transparent border-0 shadow-none"
                    >
                      <img
                        src={Images.message_box_primary_icon}
                        alt={""}
                        className="img-fluid"
                      />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <Graph
                    transaction={current_transaction}
                    co_buyer_transaction={transaction}
                    co_buyer_info={true}
                  />
                  <div className="col-12">
                    <PersonalInformationDashboard
                      current_transaction={current_transaction}
                      transaction={transaction.id}
                      co_buyer_info={true}
                      personal_info={transaction.personal_information}
                    />
                  </div>
                  <div className="col-12">
                    <AddressInformationDashboard
                      current_transaction={current_transaction}
                      transaction={transaction.id}
                      co_buyer_info={transaction.personal_information}
                      is_co_buyer={true}
                      address_info={transaction.address_information}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withTranslation()(connect(mapStateToProps)(CoBuyerInformation));
