import React, { Component } from "react";
import { Button, Form, Input, InputNumber, message, Radio, Select } from "antd";
import {
  addPersonalInfo,
  getTransactionOne,
  updatePersonalInfo,
} from "../controller/api/customerAPI";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import {
  confirm_status,
  methods,
  onNumberBlurred,
  onNumberFocused,
  onSelectBlurred,
  onSelectFocused,
  phoneValidationPattern,
} from "../controller/Global";
import { connect } from "react-redux";
import SideBar from "./sidebar/SideBar";
import {
  setCurrentTransactionAction,
  updateTransactionAction,
} from "../store/actions/transactionAction";
import PopConfirm from "./modal/PopConfirm";
import { Image as Images } from "./Images";
import HiddenDobInput from "./HiddenDobInput";
import { DateTime } from "luxon";
import { withTranslation } from "react-i18next";


class CustomerPersonalInfo extends Component {
  formRef = React.createRef();
  state = {
    is_vehicle_insured: undefined,
    pop_confirm: null,
    visible: false,
    fixed: false,
    selectedDate: utils().getToday(),
  };

  onChange = (e) => {
    this.setState({
      is_vehicle_insured: e.target.value,
    });
  };


  componentDidMount() {
    let { params } = this.props.match;
    const { user, t } = this.props;
    if (params.method === methods.edit) {
      if (this.props.current_transaction && this.props.current_transaction.id) {
        const _dob = DateTime.fromISO(this.props.current_transaction.personal_information.dob);
        const dob = {
          day: _dob.day,
          month: _dob.month,
          year: _dob.year,
        };
        this.formRef.current.setFieldsValue({
          ...this.props.current_transaction.personal_information,
          dob: dob,
        });
        this.setState({ pop_confirm: confirm_status.edit, selectedDate: dob });
      } else {
        getTransactionOne(this.props.user.default_dealership, params.trans_id)
          .then((res) => {
            if (res.data.personal_information.id !== parseInt(params.id)) {
              message.error(t('utils.warningMessage.InvalidURL'));
              history.push(routes.dashboard.self);
            } else {
              const _dob = DateTime.fromISO(res.data.personal_information.dob)
              const dob = {
                day: _dob.day,
                month: _dob.month,
                year: _dob.year,
              };
              this.formRef.current.setFieldsValue({
                ...res.data.personal_information,
                dob: dob,
              });
              this.setState({ selectedDate: dob });
            }
          })
          .catch(() => {
            message.error(t('utils.warningMessage.fetchingError'));
            history.push(routes.dashboard.self);
          });
      }
    } else {
      this.formRef.current.setFieldsValue({
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
      });
      this.setState({ pop_confirm: confirm_status.add });
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        this.setState({
          fixed: true,
        });
      } else {
        this.setState({
          fixed: false,
        });
      }
    });


  }

  get_message = () => {
    const { t } = this.props;
    const { pop_confirm } = this.state;
    if (pop_confirm === confirm_status.add) {
      return t('personalInformation.popModal.addTitle');
    } else if (pop_confirm === confirm_status.edit) {
      return t('personalInformation.popModal.updatedTitle');
    } else {
      return t('personalInformation.popModal.exitTitle');
    }
  };

  handleSubmit = (values) => {
    let { params } = this.props.match;
    const { user } = this.props;
    values.dob = `${values.dob.year}-${values.dob.month}-${values.dob.day}`;
    values["transaction"] = params.trans_id;
    values["customer"] = user?.id;
    if (params.method === methods.edit) {
      values.email = values.email.toLowerCase();
      updatePersonalInfo(values, params.id)
        .then(() => {
          this.setState({ visible: true });
        })
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
          }
          this.setState({ visible: false });
        });
    } else {
      values.email = values.email.toLowerCase();
      addPersonalInfo(values)
        .then(() => this.setState({ visible: true }))
        .catch((err) => {
          if (err.response) {
            Object.keys(err.response.data).forEach((e) => {
              message.error(err.response.data[e]);
            });
          }
          this.setState({ visible: false });
        });
    }
  };

  handleExit = (visible) => {
    this.setState({ pop_confirm: confirm_status.exit, visible });
  };

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <SideBar />
        <div className="container-fluid mt-66 main-inner-fluid">
          <div className="container">
            <div className="row mx-0 h-100 dashboard-main-inner-row">
              <div className="col-12 h-100 dashboard-inner-div p-0">
                <div className="right-side-div-main h-100">
                  <div className="row mx-0">
                    <div className="col-12">
                      <div
                        className={`row heading-fix-div ${
                          this.state.fixed && "nav-fix-div"
                        }`}
                      >
                        <div className="col-12 position-relative transition-info-div">
                          <Button
                            className="bg-transparent position-absolute border-0 p-0 back-btn-main"
                            onClick={() => this.handleExit(true)}
                          >
                            <div className="back-btn-img-div">
                              <img
                                alt=""
                                className="img-fluid back-btn-black"
                                src={Images.back_btn_black}
                              />
                              <img
                                alt=""
                                className="img-fluid back-btn-primary"
                                src={Images.arrow_left_primary}
                              />
                            </div>
                            <span>{t('utils.back')}</span>
                          </Button>
                          <span className="d-inline-block w-100 text-center">
                            {t('personalInformation.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 deal-info-div">
                      <div className="row">
                        <div className="col-12">
                          <Form
                            ref={this.formRef}
                            className={"w-100"}
                            onFinish={this.handleSubmit}
                          >
                            <div className="row">
                              <div className="col-12 col-sm-12 col-lg-6">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.firstNameLabel')} *
                                </label>
                                <Form.Item
                                    name="first_name"
                                    rules={[
                                      {
                                        required: true,
                                        message: t('utils.warningMessage.inputRequired'),
                                      },
                                    ]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      autoFocus
                                      autoCapitalize={false}
                                      placeholder=" "
                                      className="inp-form-wrap"
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-6">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.middleNameLabel')}
                                </label>
                                <Form.Item
                                    name="middle_name"
                                    label={""}
                                    rules={[]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      placeholder=" "
                                      className="inp-form-wrap"
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-6">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.lastNameLabel')} *
                                </label>
                                <Form.Item
                                    name="last_name"
                                    rules={[
                                      {
                                        required: true,
                                        message: t('utils.warningMessage.inputRequired'),
                                      },
                                    ]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      placeholder=" "
                                      className="inp-form-wrap"
                                  />
                                </Form.Item>
                              </div>
                              <div
                                  className="col-12 col-sm-12 col-lg-6 select_floating"
                                  id={"select_suffix"}
                              >
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.suffixLabel')}
                                </label>
                                <Form.Item
                                    name="suffix"
                                    rules={[]}
                                    className={
                                      "select-float group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Select
                                      onFocus={() =>
                                          onSelectFocused("select_suffix")
                                      }
                                      onBlur={() =>
                                          onSelectBlurred("select_suffix")
                                      }
                                      suffixIcon={
                                        <img
                                            src={Images.down_arrow_select}
                                            width={15}
                                            alt="down arrow"
                                            className="img-fluid"
                                        />
                                      }
                                      placeholder=""
                                  >
                                    <Select.Option
                                        disabled
                                        className="disbaled-optn-color"
                                        value={null}
                                    >
                                      {t('personalInformation.suffixLabel')}
                                    </Select.Option>
                                    <Select.Option value={"None"}>
                                      None
                                    </Select.Option>
                                    <Select.Option value="Sr">Sr</Select.Option>
                                    <Select.Option value="Jr">Jr</Select.Option>
                                    <Select.Option value="II">II</Select.Option>
                                    <Select.Option value="III">
                                      III
                                    </Select.Option>
                                    <Select.Option value="IV">IV</Select.Option>
                                  </Select>
                                </Form.Item>
                              </div>
                              <div
                                  id="dob"
                                  className="col-12 col-sm-12 col-lg-6"
                              >
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.DOBLabel')} *
                                </label>
                                <Form.Item
                                    name="dob"
                                    rules={[
                                      {
                                        required: true,
                                        message: t('utils.warningMessage.inputRequired'),
                                      },
                                    ]}
                                    className={
                                      "group-floating-label select-float group-floating-label-second"
                                    }
                                >
                                  <HiddenDobInput value={this.value}/>
                                </Form.Item>
                              </div>
                              <div
                                  id={"phone"}
                                  className="col-12 col-sm-12 col-lg-6 "
                              >
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.cellPhoneNumberLabel')} *
                                </label>
                                <Form.Item
                                    name="phone"
                                    rules={[
                                      {
                                        required: true,
                                        message: t('utils.warningMessage.inputRequired'),
                                      },
                                      {
                                        pattern: phoneValidationPattern,
                                        message: t('utils.warningMessage.minimumDigit'),
                                      },
                                    ]}
                                    className={
                                      "group-floating-label select-float group-floating-label-second position-relative"
                                    }
                                >
                                  <InputNumber
                                      formatter={(value) =>
                                          `${value}`
                                              .match(/\d*/g)
                                              .join("")
                                              .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                              .slice(1)
                                              .join("-")
                                              .replace(/-*$/g, "")
                                      }
                                      parser={(value) =>
                                          value.replace(/\$\s?|(-*)/g, "")
                                      }
                                      onFocus={() => onNumberFocused("phone")}
                                      onBlur={() => onNumberBlurred("phone")}
                                      placeholder=""
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12 col-sm-12 col-lg-6">
                                <label
                                    className="font-weight-bold"
                                >
                                  {t('personalInformation.email')} *
                                </label>
                                <Form.Item
                                    name="email"
                                    label={""}
                                    rules={[
                                      {
                                        required: true,
                                        message: t('utils.warningMessage.inputRequired'),
                                      },
                                    ]}
                                    className={
                                      "group-floating-label group-floating-label-second"
                                    }
                                >
                                  <Input
                                      autoCapitalize={"off"}
                                      placeholder=" "

                                  />
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 footer-customer-div add-new-customer-div p-0 shadow-none">
                              <div className="steps-action flex-align-center justify-content-end w-100">
                                <Button htmlType="submit" type="primary">
                                  {t('utils.save')}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopConfirm
          visible={this.state.visible}
          user={this.props.user}
          exit={this.state.pop_confirm === confirm_status.exit}
          handleExit={() => this.handleExit(false)}
        >
          {this.get_message()}
        </PopConfirm>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { current_transaction: state.current_transaction, user: state.user };
};
const mapActionToProps = {
  updateTransactionAction,
  setCurrentTransactionAction,

};

export default withTranslation()(connect(mapStateToProps, mapActionToProps)(CustomerPersonalInfo));