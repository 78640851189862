import React, { Component } from "react";
import { Button } from "antd";
import { withTranslation, Trans } from "react-i18next";

class CoBuyer extends Component {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="row mx-0 main-div-height">
              <div className="col-12 px-0">
                <div className="new-customer-heading mb-3 font-weight-bold w-100">
                  {t('coBuyerInformation.coBuyerFormStepOne.formTitle')}
                </div>
                <p className="font-weight-normal">
                  {t('coBuyerInformation.coBuyerFormStepOne.contentOne')}
                </p>
                <p className="font-weight-normal">
                  {t('coBuyerInformation.coBuyerFormStepOne.contentTwo')}
                </p>
                <p className="font-weight-normal">
                  <Trans i18nKey="coBuyerInformation.coBuyerFormStepOne.contentThree" />
                </p>
                <div className="steps-action flex-align-center justify-content-end w-100">
                  <Button type="primary" onClick={() => this.props.next()}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(CoBuyer);