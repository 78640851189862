import {parsePhoneNumber } from 'react-phone-number-input';

const usPhoneLength = 10;
export const PhoneFormat = (phone) => {
    const startWithCountryCode1 = phone.slice(0, 1);
    const startWithCountryCode2 = phone.slice(0, 2);

    const phoneLength = phone.length;

    const isStartWithCountryCode1 = (startWithCountryCode1 === '1' && phoneLength - usPhoneLength === 1);
    const isStartWithCountryCode2 = (startWithCountryCode2 === '+1' && phoneLength - usPhoneLength === 2);

    let phoneWithoutCountryCode = '';

    if (isStartWithCountryCode1 || isStartWithCountryCode2)
        return phone.replace(/\D/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 $2-$3-$4');
    else return `+1 ${phone.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}`
}



export const addCountryCode = (phoneNumber, countryCode) => {
    // Check if the phone number already starts with the country code
    const checkPhoneCountryCode = parsePhoneNumber(phoneNumber)
    if (checkPhoneCountryCode && checkPhoneCountryCode?.country === 'US') {
        return phoneNumber;
    } else {
        // Concatenate the country code to the beginning of the phone number
        return `${countryCode}${phoneNumber}`;
    }
}