import React, {useState, useMemo, useEffect} from "react";
import { Image as Images } from "./Images";
import { Link } from "react-router-dom";
import { routes } from "../controller/routes";
import { methods } from "../controller/Global";
import { reverse } from "named-urls";
import {Button, Tabs, Radio, message} from "antd";
import { getAddresses } from "../controller/api/customerAPI";
import {useTranslation} from "react-i18next";
import get from 'lodash/get';



export const AddressInformationDashboard = (props) => {
    const {
      address_info,
      transaction,
      is_co_buyer,
      btnVisible,
      current_transaction
    } = props;

    const [tabValue, setTabValue] = useState('0');
    const [addressesData, setAddressData] = useState([]);
    const { t, i18n } = useTranslation();
  const lng = navigator.language;

  const onChange = (key) => {
    console.log(key);
    setTabValue(key.target.value)
  };

  const getAddressesInfo = async () => {
    const customerId = is_co_buyer ? current_transaction.co_buyer?.id : current_transaction.customer
    const addresses = await getAddresses({
      customer: customerId,
    }).catch((err) => {
      if (err.response) {
        Object.keys(err.response.data).forEach((e) => {
          message.error(`${e}: ${err.response.data[e]}`);
        });
      }
    }).finally(() => {});


    setAddressData(addresses?.data?.results);
  }



  const moneyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
  const currentAddressValueFromHardCredit = addressesData && addressesData?.find((i) => i.position === 0 );



  const currentSelectedAddress = useMemo(() => {
    return addressesData.find((i) => i.position === Number(tabValue))
  }, [addressesData, tabValue])



  const checkIsRegisterAddress = () => {
    return currentAddressValueFromHardCredit?.address === address_info?.address_2 &&
        currentAddressValueFromHardCredit?.city === address_info?.city_2 &&
        currentAddressValueFromHardCredit?.state === address_info?.state_2 &&
        currentAddressValueFromHardCredit?.zip_code === address_info?.zip_code_2;
  }




  useEffect(() => {
    getAddressesInfo().then(r => {})
  }, []);


    return (
      <div className="row all-info-home-details">
        <div className="col-12">
          <div className="all-heading-home row align-items-center px-2">
            <div className="col-md-11">
              <div className="profile-card flex-align-center mb-3">

                <div className={"icon-circle-transparent"}>
                  <img
                      alt="add icon"
                      className="img-fluid"
                      src={Images.addressInformationIcon}
                  />
                </div>

                <div className="profile-info">
                  <span className="license-text text-uppercase">{t('addressInformation.title')}</span>
                </div>
              </div>
            </div>
            <div className={`col-md-1 mb-2 ${btnVisible && !address_info ? 'align-tem-right-right' : ''}`}>
            {btnVisible ? (
              is_co_buyer ? (
                ""
              ) : address_info ? (
                <Link
                  to={reverse(routes.dashboard.transaction.address_info, {
                    trans_id: transaction,
                    method: methods.edit,
                    id: address_info.id,
                  })}
                  className="bg-transparent border-0 shadow-none p-0"
                >
                  <Button className={"btn-update"} style={{ right: '48px' }}>{t('utils.update')}</Button>
                </Link>
              ) : (
                <Link
                  to={reverse(routes.dashboard.transaction.address_info, {
                    trans_id: transaction,
                    method: methods.add,
                  })}
                  className="bg-transparent border-0 shadow-none p-0"
                >
                  <Button className={"btn-add"} style={{ right: '48px' }}>{t('utils.add')}</Button>
                </Link>
              )
            ) : (
              ""
            )}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="row mx-0 all-inner-details">
            <div className="d-flex justify-content-center w-100">
              <Radio.Group
                  value={tabValue}
                  onChange={onChange}
                  style={{
                    marginBottom: 16,
                  }}
                  size={'large'}
                  className={'green-button-group'}
              >
                <Radio.Button value="0">{t('addressInformation.current')}</Radio.Button>
                <Radio.Button value="1">{t('addressInformation.previous')}</Radio.Button>
                <Radio.Button value="2">{t('addressInformation.secondPrevious')}</Radio.Button>
              </Radio.Group>
            </div>
              <div className={`col-12 ${currentAddressValueFromHardCredit && checkIsRegisterAddress() && tabValue === '0' ? 'green-card-container' : 'address-container'}`}>
                { is_co_buyer ? '' : (
                    checkIsRegisterAddress() && tabValue === '0'  ? <div className={'font-weight-bold mb-3'}>✓ {t('addressInformation.registerToAddress')}</div> : ''
                )}

                <div>
                  <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                    <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                      {t('addressInformation.address')}
                    </li>
                    {currentSelectedAddress ? (
                        <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                          {`${get(currentSelectedAddress, "address", "-")} ${
                              currentSelectedAddress.suite
                                  ? currentSelectedAddress.suite
                                  : " "
                          } ${get(currentSelectedAddress, "city", "-")}, ${get(
                              currentSelectedAddress,
                              "state",
                              "-"
                          )} ${get(currentSelectedAddress, "zip_code", "-")}`}
                        </li>
                    ) : (
                        <li>-</li>
                    )}
                    <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                      {t('addressInformation.housingStatus')}
                    </li>
                    {currentSelectedAddress ? (
                        <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                          {currentSelectedAddress?.housing_status ? currentSelectedAddress.housing_status : '-'}
                        </li>
                    ) : (
                        <li>-</li>
                    )}
                    <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                      {t('addressInformation.timeAtAddress')}
                    </li>
                    {currentSelectedAddress ? (
                        <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                          {
                            (!currentSelectedAddress?.duration_years && !currentSelectedAddress?.duration_months) ||
                            (currentSelectedAddress?.duration_years === 0 && currentSelectedAddress?.duration_months === 0) ? '-' :
                                `${currentSelectedAddress?.duration_years} Years ${currentSelectedAddress?.duration_months} Months`
                          }
                        </li>
                    ) : (
                        <li>-</li>
                    )}
                    <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                      {t('addressInformation.monthlyPayment')}
                    </li>
                    {currentSelectedAddress ? (
                        <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                          {
                            !currentSelectedAddress.monthly_payment ? '-' :
                                `${moneyFormatter.format(currentSelectedAddress.monthly_payment)}`
                          }
                        </li>
                    ) : (
                        <li>-</li>
                    )}
                  </ul>
                </div>
              </div>


            {!checkIsRegisterAddress() && tabValue === '0' && address_info?.address_2 ? (
                <div className={`col-12 green-card-container`}>
                  <div className={'font-weight-bold mb-3'}>✓ {t('addressInformation.registerToAddress')}</div>
                  <div>
                    <ul className="mb-0 list-inline d-inline-block w-100 pb-1">
                      <li className="list-inline-item m-0 content-options-weight float-left heading-name-text">
                        {t('addressInformation.address')}
                      </li>
                      {address_info?.address_2 ? (
                          <li className="list-inline-item m-0 font-weight-normal float-left details-text inp-form-wrap">
                            {`${get(address_info, "address_2", "-")} ${
                                address_info.suite_2
                                    ? address_info.suite_2
                                    : " "
                            } ${get(address_info, "city_2", "-")}, ${get(
                                address_info,
                                "state",
                                "-"
                            )} ${get(address_info, "zip_code_2", "-")}`}
                          </li>
                      ) : (
                          <li>-</li>
                      )}
                    </ul>
                  </div>
                </div>
            ) : ''}
          </div>
        </div>
      </div>
    );
}


export default AddressInformationDashboard;
