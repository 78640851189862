import React, { Component } from "react";
import { Image as Images } from "../Images";
import "./canvas.css";
import { Button } from "antd";
import SignatureCanvas from "react-signature-canvas";
import "./canvas.css";

class SignatureSample extends Component {
  img1 = React.createRef();
  canvas1 = React.createRef();
  signature = React.createRef();

  componentDidMount() {
    let c = this.signature.current;
    let ctx = c.refs.cv.getContext("2d");
    let image = new Image();
    image.src = Images.screen_shot_2;
    image.onload = () => {
      c.width = image.width;
      c.height = image.height;
      ctx.drawImage(image, 0, 0);
    };
  }

  toImage = () => {
    let base64String = this.signature.current.toDataURL();
    let c = this.canvas1.current;
    let ctx = c.getContext("2d");
    ctx.fillStyle = "#FF0000";
    let image = new Image();
    image.src = base64String;
    image.onload = () => {
      c.width = image.width;
      c.height = image.height;
      ctx.drawImage(image, 0, 0);
    };
  };

  render() {
    return (
      <div className="row mx-0">
        <div className="col-sm-6 col-12 position-relative">
          <img
            alt=""
            ref={this.img1}
            src={Images.screen_shot_1}
            style={{ display: "none" }}
          />
          <SignatureCanvas
            ref={this.signature}
            canvasProps={{
              width: `500`,
              height: `280`,
              className: "sigCanvas",
            }}
          />
          <Button
            className="submit-sign"
            type="primary"
            onClick={() => this.toImage()}
          >
            Submit Signature
          </Button>
        </div>
        <div className="col-sm-6 col-12">
          <canvas className="generated-canvas" ref={this.canvas1} />
        </div>
      </div>
    );
  }
}

export default SignatureSample;
