import React, { Component } from "react";
import { Button, Form, Input, InputNumber, message, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { addCoBuyer } from "../../controller/api/customerAPI";
import OtherDocumentModal from "../modal/OtherDocumentModal";
import { connect } from "react-redux";
import { Image as Images } from "../Images";
import {
  onNumberBlurred,
  onNumberFocused,
  phoneValidationPattern,
} from "../../controller/Global";
import { withTranslation, Trans } from "react-i18next";

class AddCoBuyer extends Component {
  state = {
    visible: false,
    formFilled: false,
    errorModal: false,
  };
  formRef = React.createRef();
  showCoBuyer = (visible) => {
    this.setState({
      visible: visible,
    });
  };
  handleSubmit = (values) => {
    if (this.props.user.email === values.email) {
      this.setState({ errorModal: true });
      return;
    }
    let id = this.props.match.params.trans_id;
    values.email = values.email.toLowerCase();
    addCoBuyer(id, values)
      .then(() => {
        this.showCoBuyer(true);
      })
      .catch((err) => {
        if (err.response) {
          Object.keys(err.response.data).forEach((e) => {
            message.error(`${e}: ${err.response.data[e]}`);
          });
        }
      });
  };

  onFormChange = (allFields) => {
    if (allFields.every((item) => !!item.value))
      this.setState({ formFilled: true });
    else this.setState({ formFilled: false });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="row mx-0 main-div-height">
              <div className="col-12 px-0">
                <div className="new-customer-heading mb-3 font-weight-bold w-100">
                  {t('coBuyerInformation.coBuyerFormStepTwo.formTitle')}
                </div>
                <div className="col-12 p-0">
                  <Form
                    ref={this.formRef}
                    onFinish={this.handleSubmit}
                    onFieldsChange={(changedFields, allFields) => {
                      this.onFormChange(allFields);
                    }}
                    className="w-100 my-5"
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-6">
                        <label className="font-weight-bold">
                          {t('coBuyerInformation.coBuyerFormStepTwo.firstNameLabel')} *
                        </label>
                        <Form.Item
                            name="first_name"
                            label={""}
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              autoFocus
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-6">
                        <label className="font-weight-bold">
                          {t('coBuyerInformation.coBuyerFormStepTwo.lastNameLabel')} *
                        </label>
                        <Form.Item
                            name="last_name"
                            label={""}
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              placeholder=" "
                              className="inp-form-wrap"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-12 col-lg-6">
                        <label className="font-weight-bold">
                          {t('coBuyerInformation.coBuyerFormStepTwo.emailLabel')}  *
                        </label>
                        <Form.Item
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                            ]}
                            className={
                              "group-floating-label group-floating-label-second"
                            }
                        >
                          <Input
                              autoCapitalize={"off"}
                              placeholder=""
                          />
                        </Form.Item>
                      </div>
                      <div id={"phone"} className="col-12 col-sm-12 col-lg-6">
                        <label className="font-weight-bold">
                          {t('coBuyerInformation.coBuyerFormStepTwo.cellPhoneNumberLabel')}  *
                        </label>
                        <Form.Item
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "this field is required",
                              },
                              {
                                pattern: phoneValidationPattern,
                                message: "Minimum digit should be 10",
                              },
                            ]}
                            className={
                              "group-floating-label select-float group-floating-label-second"
                            }
                        >
                          <InputNumber
                              formatter={(value) =>
                                  `${value}`
                                      .match(/\d*/g)
                                      .join("")
                                      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                      .slice(1)
                                      .join("-")
                                      .replace(/-*$/g, "")
                              }
                              parser={(value) => value.replace(/\$\s?|(-*)/g, "")}
                              onFocus={() => onNumberFocused("phone")}
                              onBlur={() => onNumberBlurred("phone")}
                              placeholder=""
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <p className="font-weight-normal">
                      <Trans i18nKey="coBuyerInformation.coBuyerFormStepTwo.contentOne" />
                    </p>
                    <p className="font-weight-normal">
                      <Trans i18nKey="coBuyerInformation.coBuyerFormStepTwo.contentTwo" />

                    </p>
                    <div className="steps-action flex-align-center justify-content-between w-100">
                      <Button
                        style={{ marginRight: "11%" }}
                        onClick={() => this.props.prev()}
                      >

                        {t('utils.back')}
                      </Button>
                      <Button
                        type="primary"
                        disabled={!this.state.formFilled}
                        htmlType="submit"
                      >
                        {t('utils.sendRequest')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.errorModal}
          onOk={() => {
            this.formRef.current.setFieldsValue({ email: "" });
            this.setState({ errorModal: false });
          }}
          title={false}
          maskClosable={false}
          className={`main-modal exit-modal-back`}
          width="33.889%"
          centered
          footer={[
            <Button
              className={"w-100"}
              key="submit"
              type={"primary"}
              onClick={() => {
                this.formRef.current.setFieldsValue({ email: "" });
                this.setState({ errorModal: false });
              }}
            >
              {t('coBuyerInformation.popModal.inputAnotherEmail')}
            </Button>,
          ]}
        >
          <div className="row modal-inner-row-data">
            <div className="col-12 text-center">
              <img
                alt="images"
                className="img-fluid"
                src={Images.error_prompt_img}
              />
              <p
                className={`font-weight-bold text-capitalize mb-2 text-color-red`}
              >
                {t('coBuyerInformation.popModal.warningMessage')}
              </p>
              <small className="d-inline-block w-100">
                {t('coBuyerInformation.popModal.inputAnotherEmail')}
              </small>
            </div>
          </div>
        </Modal>
        <OtherDocumentModal
          visible={this.state.visible}
          onClose={() => this.showCoBuyer(false)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default withTranslation()(connect(mapStateToProps)(withRouter(AddCoBuyer)));
