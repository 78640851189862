import React, { Component } from "react";
import { Button, Modal } from "antd";
import { Image as Images } from "../Images";
class NintyDaysExceedModal extends Component {
  render() {
    return (
      <Modal
        // onCancel={""}
        title={""}
        onOk={""}
        width={"600px"}
        height={"400px"}
        open={this.props.visible}
        className="prompt-modal close-btn-none pay-cash-confirm-modal ninty-days-exceed-modal"
        footer={false}
        maskClosable={false}
        centered
      >
        <div className="row prompt-modal-row">
          <div className="col-12 text-center">
            <img
              alt="icon question mark"
              className="img-fluid"
              src={Images.error_prompt_img}
            />
            <h4>Transaction limit exceeded</h4>
            <h5>
              We enable users to create a new transaction every 90 days from the
              last transaction creation date. If you wish to make any changes,
              please refer to your current active transaction.
            </h5>
            <div className="row mx-0 align-items-center justify-content-between">
              <Button
                onClick={() => {
                  this.props.onClose();
                }}
                type="danger"
                className="ninty-days-exceed-ok-btn"
              >
                {"OK"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default NintyDaysExceedModal;
