import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

export default class HelpDeskPage extends Component {
  render() {
    return(
      <>
        <Helmet>
          <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=f133c502-64a6-42ac-b381-5efa07a9f590"> </script>
        </Helmet>
      </>
    )
  }
}