import React, { useState, useEffect, useRef } from "react";
import {Button, message, Spin} from "antd";
import { addLicense, updateLicense } from "../controller/api/customerAPI";
import { history } from "../controller/history";
import { routes } from "../controller/routes";
import { connect } from "react-redux";
import SideBar from "./sidebar/SideBar";
import { Image as Images } from "./Images";
import {
  confirm_status, methods,
} from "../controller/Global";
import PopConfirm from "./modal/PopConfirm";
import CaptureDoc from "./Atoms/CaptureDoc/CaptureDoc";
import IdentityDocForm from "./Atoms/IdentityDocForm/IdentityDocForm";
import {useTranslation} from "react-i18next";
import SendLink from "./Atoms/SendLink/SendLink.tsx";
import mime from "mime-types";
import moment from "moment";


export const LicenceAdd = (props) => {
  const formRef = useRef(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [hideSidebar, setHideSideBar] = useState(false);
  const [onRevokeObjectURL, setOnRevokeObjectURL] = useState(false);
  const [selectedIssued, setSelectedIssued] = useState(null);
  const [selectedExpiry, setSelectedExpiry] = useState(null);
  const [selectedDob, setSelectedDob] = useState(null);
  const [popConfirm, setPopConfirm] = useState("")
  const [popVisible, setPopVisible] = useState(false)
  const { t } = useTranslation();
  const [isLoading ,setIsLoading] = useState(false);


  const [userInformation, setUserInformation] = useState({
    license_front: null,
    license_back: null,
    expiry_date: {
      day: null,
      month: null,
      year: null,
    },
    dob: {
      day: null,
      month: null,
      year: null,
    },
    first_name: null,
    last_name: null,
    issued_date: {
      day: null,
      month: null,
      year: null,
    },
    id_number: null,
    sex: null,
    height: null,
    eye_color: null,
  })

  const [validAddress, setValidAddress] = useState({
    address: null,
    zip_code: null,
    state: null,
    city: null,
  })
  const { current_transaction } = props;

  const preFillForm = () => {

    // Pre fill form with current transaction data

    if (current_transaction?.license?.first_name !== null) {
      if (!userInformation.front_image && !userInformation.license_back) {
        setUserInformation({
          license_front: current_transaction?.license?.front_image && current_transaction.license.front_image,
          license_back: current_transaction?.license?.back_image && current_transaction.license.back_image,
        })
      }
      let array_dob = {
        year: current_transaction?.license?.dob.split("-")[0],
        month: current_transaction?.license?.dob.split("-")[1],
        day: current_transaction?.license?.dob.split("-")[2]
      }

      let array_expiry = {
        year: current_transaction?.license?.expiry_date.split("-")[0],
        month: current_transaction?.license?.expiry_date.split("-")[1],
        day: current_transaction?.license?.expiry_date.split("-")[2]
      }
      let array_issued = {
        year: current_transaction?.license?.issued_date?.split("-")[0],
        month: current_transaction?.license?.issued_date?.split("-")[1],
        day: current_transaction?.license?.issued_date?.split("-")[2]
      }
      setSelectedDob(array_dob);
      setSelectedExpiry(array_expiry);
      setSelectedIssued(array_issued);

      const expiryDate = `${array_expiry?.month}/${array_expiry?.day}/${array_expiry?.year}`;
      const dateOfBirth = `${array_dob?.month}/${array_dob?.day}/${array_dob?.year}`;
      const issuedDate = `${array_issued?.month}/${array_issued?.day}/${array_issued?.year}`;


          formRef.current.setFieldsValue({
        expiry_date: moment(expiryDate, "MM/DD/YYYY").isValid()
          ? moment(expiryDate, "MM/DD/YYYY")
          : null,
        dob: moment(dateOfBirth, "MM/DD/YYYY").isValid()
          ? moment(dateOfBirth, "MM/DD/YYYY")
          : null,
        document_type: current_transaction?.license?.document_type,
        id_number: current_transaction?.license?.id_number,
        id_state: current_transaction?.license?.id_state,
        first_name: current_transaction?.license?.first_name,
        middle_name: current_transaction?.license?.middle_name,
        last_name: current_transaction?.license?.last_name,
        issued_date: moment(issuedDate, "MM/DD/YYYY").isValid()
          ? moment(issuedDate, "MM/DD/YYYY")
          : null,
        sex: current_transaction?.license?.sex,
        suffix: current_transaction?.license?.suffix,
        license_front: current_transaction?.license?.document[0],
        license_back: current_transaction?.license?.document[1],
        suite: current_transaction?.license?.suite,
        address: current_transaction?.license?.address,
        zip_code: current_transaction?.license?.zip_code,
        state: current_transaction?.license?.state,
        city: current_transaction?.license?.city,
        eye_color: current_transaction?.license?.eye_color,
        height: current_transaction?.license?.height,
      });
    }
  }

  const preFillFormNewUploadId = () => {
    const {
      license_front,
      license_back,
      expiry_date,
      dob,
      issued_date,
      first_name
    } = userInformation;

    if (license_front && license_back && first_name) {
      setSelectedDob(dob);
      setSelectedExpiry(expiry_date);
      setSelectedIssued(issued_date);

      const expiryDate = `${expiry_date?.month}/${expiry_date?.day}/${expiry_date?.year}`
      const dateOfBirth = `${dob?.month}/${dob?.day}/${dob?.year}`
      const issuedDate = `${issued_date?.month}/${issued_date?.day}/${issued_date?.year}`

      formRef.current.setFieldsValue({
        ...userInformation,
        expiry_date: moment(expiryDate, "MM/DD/YYYY").isValid()
            ? moment(expiryDate, "MM/DD/YYYY")
            : null,
        dob: moment(dateOfBirth, "MM/DD/YYYY").isValid()
            ? moment(dateOfBirth, "MM/DD/YYYY")
            : null,
        issued_date: moment(issuedDate, "MM/DD/YYYY").isValid()
            ? moment(issuedDate, "MM/DD/YYYY")
            : null,
      });
    }
  }

  const handleSubmit = () => {

    console.log('here submit')
    setBtnLoading(true);
    const values = formRef.current.getFieldsValue();
    const { license_front, license_back } = userInformation;
    const { params } = props.match;

    if (moment(`${selectedDob?.month}/${selectedDob?.day}/${selectedDob?.year}`, "MM/DD/YYYY").isValid()) {
      values.dob = new Date(
        `${selectedDob.year}-${selectedDob.month}-${selectedDob.day}`
      ).toISOString();
    }
    if (moment(`${selectedExpiry?.month}/${selectedExpiry?.day}/${selectedExpiry?.year}`, "MM/DD/YYYY").isValid()) {
      values.expiry_date = new Date(
        `${selectedExpiry.year}-${selectedExpiry.month}-${selectedExpiry.day}`
      ).toISOString();
    }
    if (moment(`${selectedIssued?.month}/${selectedIssued?.day}/${selectedIssued?.year}`, "MM/DD/YYYY").isValid()) {
      values.issued_date = new Date(
        `${selectedIssued.year}-${selectedIssued.month}-${selectedIssued.day}`
      ).toISOString();
    }


    formRef.current
      .validateFields()
      .then((values) => {
        let form_data = new FormData();
        if(typeof license_front !== 'string' && typeof license_back !== 'string' ) {
          if (params.method === methods.add) {
            form_data.append("license_front", license_front, `image1.${mime.extension(license_front.type)}`);
            form_data.append("license_back", license_back, `image2.${mime.extension(license_back.type)}`);
          } else {
            console.log('edit')
            form_data.append("front_image", license_front, `image1.${mime.extension(license_front.type)}`);
            form_data.append("back_image", license_back,`image2.${mime.extension(license_back.type)}`);
          }
        }


        values.dob = `${selectedDob.year}-${selectedDob.month}-${selectedDob.day}`;
        values.expiry_date = `${selectedExpiry.year}-${selectedExpiry.month}-${selectedExpiry.day}`;
        if (moment(`${selectedIssued?.month}/${selectedIssued?.day}/${selectedIssued?.year}`, "MM/DD/YYYY").isValid()) {
          values.issued_date = `${selectedIssued.year}-${selectedIssued.month}-${selectedIssued.day}`;
        }

        if (values.height) {
          // doing manipulations in height
          values.height = values.height.replace("'", ".");
          values.height = values.height.replace('"', "");
          values.height = Number(values.height);
        }

        for (let key in values) {
          values[key] && form_data.append(key, values[key]);
        }
        form_data.append("transaction", params.trans_id);
        form_data.append("doc_verified", "");

        if (license_front && license_back) {
          if (params.method === methods.edit) {
            const id = current_transaction?.license?.id ? current_transaction?.license?.id : params.id
            updateLicense(form_data, id)
              .then(() => {
                history.push(routes.dashboard.self);
                setBtnLoading(false)
              })
              .catch((err) => {
                setBtnLoading(false)
                if (err.response) {
                  Object.keys(err.response.data).forEach((e) => {
                    message.error(err.response.data[e]);
                  });
                }
              })
              .finally(() => {
                setBtnLoading(false)
                setOnRevokeObjectURL(true)
              });
          } else {
            addLicense(form_data)
              .then(() => {
                history.push(routes.dashboard.self);
                setBtnLoading(false)
              })
              .catch((err) => {
                setBtnLoading(false)
                if (err.response) {
                  Object.keys(err.response.data).forEach((e) => {
                    message.error(err.response.data[e]);
                  });
                }
              })
              .finally(() => {
                setBtnLoading(false)
                setOnRevokeObjectURL(true)
              });
          }
        } else {
          console.log("license does not exist")
          message.error("Both front and back sides required.");
          setBtnLoading(false)
        }
      })
      .catch(() => {
        setBtnLoading(false)
      });
  };

  const handleExit = (visible, exit = false) => {
    if (exit) {
      setPopConfirm(confirm_status.exit)
      setPopVisible(visible)
    } else {
      setPopVisible(visible)
    }
  };
  const get_message = () => {
    if (popConfirm === confirm_status.add) {
      return "ID Added!";
    } else if (popConfirm === confirm_status.edit) {
      return "ID Updated!";
    } else {
      return "Exit Adding ID Information ?";
    }
  };

  const updateWindowSize = () => {
    if(window.innerWidth < 760 && current_transaction) {
      setHideSideBar(true);
    } else {
      setHideSideBar(false);
    }
  };

  useEffect(() => {
    preFillFormNewUploadId()
  }, [userInformation]);

  useEffect(() => {
    preFillForm();
    updateWindowSize()
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_transaction])


  return (
    <React.Fragment>
      {hideSidebar ? null : <SideBar />}

      <div className="container-fluid mt-66 main-inner-fluid">
        <div className="container">
          <div className="row mx-0 h-100 dashboard-main-inner-row">
            <div className="col-12 h-100 dashboard-inner-div p-0">
              <div className="right-side-div-main h-100">
                <div className="row mx-0">
                  <div className="col-12">
                    <div className="row heading-fix-div"
                    >
                      <div className="col-12 position-relative transition-info-div">
                        <Button
                          className="bg-transparent position-absolute border-0 p-0 back-btn-main"
                          onClick={() => handleExit(true, true)}
                        >
                          <div className="back-btn-img-div">
                            <img
                              alt=""
                              className="img-fluid back-btn-black"
                              src={Images.back_btn_black}
                            />
                            <img
                              alt=""
                              className="img-fluid back-btn-primary"
                              src={Images.arrow_left_primary}
                            />
                          </div>
                          <span>Back</span>
                        </Button>
                        <span className="d-inline-block w-100 text-center">
                          {t('IdInformation.IdInformationForm.Title')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 add-new-customer-div mt-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="col-12 transition-information-div ">
                          <div className="transition-information-heading-container">
                            <div className="transition-information-heading font-weight-bold">
                              {t('IdInformation.IdInformationForm.Title')}
                            </div>
                            {props?.current_transaction?.dealership.id &&
                                <SendLink transId={props.match.params.trans_id}
                                          dealershipId={props.current_transaction.dealership.id}/>
                            }
                          </div>

                          <p className="font-weight-normal">
                            {t('IdInformation.IdInformationForm.contentOne')}
                          </p>
                          <p>
                            {t('IdInformation.IdInformationForm.contentTwo')}
                          </p>
                          <div className="mg-20">
                            <CaptureDoc
                                setHideSideBar={setHideSideBar}
                                userInformation={userInformation}
                                setUserInformation={setUserInformation}
                                setValidAddress={setValidAddress}
                                onRevokeObjectURL={onRevokeObjectURL}
                                licenseFrontExist={current_transaction?.license?.front_image || null}
                                licenseBackExist={current_transaction?.license?.back_image || null}
                                setIsLoading={setIsLoading}
                            />
                          </div>
                          { isLoading ? (
                              <div className="col-12 text-center spiner">
                                <Spin size="large"/>
                              </div>
                          ) : (
                          <IdentityDocForm
                              formRef={formRef}
                              handleSubmit={handleSubmit}
                              selectedIssued={selectedIssued}
                              setSelectedIssued={setSelectedIssued}
                              selectedExpiry={selectedExpiry}
                              setSelectedExpiry={setSelectedExpiry}
                              selectedDob={selectedDob}
                              setSelectedDob={setSelectedDob}
                          />
                          )}
                        </div>
                        <div className="steps-action flex-align-center justify-content-end w-100">
                          <Button
                            loading={btnLoading}
                            htmlType="submit"
                            type="primary"
                            onClick={() => handleSubmit()}
                          >
                            {t('utils.save')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopConfirm
        visible={popVisible}
        user={props.user}
        exit={popConfirm === confirm_status.exit}
        handleExit={() => handleExit(false)}
      >
        {get_message()}
      </PopConfirm>
    </React.Fragment>
  );

}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    current_transaction: state.current_transaction,
    transactions: state.transactions.transactions,
  };
};

export default connect(mapStateToProps)(LicenceAdd);
