import { TOGGLE_SIDEBAR } from "../actions/toggleAction";

const initialState = {
  showSidebar: true,
};
// eslint-disable-next-line
export const sideBarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_SIDEBAR: {
      return { ...state, showSidebar: !state.showSidebar };
    }
    default: {
      return state;
    }
  }
};
