import React, { Component } from "react";
import { Modal } from "antd";
import { history } from "../../controller/history";
import { routes } from "../../controller/routes";
import { Image as Images } from "../Images";
import { withTranslation } from "react-i18next";

class OtherDocumentModal extends Component {
  render() {
    const { t } = this.props;
    return (
      <Modal
        title={false}
        open={this.props.visible}
        onOk={() => history.push(routes.dashboard.self)}
        onCancel={this.props.onClose}
        className="main-modal close-btn-none add-transition-confirm"
        okText={t('coBuyerInformation.popModal.returnToTransactionButton')}
        width="31%"
        centered
      >
        <div className="row modal-inner-row-data">
          <div className="col-12 text-center">
            <img
              src={Images.check_icon_rounded}
              className="img-fluid"
              alt="check icon"
            />
            <p className="mb-0 mt-1 font-weight-bold">
              {t('coBuyerInformation.popModal.emailSentToCoBuyerMessage')}
            </p>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(OtherDocumentModal);
